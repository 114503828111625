import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login'
import _ from 'lodash'

import apiKeys from '../../config/apiKeys'

import GmailSvg from '../../assets/img/social/gmailSvg.svg'
import FacebookSvg from '../../assets/img/social/facbookSvg.svg'

export default (props) => {
    const { t, i18n } = useTranslation()

    const facebookCallback = async (response) => {
        let userDetails = {}
        let country = null
        if (response?.country) {
            country = props.countries?.find((c) => c.name === response?.country).iso2_code
        }
        if (response && response.email) {
            let nameArr = response.name.split(' ')
            let firstName = _.first(nameArr)
            let lastName = _.last(nameArr)
            userDetails = {
                fname: firstName,
                lname: lastName,
                email: response.email,
                password: response.id,
                country: country,
                code: country,
                loginVia: 'facebook',
            }
            props.onSocialAuthComplete(userDetails)
        }
    }

    const googleCallback = async (response) => {
        let userDetails = {}
        if (response && response.profileObj && response.profileObj.googleId) {
            let profile = response.profileObj
            userDetails = {
                fname: profile.givenName,
                lname: profile.familyName,
                email: profile.email,
                password: profile.googleId,
                loginVia: 'google',
            }
        }
        props.onSocialAuthComplete(userDetails)
    }

    return (
        <div className="row mr-0 ml-0  text-center">
            <div className="col-12 col-md-6 mt-3 w-100 pl-1 pr-1">
                <GoogleLogin
                    clientId={apiKeys.googleClientID}
                    buttonText="Login"
                    onSuccess={googleCallback}
                    onFailure={(responseGoogle) => console.log('on failure - ', responseGoogle)}
                    cookiePolicy={'single_host_origin'}
                    render={(renderProps) => (
                        <button
                            className="btn google-bg w-100 row justify-content-around align-items-center"
                            onClick={renderProps.onClick}
                        >
                            <img className="ml-2 mr-2" src={GmailSvg} alt="Continue with google" />

                            <span className="font-16-500 white-color">{t('Continue-with-google')}</span>
                        </button>
                    )}
                />
            </div>
            <div className="col-12 col-md-6 mt-3 w-100 pl-1 pr-1">
                <FacebookLogin
                    appId={apiKeys.facebookAppID}
                    autoLoad={false}
                    fields="name,email,picture,country"
                    scope="public_profile,email"
                    onClick={() => {
                        console.log('login clicked')
                    }}
                    callback={facebookCallback}
                    render={(renderProps) => (
                        <button
                            className="btn fb-bg w-100 row justify-content-around align-items-center"
                            onClick={renderProps.onClick}
                        >
                            <img className="ml-2 mr-2" src={FacebookSvg} alt="Continue with facebook" />
                            <span className="font-16-500 white-color">{t('Continue-with-facebook')}</span>
                        </button>
                    )}
                />
            </div>
        </div>
    )
}
