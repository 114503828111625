import React, { useState, useEffect, useRef } from 'react'

import { Form, InputGroup, Button, Row, Col } from 'react-bootstrap'
import { FaSearch } from 'react-icons/fa'
import _ from 'lodash'
import qs from 'query-string'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from '../../../contexts/localStorage'
import { PARCEL_CONSTANTS } from '../../../constants/constants'
import { useAuth } from '../../../contexts/AuthContext'
import { getData } from '../../../utils/api'

export default (props) => {
    const { t, i18n } = useTranslation()

    const queryParams = qs.parse(window.location.search)
    const [uuid, setUuid] = useState(queryParams.uuid || '')
    const [from, setFrom] = useState(queryParams.from || null)
    const [to, setTo] = useState(queryParams.to || null)
    const { warehouseId, authToken } = useAuth()
    const fromRef = useRef(null)
    const toRef = useRef(null)
    const uuidRef = useRef(null)

    const onParcelIDChange = (event) => {
        let value = event.target.value
        setUuid(value)
        if (value && value !== '') {
            props.addQueryParam('uuid', value)
        } else {
            props?.removeQueryParam('uuid')
            document?.getElementById('dashboard-search-back')?.click()
        }
    }

    // const search = async () => {
    // 	let filteredData = [...(props?.parcelData)];
    // 	if (uuid && uuid !== "") {
    // 		filteredData = filteredData?.filter(fd =>
    // 			fd?.uuid?.toLowerCase() === uuid?.toLowerCase() || fd?.awb?.toLowerCase() === uuid?.toLowerCase()
    // 			|| fd?.uuid?.toLowerCase() === uuid?.split("-")[0].toLowerCase()
    // 		);
    // 		if (filteredData?.length === 0) {
    // 			let allParcelsFromApi = props?.allParcels
    // 			try {
    // 				props?.setLoading(true)
    // 				let requestUrl = `/v2/parcels?key=all&warehouse_id=${warehouseId}`
    // 				const response = await getData(
    // 					"parcelService",
    // 					requestUrl,
    // 					authToken
    // 				);
    // 				allParcelsFromApi =
    // 					!response.status || response.error ? allParcelsFromApi : response.payload.parcels;
    // 				props?.setLoading(false)
    // 			} catch (error) {
    // 				console.log(error)
    // 				props?.setLoading(false)
    // 			}
    // 			let parcel = allParcelsFromApi.find(fd =>
    // 				fd?.uuid?.toLowerCase() === uuid?.toLowerCase() || fd?.awb?.toLowerCase() === uuid?.toLowerCase()
    // 			);
    // 			if (props?.category !== PARCEL_CONSTANTS.ALL) {
    // 				filteredData = props?.parcelData?.filter(parent => parent?.id === parcel?.parent_id)
    // 			} else {
    // 				filteredData = allParcelsFromApi?.filter(p => p?.id === parcel?.id)
    // 			}
    // 		}
    // 	}

    // 	if (from || to) {
    // 		let toDate = to, fromDate = from;

    // 		if (!toDate || toDate === "") {
    // 			toDate = new Date();
    // 			setTo(toDate);
    // 		}

    // 		toDate = new Date(toDate).getTime();

    // 		if (toDate && toDate !== "") {
    // 			filteredData = _.filter(filteredData, (data) => {
    // 				let updatedAt = new Date(data.updated_at).getTime();
    // 				switch (props?.category) {
    // 					case PARCEL_CONSTANTS.ALL:
    // 						const recDate = data?.ParcelLogs?.find(ps => Number(ps?.parcel_status_id) === PARCEL_CONSTANTS.RECEIVED_PARCEL_STATUS_ID)?.created_at
    // 						updatedAt = new Date(recDate).getTime();
    // 						break;
    // 					case PARCEL_CONSTANTS.ACTIVE:
    // 					case PARCEL_CONSTANTS.READY_PENDING:
    // 						const dispDate = data?.dispatched_date || data?.ParcelLogs?.find(ps => Number(ps?.parcel_status_id) === PARCEL_CONSTANTS.DISPATCHED_PARCEL_STATUS_ID)?.created_at
    // 						updatedAt = new Date(dispDate).getTime();
    // 						break;
    // 					case PARCEL_CONSTANTS.DELIVERED:
    // 						const delDate = data?.delivery_date || data?.ParcelLogs?.find(ps => Number(ps?.parcel_status_id) === PARCEL_CONSTANTS.DELIVERED_PARCEL_STATUS_ID)?.created_at
    // 						updatedAt = new Date(delDate).getTime();
    // 						break;
    // 					default:
    // 						break;
    // 				}
    // 				return !fromDate || fromDate === "" ? (updatedAt <= toDate)
    // 					: ((updatedAt <= toDate) && (updatedAt >= new Date(fromDate).getTime()))
    // 			});
    // 		}
    // 	}
    // 	// if (props?.category === PARCEL_CONSTANTS.ALL) {
    // 	// props?.setCounts({ ...props?.counts, [props?.category]: filteredData?.length })
    // 	// }
    // 	props.setParcelData([...filteredData]);
    // };

    const search = () => {
        props?.setReload(!props?.reload)
    }

    useEffect(() => {
        props.addQueryParam('to', to)
    }, [to])

    useEffect(() => {
        props.addQueryParam('from', from)
    }, [from])

    // useEffect(() => {
    // 	if (storedValue) {
    // 		search();
    // 	}
    // }, [])

    useEffect(() => {
        if (!queryParams.uuid || queryParams.uuid === '') {
            setUuid('')
            uuidRef.current.value = ''
        }
        if (!queryParams.from || queryParams.from === null) {
            fromRef.current.value = ''
        }
        if (!queryParams.to || queryParams.to === null) {
            toRef.current.value = ''
        }
    }, [queryParams.uuid, queryParams.from, queryParams.to])

    return (
        <Col sm={12} md={10} className="px-1">
            <div className="parcel-search-form w-100">
                <div className="search-row">
                    <Col md={'3'} sm={'12'} className="search-col">
                        <InputGroup>
                            <Form.Control
                                defaultValue={uuid}
                                ref={uuidRef}
                                type="text"
                                name="uuid"
                                placeholder={t('Type your parcel ID')}
                                onChange={onParcelIDChange}
                            />
                            <InputGroup.Append>
                                <span className="input-group-text tr-cursor" onClick={search}>
                                    <FaSearch />
                                </span>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                    <Col md={'3'} sm={'12'} className="block-margin-left search-col">
                        <InputGroup>
                            <Form.Control
                                type="date"
                                name="from"
                                className="form-control-line-height"
                                placeholder="From"
                                defaultValue={from}
                                ref={fromRef}
                                onChange={(e) => {
                                    if (e.target.value && e.target.value !== '') {
                                        setFrom(e.target.value)
                                    } else {
                                        document?.getElementById('dashboard-search-back')?.click()
                                    }
                                }}
                            />
                        </InputGroup>
                    </Col>
                    <Col md={'3'} sm={'12'} className="block-margin-left search-col">
                        <InputGroup>
                            <Form.Control
                                type="date"
                                name="to"
                                className="form-control-line-height"
                                placeholder="To"
                                defaultValue={to}
                                ref={toRef}
                                onChange={(e) => {
                                    if (e.target.value && e.target.value !== '') {
                                        setTo(e.target.value)
                                    } else {
                                        document?.getElementById('dashboard-search-back')?.click()
                                    }
                                }}
                            />
                        </InputGroup>
                    </Col>
                    <Col md={'3'} sm={'12'} className="block-margin-left search-col">
                        <Button type="submit" className="w-100" onClick={search}>
                            {t('Search')}
                        </Button>
                    </Col>
                </div>
            </div>
        </Col>
    )
}
