import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { REGISTRATION_FORM } from '../../../constants/navigationConstants'
import { MEMBERSHIP_CONSTANTS } from '../../../constants/constants'
import { Markup } from 'interweave'
import vertLogoAmcham from './vert-logo.png'
import freeYear from './free-year.png'
import i18next from 'i18next'
import { useAuth } from '../../../contexts/AuthContext'

function Plans() {
    const { t } = useTranslation()
    const { setDefaultPromoCode } = useAuth()
    const handleSignUpClick = async (plan) => {
        if (plan === MEMBERSHIP_CONSTANTS.PREMIUM) {
            await setDefaultPromoCode(process?.env?.REACT_APP_FREE_SUB_OFFER_CODE_PREMIUM_AMCHAM)
        } else if (plan === MEMBERSHIP_CONSTANTS.BUSINESS) {
            await setDefaultPromoCode(process?.env?.REACT_APP_FREE_SUB_OFFER_CODE_BUSINESS_AMCHAM)
        }
    }

    return (
        <div className="main-section-container mb-4 w-100 d-flex flex-column text-center">
            <div className="display-over-md container-fluid container-width">
                <div className="row justify-content-center align-items-center mt-5">
                    <div className="col-12 col-md-4 pl-2 pr-2">
                        <Link
                            to={{
                                pathname: REGISTRATION_FORM,
                                state: {
                                    membership_plan_id: MEMBERSHIP_CONSTANTS.BASIC,
                                },
                            }}
                            onClick={() => {
                                window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth',
                                })
                            }}
                        >
                            <div className="white-background pt-3 pb-3 mr-1 ml-1 pr-4 pl-4 membership-plan-component min-height-424">
                                <h2 className={'mt-1'}>{t('Basic')}</h2>
                                <p className="text-green promotion-price">{t('Free')}</p>
                                <div>
                                    <div className="promotion">
                                        <i className="bx bx-check-circle green_lbl"></i>
                                        <p className="d-inline mb-0">{t('Free address')}</p>
                                        <hr />
                                        <i className="bx bx-check-circle green_lbl"></i>
                                        <p className="d-inline mb-0">{t('Shipping Discount')}</p>
                                        <hr />
                                        <i className="bx bx-check-circle green_lbl"></i>
                                        <p className="d-inline mb-0">{t('Pay only per 100 g')}</p>
                                        <hr />
                                        <i className="bx bx-check-circle green_lbl"></i>
                                        <p className="d-inline">{t('1 product photo')}</p> <br />
                                        <br />
                                    </div>

                                    <button
                                        type="button"
                                        className="btn btn-primary color-inverse-default-button mb-3 mt-5 p-0"
                                    >
                                        {t('Select plan')}
                                    </button>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-12 col-md-4 pl-2 pr-2">
                        <Link
                            className="mt-3"
                            to={{
                                pathname: REGISTRATION_FORM,
                                state: {
                                    membership_plan_id: MEMBERSHIP_CONSTANTS.PREMIUM,
                                },
                            }}
                            onClick={() => {
                                handleSignUpClick(MEMBERSHIP_CONSTANTS.PREMIUM)
                                window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth',
                                })
                            }}
                        >
                            <div className="white-background pt-3 pb-3 mr-1 ml-1 pr-4 pl-4 membership-plan-component min-height-600">
                                <span
                                    className={`amcham-popular-highlighter ${i18next.language === 'ar' ? 'rtl-badge-position' : 'ltr-badge-position'} green-badge`}
                                >
                                    {t('Most popular')}
                                </span>
                                <img className="vert-logo-amcham " src={vertLogoAmcham} />
                                <h2 className={'mt-1'}>{t('Premium')}</h2>
                                <p className="text-grey d-inline promotion-price">$5</p>
                                <span className="blue-color">{t('per month')}</span>
                                <div>
                                    <span className="red font-16-500">* </span>
                                    <span className="font-16">
                                        <strong>{t('1 Year Free Premium Membership')}</strong>
                                    </span>
                                    <br />
                                    <p className="font-16-400">{t('For AmCham Dubai members')}</p>
                                    <div className="promotion">
                                        <i className="bx bx-check-circle green_lbl"></i>
                                        <p className="d-inline mb-0">
                                            <Markup content={t('5 Product Photo')} />
                                        </p>
                                        <hr />
                                        <i className="bx bx-check-circle green_lbl"></i>
                                        <p className="d-inline mb-0">
                                            <Markup content={t('15 days free storage')} />
                                        </p>
                                        <hr />
                                        <i className="bx bx-check-circle green_lbl"></i>
                                        <p className="d-inline mb-0">
                                            <Markup content={t('10 off first shipment')} />
                                        </p>
                                        <hr />
                                        <i className="bx bx-check-circle green_lbl"></i>
                                        <p className="d-inline">
                                            <Markup content={t('Package Consolidation')} />
                                        </p>
                                        <hr />
                                        <i className="bx bx-check-circle green_lbl"></i>
                                        <p className="d-inline">
                                            <Markup content={t('Package repacking')} />
                                        </p>{' '}
                                        <br />
                                    </div>
                                    <div className="mt-3 d-flex justify-content-center">
                                        <img src={freeYear} className="free-year-badge" />
                                    </div>

                                    <button type="button" className="btn btn-primary mb-3 mt-4">
                                        {t('Free Sign Up')}
                                    </button>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-12 col-md-4 pl-2 pr-2">
                        <Link
                            to={{
                                pathname: REGISTRATION_FORM,
                                state: {
                                    membership_plan_id: MEMBERSHIP_CONSTANTS.BUSINESS,
                                },
                            }}
                            onClick={() => {
                                handleSignUpClick(MEMBERSHIP_CONSTANTS.BUSINESS)
                                window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth',
                                })
                            }}
                        >
                            <div className="white-background pt-3 pb-3 mr-1 ml-1 pr-4 pl-4 membership-plan-component min-height-424">
                                <h2 className={'mt-1'}>{t('Business')}</h2>
                                <p className="text-grey d-inline promotion-price">$10</p>
                                <span className="blue-color">{t('per month')}</span>
                                <div>
                                    <div className="promotion">
                                        <i className="bx bx-check-circle green_lbl"></i>
                                        <p className="d-inline mb-0">
                                            <Markup content={t('30 days Free Storage')} />
                                        </p>
                                        <hr />
                                        <i className="bx bx-check-circle green_lbl"></i>
                                        <p className="d-inline mb-0">
                                            <Markup content={t('Special rates on Heavy Weights')} />
                                        </p>
                                        <hr />
                                        <i className="bx bx-check-circle green_lbl"></i>
                                        <p className="d-inline mb-0">
                                            <Markup content={t('Return Service')} />
                                        </p>
                                        <hr />
                                        <i className="bx bx-check-circle green_lbl"></i>
                                        <p className="d-inline">
                                            <Markup content={t('Insurance (goods and transit)')} />
                                        </p>
                                        <hr />
                                        <i className="bx bx-check-circle green_lbl"></i>
                                        <p className="d-inline">
                                            <Markup content={t('Regenerate Invoice')} />
                                        </p>{' '}
                                        <br />
                                    </div>

                                    <button
                                        type="button"
                                        className="btn btn-primary color-inverse-default-button mb-3 mt-4 p-0"
                                    >
                                        {t('Select plan')}
                                    </button>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="display-under-md container-fluid pr-0 pl-0">
                <div className="container-fluid container-width">
                    <ul className="nav nav-tabs justify-content-center mb-0 pl-0 pr-0" id="myTab" role="tablist">
                        <li className="col-4 col-sm-4 w-100 pr-0 pl-0 nav-item text-center ">
                            <a
                                className="nav-link plan-link w-100 "
                                id="basic-plan-tab"
                                data-toggle="tab"
                                href="#basic-plan-tab-content"
                                role="tab"
                                aria-controls="basic-plan-tab-content"
                                aria-selected="true"
                            >
                                <span>{t('Basic')}</span>
                            </a>
                        </li>
                        <li className="col-4 col-sm-4 w-100 pr-0 pl-0 nav-item text-center ">
                            <a
                                className="nav-link plan-link w-100 active white-background"
                                id="premium-plan-tab"
                                data-toggle="tab"
                                href="#premium-plan-tab-content"
                                role="tab"
                                aria-controls="premium-plan-tab-content"
                                aria-selected="false"
                            >
                                <span>{t('Premium')}</span>
                            </a>
                        </li>
                        <li className="col-4 col-sm-4 w-100 pr-0 pl-0 nav-item text-center">
                            <a
                                className="nav-link plan-link w-100 "
                                id="business-plan-tab"
                                data-toggle="tab"
                                href="#business-plan-tab-content"
                                role="tab"
                                aria-controls="business-plan-tab-content"
                                aria-selected="false"
                            >
                                <span>{t('Business')}</span>
                            </a>
                        </li>
                    </ul>

                    <div className="tab-content" id="myTabContent">
                        <div
                            className="tab-pane fade mt-4"
                            id="basic-plan-tab-content"
                            role="tabpanel"
                            aria-labelledby="basic-plan-tab"
                        >
                            <Link
                                to={{
                                    pathname: REGISTRATION_FORM,
                                    state: {
                                        membership_plan_id: MEMBERSHIP_CONSTANTS.BASIC,
                                    },
                                }}
                                onClick={() => {
                                    window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: 'smooth',
                                    })
                                }}
                            >
                                <div className="white-background pt-3 pb-3 membership-plan-component min-height-424">
                                    <h2>{t('Basic')}</h2>
                                    <p className="text-green promotion-price">{t('Free')}</p>
                                    <div>
                                        <div className="promotion">
                                            <i className="bx bx-check-circle green_lbl"></i>
                                            <p className="d-inline mb-0">{t('Free address')}</p>
                                            <hr />
                                            <i className="bx bx-check-circle green_lbl"></i>
                                            <p className="d-inline mb-0">{t('Shipping Discount')}</p>
                                            <hr />
                                            <i className="bx bx-check-circle green_lbl"></i>
                                            <p className="d-inline mb-0">{t('Pay only per 100 g')}</p>
                                            <hr />
                                            <i className="bx bx-check-circle green_lbl"></i>
                                            <p className="d-inline">{t('1 product photo')}</p> <br />
                                            <br />
                                        </div>

                                        <button
                                            type="button"
                                            className="btn btn-primary color-inverse-default-button mb-3 mt-5"
                                        >
                                            {t('Select plan')}
                                        </button>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div
                            className="tab-pane fade mt-4 show active white-background"
                            id="premium-plan-tab-content"
                            role="tabpanel"
                            aria-labelledby="premium-plan-tab"
                        >
                            <Link
                                to={{
                                    pathname: REGISTRATION_FORM,
                                    state: {
                                        membership_plan_id: MEMBERSHIP_CONSTANTS.PREMIUM,
                                    },
                                }}
                                onClick={() => {
                                    handleSignUpClick(MEMBERSHIP_CONSTANTS.PREMIUM)
                                    window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: 'smooth',
                                    })
                                }}
                            >
                                <div className="white-background pt-3 pb-3 position-relative membership-plan-component min-height-600">
                                    <span
                                        className={`amcham-popular-highlighter ${i18next.language === 'ar' ? 'rtl-badge-position' : 'ltr-badge-position'} green-badge`}
                                    >
                                        {t('Most popular')}
                                    </span>
                                    <img className="vert-logo-amcham" src={vertLogoAmcham} />
                                    <h2>{t('Premium')}</h2>
                                    <p className="text-grey d-inline promotion-price">$5</p>
                                    <span className="blue-color">{t('per month')}</span>
                                    <div>
                                        <span className="red font-16-500">* </span>
                                        <span className="font-16-500">{t('1 Year Free Premium Membership')}</span>
                                        <br />
                                        <p className="font-16-400">{t('For AmCham Dubai members')}</p>
                                        <div className="promotion mb-5">
                                            <i className="bx bx-check-circle green_lbl"></i>
                                            <p className="d-inline mb-0">
                                                <Markup content={t('5 Product Photo')} />
                                            </p>
                                            <hr />
                                            <i className="bx bx-check-circle green_lbl"></i>
                                            <p className="d-inline mb-0">
                                                <Markup content={t('15 days free storage')} />
                                            </p>
                                            <hr />
                                            <i className="bx bx-check-circle green_lbl"></i>
                                            <p className="d-inline mb-0">
                                                <Markup content={t('10 off first shipment')} />
                                            </p>
                                            <hr />
                                            <i className="bx bx-check-circle green_lbl"></i>
                                            <p className="d-inline">
                                                <Markup content={t('Package Consolidation')} />
                                            </p>
                                            <hr />
                                            <i className="bx bx-check-circle green_lbl"></i>
                                            <p className="d-inline">
                                                <Markup content={t('Package repacking')} />
                                            </p>{' '}
                                            <br />
                                        </div>
                                        <div className="mt-3 d-flex justify-content-center">
                                            <img src={freeYear} className="free-year-badge" />
                                        </div>

                                        <button type="button" className="btn btn-primary mb-3 mt-4">
                                            {t('Free Sign Up')}
                                        </button>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div
                            className="tab-pane fade mt-4"
                            id="business-plan-tab-content"
                            role="tabpanel"
                            aria-labelledby="business-plan-tab"
                        >
                            <Link
                                to={{
                                    pathname: REGISTRATION_FORM,
                                    state: {
                                        membership_plan_id: MEMBERSHIP_CONSTANTS.BUSINESS,
                                    },
                                }}
                                onClick={() => {
                                    handleSignUpClick(MEMBERSHIP_CONSTANTS.BUSINESS)
                                    window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: 'smooth',
                                    })
                                }}
                            >
                                <div className="white-background pt-3 pb-3 h-100 membership-plan-component min-height-424">
                                    <h2>{t('Business')}</h2>
                                    <p className="text-grey d-inline promotion-price">$10</p>
                                    <span className="blue-color">{t('per month')}</span>
                                    <div>
                                        <div className="promotion">
                                            <i className="bx bx-check-circle green_lbl"></i>
                                            <p className="d-inline mb-0">
                                                <Markup content={t('30 days Free Storage')} />
                                            </p>
                                            <hr />
                                            <i className="bx bx-check-circle green_lbl"></i>
                                            <p className="d-inline mb-0">
                                                <Markup content={t('Special rates on Heavy Weights')} />
                                            </p>
                                            <hr />
                                            <i className="bx bx-check-circle green_lbl"></i>
                                            <p className="d-inline mb-0">
                                                <Markup content={t('Return Service')} />
                                            </p>
                                            <hr />
                                            <i className="bx bx-check-circle green_lbl"></i>
                                            <p className="d-inline">
                                                <Markup content={t('Insurance (goods and transit)')} />
                                            </p>
                                            <hr />
                                            <i className="bx bx-check-circle green_lbl"></i>
                                            <p className="d-inline">
                                                <Markup content={t('Regenerate Invoice')} />
                                            </p>{' '}
                                            <br />
                                        </div>

                                        <button
                                            type="button"
                                            className="btn btn-primary color-inverse-default-button mb-3 mt-4"
                                        >
                                            {t('Select plan')}
                                        </button>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Plans
