import { now } from 'moment'

export const humanize = (str) => {
    if (!str) return str
    let i,
        frags = str.toLowerCase().split('_')
    for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
    }
    return frags.join(' ')
}

export const amountsProximating = (str) => {
    return parseInt(str)?.toFixed(2)
}

export const capitalizeFirstChar = (str) => {
    let updatedStr = ''
    if (str?.length > 0) {
        const firstChar = str[0]
        updatedStr = [firstChar.toUpperCase(), ...str?.slice(1, str?.length).toLowerCase()]?.join('')
    }
    return updatedStr
}

/**
 * Simulates selecting a certain text and then pressing ctrl+c (to copy)
 */
export const copyTextToClipboard = async (textToCopy) => {
    if (navigator.clipboard && window.isSecureContext) {
        // only available in https or localhost
        return navigator.clipboard.writeText(textToCopy)
    } else {
        // fallback to creating an invisible UI element and running
        // the copy command
        let textArea = document.createElement('textarea')
        textArea.value = textToCopy
        // make the textarea out of viewport
        textArea.style.position = 'fixed'
        textArea.style.left = '-999999px'
        textArea.style.top = '-999999px'
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()
        // copy then remove temp element
        document.execCommand('copy')
        textArea.remove()
    }
}

export const checkForLifeTimeSubscribtion = (expiryDate) => {
    const expiryYear = new Date(expiryDate).getFullYear()
    const currYear = new Date(now()).getFullYear()
    return Number(expiryYear) - Number(currYear) >= 5
}

export const checkStrValidity = (str, field) => {
    if (
        field !== 'password' &&
        /attack|fake|test|user|password|admin|root|dummy|demo|sample|fuck|shit|bitches|bitch|asshole|person|people|customer|customers|company|companies|account|accounts|operator|operators|administrator|administrators|buyer|buyers|seller|sellers|manager|managers|moderator|moderators/i.test(
            str,
        )
    ) {
        return { msg: "Shouldn't include test or bad words", status: false }
    } else if (['fname', 'lname'].includes(field)) {
        if (/[!@#$%^&*()_+\=\[\]{};':"\\|,<>\/?]+/.test(str)) {
            return { msg: "Shouldn't include special characters", status: false }
        } else if (/[0-9]/.test(str)) {
            return { msg: "Shouldn't include numbers", status: false }
        } else if (str?.length < 2) {
            return { msg: "Shouldn't be less than 2 characters", status: false }
        } else if (str?.length > 25) {
            return { msg: "Shouldn't be more than 25 characters", status: false }
        }
    } else if (field === 'email') {
        const email = str.split('@')[0]
        const domain = str.split('@')[1]
        if (email?.length > 50) {
            return { msg: "Email shouldn't be more than 50 characters", status: false }
        } else if (domain?.length > 30) {
            return { msg: "Domain shouldn't be more than 30 characters", status: false }
        }
    } else if (field === 'password') {
        const msgs = {
            '1lc': 'One lowercase letter',
            '1ul': 'One uppercase letter',
            passl: '8 characters minimum',
            '1n': 'One numeric character',
            '1sc': 'and 1 special character',
        }
        const validations = {}
        // if (str?.length < 6) {
        // 	return { msg: "6 characters minimum", status: false }
        // }
        // if (str?.length > 30) {
        // 	return { msg: "Shouldn't be more than 30 characters", status: false }
        // }
        if (!/(?=.*[a-z])/.test(str)) {
            validations['1lc'] = {
                msg: msgs['1lc'],
                status: false,
            }
        } else {
            validations['1lc'] = {
                msg: msgs['1lc'],
                status: true,
            }
        }

        if (!/(?=.*[A-Z])/.test(str)) {
            validations['1ul'] = {
                msg: msgs['1ul'],
                status: false,
            }
        } else {
            validations['1ul'] = {
                msg: msgs['1ul'],
                status: true,
            }
        }

        if (!/(?=.{8,30})/.test(str)) {
            validations['passl'] = {
                msg: msgs['passl'],
                status: false,
            }
        } else {
            validations['passl'] = {
                msg: msgs['passl'],
                status: true,
            }
        }

        if (!/(?=.*[0-9])/.test(str)) {
            validations['1n'] = {
                msg: msgs['1n'],
                status: false,
            }
        } else {
            validations['1n'] = {
                msg: msgs['1n'],
                status: true,
            }
        }

        if (!/(?=.*[!\?@_#\$%<>~:`\^&\*\-])/.test(str)) {
            validations['1sc'] = {
                msg: msgs['1sc'],
                status: false,
            }
        } else {
            validations['1sc'] = {
                msg: msgs['1sc'],
                status: true,
            }
        }

        return validations
    } else if (field === 'phone') {
        if (str?.length < 10) {
            return { msg: "Shouldn't be less than 10 digits", status: false }
        }
        if (str?.length > 20) {
            return { msg: "Shouldn't be more than 20 digits", status: false }
        }
    } else if (['address', 'address2'].includes(field)) {
        if (str?.length < 5) {
            return {
                msg:
                    field === 'address'
                        ? "Address line 1 shouldn't be less than 5 characters"
                        : "Address line 2 shouldn't be less than 5 characters",
                status: false,
            }
        }
        if (str?.length > 40) {
            return {
                msg:
                    field === 'address'
                        ? "Address line 1 shouldn't be more than 40 characters"
                        : "Address line 2 shouldn't be more than 40 characters",
                status: false,
            }
        }
        if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*[#,&\^\.])/.test(str)) {
            return {
                msg:
                    field === 'address'
                        ? "Address line 1 shouldn't include other than english alphapets "
                        : "Address line 2 shouldn't include other than english alphapets",
                status: false,
            }
        }
    }
    return { msg: null, status: true }
}

export const cc_format = (value) => {
    let v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
    let matches = v.match(/\d{4,16}/g)
    let match = (matches && matches[0]) || ''
    let parts = []

    for (let i = 0, len = match.length; i < len; i += 4) {
        parts.push(match.substring(i, i + 4))
    }

    if (parts.length) {
        return parts.join(' ')
    } else {
        return value
    }
}

export const calculateDifferenceInDays = (expiryDate) => {
    // const currentDate = new Date()
    // const expiryDate = new Date(expiryDate)
    // let month = minDate.getUTCMonth() + 1; //months from 1-12
    // // const year = minDate.getUTCFullYear();
    // let separator = "-"
    // if (Number(month) <= 9) {
    // 	separator = "-0"
    // }

    // const nowDate = year + separator + month
    // return nowDate
    // let startDate = new Date()
    // let endDate = new Date(expiryDate)
    // return (endDate.getMonth() -
    // startDate.getMonth() +
    // 12 * (endDate.getFullYear() - startDate.getFullYear()))
    let startDate = new Date()
    let endDate = new Date(expiryDate)
    let differenceInTime = endDate.getTime() - startDate.getTime()
    let differenceInDays = differenceInTime / (1000 * 3600 * 24)
    return Math.ceil(differenceInDays)
}

export const creditCardType = (cc) => {
    let amex = new RegExp('^3[47][0-9]{13}$')
    let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$')
    let cup1 = new RegExp('^62[0-9]{14}[0-9]*$')
    let cup2 = new RegExp('^81[0-9]{14}[0-9]*$')

    let mastercard = new RegExp('^5[1-5][0-9]{14}$')
    let mastercard2 = new RegExp('^2[2-7][0-9]{14}$')

    let disco1 = new RegExp('^6011[0-9]{12}[0-9]*$')
    let disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$')
    let disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$')

    let diners = new RegExp('^3[0689][0-9]{12}[0-9]*$')
    let jcb = new RegExp('^35[0-9]{14}[0-9]*$')

    if (visa.test(cc)) {
        return 'VISA'
    }
    if (amex.test(cc)) {
        return 'AMEX'
    }
    if (mastercard.test(cc) || mastercard2.test(cc)) {
        return 'MASTERCARD'
    }
    if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
        return 'DISCOVER'
    }
    if (diners.test(cc)) {
        return 'DINERS'
    }
    if (jcb.test(cc)) {
        return 'JCB'
    }
    if (cup1.test(cc) || cup2.test(cc)) {
        return 'CHINA_UNION_PAY'
    }
    return undefined
}

export const expirydate_format = (date) => {
    return date
        .replace(
            /^([1-9]\/|[2-9])$/g,
            '0$1/', // To handle 3/ > 03/
        )
        .replace(
            /^(0[1-9]{1}|1[0-2]{1})$/g,
            '$1/', // 11 > 11/
        )
        .replace(
            /^([0-1]{1})([3-9]{1})$/g,
            '0$1/$2', // 13 > 01/3
        )
        .replace(
            /^(\d)\/(\d\d)$/g,
            '0$1/$2', // To handle 1/11 > 01/11
        )
        .replace(
            /^(0?[1-9]{1}|1[0-2]{1})([0-9]{2})$/g,
            '$1/$2', // 141 > 01/41
        )
        .replace(
            /^([0]{1,})\/|[0]{1,}$/g,
            '0', // To handle 0/ > 0 and 00 > 0
        )
        .replace(
            /[^\d\/]|^[\/]{0,}$/g,
            '', // To allow only numbers and /
        )
        .replace(
            /\/\//g,
            '/', // Prevent entering more than 1 /
        )
}

let patt = /[^\u0000-\u007F ]+/

export const notAllowAR = (value) => {
    if (patt.test(value)) {
        value = value.replace(patt, '')
    }

    return value
}
