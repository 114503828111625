import { Link } from 'react-router-dom'
import useAnalytics from '../../../../analytics/useAnalytics'
import { CONTACT_US } from '../../../../constants/navigationConstants'
import styles from './styles.module.scss'

const Content = () => {
    const analytics = useAnalytics()

    return (
        <div className={`${styles['content']}`}>
            <div className="mb-3">World-class Technology Driven Shipping Experience</div>
            <div className="mb-4">Make your Business Reliable & Scalable</div>
            <div className="d-inline-block">
                <Link
                    className={`btn btn-outline-primary ${styles['outline-btn-overrides']}`}
                    to={CONTACT_US}
                    onClick={() => analytics.events.contactUs({ source: 'Ecommerce' })}
                >
                    KNOW MORE
                </Link>
            </div>
        </div>
    )
}

export default Content
