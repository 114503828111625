import styles from './styles.module.scss'

const BackgroundTapeWrapper = ({ children }) => {
    return (
        <div className="position-relative">
            <div className={styles['contents']}>
                <div className={`${styles['shopini-logistics']}`}>E-COMMERCE SOLUTIONS</div>
                <div>{children}</div>
            </div>
            <div className={`d-none d-lg-block position-absolute ${styles['seamless-background-image-tape']}`}></div>
        </div>
    )
}

export default BackgroundTapeWrapper
