import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import axios from 'axios'
import { useAuth } from '../../contexts/AuthContext'

export default () => {
    const { authToken } = useAuth()
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [manifest, setManifest] = useState({
        consols: [],
        parcels: [],
        trackings: [],
    })

    const handleInputs = (e) => {
        setManifest({
            ...manifest,
            [e.target.name]:
                e.target.value?.length > 0
                    ? e.target.value.replaceAll(' ', '').replaceAll('42019809 ', '').replaceAll('\n', ',').split(',')
                    : [],
        })
    }

    useEffect(() => {
        if (manifest?.consols?.length === 0 && manifest?.parcels?.length === 0 && manifest?.trackings?.length === 0) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
        console.log(manifest, 'mustafa')
    }, [JSON.stringify(manifest)])

    const exportManifest = async (e) => {
        if (e) e.preventDefault()
        try {
            setLoading(true)
            const defaultOptions = {
                headers: {
                    Authorization: authToken ? `Bearer ${authToken}` : '',
                },
            }
            let { data } = await axios.post(
                `${process.env.REACT_APP_PARCEL_SERVICES}/api/get-manifest`,
                { ...manifest },
                { ...defaultOptions },
            )
            if (data && data !== '') {
                const outputFilename = `${Date.now()}.csv`
                const aElement = document.createElement('a')
                aElement.setAttribute('download', outputFilename)
                const href = URL.createObjectURL(new Blob(['\ufeff', data], { type: 'text/csvcharset=utf-8' }))
                aElement.href = href
                aElement.setAttribute('target', '_blank')
                aElement.click()
                URL.revokeObjectURL(href)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }

    return (
        <form id="manifestForm" className="text-start w-100" onSubmit={(e) => exportManifest(e)}>
            <div className="main-section-container mb-2 full-height-page-small-nav w-100 d-flex flex-column">
                <div className="container-fluid container-width">
                    <Row className="justify-content-around mt-2 mt-mobile-0">
                        <Col lg={12} xs={12} className="mt-4 pt-2 px-2">
                            <Row className="justify-content-center">
                                <div className="col-md-10 col-sm-12 mb-2">
                                    <div className="form-group">
                                        <label htmlFor="consolstext">Enter Consol Boxes IDs</label>
                                        <textarea
                                            autocomplete="off"
                                            className="form-control"
                                            id="consolstext"
                                            name="consols"
                                            minLength={1}
                                            value={manifest.consols}
                                            onChange={handleInputs}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-10 col-sm-12 mb-2">
                                    <div className="form-group">
                                        <label htmlFor="parcelstext">Enter Parcels IDs</label>
                                        <textarea
                                            autocomplete="off"
                                            className="form-control"
                                            id="parcelstext"
                                            name="parcels"
                                            minLength={1}
                                            value={manifest.parcels}
                                            onChange={handleInputs}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-10 col-sm-12 mb-2">
                                    <div className="form-group">
                                        <label htmlFor="trackingstext">Enter Supplier Tracking Numbers</label>
                                        <textarea
                                            autocomplete="off"
                                            className="form-control"
                                            id="trackingstext"
                                            name="trackings"
                                            minLength={1}
                                            value={manifest.trackings}
                                            onChange={handleInputs}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-10 col-sm-12 mb-3">
                                    <small className="color-red">
                                        ** please fill the fields with values separated by either as , or a new line
                                    </small>
                                </div>
                                <div className="col-md-10 col-sm-12 text-center">
                                    <button type="submit" className="btn btn-primary" disabled={loading || disabled}>
                                        {loading ? (
                                            <div class="spinner-border text-light" role="status"></div>
                                        ) : (
                                            'Download Manifest'
                                        )}
                                    </button>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        </form>
    )
}
