import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const ScrollTop = () => {
    const { listen } = useHistory()

    useEffect(() => {
        const unRegisterListen = listen(() => {
            window.scrollTo(0, 0)
        })
        return () => {
            console.log('un registered')
            unRegisterListen()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return null
}

export default ScrollTop
