import React from 'react'
import { useTranslation } from 'react-i18next'
import { Markup } from 'interweave'
import TabBody from './tabBody'

function Tab8() {
    const { t } = useTranslation()

    const questionsAndAnswers = [
        { containerId: 'eightone', collapseId: 'collapseEightOne', question: t('Qeightone'), answer: t('Aeightone') },
        { containerId: 'eighttwo', collapseId: 'collapseEightTwo', question: t('Qeighttwo'), answer: t('Aeighttwo') },
        {
            containerId: 'eightthree',
            collapseId: 'collapseEightThree',
            question: t('Qeightthree'),
            answer: t('Aeightthree'),
        },
        {
            containerId: 'eightfour',
            collapseId: 'collapseEightFour',
            question: t('Qeightfour'),
            answer: t('Aeightfour'),
        },
        {
            containerId: 'eightfive',
            collapseId: 'collapseEightFIve',
            question: t('Qeightfive'),
            answer: t('Aeightfive'),
        },
        { containerId: 'eightsix', collapseId: 'collapseEightSix', question: t('Qeightsix'), answer: t('Aeightsix') },
        {
            containerId: 'eightseven',
            collapseId: 'collapseEightSeven',
            question: t('Qeightseven'),
            answer: t('Aeightseven'),
        },
        {
            containerId: 'eighteight',
            collapseId: 'collapseEightEight',
            question: t('Qeighteight'),
            answer: t('Aeighteight'),
        },
        {
            containerId: 'eightnine',
            collapseId: 'collapseEightNine',
            question: t('Qeightnine'),
            answer: t('Aeightnine'),
        },
        // { containerId: "eightnine", collapseId: "collapseEightNine", question: t("Qeightnine"), answer: t("Aeightnine") },
        // { containerId: "eightten", collapseId: "collapseEightTen", question: t("Qeightten"), answer: [t("Aeightten-1"), t("Aeightten-2")]},
    ]
    return (
        <>
            <div className="accordion" id="accountNavigationQuestions">
                <div className="card align-items-start">
                    <p className="sub-page-section-header mb-show-block text-start">{t('Buy it for me service')}</p>
                </div>
                {questionsAndAnswers?.map((questionAndAnswer) => (
                    <TabBody questionAndAnswer={questionAndAnswer} />
                ))}
            </div>
        </>
    )
}

export default Tab8
