import List from './list'
import Title from './title'
import styles from './styles.module.scss'

const WhyShopini = () => {
    return (
        <div className={`${styles['why-shopini']}`}>
            <div className={`${styles['title']}`}>
                <Title />
            </div>
            <div className={`${styles['list']}`}>
                <List />
            </div>
        </div>
    )
}

export default WhyShopini
