import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import useAnalytics from '../../../analytics/useAnalytics'
import { MEMBERSHIP_PLANS } from '../../../constants/navigationConstants'

const SignUpButton = styled(Link)`
    display: inline-block;
    padding: 10px 42px;
    background-color: #ff8f6b;
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 6px;

    &:hover {
        color: #ffffff;
    }
`

const Footer = () => {
    const [t, i18n] = useTranslation()
    const isAr = i18n.language === 'ar'

    const analytics = useAnalytics()

    return (
        <div className="d-flex flex-column align-items-center justify-content-center text-center py-4">
            <p className={`mb-3 mb-lg-1 px-4 px-lg-0 fs-26px ${isAr ? 'font-weight-bolder' : 'fw-500'}`}>
                {t('TRUE_WEIGHT_ready-to-enjoy')}
            </p>
            <p className="mb-1">
                <SignUpButton to={MEMBERSHIP_PLANS} onClick={analytics.events.signupButton}>
                    {t('TRUE_WEIGHT_sign-up')}
                </SignUpButton>
            </p>
            <p className="mb-1 fs-14px">{t('TRUE_WEIGHT_no-credit-card')}</p>
            <p className="mb-0 fs-14px">{t('TRUE_WEIGHT_terms_n_conditions')}</p>
        </div>
    )
}

export default Footer
