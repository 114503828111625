import React, { useEffect, useMemo, useState } from 'react'
import AmazonPay from '../AmazonPay'
import qs from 'query-string'
import { HOMEPAGE } from '../../constants/navigationConstants'
import { useAuth } from '../../contexts/AuthContext'
import { Alert, Col, Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import CreditCard from '../AmazonPay/CreditCard'
import AmazonPayButton from '../AmazonPay/AmazonPayButton'

const MobilePayment = () => {
    const [paymentRequestData, setPaymentRequestData] = useState({})
    const [addOnServices, setAddOnServices] = useState([])
    const [creditCard, setCreditCard] = useState([])
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState(null)
    const [isAmazonPay, setIsAmazonPay] = useState(true)
    const [isPaypal, setIsPaypal] = useState(true)
    const [isOthers, setIsOthers] = useState(false)
    const [loaderMessage, setLoaderMessage] = useState('')
    const queryParam = qs.parse(decodeURIComponent(window?.location?.search))
    const targetOrigin = useMemo(() => process?.env?.REACT_APP_MOBILE_POST_MESSAGE_TARGET_ORIGIN, [])

    const { setAuthToken, authToken } = useAuth()
    const { t, i18n } = useTranslation()

    const onPaymentCompletion = async (response) => {
        if (response && response.status) {
            window.webkit.messageHandlers.backHomePage.postMessage(JSON.stringify({ status: 'success' }), targetOrigin)
        } else {
            window.webkit.messageHandlers.backHomePage.postMessage(JSON.stringify({ status: 'fail' }), targetOrigin)
        }
    }

    useEffect(() => {
        setLoaderMessage(t('Please wait while we load your payment details'))
        setLoading(false)
    }, [])

    useEffect(async () => {
        if (queryParam?.lng && queryParam?.lng !== '') {
            localStorage.setItem('i18nextLng', queryParam?.lng)
            i18n?.changeLanguage(queryParam?.lng)
        }
        if (!queryParam?.auth_token || queryParam?.auth_token === '') {
            setMessage('401')
        } else {
            await setAuthToken(queryParam?.auth_token)
        }
    }, [window?.location?.search])

    return (
        <Container className="align-items-center justify-content-center mt-4">
            {message ? (
                <Alert variant={'danger'}>{t(message)}</Alert>
            ) : (
                <AmazonPay
                    paymentRequestData={paymentRequestData}
                    paymentDetails={paymentRequestData}
                    setShipmentLoading={setLoading}
                    onPaymentCompletion={onPaymentCompletion}
                    paymentType={'add-card'}
                    shipmentLoading={loading}
                    totalPayment={0.01}
                    parcels={[]}
                    addOnServices={[]}
                    fee={{}}
                    setCreditCard={setCreditCard}
                    creditCard={creditCard}
                    setIsAmazonPay={setIsAmazonPay}
                    isAmazonPay={isAmazonPay}
                    isPaypal={isPaypal}
                    setIsPaypal={setIsPaypal}
                    mobileRequest={true}
                    mobileAuthToken={queryParam?.auth_token}
                    setIsOthers={setIsOthers}
                />
            )}
        </Container>
    )
}

export default MobilePayment
