import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ShortUniqueId from 'short-unique-id'
import { isUndefined } from 'lodash'
import UploadFiles from '../../uploadFiles'
import AmazonBrand from './amazon.svg'
import OthersBrand from './others.svg'
import { postData } from '../../../utils/api'
import { useAuth } from '../../../contexts/AuthContext'
import axios from 'axios'
export default ({
    show,
    handleClose,
    handleSubmit,
    showResultsModal,
    setShowResultsModal,
    uploadTracking = false,
    reqId,
}) => {
    const { t, i18n } = useTranslation()
    const { authToken, warehouseId } = useAuth()
    const [loading, setLoading] = useState(false)

    const handleChangeStatus = async ({ meta, file }, status) => {
        if (status === 'done') {
            const uploadForm = document.getElementById('upload-incoming-orders')
            const formObj = new FormData(uploadForm)
            let keyName = uploadTracking ? 'tracking_number' : 'parcels'
            formObj.append(`${keyName}`, file)
            let uploadResponse
            try {
                setLoading(true)
                const defaultOptions = {
                    headers: {
                        Authorization: authToken ? `Bearer ${authToken}` : '',
                        'Content-Type': Object.fromEntries(formObj)?.type,
                    },
                }
                let requestUrl = uploadTracking
                    ? // `/api/upload/parcel-tracking?warehouse_id=${warehouseId}&list_upload_id=${reqId}`
                      `/api/upload/parcel-tracking-wo-list-v2?warehouse_id=${warehouseId}`
                    : `/api/upload/expected-parcels?warehouse_id=${warehouseId}&website_id=1`
                uploadResponse = await axios.post(
                    `${process.env.REACT_APP_PARCEL_SERVICES}${requestUrl}`,
                    formObj,
                    defaultOptions,
                )
                setShowResultsModal({
                    show: 1,
                    status: uploadResponse?.data?.status,
                    message: uploadResponse?.data?.message,
                })
                setLoading(false)
            } catch (error) {
                setShowResultsModal({
                    show: 1,
                    status: 0,
                    message: error?.response?.data?.message,
                })
                setLoading(false)
            }
            handleClose()
        }
    }

    const providers = [
        { name: 'Amazon Orders', brand: AmazonBrand, website_id: 1 },
        { name: 'Amazon Shipments Without Lastmile', brand: AmazonBrand, website_id: 4 },
        { name: 'Others Without Lastmile', brand: OthersBrand, website_id: 2 },
        { name: 'Others With Lastmile', brand: OthersBrand, website_id: 3 },
    ]

    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName={i18n.language + ''}
            aria-labelledby="contained-modal-title-vcenter"
            id={`upload-orders`}
            size={'lg'}
            centered
        >
            <Modal.Header
                className={`border-bottom-0 justify-content-between border-bottom-none bg-white ${i18n?.language === 'ar' ? 'close-ar' : ''}`}
                closeButton
            >
                <p className={`${i18n?.language === 'ar' ? 'text-right' : 'text-left'} mx-4`}>
                    {uploadTracking ? <h4>{t('Upload tracking number')}</h4> : <h4>{t('Upload Orders')}</h4>}
                    <small>{t('Select a spreadsheet to upload (Excel, CSV)')}</small>
                </p>
            </Modal.Header>
            <Modal.Body className={`py-0 text-start mx-4`}>
                <div className={'rounded'}>
                    <UploadFiles
                        handleChangeStatus={handleChangeStatus}
                        formId={'upload-incoming-orders'}
                        accept={
                            'text/rtf, text/plain, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        }
                    />
                    {uploadTracking ? (
                        <span className="d-block my-2 text-center w-100">
                            {t('Click')}
                            &nbsp;
                            <a
                                className="blue-color"
                                onClick={() =>
                                    window.open(
                                        `${process?.env?.REACT_APP_PARCEL_SERVICES}/api/download/tracking-sample`,
                                    )
                                }
                            >
                                {t('here')}
                            </a>
                            &nbsp;
                            {t('to download sample excel sheet')}
                        </span>
                    ) : (
                        <>
                            <p className="my-3">{t('Download template for excel sheet')}</p>
                            <Row className="my-3">
                                {providers.map((p) => (
                                    <Col lg={3} md={3} sm={6} className="mb-2 d-flex align-items-stretch">
                                        <div
                                            key={p?.name}
                                            className="tr-cursor brand-card px-1 py-3 rounded text-center w-100"
                                            onClick={() =>
                                                window.open(
                                                    `${process?.env?.REACT_APP_PARCEL_SERVICES}/api/download/courier-sample?template_sheet_id=${p?.website_id}`,
                                                )
                                            }
                                        >
                                            <img src={p?.brand} />
                                            <p className="mt-4 mb-0">{t(p?.name)}</p>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    )
}
