import React, { useEffect, useState } from 'react'
import {
    Alert,
    Button,
    Card,
    Col,
    Container,
    Form,
    Table,
    useAccordionToggle,
} from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import * as UserInfo from '../../../services/userInfoService'
import { humanize } from '../../../utils/string'
import Loader from '../../../components/common/loader'
import {
    getMaximumWeight,
    RATE_CALCULATOR_INPUTS,
    SERVICES_NO_PROMOCODE,
    SERVICES_WITH_VAT,
    WEIGHT_TO_DIMENSION,
} from '../../../constants/constants'
import PhoneInput from 'react-phone-input-2'
import ReactFlagsSelect from 'react-flags-select'
import { postData } from '../../../utils/api'
import useCountries from '../../../utils/useCountries'
import { useAuth } from '../../../contexts/AuthContext'
import OurTrueWeight from './our-true-weight/OurTrueWeight'
import BoxFigure from './box-figure/BoxFigure'
import New from './our-true-weight/new.svg'
import NewAr from './our-true-weight/new-ar.svg'
import styled from 'styled-components'
import styles from './styles.module.scss'
import useAnalytics from '../../../analytics/useAnalytics'
import { screenEvent } from '../../../analytics/constants'

const StyledNew = styled.img`
    position: absolute;
    z-index: 1;
    top: 20%;
    right: ${(props) => (props.isAr ? 0 : 'unset')};
    left: ${(props) => (props.isAr ? 'unset' : 0)};
    transform: translate(${(props) => (props.isAr ? '30%' : '-30%')}, -50%);
`

const { KG, LB, CM, INCH } = WEIGHT_TO_DIMENSION

function CalculateShippingFees() {
    const { t, i18n } = useTranslation()
    //original state of expanded in the advanced calculator section
    const [expanded, setExpanded] = useState(true)

    const { countries } = useCountries()
    const { warehousesList, setWarehouseId, warehouseId } = useAuth()
    const alignment = i18n.language === 'en' ? 'ltr' : 'rtl'
    const classNameForPhoneInput = i18n.language === 'ar' ? 'ltr-direction w-100' : 'w-100'

    const [floater, setFloater] = useState('')

    const [rateCalculatorData, setRateCalculatorData] = useState({
        country_id: 0,
        weight: '',
        height: '',
        width: '',
        length: '',
        weight_unit: 'lb',
        dimension_unit: 'in',
    })
    const [contactUserInfo, setContactUserInfo] = useState({
        name: '',
        email: '',
        phone: '',
        content: 'For more than 70 kg / 150 lb, fill the form to get a call back from our sales team',
        subject: 'Maximum weight exceeded',
        country: 'AE',
        code: '',
    })
    const [chargesList, setChargesList] = useState([])
    const [chargeableWeight, setChargeableWeight] = useState(0)
    const [chargeableWeightUnit, setChargeableWeightUnit] = useState('lb')
    const [showLoader, setShowLoader] = useState(false)
    const [validationInput, setValidationInput] = useState('')
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const maxWeight = getMaximumWeight(rateCalculatorData.weight_unit)
    const [submitResult, setSubmitResult] = useState({
        message: '',
        severity: '',
    })
    const analytics = useAnalytics()

    useEffect(() => {
        setFloater(i18n.language === 'ar' ? 'float-left' : 'float-right')
    }, [])

    let name, value
    const handleInputs = (event) => {
        name = event.target.name
        value = event.target.value
        setRateCalculatorData({ ...rateCalculatorData, [name]: value })
    }

    const handleContactUsFormInputs = (event) => {
        name = event.target.name
        value = event.target.value
        setContactUserInfo({ ...contactUserInfo, [name]: value })
    }

    const handleWeightChange = (event) => {
        name = event.target.name
        value = event.target.value
        event.target.value === KG
            ? setRateCalculatorData({
                ...rateCalculatorData,
                dimension_unit: CM,
                [name]: value,
            })
            : setRateCalculatorData({
                ...rateCalculatorData,
                dimension_unit: INCH,
                [name]: value,
            })
    }

    const handleDimensionChange = (event) => {
        name = event.target.name
        value = event.target.value
        event.target.value === CM
            ? setRateCalculatorData({
                ...rateCalculatorData,
                weight_unit: KG,
                [name]: value,
            })
            : setRateCalculatorData({
                ...rateCalculatorData,
                weight_unit: LB,
                [name]: value,
            })
    }

    const openModal = (modalId) => {
        document.querySelector(modalId).click()
    }

    const handleAnalytics = () => {
        const shipFrom = warehousesList.find((warehouse) => warehouse.id === Number(warehouseId))?.country
        const shipTo = countries.find((country) => country.id === Number(rateCalculatorData.country_id))?.iso2_code
        if (shipFrom && shipTo) {
            const analyticsAttributes = {
                shipFrom,
                shipTo,
                parcelWeight: Number(rateCalculatorData.weight),
                weightUnit: `${rateCalculatorData.weight_unit}`.toUpperCase(),
            }
            analytics.events.calculateShippingCostButton(analyticsAttributes)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        handleAnalytics()
        if (rateCalculatorData.weight !== '' && parseInt(rateCalculatorData.weight) >= maxWeight && maxWeight !== 0) {
            openModal('#contactUsFormModal')
            analytics.events.screenVisited({ screen: screenEvent.CALCULATOR_CONTACT })
        } else {
            setChargesList([])
            setShowLoader(true)
            setError(null)
            openModal('#chargesModal')
            analytics.events.screenVisited({ screen: screenEvent.CALCULATOR_RESULT })
            try {
                const calculatedList = await UserInfo.postRateCalculation(rateCalculatorData, warehouseId, true)
                setChargesList(calculatedList.payload)
                setChargeableWeight(calculatedList.extra.chargeable_weight)
                setChargeableWeightUnit(t(calculatedList.extra.chargeable_weight_unit))
                setShowLoader(false)
            } catch (error) {
                setShowLoader(false)
                setError('Currently no service available, please try later.')
            }
        }
    }

    const validateInputs = () => {
        setValidationInput('rate-calculator-input-validation')
    }

    const handleContactUsFormSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        const res = await postData('notificationService', '/contact-form/send', contactUserInfo)
        if (res && res.message) {
            setSubmitResult({
                message: 'Thank you for submitting your enquiry, we will get back to you',
                severity: 'success',
            })
        } else {
            setSubmitResult({
                message: 'Something went wrong !',
                severity: 'danger',
            })
        }
        setLoading(false)
        setContactUserInfo({
            name: '',
            email: '',
            phone: '',
            content: 'For more than 70 kg / 150 lb, fill the form to get a call back from our sales team',
            subject: 'Maximum weight exceeded',
            country: 'AE',
            code: '',
        })
    }

    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionToggle(eventKey, () => setExpanded(!expanded))

        return (
            <div>
                <Button variant="link" onClick={decoratedOnClick}
                        className="advanced-calculater">
                    {children}
                </Button>
            </div>
        )
    }

    useEffect(() => {
        setRateCalculatorData({
            ...rateCalculatorData,
            length: '1',
            height: '1',
            width: '1',
        })
    }, [expanded])

    function AdvancedCalculator() {
        return (
            <Form.Row className="advancedCalculatorStyleForMobile">
                <Form.Group as={Col} controlId="length">
                    <Form.Label className="alignment">{t('Length')}</Form.Label>
                    <Form.Control
                        type="number"
                        step="0.01"
                        className={`form-control ${validationInput}`}
                        name="length"
                        min={RATE_CALCULATOR_INPUTS.DIMENSION_MIN_VALUE}
                        max={
                            rateCalculatorData.dimension_unit === 'in'
                                ? RATE_CALCULATOR_INPUTS.DIMENSION_MAX_VALUE_INCH
                                : RATE_CALCULATOR_INPUTS.DIMENSION_MAX_VALUE_CM
                        }
                        value={rateCalculatorData.length}
                        onChange={handleInputs}
                    />
                </Form.Group>

                <Form.Group as={Col} controlId="width">
                    <Form.Label className="alignment">{t('Width')}</Form.Label>
                    <Form.Control
                        type="number"
                        step="0.01"
                        className={`form-control ${validationInput}`}
                        name="width"
                        min={RATE_CALCULATOR_INPUTS.DIMENSION_MIN_VALUE}
                        max={
                            rateCalculatorData.dimension_unit === 'in'
                                ? RATE_CALCULATOR_INPUTS.DIMENSION_MAX_VALUE_INCH
                                : RATE_CALCULATOR_INPUTS.DIMENSION_MAX_VALUE_CM
                        }
                        value={rateCalculatorData.width}
                        onChange={handleInputs}
                    />
                </Form.Group>

                <Form.Group as={Col} controlId="height">
                    <Form.Label className="alignment">{t('Height')}</Form.Label>
                    <Form.Control
                        type="number"
                        step="0.01"
                        className={`form-control ${validationInput}`}
                        name="height"
                        min={RATE_CALCULATOR_INPUTS.DIMENSION_MIN_VALUE}
                        max={
                            rateCalculatorData.dimension_unit === 'in'
                                ? RATE_CALCULATOR_INPUTS.DIMENSION_MAX_VALUE_INCH
                                : RATE_CALCULATOR_INPUTS.DIMENSION_MAX_VALUE_CM
                        }
                        value={rateCalculatorData.height}
                        onChange={handleInputs}
                    />
                </Form.Group>

                <Form.Group as={Col} controlId="unit">
                    <Form.Label className="alignment">{t('Unit')}</Form.Label>
                    <Form.Select
                        className={`form-control ${validationInput}`}
                        name="dimension_unit"
                        onChange={handleDimensionChange}
                        value={rateCalculatorData.dimension_unit}
                        required
                    >
                        <option value="">{t('Select dimension unit')}</option>
                        <option value="cm">{t('Cm')}</option>
                        <option value="in">{t('Inch')}</option>
                    </Form.Select>
                </Form.Group>
            </Form.Row>
        )
    }

    const createWarehousesLabels = () => {
        const customLabels = {}
        warehousesList?.forEach(
            (w) =>
                (customLabels[w.country] = {
                    primary: w.name,
                    secondary: w.active ? '' : <div
                        className="mx-1 coming-soon orange-bg">{t('Coming soon')}</div>,
                }),
        )
        return customLabels
    }

    return (
        <Container fluid id="Calculate shipping fees"
                   className="smooth-scroll shop-ship-section-container text-center">
            <p className="section-title">{t('Calculate shipping fees')}</p>
            <p>{t('Calculate shipping fees description')}</p>
            <div className="container-fluid">
                <div className="row justify-content-center align-items-center">
                    <div
                        className={`flex-grow-1 mb-3 mb-xl-0 col-12 ${styles.trueWeight} px-xl-2 overflow-show`}>
                       {/* <OurTrueWeight />*/}
                    </div>
                    <div
                        className={`flex-grow-1 mb-3 mb-xl-0 col-12 px-0 ${styles.calculator}`}>
                        <Card>
                            <Form className="calculate-form mb-white-background"
                                  onSubmit={handleSubmit}>
                                <Form.Row>
                                    <Form.Group as={Col}
                                                controlId="formShipParcelFrom">
                                        <Form.Label
                                            className={`required ${alignment}`}>
                                            {t('Ship Parcel from')}
                                        </Form.Label>
                                        <ReactFlagsSelect
                                            countries={warehousesList?.map((warehouse) => warehouse.country) || 'US'}
                                            customLabels={createWarehousesLabels()}
                                            className={`ltr-direction text-muted`}
                                            selected={warehousesList?.find((w) => w.id === warehouseId)?.country}
                                            onSelect={(code) => {
                                                // setWarehouseId(
                                                //     warehousesList?.find(
                                                //         (w) => w.country === code
                                                //     )?.id
                                                // )
                                                if (warehousesList?.find((w) => w.country === code)?.active) {
                                                    setWarehouseId(warehousesList?.find((w) => w.country === code)?.id)
                                                } else {
                                                    setWarehouseId(1)
                                                }
                                            }}
                                            onFocus={(e) => {
                                                if (e.target.autocomplete) {
                                                    e.target.autocomplete = 'noautocomplete'
                                                }
                                            }}
                                            showSelectedLabel
                                            placeholder={'Select a warehouse'}
                                            alignOptionsToRight
                                            fullWidth
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}
                                                controlId="formShipParcelTo"
                                                className="w-100">
                                        <Form.Label
                                            className={`required ${alignment}`}>
                                            {t('Ship parcel to')}
                                        </Form.Label>
                                        <select
                                            className={`form-control ${validationInput}`}
                                            name="country_id"
                                            onChange={handleInputs}
                                            value={rateCalculatorData.country}
                                            required
                                        >
                                            <option
                                                value="">{t('Select a country')}</option>
                                            {countries?.map((country) => (
                                                <option key={country.id}
                                                        value={country.id}>
                                                    {country.name}
                                                </option>
                                            ))}
                                        </select>
                                    </Form.Group>
                                    <Form.Group as={Col}
                                                controlId="parcelWeight"
                                                className="w-100">
                                        <Form.Label
                                            className={`required ${alignment}`}>
                                            {t('Parcel weight')}
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            step="0.01"
                                            className={`${validationInput}`}
                                            name="weight"
                                            value={rateCalculatorData.weight}
                                            onChange={handleInputs}
                                            min={RATE_CALCULATOR_INPUTS.WEIGHT_MIN_VALUE}
                                            required
                                            isInvalid={rateCalculatorData.weight >= 50}
                                        />
                                        {rateCalculatorData.weight >= 50 && (
                                            <Form.Control.Feedback
                                                type="invalid"
                                                className={i18n?.language === 'ar' ? 'text-right' : 'text-left'}
                                            >
                                                {t('Shipping of large packages may take more time to prepare and ship')}
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="wightUnit"
                                                className="w-100">
                                        <Form.Label
                                            className={`required ${alignment}`}>{t('Weight unit')}</Form.Label>
                                        <select
                                            className={`form-control ${validationInput}`}
                                            name="weight_unit"
                                            onChange={handleWeightChange}
                                            value={rateCalculatorData.weight_unit}
                                            required
                                        >
                                            <option
                                                value="">{t('Select weight unit')}</option>
                                            <option
                                                value="kg">{t('Kg')}</option>
                                            <option
                                                value="lb">{t('Lb')}</option>
                                        </select>
                                    </Form.Group>
                                </Form.Row>

                                {/*<Accordion>
							<CustomToggle eventKey="0">
								<span className="mb-0 blue-color">
									{t("Advanced calculator")}
								</span>
								{expanded && (
									<img className="accordion-arrow" src={arrowDown} alt={""} />
								)}
								{!expanded && (
									<img className="accordion-arrow" src={arrowUp} alt={""} />
								)}
							</CustomToggle>
							<Accordion.Collapse eventKey="0">
								<>{AdvancedCalculator()}</>
							</Accordion.Collapse>
						</Accordion>*/}

                                <button type="submit"
                                        className="btn btn-primary btn-block"
                                        onClick={validateInputs}>
                                    {t('Calculate shipping cost button')}
                                </button>
                                <a id="chargesModal" data-toggle="modal"
                                   data-target="#charges-table-modal" />
                                <a id="contactUsFormModal" data-toggle="modal"
                                   data-target="#contact-us-form" />
                                <div
                                    className="d-flex flex-row flex-nowrap align-items-stretch mt-2 fs-10">
                                    <div
                                        className="d-flex justify-content-center h-100 mx-1"
                                        style={{ paddingTop: '2px' }}
                                    >
                                        <img
                                            src={require('./info-icon.svg').default}
                                            alt="" />
                                    </div>
                                    <div
                                        className={`${i18n.language === 'ar' ? 'text-right' : 'text-left'} fs-10px`}>
                                        <Trans i18nKey="calculator_true_weight">
                                            <span className="text-blue"></span>
                                            <span
                                                className="color-green"></span>
                                        </Trans>
                                    </div>
                                </div>
                            </Form>
                            <div
                                className="modal fade"
                                id="charges-table-modal"
                                tabIndex="-1"
                                aria-labelledby="new-address-modal"
                                aria-hidden="true"
                            >
                                <div
                                    className={`modal-dialog modal-dialog-centered login-modal-width ${styles['custom-modal-lg']}`}
                                >
                                    <div className="modal-content p-3 w-100">
                                        <div
                                            className="modal-header border-bottom-0 text-start">
                                            <h2 className="sub-section-title"
                                                id="addModalLabel">
                                                {t('Estimated shipping Cost with Shopiniworld')}
                                            </h2>
                                            <button
                                                type="button"
                                                className={`close ml-0 mr-0 ${floater}`}
                                                data-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <i className="bx bx-x" />
                                            </button>
                                        </div>
                                        <div className="modal-body w-100 py-0">
                                            {/*<p className="text-start description">
                                    {t("Chargeable Weight is")}
                                    {chargeableWeight} {chargeableWeightUnit}
                                </p>*/}
                                            {!!error && <Alert
                                                variant={'danger'}>{error}</Alert>}
                                            {!showLoader && (
                                                <div
                                                    className={`position-relative bg-grey-6 rounded ${styles['px-3-5']} py-3 fw-300`}
                                                >
                                                    <StyledNew
                                                        isAr={i18n.language == 'ar'}
                                                        alt=""
                                                        src={i18n.language == 'ar' ? NewAr : New}
                                                    />
                                                    <div
                                                        className="d-flex flex-row flex-nowrap align-items-stretch fs-14px">
                                                        <div
                                                            className="d-flex justify-content-center h-100 mx-1"
                                                            style={{ paddingTop: '2px' }}
                                                        >
                                                            <img
                                                                src={require('./info-icon.svg').default}
                                                                alt=""
                                                                width={13}
                                                                height={13}
                                                            />
                                                        </div>
                                                        <div
                                                            className={`${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}
                                                        >
                                                            <Trans
                                                                i18nKey="calculator_true_weight_modal">
                                                                <span
                                                                    className="text-blue-color"></span>
                                                                <span
                                                                    className="color-green font-weight-bold"></span>
                                                                <span
                                                                    className="text-blue-color font-weight-bold"></span>
                                                            </Trans>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <TableDisplay
                                                showLoader={showLoader}
                                                chargesList={chargesList}
                                                rateCalculatorData={rateCalculatorData}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="modal fade"
                                id="contact-us-form"
                                tabIndex="-1"
                                aria-labelledby="constact-us"
                                aria-hidden="true"
                            >
                                <div
                                    className="modal-dialog modal-dialog-centered login-modal-width">
                                    <div className="modal-content p-3 w-100">
                                        <div
                                            className="modal-header border-bottom-0 pb-0 pt-0">
                                            <button
                                                type="button"
                                                className={`close ${floater}`}
                                                data-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <i className="bx bx-x" />
                                            </button>
                                        </div>
                                        <div className="modal-body w-100">
                                            <h3 className={`${alignment} mx-3 mb-4 text-capitalize`}>
                                                {t('Contact us now')}
                                            </h3>
                                            <div
                                                className="d-flex flex-column justify-content-center align-items-center">
                                                <Alert variant={'danger'}>
                                                    {t('For more than')}
                                                    <strong
                                                        className="mx-1">{`${maxWeight} ${t(
                                                        rateCalculatorData.weight_unit,
                                                    )}`}</strong>
                                                    {t(', fill the form to get a call back from our sales team')}
                                                </Alert>
                                                <form className="w-100 p-3"
                                                      onSubmit={handleContactUsFormSubmit}>
                                                    <div
                                                        className="form-row mb-1 p-0">
                                                        <div
                                                            className={`col-12 col-md-6 mb-3 ${alignment}`}>
                                                            <label
                                                                htmlFor="contactUsNameInput">{t('Name')}</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="contactUsNameInput"
                                                                placeholder={t('Name')}
                                                                onChange={handleContactUsFormInputs}
                                                                name="name"
                                                                value={contactUserInfo.name}
                                                                required
                                                            />
                                                        </div>
                                                        <div
                                                            className={`col-12 col-md-6 mb-3 ${alignment}`}>
                                                            <label
                                                                htmlFor="contactUsEmailInput">{t('Email')}</label>
                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                id="contactUsEmailInput"
                                                                placeholder="name@example.com"
                                                                onChange={handleContactUsFormInputs}
                                                                name="email"
                                                                value={contactUserInfo.email}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="form-row mb-1">
                                                        <div
                                                            className={`col-12 col-md-6 mb-3 ${alignment}`}>
                                                            <label
                                                                htmlFor="registerShipto">{t('Country')}</label>
                                                            <ReactFlagsSelect
                                                                countries={countries?.map(
                                                                    (country) => country.iso2_code,
                                                                )}
                                                                className={`${
                                                                    i18n.language === 'ar' && 'ltr-direction'
                                                                } h-120`}
                                                                selected={contactUserInfo.country}
                                                                onSelect={(code) => {
                                                                    setContactUserInfo({
                                                                        ...contactUserInfo,
                                                                        country: code,
                                                                        code: code,
                                                                    })
                                                                }}
                                                                showSelectedLabel
                                                                placeholder={'Select a country'}
                                                                alignOptionsToRight
                                                                fullWidth
                                                            />
                                                        </div>
                                                        <div
                                                            className={`col-12 col-md-6 mb-3 ${alignment}`}>
                                                            <label
                                                                htmlFor="contactUsMobileInput">{t('Phone')}</label>
                                                            <PhoneInput
                                                                country={
                                                                    contactUserInfo.country &&
                                                                    contactUserInfo.country.toLocaleLowerCase()
                                                                }
                                                                inputProps={{
                                                                    name: 'phone',
                                                                }}
                                                                id="contactUsMobileInput"
                                                                onChange={(phoneValue) =>
                                                                    setContactUserInfo({
                                                                        ...contactUserInfo,
                                                                        phone: phoneValue,
                                                                    })
                                                                }
                                                                inputClass="form-control w-100"
                                                                value={contactUserInfo.phone}
                                                                containerClass={classNameForPhoneInput}
                                                            />
                                                        </div>
                                                    </div>
                                                    <button
                                                        disabled={loading}
                                                        id="contact-us-submit"
                                                        className="btn btn-primary btn-block"
                                                        type="submit"
                                                    >
                                                        {loading ? (
                                                            <div
                                                                class="spinner-border text-light"
                                                                role="status"></div>
                                                        ) : (
                                                            t('Submit')
                                                        )}
                                                    </button>
                                                </form>
                                                {submitResult.message && submitResult.message !== '' && (
                                                    <div className="w-100 px-3">
                                                        <Alert
                                                            variant={submitResult.severity}>
                                                            <i
                                                                class={`${
                                                                    submitResult.severity === 'success'
                                                                        ? 'green-check fas fa-check-circle'
                                                                        : 'fas fa-times-circle'
                                                                } mx-2`}
                                                            ></i>
                                                            {t(submitResult.message)}
                                                        </Alert>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className={`flex-grow-1 col-12 ${styles.box} px-xl-0`}>
                        {/*<BoxFigure />*/}
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default CalculateShippingFees

//to be edited removed later on
export const TableDisplay = (props) => {
    const { t, i18n } = useTranslation()
    return (
        <div
            className="calculation-table-scrollbar table-wrapper-scroll-y overflow-x-hidden mt-3 w-100 mr-0">
            {props.showLoader ? (
                <div>
                    <Loader />
                </div>
            ) : null}
            <Table striped hover>
                <tbody>
                {props.chargesList?.map((chargeCompany) => (
                    <tr
                        key={chargeCompany.service}
                        className="row justify-content-start align-items-center pr-3 pl-3 text-start"
                    >
                        <img
                            className="col-3 col-sm-2"
                            height={'60'}
                            src={chargeCompany.company_logo}
                            alt={'shipping-company'}
                        />
                        <div
                            className="col-5 col-sm-8 d-flex flex-column text-start">
                            <p className="mb-1 mt-3">{humanize(chargeCompany?.service)}</p>
                            <p className="text-grey">{chargeCompany?.provider ? chargeCompany.provider : null}</p>
                            {SERVICES_NO_PROMOCODE.includes(chargeCompany?.shipping_company_id) && (
                                <small
                                    className="mb-3">{t('These items are not allowed')}</small>
                            )}
                            {SERVICES_WITH_VAT.includes(chargeCompany?.shipping_company_id) && (
                                <small
                                    className="mb-3">{t('VAT amount will be added to the shipping fees')}</small>
                            )}
                        </div>
                        <p className={`col-4 col-sm-2 ${i18n.language === 'ar' ? 'text-left' : 'text-right'}`}>
                            <span
                                className="d-block">{parseFloat(chargeCompany.amount)?.toFixed(2)} $</span>
                            <span className="d-block font-12">
                                    {chargeCompany?.eta_days?.split(' ')[0]}&nbsp;
                                {t(chargeCompany?.eta_days?.split(' ')[1])}
                                </span>
                        </p>
                    </tr>
                ))}
                </tbody>
            </Table>
        </div>
    )
}
