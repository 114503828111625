import React from 'react'
import { useTranslation } from 'react-i18next'
import './shopUsBrandsSection.css'

function ImageSetContainer({ srcSet, banner, isMobile }) {
    const { i18n } = useTranslation()
    let count = 0
    return (
        <div className={banner ? 'imageSet-container-banner' : 'imageSet-container'}>
            {srcSet?.map((img) => (
                <>
                    <div
                        key={img.file.default}
                        className={!banner ? `${isMobile ? 'mx-2' : 'mx-3'} small-img-carousel` : ''}
                    >
                        <img
                            src={img.file.default}
                            className={`${banner && !isMobile ? `banner-img-margin-${i18n.language}` : ''} ${img.imageClass}`}
                            alt={!banner ? 'shopiniworld-brands' : 'shopiniworld-banner '}
                        />
                    </div>
                </>
            ))}
        </div>
    )
}

export default ImageSetContainer
