import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import Tab from 'react-bootstrap/Tab'
import './styles.scss'
import Nav from 'react-bootstrap/Nav'
import CustomizedTab from './CustomizedTab'
import usStores from './storesList/usStores'
import uaeStores from './storesList/uaeStores'
// import ukStores from "./storesList/ukStores";

import { categoriesList } from './Categories/index.js'

import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'

import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const Stores = () => {
    const { t, i18n } = useTranslation()

    const [tab, setTab] = useState('us')

    const categoriesIds = categoriesList.map((category) => category.id)

    const [selectedCategories, setSelectedCategories] = useState(categoriesIds)
    const isAllCategoriesSelected = categoriesIds.length > 0 && selectedCategories.length === categoriesIds.length
    const isSomeCategoriesSelected = selectedCategories.length && selectedCategories.length < categoriesIds.length

    const handleSelectCategory = (event) => {
        const value = event.target.value
        if (value[value.length - 1] === 'all') {
            setSelectedCategories(selectedCategories.length === categoriesIds.length ? [] : categoriesIds)
            return
        }
        setSelectedCategories(value)
    }

    // can be re written using sets
    const handleSelectCategorySideBar = (categoryId, checked, selectAll = false) => {
        if (selectAll) {
            setSelectedCategories(selectedCategories.length === categoriesIds.length ? [] : categoriesIds)
            return
        }
        let newSelectedCategories = [...selectedCategories]
        if (checked) {
            if (!selectedCategories.includes(categoryId)) newSelectedCategories.push(categoryId)
        } else {
            newSelectedCategories = newSelectedCategories.filter((category) => category !== categoryId)
        }
        setSelectedCategories(Array.from(newSelectedCategories))
    }

    useEffect(() => {
        console.log('selected categories', selectedCategories)
    }, [selectedCategories])

    useEffect(() => {
        setSelectedCategories(categoriesIds)
    }, [i18n.language])

    return (
        <div className="bg-grey">
            <div className="stores-container d-flex flex-row no-wrap align-items-start">
                <div
                    className={`categories-multi-select-side d-none d-lg-block ${
                        i18n.language === 'ar' ? 'text-right' : 'text-left'
                    }`}
                >
                    <div className="categories-title">{t('Categories')}</div>
                    <div>
                        <FormGroup>
                            <FormControlLabel
                                className="m-0"
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        backgroundColor: 'unset !important',
                                        fontSize: '14px !important',
                                        fontWeight: '500 !important',
                                        color: '#243548 !important',
                                        ...(isAllCategoriesSelected && {
                                            color: '#0000FF !important',
                                        }),
                                    },
                                }}
                                control={
                                    <Checkbox
                                        sx={{
                                            '&.MuiCheckbox-colorPrimary.Mui-checked': {
                                                color: '#0A20E1 !important',
                                            },
                                            '&.MuiCheckbox-indeterminate': {
                                                color: '#0A20E1 !important',
                                            },
                                        }}
                                        onChange={() => handleSelectCategorySideBar(null, null, true)}
                                        checked={isAllCategoriesSelected}
                                        indeterminate={isSomeCategoriesSelected}
                                    />
                                }
                                label={
                                    <div className="d-flex no-wrap align-items-center">
                                        <img
                                            width={22}
                                            height={22}
                                            className={`cat-icon ${i18n.language === 'ar' ? 'ml-2' : 'mr-2'}`}
                                            src={require('./Categories/icons/all.svg')?.default}
                                            alt={'All categories'}
                                        />
                                        <span className={`checkbox-label-font ${i18n.language === 'ar' && 'ar'}`}>
                                            {t('All categories')}
                                        </span>
                                    </div>
                                }
                            />
                            {categoriesIds.map((category) => (
                                <FormControlLabel
                                    className="m-0"
                                    sx={{
                                        '& .MuiFormControlLabel-label': {
                                            backgroundColor: 'unset !important',
                                            fontSize: '14px !important',
                                            fontWeight: '500 !important',
                                            color: '#243548 !important',
                                            ...(selectedCategories.includes(category) && {
                                                color: '#0000FF !important',
                                            }),
                                        },
                                    }}
                                    control={
                                        <Checkbox
                                            sx={{
                                                // "&.MuiButtonBase-root": {
                                                //     "::after": {
                                                //         content: "''",
                                                //         backgroundImage: `url('../cat.svg')`,
                                                //         width: "15px",
                                                //         height: "15px",
                                                //     },
                                                // },
                                                '&.MuiCheckbox-colorPrimary.Mui-checked': {
                                                    color: '#0A20E1 !important',
                                                },
                                            }}
                                            checked={selectedCategories.includes(category)}
                                            onChange={(e) => handleSelectCategorySideBar(category, e?.target?.checked)}
                                        />
                                    }
                                    label={
                                        <div className="d-flex no-wrap align-items-center">
                                            <img
                                                width={22}
                                                height={22}
                                                className={`cat-icon ${i18n.language === 'ar' ? 'ml-2' : 'mr-2'}`}
                                                src={categoriesList.find((cat) => cat.id === category)?.image}
                                                alt={
                                                    categoriesList.find((cat) => cat.id === category)[
                                                        i18n.language === 'ar' ? 'titleAr' : 'title'
                                                    ]
                                                }
                                            />
                                            <span
                                                className={`fs-14 checkbox-label-font ${
                                                    i18n.language === 'ar' && 'ar'
                                                }`}
                                            >
                                                {
                                                    categoriesList.find((cat) => cat.id === category)[
                                                        i18n.language === 'ar' ? 'titleAr' : 'title'
                                                    ]
                                                }
                                            </span>
                                        </div>
                                    }
                                />
                            ))}
                        </FormGroup>
                    </div>
                </div>
                <div className="flex-grow-1">
                    <Tab.Container defaultActiveKey="uae" activeKey={tab} onSelect={(tab) => setTab(tab)}>
                        <div className="filters-container d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-lg-center">
                            <div className="stores-title">{t('directoy_stores')}</div>
                            <div className="filters">
                                <Nav className="tab-bar d-inline-block">
                                    <Nav.Link eventKey="us">{t('United States')}</Nav.Link>
                                    {/* <Nav.Link eventKey="uk">
                                        {t("United Kingdom")}
                                    </Nav.Link> */}
                                    <Nav.Link eventKey="uae">{t('United Arab Emirates')}</Nav.Link>
                                </Nav>

                                <div className="d-block d-lg-none text-center">
                                    <FormGroup className="d-inline-block">
                                        <FormControl>
                                            <Select
                                                sx={
                                                    !selectedCategories.length && {
                                                        '&.MuiInputBase-root': {
                                                            '.MuiSelect-select': {
                                                                'span::before': {
                                                                    content: `"${t('Filter by category')}"`,
                                                                },
                                                            },
                                                        },
                                                    }
                                                }
                                                className={`${
                                                    i18n.language === 'ar' ? 'text-right left-arrow' : 'text-left'
                                                }`}
                                                multiple
                                                value={selectedCategories}
                                                onChange={handleSelectCategory}
                                                input={<OutlinedInput className="custom-outlined-input" />}
                                                renderValue={() => t('Filter by category')}
                                                MenuProps={MenuProps}
                                            >
                                                <MenuItem
                                                    value="all"
                                                    sx={{
                                                        '& .MuiMenuItem-root': {
                                                            backgroundColor: 'unset !important',
                                                        },
                                                    }}
                                                >
                                                    <Checkbox
                                                        sx={{
                                                            '&.MuiCheckbox-colorPrimary.Mui-checked': {
                                                                color: '#0A20E1 !important',
                                                            },
                                                            '&.MuiCheckbox-indeterminate': {
                                                                color: '#0A20E1 !important',
                                                            },
                                                        }}
                                                        checked={isAllCategoriesSelected}
                                                        indeterminate={isSomeCategoriesSelected}
                                                    />

                                                    <ListItemText
                                                        sx={{
                                                            '& .MuiListItemText-primary': {
                                                                fontSize: '14px !important',
                                                                fontWeight: '500 !important',
                                                                color: '#243548 !important',
                                                                textAlign: i18n.language === 'ar' ? 'right' : 'left',

                                                                ...(isAllCategoriesSelected && {
                                                                    color: '#0000FF !important',
                                                                }),
                                                            },
                                                        }}
                                                        primary={
                                                            <div className="d-flex no-wrap align-items-center">
                                                                <img
                                                                    width={22}
                                                                    height={22}
                                                                    className={`cat-icon ${
                                                                        i18n.language === 'ar' ? 'ml-2' : 'mr-2'
                                                                    }`}
                                                                    src={require('./Categories/icons/all.svg')?.default}
                                                                    alt={'All categories'}
                                                                />
                                                                <span
                                                                    className={`fs-14 pt-1 checkbox-label-font ${
                                                                        i18n.language === 'ar' && 'ar'
                                                                    }`}
                                                                >
                                                                    {t('All categories')}
                                                                </span>
                                                            </div>
                                                        }
                                                    />
                                                </MenuItem>
                                                {categoriesIds.map((name) => (
                                                    <MenuItem
                                                        sx={{
                                                            '.MuiListItemText-primary': {
                                                                fontSize: '14px !important',
                                                                fontWeight: '500 !important',
                                                                color: '#243548',
                                                                textAlign: i18n.language === 'ar' ? 'right' : 'left',
                                                            },
                                                            '&.Mui-selected': {
                                                                backgroundColor: 'unset',
                                                                '.MuiListItemText-primary': {
                                                                    backgroundColor: 'unset',
                                                                    color: '#0000FF !important',
                                                                },
                                                            },
                                                        }}
                                                        key={name}
                                                        value={name}
                                                    >
                                                        <Checkbox
                                                            sx={{
                                                                '&.MuiCheckbox-colorPrimary.Mui-checked': {
                                                                    color: '#0A20E1 !important',
                                                                },
                                                            }}
                                                            checked={selectedCategories.indexOf(name) > -1}
                                                        />
                                                        <ListItemText
                                                            primary={
                                                                <div className="d-flex no-wrap align-items-center">
                                                                    <img
                                                                        width={22}
                                                                        className={`cat-icon ${
                                                                            i18n.language === 'ar' ? 'ml-2' : 'mr-2'
                                                                        }`}
                                                                        src={
                                                                            categoriesList.find(
                                                                                (cat) => cat.id === name,
                                                                            )?.image
                                                                        }
                                                                        alt={
                                                                            categoriesList.find(
                                                                                (cat) => cat.id === name,
                                                                            )[
                                                                                i18n.language === 'ar'
                                                                                    ? 'titleAr'
                                                                                    : 'title'
                                                                            ]
                                                                        }
                                                                    />
                                                                    <span
                                                                        className={`pt-1 checkbox-label-font ${
                                                                            i18n.language === 'ar' && 'ar'
                                                                        }`}
                                                                    >
                                                                        {
                                                                            categoriesList.find(
                                                                                (cat) => cat.id === name,
                                                                            )[
                                                                                i18n.language === 'ar'
                                                                                    ? 'titleAr'
                                                                                    : 'title'
                                                                            ]
                                                                        }
                                                                    </span>
                                                                </div>
                                                            }
                                                        />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Tab.Content>
                                <Tab.Pane eventKey="uae">
                                    <CustomizedTab stores={uaeStores} selectedCategories={selectedCategories} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="us">
                                    <CustomizedTab stores={usStores} selectedCategories={selectedCategories} />
                                </Tab.Pane>
                                {/* <Tab.Pane eventKey="uk">
                                    <CustomizedTab
                                        stores={ukStores}
                                        selectedCategories={selectedCategories}
                                    />
                                </Tab.Pane> */}
                            </Tab.Content>
                        </div>
                    </Tab.Container>
                </div>
            </div>
        </div>
    )
}

export default Stores
