import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'

import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import { postData, getData } from '../../utils/api'
import { useAuth } from '../../contexts/AuthContext'

import PaymentCard from './card'
import PaymentCardList from './cardList'
import { checkToggleByName } from '../../services/parcelApi'
import { TOGGLES } from '../../constants/constants'
import * as navigationConstants from '../../constants/navigationConstants'
import { Link } from 'react-router-dom'
import useSavedCardsList from '../../utils/useSavedCardsList'
import { useMediaQuery } from 'react-responsive'
import { Alert } from 'react-bootstrap'

export default ({
    paymentRequestData,
    paymentType,
    onPaymentCompletion,
    setLoaderMessage,
    setLoading,
    consolidationFlow,
}) => {
    const { t, i18n } = useTranslation()
    const { authToken, userPin, setMembershipPlanID } = useAuth()

    const stripe = useStripe()
    const elements = useElements()

    const showSkip = !['parcel', 'consolidation'].includes(paymentType) && paymentRequestData.amount === 0
    const [isValid, setIsValid] = useState(false)
    const [name, setName] = useState('')
    const [saveCard, setSaveCard] = useState(paymentType.includes('membership'))
    const [selectedCard, setSelectedCard] = useState(null)
    const [savedCardList, setSavedCardList] = useState([])
    const [paymentError, setPaymentError] = useState('')
    const [paymentButtonText, setPaymentButtonText] = useState('')
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

    const onNameChange = (e) => {
        let value = e.target.value
        setName(value)
    }

    const toggleSaveCard = (e) => {
        setSaveCard(e.target.checked)
    }

    const onCardElementsValueChange = (event, element) => {
        var displayError = document.getElementById(`card-${element}-error`)
        if (event.error) {
            displayError.textContent = event.error.message
            setIsValid(false)
        } else {
            displayError.textContent = ''
            setIsValid(true)
        }
    }

    const handleSubmit = async (event, skip = false) => {
        event.preventDefault()
        let request = {
            ...paymentRequestData,
            name: name,
            user_pin: userPin,
        }
        if (!skip) {
            if (!isValid) return
            setLoading(true)
            setLoaderMessage(t('Please wait while we process your payment.'))
            if (selectedCard && selectedCard.card_reference) {
                request['customer_id'] = selectedCard.card_reference
                request['name'] = selectedCard.name
            } else {
                const { error, paymentMethod } = await stripe.createPaymentMethod({
                    type: 'card',
                    card: elements.getElement(CardNumberElement),
                })

                if (!error) {
                    const { id, card } = paymentMethod
                    request['id'] = id
                    request['save_card'] = saveCard
                    request['card'] = card
                } else {
                    console.log(error.message)
                    setPaymentError(t(error.message))
                    setLoading(false)
                    return
                }
            }

            if (!request.customer_id && !request.card) return
        }
        setLoading(true)
        try {
            let response = await postData(
                'paymentService',
                `/pay?type=${skip ? 'membership-skip' : paymentType}`,
                request,
                authToken,
            )
            response.skip = skip
            onPaymentCompletion(response)
        } catch (error) {
            console.log('error - ', error)
            onPaymentCompletion(error)
        }
        setLoading(false)
    }

    const getSavedCard = async () => {
        const response = await getData('paymentService', '/cards', authToken)
        if (response.status && response.payload && response.payload.length) {
            setSavedCardList(response.payload)
        }
        setLoading(false)
    }

    useEffect(async () => {
        await getSavedCard()
    }, [])

    useEffect(() => {
        if (selectedCard) {
            setName(selectedCard.name)
            setIsValid(true)
        }
    }, [selectedCard])

    useEffect(() => {
        setPaymentButtonText(saveCard && paymentRequestData.amount === 0 ? 'Save Card' : 'Save and Continue')
    }, [paymentRequestData.amount])

    return (
        <>
            {paymentError && <Alert variant={'danger'}>{paymentError}</Alert>}
            <h6 className="mb-3">
                {t('Payment Option')}
                <span className="color-green mx-2">*</span>
            </h6>
            <form onSubmit={handleSubmit} className="payment-stripe-checkout">
                <Row className={isMobile ? 'px-3' : ''}>
                    <Col md={12} className="mobile-no-padding mobile-top-padding">
                        {!savedCardList || !savedCardList.length ? (
                            <PaymentCard
                                name={name}
                                onNameChange={onNameChange}
                                onCardElementsValueChange={onCardElementsValueChange}
                                toggleSaveCard={paymentType === 'membership' ? false : toggleSaveCard}
                            />
                        ) : (
                            <PaymentCardList
                                name={name}
                                onNameChange={onNameChange}
                                onCardElementsValueChange={onCardElementsValueChange}
                                toggleSaveCard={toggleSaveCard}
                                savedCardList={savedCardList}
                                selectedCard={selectedCard}
                                setSelectedCard={setSelectedCard}
                            />
                        )}
                    </Col>
                </Row>
                <Row
                    className={`paddingtop15 ${showSkip && !isMobile ? `padding-3 ${i18n.language === 'ar' ? 'justify-content-end' : ''}` : ''}`}
                    id="payment_credit"
                >
                    <Col md={8} />
                    <Col md={4}>
                        {consolidationFlow &&
                        paymentRequestData.consolidation_type &&
                        paymentRequestData.consolidation_type !== '' ? null : (
                            <div className="mobile-pay-now pb-3 px-2">
                                <Row className="mobile-show-subtotal font-weight-600 mobile-subtotal-padding ">
                                    <div className="mobile-width-50-percent float-left float-right-ar">
                                        {t('Subtotal')}
                                    </div>
                                    <div
                                        className={`mobile-width-50-percent ${i18n.language === 'ar' ? 'text-left' : 'text-right'}`}
                                    >
                                        {paymentRequestData.amount && paymentRequestData.amount < 0
                                            ? 0
                                            : paymentRequestData.amount?.toFixed(2)}
                                        $
                                    </div>
                                </Row>
                            </div>
                        )}
                    </Col>
                    {showSkip && (
                        <Col
                            xs={{ span: isMobile ? 12 : 3, offset: isMobile || i18n.language === 'ar' ? 0 : 5 }}
                            className={isMobile ? 'btn color-inverse-default-button text-center mb-4 py-2' : ' pt-2'}
                        >
                            <Link
                                onClick={(e) => handleSubmit(e, true)}
                                className="font-weight-bold"
                                to={navigationConstants.VERIFY_EMAIL}
                            >
                                {t('Skip for now')}
                            </Link>
                        </Col>
                    )}
                    <Col xs={{ span: isMobile ? 12 : showSkip ? 4 : 12 }} className="mobile-checkout">
                        <button
                            className="rounded form-control border-radius-10 padding-vertical-15 font-weight-500 btn btn-primary"
                            disabled={!(isValid && name !== '')}
                            id="save_payment"
                            type="submit"
                        >
                            <span className="credit-spinner spinner-border spinner-border-sm d-none white-color margin-btm-4" />
                            <span className="credit-text">{t(paymentButtonText)}</span>
                        </button>
                    </Col>
                </Row>
            </form>
        </>
    )
}
