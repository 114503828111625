import React, { useEffect, useState } from 'react'
import CarouselImageSet from './carouselImageSet'
import { useTranslation } from 'react-i18next'
import { Container } from 'react-bootstrap'
import './shopUsBrandsSection.css'
import { useMediaQuery } from 'react-responsive'

function ShopUsBrandsSection() {
    const { t, i18n } = useTranslation()
    const [imagesSrc, setImagesSrc] = useState([])
    const isMobile = useMediaQuery({ query: '(max-width: 1017px)' })
    const isMediumLarge = useMediaQuery({ minWidth: 1017, maxWidth: 1552 })

    useEffect(() => {
        const svgs = async () => {
            const reqSvgs = await require.context('../shopUsBrandsSection', true, /\.svg$/)
            return reqSvgs.keys().map((path) => ({ path, file: reqSvgs(path) }))
        }

        async function getSvgs() {
            const x = await svgs()
            setImagesSrc(x)
        }
        getSvgs()
    }, [setImagesSrc])

    return (
        <Container fluid className="favorite-brands-container text-center">
            <Container fluid className="container-width">
                <p className="section-title">{t('Shop from your favorite brand')}</p>
                <CarouselImageSet isMobile={isMobile} imagesSrc={imagesSrc} isMediumLarge={isMediumLarge} />
            </Container>
        </Container>
    )
}

export default ShopUsBrandsSection
