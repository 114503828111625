import React, { useState, useEffect } from 'react'
import { Tab, Tabs, Table, Row, Col, Spinner } from 'react-bootstrap'
import { FaSearch, FaCircle } from 'react-icons/fa'
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'

import { ParcelDrawer, RouteProgressBlock } from '../style'

import { parcelFormattedDimensions, parcelFormattedWeight } from '../../../utils/parcel'
import { getData } from '../../../utils/api'

import { useAuth } from '../../../contexts/AuthContext'

import { parcelTableDateFormat } from '../../../utils/moment'
import ParcelImageView from './images'
import { logDOM } from '@testing-library/react'
import { PARCEL_CONSTANTS } from '../../../constants/constants'
import useAnalytics from '../../../analytics/useAnalytics'
import { screenEvent } from '../../../analytics/constants'
import { isNull } from 'lodash'

export default ({ parcel, isDrawerOpen, setIsDrawerOpen }) => {
    const { t, i18n } = useTranslation()
    const { authToken } = useAuth()
    const analytics = useAnalytics()
    const [parcelDetails, setParcelDetails] = useState({})
    const [openedBoxTrackingDetails, setOpenedBoxTrackingDetails] = useState('')
    const [secondaryBoxes, setSecondaryBoxes] = useState([])

    const [loading, setLoading] = useState(true)

    const parcelType = (openedParcel) => openedParcel?.uuid?.substring(0, 2)
    const isConsolidation = (openedParcel) =>
        [PARCEL_CONSTANTS.BOX_CONSOLIDATION, PARCEL_CONSTANTS.ITEM_CONSOLIDATION].includes(parcelType(openedParcel))
    const isMps = (openedParcel) => openedParcel?.is_mps

    const prepareLogs = (parcel) => {
        let lmStatuses = parcel['IntegrationTrackingStatusesLogs'] || []
        if (parcel.ParcelLogs && parcel.ParcelLogs.length > 0) {
            parcel['ParcelLogs'] = [
                ...(parcel['ParcelLogs'] || []),
                ...(lmStatuses
                    .filter(
                        (lm) =>
                            lm.status_id !== PARCEL_CONSTANTS.DELIVERED_PARCEL_STATUS_ID && !isNull(lm?.TrackingStatus),
                    )
                    .map((l) => {
                        let detail = l?.TrackingStatus?.detail || ''
                        delete l['TrackingStatus']
                        return {
                            ...l,
                            detail,
                            ParcelStatus: {
                                name: detail,
                            },
                        }
                    }) || []),
            ]
        }
        delete parcel['IntegrationTrackingStatusesLogs']
    }

    useEffect(() => {
        analytics.events.screenVisited({ screen: screenEvent.SHIPMENT_DETAILS })
    }, [])

    useEffect(async () => {
        if (parcel.uuid) {
            let response = {}
            if (isConsolidation(parcel)) {
                response = await getData('parcelService', `/parcel/multi-box/${parcel.uuid}`, authToken)
            } else {
                response = await getData('parcelService', `/parcel/${parcel.uuid}`, authToken)
            }
            let sBoxes = []
            if (response.status && response.payload) {
                if (response?.payload.is_mps) {
                    sBoxes = response?.payload?.secondaryBoxes
                    for (let sBox of sBoxes) {
                        prepareLogs(sBox)
                    }
                    setSecondaryBoxes(sBoxes)
                    delete response?.payload?.secondaryBoxes
                }
                let mainParcel = response?.payload
                prepareLogs(mainParcel)
                setParcelDetails(mainParcel)
                // console.table(response);
                setOpenedBoxTrackingDetails(
                    (isConsolidation(parcel) && sBoxes?.length === 0) || !isConsolidation(parcel) ? parcel.uuid : '',
                )
                setLoading(false)
            }
        }
    }, [isDrawerOpen])

    const generateParcelDetailsRow = (parcelRow) => {
        return (
            <>
                {isConsolidation(parcelRow) ? (
                    <Row>
                        <Col
                            xs={6}
                            md={6}
                            className={`py-3 topbrdr0 ${i18n.language === 'ar' ? 'mr-1 text-right' : 'ml-1 text-left'}`}
                        >
                            <a target="_blank" href={parcelRow?.ParcelShipmentTracking?.tracking_label}>
                                <i className={`${i18n.language === 'ar' ? 'ml-2' : 'mr-2'} far fa-file-alt`}></i>
                                {t('View Air waybill')}
                            </a>
                        </Col>
                    </Row>
                ) : (
                    <>
                        <Row>
                            <Col
                                xs={6}
                                md={6}
                                className={`py-3 topbrdr0 ${
                                    i18n.language === 'ar' ? 'mr-1 text-right' : 'ml-1 text-left'
                                }`}
                            >
                                <a target="_blank" href={parcelRow?.invoice_link}>
                                    <i className={`${i18n.language === 'ar' ? 'ml-2' : 'mr-2'} far fa-file-alt`}></i>
                                    {t('View Invoice')}
                                </a>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                xs={6}
                                md={6}
                                className={`py-3 topbrdr0 ${
                                    i18n.language === 'ar' ? 'mr-1 text-right' : 'ml-1 text-left'
                                }`}
                            >
                                <a target="_blank" href={parcelRow?.ParcelShipmentTracking?.tracking_label}>
                                    <i className={`${i18n.language === 'ar' ? 'ml-2' : 'mr-2'} far fa-file-alt`}></i>
                                    {t('View Air waybill')}
                                </a>
                            </Col>
                        </Row>
                    </>
                )}
                <Table responsive className={`table-thead ${!isConsolidation(parcelRow) && 'mt-3'}`}>
                    <tbody>
                        <tr className="fstyle-w500 fs-12 gray-2 line-height-p160 bg-color-gray2">
                            <td width="3%" className="">
                                #
                            </td>
                            <td>{t('Parcel ID')}</td>
                            <td className="th-wd-125">{t('Actual Weight')}</td>
                            <td className="th-wd-120">{t('Dims Inch')} </td>
                            <td>{t('Value usd')}</td>
                        </tr>
                        {parcelRow.ParcelChildren &&
                            parcelRow.ParcelChildren.length > 0 &&
                            parcelRow.ParcelChildren.map((item, index) => {
                                return (
                                    <tr className="fs-14 line-height-p160">
                                        <td>{index + 1}</td>
                                        <td>{item.uuid}</td>
                                        <td>
                                            <nobr>
                                                {item?.weight_value && item?.weight_unit ? (
                                                    <>
                                                        {item?.weight_value}
                                                        <span className="text-uppercase">
                                                            {' '}
                                                            {item?.weight_unit || 'lb'}{' '}
                                                        </span>
                                                    </>
                                                ) : (
                                                    t('In Progress')
                                                )}
                                            </nobr>
                                        </td>
                                        <td>
                                            <nobr>{t(parcelFormattedDimensions(item, t))}</nobr>
                                        </td>
                                        <td>
                                            <nobr>$ {item?.amount}</nobr>
                                        </td>
                                    </tr>
                                )
                            })}
                        <tr className="fstyle-w500 fs-15 line-height-p160">
                            <td colSpan="2">{parcelRow.uuid}</td>
                            <td>
                                <nobr>
                                    {parcelRow?.weight_value && parcelRow.weight_unit ? (
                                        <>
                                            {parcelRow?.weight_value}
                                            <span className="text-uppercase">{parcelRow.weight_unit || 'lb'}</span>
                                        </>
                                    ) : (
                                        t('In Progress')
                                    )}
                                </nobr>
                            </td>
                            <td className="td-wd-210 direction-ltr">
                                <nobr>{t(parcelFormattedDimensions(parcelRow, t))}</nobr>
                            </td>
                            <td className="td-wd-130">
                                <nobr>$ {parcelRow.amount}</nobr>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                {parcelRow?.ParcelFiles && parcelRow.ParcelFiles.length ? (
                    <ParcelImageView parcel={parcelRow} width={'100%'} height={'345'} hasSearchIcon={false} />
                ) : null}
                {/* <Row className="parcel-accordion-items">
          <Col
            xs={6}
            sm={6}
            className={`fontnclr12 topbrdr0 ${
              i18n.language === "ar" ? "text-right" : "text-left"
            }`}
          >
            {t("Supplier")}
          </Col>
          <Col
            xs={6}
            sm={6}
            className={`topbrdr0 ${
              i18n.language === "ar" ? "text-right" : "text-left"
            }`}
          >
            {parcelRow?.Website && parcelRow.Website.name ? (
              parcelRow.Website.name
            ) : (
              <span className="fs-12 gray-3">{t("In progress")}</span>
            )}
          </Col>
        </Row> */}
                <Row className="parcel-accordion-items">
                    <Col
                        xs={6}
                        md={6}
                        className={`fontnclr12 topbrdr0 ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}
                    >
                        {t('Actual Weight')}
                    </Col>
                    <Col xs={6} md={6} className={`topbrdr0 ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}>
                        {parcelRow?.weight_value ? (
                            t(parcelFormattedWeight(parcelRow))
                        ) : (
                            <span className="fs-12 gray-3">{t('In progress')}</span>
                        )}
                    </Col>
                </Row>
                <Row className="parcel-accordion-items">
                    <Col
                        xs={6}
                        md={6}
                        className={`fontnclr12 topbrdr0 ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}
                    >
                        {t('Dimensions')}
                    </Col>
                    <Col xs={6} md={6} className={`topbrdr0 ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}>
                        {parcelRow?.dimension_length ? (
                            t(parcelFormattedDimensions(parcelRow, t))
                        ) : (
                            <span className="fs-12 gray-3">{t('In progress')}</span>
                        )}
                    </Col>
                </Row>
                <Row className="parcel-accordion-items">
                    <Col
                        xs={6}
                        md={6}
                        className={`fontnclr12 topbrdr0 ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}
                    >
                        {t('Chargeable Weight')}
                    </Col>
                    <Col xs={6} md={6} className={`topbrdr0 ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}>
                        {parcelRow?.chargeable_weight ? (
                            t(parcelFormattedWeight(parcelRow, false))
                        ) : (
                            <span className="fs-12 gray-3">{t('In progress')}</span>
                        )}
                    </Col>
                </Row>
                {!isConsolidation(parcelRow) && !isMps(parcelRow) && (
                    <>
                        <Row className="parcel-accordion-items">
                            <Col
                                xs={6}
                                md={6}
                                className={`fontnclr12 topbrdr0 ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}
                            >
                                {t('Shipping Fees')}
                            </Col>

                            <Col
                                xs={6}
                                md={6}
                                className={`topbrdr0 ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}
                            >
                                {parcelRow.ParcelPayment && parcelRow.ParcelPayment.charges ? (
                                    (parcelRow?.cod ? 0.0 : parcelRow.ParcelPayment.charges) + ' USD'
                                ) : (
                                    <span className="fs-12 gray-3">{t('NA')}</span>
                                )}
                            </Col>
                        </Row>
                        <Row className="parcel-accordion-items">
                            <Col
                                xs={6}
                                md={6}
                                className={`fontnclr12 topbrdr0 ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}
                            >
                                {t('Payment Type')}
                            </Col>

                            <Col
                                xs={6}
                                md={6}
                                className={`topbrdr0 ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}
                            >
                                {!parcelRow?.cod
                                    ? t('ppd')
                                    : `${t('cod')} (${parcelRow?.ParcelPayment?.charges || 0} USD)`}
                            </Col>
                        </Row>
                    </>
                )}
                <p className="pl-2">
                    <a
                        className={`link-items-popup my-1 ${i18n.language === 'ar' ? 'float-right' : 'float-left'}`}
                        data-toggle="collapse"
                        href="#collapseItems"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseItems"
                    >
                        {parcelRow.ParcelItems && parcelRow.ParcelItems.length ? (
                            <>
                                {`${t('See')} ${parcelRow.ParcelItems.length} ${t('Items')} `}{' '}
                                <i className="bx bx-chevron-down" />
                            </>
                        ) : (
                            t('In Progress')
                        )}
                    </a>
                </p>
                <div className="collapse" id="collapseItems">
                    <table
                        id="itemParcelTable"
                        className="table table-bordred"
                        style={{ 'border-collapse': 'collapse' }}
                    >
                        <tbody>
                            {parcelRow.ParcelItems &&
                                parcelRow.ParcelItems.length &&
                                parcelRow.ParcelItems.map((item, index) => {
                                    return (
                                        <tr className="fontn16 d-flex justify-content-between align-items-center">
                                            <td className="topbrdr0">{item.item_name}</td>

                                            <td className="text-right topbrdr0 ">
                                                {i18n.language === 'ar' ? (
                                                    <span className="float-left">X {item.quantity}</span>
                                                ) : (
                                                    <span className="float-right">X {item.quantity}</span>
                                                )}
                                            </td>
                                            <td className="text-right topbrdr0 ">$ {item.amount}</td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </table>
                </div>
                {/* <div className="float-right hide"  style="max-width:350px ">
				<a href="{url('/')}" className="">{t('View Shipping Invoice')}</a>
				</div> */}
            </>
        )
    }

    const mpsTrackingNumberList = (mps) => {
        const trackingNoList = isConsolidation(mps) ? (
            <div
                aria-controls={mps.uuid}
                aria-expanded={openedBoxTrackingDetails === mps.uuid}
                className="mx-2 d-flex justify-content-between my-3"
            >
                <span>{`${t('Parcel Tracking No')} ${mps?.ParcelShipmentTracking?.tracking_number || ''}`}</span>
                <span
                    onClick={() => setOpenedBoxTrackingDetails(openedBoxTrackingDetails === mps.uuid ? '' : mps.uuid)}
                >
                    {openedBoxTrackingDetails === mps.uuid ? (
                        <i class="bx bx-chevron-up font-size-20"></i>
                    ) : (
                        <i class="bx bx-chevron-down font-size-20"></i>
                    )}
                </span>
            </div>
        ) : null
        let parcelInternalLogs =
            mps.ParcelLogs && mps.ParcelLogs.length
                ? mps.ParcelLogs?.filter(
                      (pl) =>
                          pl?.parcel_status_id &&
                          ![
                              PARCEL_CONSTANTS.DELIVERED_PARCEL_STATUS_ID,
                              PARCEL_CONSTANTS.SHOTLM_PARCEL_STATUS_ID,
                          ].includes(Number(pl?.parcel_status_id)),
                  ).sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                : []
        let parcelExternalLogs =
            mps.ParcelLogs && mps.ParcelLogs.length
                ? mps.ParcelLogs?.filter(
                      (pl) =>
                          !pl?.parcel_status_id ||
                          [
                              PARCEL_CONSTANTS.DELIVERED_PARCEL_STATUS_ID,
                              PARCEL_CONSTANTS.SHOTLM_PARCEL_STATUS_ID,
                          ].includes(Number(pl?.parcel_status_id)),
                  ).sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                : []
        let parcelLogs = [...parcelExternalLogs, ...parcelInternalLogs]
        return (
            <>
                {trackingNoList}
                <div className={openedBoxTrackingDetails === mps.uuid && 'bg-color-gray2 pb-3 '}>
                    {parcelLogs &&
                        parcelLogs.length &&
                        parcelLogs.map((log, index) => {
                            return (
                                <>
                                    <div
                                        className={`collapse ${openedBoxTrackingDetails === mps.uuid && 'show'}`}
                                        id={mps.uuid}
                                    >
                                        <div className="tracking-item">
                                            <div className="tracking-icon status-intransit">
                                                {/* <FaCircle height={"100%"} width={"100%"} /> */}
                                                <svg
                                                    className="svg-inline--fa fa-circle fa-w-16"
                                                    aria-hidden="true"
                                                    data-prefix="fas"
                                                    data-icon="circle"
                                                    role="img"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    data-fa-i2svg=""
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                                    />
                                                </svg>
                                            </div>
                                            <div className="tracking-content">
                                                {parcelTableDateFormat(log.created_at)}
                                                <span>{log.country || 'United States'}</span>
                                                <span>
                                                    {log.detail !== ''
                                                        ? t(log.detail?.replaceAll(':', ''))
                                                        : t(log.ParcelStatus.name?.replaceAll(':', ''))}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                </div>
            </>
        )
    }

    const boxDetailes = (parcel, index = -1) => {
        return (
            <>
                <Table className="track-carrier-table">
                    <tbody>
                        <tr className={index > 0 && 'd-none'}>
                            <td
                                className={`border-bottom-0 py-3 ${i18n.language === 'ar' ? 'pr-1 text-right' : 'pl-1 text-left'}`}
                            >
                                <a target="_blank" href={parcel?.invoice_link}>
                                    <i className={`${i18n.language === 'ar' ? 'ml-2' : 'mr-2'} far fa-file-alt`}></i>
                                    {t('View Invoice')}
                                </a>
                            </td>
                        </tr>
                        <tr className={index > 0 && 'd-none'}>
                            <td
                                className={`border-bottom-0 font16-left-gry3 topbrdr0 ${
                                    i18n.language === 'ar' ? 'text-right' : 'text-left'
                                }`}
                            >
                                {t('Consolidation Type')}
                            </td>
                            <td
                                className={`border-bottom-0 topbrdr0 ${
                                    i18n.language === 'ar' ? 'text-left' : 'text-right'
                                } 
                `}
                            >
                                {(parcelType(parcel) === 'IC' && t('Item Consolidation')) ||
                                    (parcelType(parcel) === 'BC' && t('Box Consolidation'))}
                            </td>
                        </tr>
                        <tr className={index > 0 && 'd-none'}>
                            <td
                                className={`border-bottom-0 font16-left-gry3 topbrdr0 ${
                                    i18n.language === 'ar' ? 'text-right' : 'text-left'
                                }`}
                            >
                                {t('Shipping Fees')}
                            </td>

                            <td
                                className={`border-bottom-0 topbrdr0 ${
                                    i18n.language === 'ar' ? 'text-left' : 'text-right'
                                }`}
                            >
                                {parcel?.cod ? 0.0 : parcel?.ParcelPayment?.charges || 0} USD
                            </td>
                        </tr>
                        <tr className={index > 0 && 'd-none'}>
                            <td
                                className={`border-bottom-0 font16-left-gry3 topbrdr0 ${
                                    i18n.language === 'ar' ? 'text-right' : 'text-left'
                                }`}
                            >
                                {t('Payment Type')}
                            </td>

                            <td
                                className={`border-bottom-0 topbrdr0 ${
                                    i18n.language === 'ar' ? 'text-left' : 'text-right'
                                }`}
                            >
                                {!parcel?.cod ? t('ppd') : `${t('cod')} (${parcel?.ParcelPayment?.charges || 0} USD)`}
                            </td>
                        </tr>
                        <tr>
                            <td
                                className={`${
                                    i18n.language !== 'ar' ? 'text-left rounded-left' : 'text-right rounded-right'
                                } px-2 text-dark-grey fs-16 topbrdr0 border-top-0 border-bottom-0 light-red-box`}
                            >
                                {t('Box')} {index !== -1 && index + 1}
                            </td>
                            <td
                                className={`${
                                    i18n.language !== 'ar' ? 'text-right rounded-right' : 'text-left rounded-left'
                                } px-2 border-top-0 border-bottom-0 light-red-box topbrdr0`}
                            >
                                <a
                                    className="consolidated-parcel"
                                    data-toggle="collapse"
                                    href={`#Box-${parcel.uuid}`}
                                    role="button"
                                    aria-expanded={openedBoxTrackingDetails === parcel.uuid}
                                    aria-controls={`Box-${parcel.uuid}`}
                                    onClick={() =>
                                        setOpenedBoxTrackingDetails(
                                            openedBoxTrackingDetails === parcel.uuid ? parcel.uuid : '',
                                        )
                                    }
                                >
                                    {openedBoxTrackingDetails === parcel.uuid ? (
                                        <i className="bx bx-chevron-up font-size-20" />
                                    ) : (
                                        <i className="bx bx-chevron-down font-size-20" />
                                    )}
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <div
                    className={`table-responsive collapse mb-3 ${openedBoxTrackingDetails === parcel.uuid && 'show'}`}
                    id={`Box-${parcel.uuid}`}
                >
                    {generateParcelDetailsRow(parcel)}
                </div>
            </>
        )
    }

    return (
        <>
            <SwipeableDrawer
                anchor={'right'}
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
                onOpen={() => setIsDrawerOpen(true)}
                variant={'temporary'}
            >
                <ParcelDrawer>
                    {!loading && Object.keys(parcel).length && isDrawerOpen ? (
                        <>
                            <span
                                className="bx mrg-lft3 larrow-gry"
                                data-dismiss="drawer"
                                aria-label="Close"
                                onClick={() => setIsDrawerOpen(false)}
                            >
                                {i18n.language === 'ar' ? <FiArrowRight /> : <FiArrowLeft />}
                            </span>
                            <p className="acph" id="parcel-awb">
                                {parcelDetails.uuid}
                            </p>
                            <div id="apmodal">
                                <Tabs defaultActiveKey="route-progress" id="uncontrolled-tab-example">
                                    <Tab
                                        eventKey="route-progress"
                                        title={t('Route Progress')}
                                        tabClassName="mrl0 drawer-tabs"
                                        id="nav-route-tab"
                                    >
                                        <RouteProgressBlock>
                                            {parcelDetails.ParcelPayment ? (
                                                <Table className="track-carrier-table">
                                                    <tbody>
                                                        <tr>
                                                            <td
                                                                className={`font16-left-gry3 topbrdr0 ${
                                                                    i18n.language === 'ar' ? 'text-right' : 'text-left'
                                                                }`}
                                                            >
                                                                {t('Shipping Company')}
                                                            </td>
                                                            <td
                                                                className={`topbrdr0 ${
                                                                    i18n.language === 'ar' ? 'text-left' : 'text-right'
                                                                }`}
                                                            >
                                                                {parcelDetails.ParcelPayment.ShippingCompany ? (
                                                                    <>
                                                                        <img
                                                                            src={`/images/logo/${parcelDetails.ParcelPayment.ShippingCompany.logo}`}
                                                                            width="50"
                                                                            alt={'shipping-logo'}
                                                                        />
                                                                        {parcelDetails.ParcelPayment.ShippingCompany
                                                                            .shipping_name || null}
                                                                    </>
                                                                ) : null}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                className={`font16-left-gry3 topbrdr0 ${
                                                                    i18n.language === 'ar' ? 'text-right' : 'text-left'
                                                                }`}
                                                            >
                                                                {parcelDetails?.ParcelChildren.length > 0
                                                                    ? t('MASTER')
                                                                    : t('Tracking Number')}
                                                            </td>
                                                            <td
                                                                className={`fontn16-rght ${
                                                                    i18n.language === 'ar' ? 'text-left' : 'text-right'
                                                                }`}
                                                            >
                                                                {parcelDetails.ParcelShipmentTracking &&
                                                                parcelDetails.ParcelShipmentTracking.tracking_number ? (
                                                                    <a
                                                                        href={
                                                                            parcelDetails.ParcelPayment?.ShippingCompany
                                                                                ?.tracking_url ||
                                                                            parcelDetails?.ParcelShipmentTracking
                                                                                ?.tracking_label ||
                                                                            ''
                                                                        }
                                                                        id={
                                                                            parcelDetails.ParcelShipmentTracking
                                                                                .tracking_number
                                                                        }
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        {
                                                                            parcelDetails.ParcelShipmentTracking
                                                                                .tracking_number
                                                                        }
                                                                    </a>
                                                                ) : (
                                                                    t('In Progress')
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            ) : null}
                                            <>
                                                {isConsolidation(parcelDetails) && isMps(parcelDetails)
                                                    ? [parcelDetails, ...secondaryBoxes].map((box) =>
                                                          mpsTrackingNumberList(box),
                                                      )
                                                    : mpsTrackingNumberList(parcelDetails)}
                                            </>
                                        </RouteProgressBlock>
                                    </Tab>
                                    <Tab
                                        eventKey="parcel-details"
                                        title={t('Parcel Details')}
                                        id="nav-detail-parcel-tab"
                                        tabClassName={'parcel-drawer-details-tab drawer-tabs drawer-tabs'}
                                    >
                                        {isConsolidation(parcelDetails)
                                            ? isMps(parcelDetails)
                                                ? [parcelDetails, ...secondaryBoxes]?.map((box, index) =>
                                                      boxDetailes(box, index),
                                                  )
                                                : boxDetailes(parcelDetails)
                                            : generateParcelDetailsRow(parcelDetails)}
                                    </Tab>
                                </Tabs>
                            </div>
                        </>
                    ) : (
                        <div className="loading">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">{t('Loading')}</span>
                            </Spinner>
                        </div>
                    )}
                </ParcelDrawer>
            </SwipeableDrawer>
        </>
    )
}
