import React from 'react'
import oneSvg from './one.svg'
import { useTranslation } from 'react-i18next'

function StepOne() {
    const { t, i18n } = useTranslation()
    return (
        <div className="main-section-container grey-background">
            <div className="container mb-4">
                <h1 className="sub-page-header">{t('Why choose us?')}</h1>
                <div className="col-12 col-sm-12 col-md-8  mx-auto">
                    <p className="mb-5">{t('Why choose us? description')}</p>
                </div>
                <div className="row justify-content-between justify-content-around-under-768 mt-4">
                    <div className="col-12 col-sm-9 col-md-5 flex-grow-1 mb-4 align-self-center text-center">
                        <img alt="..." className="width-100 big-section-img" src={oneSvg} />
                    </div>
                    <div className="col-12 col-sm-9 col-md-5 flex-grow-1 mb-4 post-450-start-text-align align-self-center">
                        <h2 className="sub-page-section-header mb-3">{t('Discounted shipping rates')}</h2>
                        <p className="text-grey">{t('Discounted shipping rates in why choose us page description')}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StepOne
