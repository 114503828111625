import React, { useEffect, useRef, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import { useAuth } from '../../contexts/AuthContext'

import * as NAVIGATION_CONSTANTS from '../../constants/navigationConstants'
import { MEMBERSHIP_CONSTANTS } from '../../constants/constants'

import PaymentSummary from '../../components/membershipPaymentSummary'
import Payment from '../../components/stripe'

import { Campaign, Loader } from '../../components/common'
import { useEmailAndPlanChecking } from '../../components/auth'
import AmazonPay from '../../components/AmazonPay'

import { postData } from '../../utils/api'
import useAnalytics from '../../analytics/useAnalytics'
import { screenEvent } from '../../analytics/constants'

export default (props) => {
    const { t, i18n } = useTranslation()
    const {
        authToken,
        membershipPlanID,
        setMembershipPlanID,
        setDefaultPromoCode,
        isSubYearly,
        isRenewalYearly,
        userEmail,
        userPin,
    } = useAuth()
    const [membershipId, setMembershipID] = useState(props.location.state?.membership_plan_id ?? membershipPlanID)
    const [loading, setLoading] = useState(true)
    const [paymentRequestData, setPaymentRequestData] = useState({})
    const [paymentDetails, setPaymentDetails] = useState({})
    const [proceedToNextPage, setProceedToNextPage] = useState(null)
    const [redirectToDashboard, setRedirectToDashboard] = useState(false)
    const [paymentStatus, setPaymentStatus] = useState('in-progress')
    const [membershipPlanName, setMembershipPlanName] = useState(null)
    const [membershipCost, setMembershipCost] = useState(null)
    const [creditCard, setCreditCard] = useState({})
    const [paymentButtonText, setPaymentButtonText] = useState('')
    const [isAmazonPay, setIsAmazonPay] = useState(false)
    const [isPaypal, setIsPaypal] = useState(false)
    const [isOthers, setIsOthers] = useState(false)
    const [subDefaultPromoCode, setSubDefaultPromoCode] = useState(null)
    const [proceedToPay, setProceedToPay] = useState(false)
    const location = useLocation()
    const paymentType =
        props.location.state?.membership_plan_id && props.location.state?.membership_plan_id != membershipPlanID
            ? 'membership-update'
            : props.location.state?.renewal?.status
              ? 'membership-renewal'
              : 'membership'
    const planRenewalType = props.location.state?.renewal?.status ? props.location.state?.renewal?.type : null
    const [showSkip, setShowSkip] = useState(false)
    const analytics = useAnalytics()
    const paymentStartedRef = useRef(false)
    const analyticsEventAttributes = {
        membershipType: MEMBERSHIP_CONSTANTS[`${membershipId}`],
        planType: location?.state?.analytics_is_yearly ? 'Yearly' : 'Monthly',
        subtotal: paymentDetails?.grandTotal,
        promoCode: paymentDetails?.discount?.promo_code ?? undefined,
        discountAmount: paymentDetails?.discount?.promo_code
            ? (paymentDetails?.grandTotal - paymentDetails?.discountedTotal ?? 0)
            : undefined,
        totalAmount: paymentDetails?.discount?.promo_code
            ? paymentDetails?.discountedTotal
            : paymentDetails?.grandTotal,
        paymentMethod: (paymentDetails?.promo_code ? paymentDetails?.discountedTotal : paymentDetails?.grandTotal)
            ? creditCard?.card_type === 'PAYPAL'
                ? 'PayPal'
                : creditCard?.card_type === 'AMAZON_PAY'
                  ? 'Amazon'
                  : undefined
            : undefined,
        source: location?.state?.analytics_source ?? 'Registration',
    }

    const onPaymentCompletion = async (response) => {
        if (response && response.status) {
            setPaymentStatus('success')
            localStorage.removeItem('defaultPromoCode')
            setDefaultPromoCode('')
            setMembershipPlanID(membershipId)
            if (paymentType === 'membership-update' && (creditCard?.id || response?.skip)) {
                setProceedToNextPage('update')
            } else if (paymentType === 'membership-renewal' && (creditCard?.id || response?.skip)) {
                setProceedToNextPage('renew')
            } else {
                if (response.skip) {
                    setMembershipPlanID(membershipId)
                    const sendVerificationEmail = async () => {
                        setLoading(true)
                        let response = await postData('userService', '/v2/verify-email', {
                            email: userEmail ? userEmail : localStorage.getItem('userEmail'),
                        })
                        setLoading(false)
                    }
                    try {
                        await sendVerificationEmail()
                        setProceedToNextPage('skip')
                    } catch (error) {
                        setLoading(false)
                        console.log(error?.message)
                    }
                } else {
                    setProceedToNextPage('sub')
                }
            }
        } else {
            setPaymentStatus('fail')
        }
    }

    const initMembershipDetails = () => {
        switch (parseInt(membershipId)) {
            case 2:
                setMembershipPlanName(MEMBERSHIP_CONSTANTS.PREMIUM_NAME)
                if (
                    !(
                        (isSubYearly && paymentType === 'membership') ||
                        (isRenewalYearly && ['membership-renewal', 'membership-update']?.includes(paymentType)) ||
                        (planRenewalType && planRenewalType === 'premMonthly')
                    )
                ) {
                    setMembershipCost(MEMBERSHIP_CONSTANTS.PREMIUM_COST_MONTH)
                } else {
                    setMembershipCost(MEMBERSHIP_CONSTANTS.PREMIUM_COST_YEAR)
                }
                break
            case 3:
                setMembershipPlanName(MEMBERSHIP_CONSTANTS.BUSINESS_NAME)
                if (
                    !(
                        (isSubYearly && paymentType === 'membership') ||
                        (isRenewalYearly && ['membership-renewal', 'membership-update']?.includes(paymentType)) ||
                        (planRenewalType && planRenewalType === 'businessMonthly')
                    )
                ) {
                    setMembershipCost(MEMBERSHIP_CONSTANTS.BUSINESS_COST_MONTH)
                } else {
                    setMembershipCost(MEMBERSHIP_CONSTANTS.BUSINESS_COST_YEAR)
                }
                break
        }
    }

    useEffect(() => {
        initMembershipDetails()

        setLoading(false)
    }, [membershipId, isSubYearly, isRenewalYearly])

    useEffect(() => {
        setPaymentDetails({
            items: [
                {
                    title: 'Plan',
                    value: membershipPlanName,
                },
                {
                    title: 'Subscription',
                    value: `$${membershipCost}`,
                },
                {
                    title: 'VAT',
                    value: 'Free',
                },
            ],
            grandTotal: membershipCost,
            is_yearly: isSubYearly || isRenewalYearly || ['premYearly', 'businessYearly'].includes(planRenewalType),
        })
    }, [membershipCost, membershipPlanName, isSubYearly, isRenewalYearly, planRenewalType])

    useEffect(() => {
        setPaymentRequestData({
            total: paymentDetails.discount ? paymentDetails.discountedTotal : membershipCost,
            membership_plan_id: membershipId,
            discount: paymentDetails.discount || null,
            is_yearly:
                (isSubYearly && paymentType === 'membership') ||
                (isRenewalYearly && ['membership-renewal', 'membership-update']?.includes(paymentType)) ||
                ['premYearly', 'businessYearly'].includes(planRenewalType),
        })
    }, [membershipCost, membershipPlanName, isSubYearly, isRenewalYearly, planRenewalType])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        })
    }, [])

    useEffect(() => {
        if (!authToken || authToken === '') {
            props.history.push('/home')
        } else {
            if (
                ['membership', 'membership-update']?.includes(paymentType) &&
                Number(membershipId) === MEMBERSHIP_CONSTANTS.PREMIUM
            ) {
                if (localStorage.getItem('defaultPromoCode')) {
                    localStorage.removeItem('defaultPromoCode')
                }
                setSubDefaultPromoCode(process.env.REACT_APP_FREE_SUB_OFFER_CODE_PREMIUM_NORMAL)
            }
        }
    }, [membershipId, authToken])

    const handleSubmit = async (event, isPay = false) => {
        event.preventDefault()

        if (analyticsEventAttributes.promoCode) {
            const { membershipType, planType, subtotal, promoCode, discountAmount, totalAmount, source } =
                analyticsEventAttributes
            analytics.events.membershipPromoSubscribeButton({
                membershipType,
                planType,
                subtotal,
                promoCode,
                discountAmount,
                totalAmount,
                source,
            })
        }

        setLoading(true)
        const totalAmount = paymentDetails?.promo_code ? paymentDetails?.discountedTotal : paymentDetails?.grandTotal
        let data = {
            total_amount: totalAmount,
            card: {
                name: creditCard?.name,
                digits: creditCard?.number,
            },
            discount: paymentDetails?.promo_code
                ? {
                      original_amount: paymentDetails?.discount?.original_amount || 0,
                      discounted_amount: paymentDetails?.discount?.discounted_amount || 0,
                      promo_code_value: paymentDetails?.discount?.promo_code_value || 0,
                      promo_code_type: paymentDetails?.discount?.promo_code_type || '',
                      promo_code: paymentDetails?.promo_code || '',
                  }
                : null,
            is_update: paymentType === 'membership-update' ? true : false,
            card_reference: creditCard?.card_reference,
            membership_plan_id: paymentRequestData?.membership_plan_id,
            is_yearly: paymentDetails?.is_yearly,
            user_pin: userPin,
        }
        let response
        if (data.total_amount === 0) {
            setProceedToPay(false)
            response = await postData(
                'paymentService',
                `/pay?type=${paymentType === 'membership' ? 'membership-skip' : paymentType}`,
                data,
                authToken,
            )
            if (response?.status) {
                response.skip = true
            }
        } else {
            if (creditCard?.id && data.total_amount > 0) {
                if (creditCard?.card_type === 'PAYPAL') {
                    response = await postData(
                        'paymentService',
                        `/paypal/agreement?payment_type=${paymentType}&capture=true`,
                        data,
                        authToken,
                    )
                } else {
                    response = await postData(
                        'paymentService',
                        `/amazon/signature?type=${paymentType}&capture=true`,
                        data,
                        authToken,
                    )
                }
            } else {
                setProceedToPay(true)
            }
        }
        setLoading(false)
        onPaymentCompletion(response)
    }

    useEffect(() => {
        setPaymentButtonText(paymentRequestData.amount === 0 ? 'Save Card' : 'Save and Continue')
    }, [paymentRequestData.amount])

    useEffect(() => {
        if (proceedToPay && !showSkip && !paymentStartedRef.current) {
            paymentStartedRef.current = true
            const { membershipType, planType, subtotal, promoCode, discountAmount, totalAmount, source } =
                analyticsEventAttributes
            analytics.events.membershipPaymentStarted({
                membershipType,
                planType,
                subtotal,
                promoCode,
                discountAmount,
                totalAmount,
                source,
            })
            analytics.events.screenVisited({ screen: screenEvent.MEMBERSHIP_PAYMENT })
        }
    }, [showSkip, proceedToPay, JSON.stringify(analyticsEventAttributes)])

    useEffect(() => {
        analytics.events.screenVisited({ screen: screenEvent.MEMBERSHIP_PROMO_CODE })
    }, [])

    return (
        <>
            {redirectToDashboard && <Redirect to={NAVIGATION_CONSTANTS.DASHBOARD} />}
            {['skip', 'sub'].includes(proceedToNextPage) ? (
                <Redirect
                    to={{
                        pathname: NAVIGATION_CONSTANTS.ONE_TIME_VERIFY_EMAIL,
                        search: '',
                        state:
                            props.location && props.location.state && props.location.state
                                ? {
                                      ...props.location.state,
                                      paymentSkipped: proceedToNextPage === 'skip' ? true : false,
                                  }
                                : { paymentSkipped: proceedToNextPage === 'skip' ? true : false },
                    }}
                />
            ) : ['update', 'renew'].includes(proceedToNextPage) ? (
                <Redirect
                    to={{
                        pathname: NAVIGATION_CONSTANTS.PAYMENT_STATUS + '/' + paymentStatus,
                        search: '',
                        state: {
                            ...(props.location && props.location.state && props.location.state
                                ? props.location.state
                                : {}),
                            membershipPaymentSavedCardEventsAttributes: analyticsEventAttributes,
                        },
                    }}
                />
            ) : null}
            {loading ? (
                <Loader />
            ) : (
                <section className="full-height-page-small-nav content pb-0 pt-0 shipping-options-container-wrapper">
                    <div className="container padding-vertical-25 payment-option-save-card-height shipping-options-container">
                        {!showSkip && proceedToPay && (
                            <Row className="justify-content-center align-items-center">
                                <Col lg={8} md={12} sm={12} className="px-0">
                                    <AmazonPay
                                        paymentRequestData={paymentRequestData}
                                        setShipmentLoading={setLoading}
                                        onPaymentCompletion={onPaymentCompletion}
                                        paymentType={paymentType}
                                        shipmentLoading={loading}
                                        totalPayment={
                                            paymentRequestData?.discount?.discounted_amount > 0
                                                ? paymentRequestData?.discountedTotal
                                                : paymentRequestData?.grandTotal
                                        }
                                        paymentDetails={paymentDetails}
                                        setCreditCard={setCreditCard}
                                        creditCard={creditCard}
                                        handleSubmit={handleSubmit}
                                        isAmazonPay={isAmazonPay}
                                        setIsAmazonPay={setIsAmazonPay}
                                        isPaypal={isPaypal}
                                        setIsPaypal={setIsPaypal}
                                        setIsOthers={setIsOthers}
                                        showSkip={showSkip}
                                        setProceedToPay={setProceedToPay}
                                        analyticsEventAttributes={analyticsEventAttributes}
                                    />
                                </Col>
                            </Row>
                        )}
                        {!proceedToPay && (
                            <Row>
                                <Col
                                    md={{ span: 12, order: 'last' }}
                                    sm={{ span: 12, order: 'last' }}
                                    xs={{ span: 12, order: 'last' }}
                                    lg={{ span: 7, order: 'first' }}
                                    className={`d-flex align-items-start text-start ${i18n?.language === 'ar' ? '' : 'px-0'}`}
                                >
                                    <Campaign loading={loading} />
                                </Col>
                                <Col
                                    md={{ span: 12, order: 'first' }}
                                    sm={{ span: 12, order: 'first' }}
                                    xs={{ span: 12, order: 'first' }}
                                    lg={{ span: 5, order: 'last' }}
                                    className={`text-start ${i18n?.language === 'ar' ? '' : 'px-0'}`}
                                >
                                    <PaymentSummary
                                        paymentSummary={paymentDetails}
                                        setPaymentSummary={setPaymentDetails}
                                        membershipId={membershipId ? membershipId : membershipPlanID}
                                        defaultPromoCode={subDefaultPromoCode}
                                        setShowSkip={setShowSkip}
                                        setProceedToPay={setProceedToPay}
                                        submit={(showSkip || !proceedToPay) && handleSubmit}
                                    />
                                </Col>
                            </Row>
                        )}
                    </div>
                </section>
            )}
        </>
    )
}
