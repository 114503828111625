import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import ConsolidatedItemsSvg from './consolidatedItemsSvg.svg'
import ShipmentPromoCode from '../promoCode/ShipmentPromoCode'
import { Loader } from '../common'
import SummaryDetails from '../parcelPaymentSummary/SummaryDetails'
import { SERVICES_NO_PROMOCODE } from '../../constants/constants'
import TenPercent from '../parcelPaymentSummary/10percent.svg'
import { useAuth } from '../../contexts/AuthContext'

export default ({
    parcels,
    totalChargableWeight,
    paymentSummary,
    setPromoCode,
    promoCode,
    loading,
    submit,
    payable,
    totalParcelValue,
    consolidationType = '',
}) => {
    const { t, i18n } = useTranslation()
    const { membershipPlanID } = useAuth()
    return (
        <>
            <div className="widget widget_packages pb-0">
                <div className="widget__inner24 summary-right summary-width">
                    <h4 className="font18-gry1 pb-2 font-weight-600 px-4 pt-3"> {t('Consolidated Items')}</h4>
                    {loading ? (
                        <div className="py-5">
                            <Loader />
                        </div>
                    ) : (
                        <>
                            <SummaryDetails
                                // paymentSummary={payable?.rates?.find(rate => rate.service === paymentSummary.service)}
                                paymentSummary={paymentSummary}
                                totalParcelValue={totalParcelValue}
                                setPromoCode={setPromoCode}
                                promoCode={promoCode}
                                parcels={parcels}
                                payables={payable?.parcel_data}
                                showConsolidatedParcelsText={true}
                                totalChargableWeight={totalChargableWeight}
                                consolidationType={consolidationType}
                            />
                            {paymentSummary?.subscription_discount?.discount_value > 0 &&
                                Number(membershipPlanID) > 1 && (
                                    <Row className="font18-gry1 line-height-40 mobile-hide-subtotal px-4">
                                        <Col md={10} sm={10} className="mobile-width-50-percent text-start">
                                            <span>{t('Membership Discount')}</span>
                                        </Col>
                                        <Col md={2} sm={2} className="text-end mobile-width-50-percent text-end">
                                            <span className="color-green">
                                                {paymentSummary?.membership_discount?.discount_value}%
                                            </span>
                                        </Col>
                                    </Row>
                                )}
                            <Row className="header-main-menu-text mt-3 mx-4 pb-3 word-spacing">
                                <Col md={12} sm={12} className="grey-background rounded py-2">
                                    <Row>
                                        <Col md={1} xs={1} className="p-1">
                                            <i className={`step-color-orange bx bx-info-circle`}></i>
                                        </Col>
                                        <Col md={11} xs={11} className="p-1">
                                            {/* <span className="font-weight-light font-14 mt-2">
											{t("The approximate shipping charges for your consolidated parcels is")}
											<strong className="mx-2 font-weight-bold blue-color word-spacing">${paymentSummary.total?.toFixed(2)}</strong>
											{t("and you will be approximately saving")}
											<strong className="mx-2 font-weight-bold color-green word-spacing">${paymentSummary.you_save?.toFixed(2)}</strong>
											{t("Knowing that consolidation fees of")}
											<strong className="mx-2 font-weight-bold step-color-orange word-spacing">${paymentSummary.consolidation_fee?.toFixed(2)}</strong>
											{t("will be added")}
										</span> */}
                                            <span className="font-weight-light font-14 mt-2">
                                                {t('before consolidation desc')}
                                            </span>
                                            <br />
                                            <p
                                                className={`m-0 mt-2 px-2 rounded bg-grey-1-alpha font-weight-light ${
                                                    i18n.language === 'ar' ? 'ml-2' : 'mr-2'
                                                }`}
                                            >
                                                {t('actual-and-dim-might-vary')}
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={12} sm={12} className="grey-background rounded mt-2 py-2">
                                    <Row>
                                        <Col md={1} xs={1} className="p-1">
                                            <i className={`blue-color bx bx-info-circle`}></i>
                                        </Col>
                                        <Col md={11} xs={11} className="p-1">
                                            <span className="font-weight-light font-14 mt-2">
                                                {t('We will auto-deduct the full shipment fees once your shipment is')}
                                                <strong className="font-weight-bold mx-2">{t('Ready to ship')}</strong>
                                            </span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {paymentSummary?.applied_discount_type === 'membership' &&
                                paymentSummary?.membership_discount?.discounted_amount > 0 && (
                                    <>
                                        <div className="mb-2 rounded px-1 mx-4 py-3 bg-light-green text-center">
                                            <img src={TenPercent} className="img-fluid" />
                                            &nbsp;
                                            <strong>
                                                {t(
                                                    `${paymentSummary?.membership_discount?.discount_percent}% discount`,
                                                )}
                                            </strong>
                                            &nbsp;
                                            {t('on Shipping Fees Applied')}
                                        </div>
                                        <div className="d-flex mx-4 font-15">
                                            <p>**</p>
                                            &nbsp;&nbsp;
                                            <p>{t('If the promo code is more than the Discount')}</p>
                                        </div>
                                    </>
                                )}
                            {submit && (
                                <Row className="mx-2">
                                    <Col md={12} sm={12} className="pb-3">
                                        <div className="mobile-pay-now paypal-ipad">
                                            <Button
                                                id="shipmentOptionBtn"
                                                className="rounded u-userLauncherColor credit_button form-control border-radius-10 padding-vertical-15 font-weight-500 w-100"
                                                data-toggle={!payable.parcel_data && 'modal'}
                                                data-target={!payable.parcel_data && '#payment-submit'}
                                                onClick={payable?.parcel_data && submit}
                                            >
                                                {payable?.parcel_data ? t('Continue to Payment') : t('Place Order')}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </>
                    )}
                </div>
                <div
                    className="modal fade"
                    id="payment-submit"
                    tabIndex="-1"
                    aria-labelledby="payment-submit"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered login-modal-width">
                        <div className="modal-content p-3 w-100">
                            <div className="modal-header border-bottom-0 pb-0 pt-0">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body w-100">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <img alt="..." src={ConsolidatedItemsSvg} />
                                    <h2 className="my-3">{t('Consolidation')}</h2>
                                    <p className="text-center text-muted mx-5">
                                        {t('Continue to Payment description')}
                                    </p>
                                    <Button
                                        id="shipmentOptionBtn"
                                        className="rounded u-userLauncherColor credit_button form-control border-radius-10 padding-vertical-15 font-weight-500 w-100"
                                        data-dismiss="modal"
                                        disabled={loading}
                                        onClick={!payable?.parcel_data && submit}
                                    >
                                        {loading ? (
                                            <div class="spinner-border text-light" role="status"></div>
                                        ) : (
                                            t('Proceed to Consolidate')
                                        )}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
