import { head } from 'lodash'
import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Collapse } from 'react-bootstrap'

import { useTranslation } from 'react-i18next'
import AmazonPay from '../AmazonPay'
import AmazonPayButton from './AmazonPayButton'
import CreditCard from './CreditCard'
import amazonPay from './amazon-pay.svg'
import payPalHeading from './PayPal.svg'
import master from './master.svg'
import OtherPaymentMethods from './OtherPaymentMethods-bkup'
import ADCB from './ADCB'

import PayPalButton from '../PayPal/PayPalButton'
import PayPal from './PayPal.svg'
import { useAuth } from '../../contexts/AuthContext'
import PaypalCardPayment from '../PayPal/PaypalCardPayment'
import BackButtonSvg from '../../assets/img/icons/backButton.svg'

export default ({
    name,
    savedCardList,
    selectedCard,
    setSelectedCard,
    grayBg,
    paymentRequestData,
    setShipmentLoading,
    onPaymentCompletion,
    shipmentLoading,
    parcels,
    addOnServices,
    loading,
    setLoading,
    setLoaderMessage,
    creditCardDetails,
    setCreditCardDetails,
    paymentType,
    paymentDetails,
    setCreditCard,
    setIsAmazonPay = null,
    setIsPaypal = null,
    walletPayment = false,
    mobileRequest = false,
    mobileAuthToken = '',
    setIsOthers = null,
    byPassPayment = false,
    wallet = null,
    handleSubmit = null,
    setProceedToPay = null,
    analyticsEventAttributes = null,
    walletBalance,
}) => {
    const { t, i18n } = useTranslation()
    const [cardType, setCardType] = useState('')
    const [disabled, setDisabled] = useState(false)
    const { username } = useAuth()

    const formatCard = (card) => {
        if (card) {
            if (['AMAZON_PAY', 'PAYPAL']?.includes(card?.card_type)) {
                return card?.name?.split(' ')[0]
            } else if (card && card.card_no) {
                return `**** **** **** (${card.card_no})`
            }
        }
        return ''
    }

    const toggleSavedCard = (event, card, cardType = null) => {
        let checked = event.target.checked
        if (checked) {
            setSelectedCard(card)
            if (card?.id) {
                setCreditCard({ ...card, number: card.card_no })
                if (setIsAmazonPay) {
                    setIsAmazonPay(false)
                }
                if (setIsPaypal) {
                    setIsPaypal(false)
                }

                // if (setIsAmazonPay) {

                // 	if (card?.card_type === "AMAZON_PAY") {
                // 		setIsAmazonPay(true)
                // 	} else {
                // 		setIsAmazonPay(false)
                // 	}
                // }
            }
        } else {
            setSelectedCard(null)
            if (setIsAmazonPay) {
                setIsPaypal(false)
                setIsOthers(false)
                if (cardType && cardType === 'AMAZON') {
                    setIsAmazonPay(true)
                } else {
                    setIsAmazonPay(false)
                }
            }
            if (setIsPaypal) {
                setIsAmazonPay(false)
                setIsOthers(false)
                if (cardType && cardType === 'PAYPAL') {
                    setIsPaypal(true)
                } else {
                    setIsPaypal(false)
                }
            }
            if (setIsOthers) {
                setIsAmazonPay(false)
                setIsPaypal(false)
                if (cardType && cardType === 'OTHERS') {
                    setIsOthers(true)
                } else {
                    setIsOthers(false)
                }
            }
        }
    }

    const toggleSelectedCardContainer = (card, cardType = null) => {
        setSelectedCard(card)
        if (card?.id) {
            setCreditCard({ ...card, number: card.card_no })
            if (setIsAmazonPay) {
                setIsAmazonPay(false)
            }
            if (setIsPaypal) {
                setIsPaypal(false)
            }
            if (setIsOthers) {
                setIsOthers(false)
            }
            // if (setIsAmazonPay) {
            // 	if (card?.card_type === "AMAZON_PAY") {
            // 		setIsAmazonPay(true)
            // 	} else {
            // 		setIsAmazonPay(false)
            // 	}
            // }
        } else {
            setCreditCard({})
            if (setIsAmazonPay) {
                if (cardType && cardType === 'AMAZON') {
                    setIsAmazonPay(true)
                    setCreditCardDetails({ name: username?.split(' ')[0] })
                } else {
                    setIsAmazonPay(false)
                }
            }
            if (setIsPaypal) {
                if (cardType && cardType === 'PAYPAL') {
                    setIsPaypal(true)
                    setCreditCardDetails({ name: username?.split(' ')[0] })
                } else {
                    setIsPaypal(false)
                }
            }
            if (setIsOthers) {
                if (cardType && cardType === 'Others') {
                    setIsOthers(true)
                    setCreditCardDetails({ name: username })
                    if (document.getElementById('adcb-checkout')) {
                        document.getElementById('adcb-pay')?.click()
                    }
                } else {
                    setIsOthers(false)
                }
            }
        }
        setCardType(cardType)
    }

    useEffect(() => {
        if (savedCardList && savedCardList.length !== 0 && !selectedCard) {
            const card = savedCardList.find((savedCard) => savedCard.is_default)
            // if (walletPayment && card?.card_type !== "AMAZON_PAY") {
            setSelectedCard({ ...card })
            setCreditCard({ ...card, number: card?.card_no })
            // } else {
            // 	setSelectedCard({})
            // 	setCreditCard({})
            // }
            if (setIsAmazonPay) {
                setIsAmazonPay(false)
            }
            if (setIsPaypal) {
                setIsPaypal(false)
            }
            if (setIsOthers) {
                setIsOthers(false)
            }

            // if (setIsAmazonPay) {
            // 	if (card?.card_type === "AMAZON") {
            // 		setIsAmazonPay(true)
            // 	} else {
            // 		setIsAmazonPay(false)
            // 	}
            // }
        }
    }, [savedCardList?.length])

    useEffect(() => {
        const affectedKey = paymentType === 'consolidation' ? 'amount' : 'total'
        setDisabled(
            byPassPayment ||
                loading ||
                paymentRequestData[affectedKey] === 0 ||
                paymentDetails?.grandTotal === 0 ||
                paymentDetails?.discountedTotal === 0,
        )
    }, [
        byPassPayment,
        loading,
        paymentRequestData?.total,
        paymentRequestData?.amount,
        paymentDetails?.grandTotal,
        wallet?.walletAmount,
        paymentDetails?.discountedTotal,
    ])

    useEffect(() => {
        if (disabled) {
            setIsAmazonPay(false)
            setIsPaypal(false)
            setIsOthers(false)
        }
    }, [disabled])

    return (
        <div
            style={{
                opacity: disabled ? 0.4 : 1,
                pointerEvents: disabled ? 'none' : 'initial',
            }}
        >
            {savedCardList?.length > 0 && (
                <>
                    <h6 className="my-3">{t('Payment-options')}</h6>
                    <div className="card-list">
                        <Form.Group>
                            {savedCardList?.map((card, index) => {
                                return (
                                    <Col
                                        sm={12}
                                        disabled={disabled}
                                        className={`align-items-center justify-content-between saved-card-container ${grayBg ? 'gray-bg' : ''}`}
                                        onClick={(e) => toggleSelectedCardContainer(card)}
                                    >
                                        <Form.Check
                                            type="radio"
                                            label={
                                                <div className="saved-card-details">
                                                    <span>{formatCard(card)}</span>
                                                </div>
                                            }
                                            name={card.id}
                                            id={card.id}
                                            disabled={byPassPayment || loading || paymentRequestData?.total === 0}
                                            checked={selectedCard && selectedCard.id === card.id}
                                            onChange={(e) => toggleSavedCard(e, card, null)}
                                        />
                                        <span>
                                            <img
                                                src={
                                                    !card?.card_type || card?.card_type === 'AMAZON_PAY'
                                                        ? amazonPay
                                                        : card?.card_type === 'PAYPAL'
                                                          ? PayPal
                                                          : master
                                                }
                                            />
                                        </span>
                                    </Col>
                                )
                            })}
                        </Form.Group>
                    </div>
                    {!mobileRequest && handleSubmit && (
                        <button
                            className={`rounded form-control border-radius-10 padding-vertical-10 font-weight-500 btn btn-primary mt-3`}
                            id="save_payment"
                            onClick={(e) => {
                                if (selectedCard?.id) {
                                    handleSubmit(e, false)
                                }
                            }}
                        >
                            <span className="credit-spinner spinner-border spinner-border-sm d-none white-color margin-btm-4" />
                            <span className="credit-text">{t('Continue')}</span>
                        </button>
                    )}
                </>
            )}
            <h6 className="my-3">{t('Select a new method')}</h6>
            <div className="card-list">
                <Form.Group>
                    {paymentType !== 'add-card' && (
                        <>
                            {/* 
						<Col sm={12} disabled={disabled} className={`saved-card-container ${(!selectedCard ? "new-card-option-selected" : "")}`} onClick={(e) => toggleSelectedCardContainer(null, "OTHER")}>
							<Form.Check
								type="radio"
								label={<div className="saved-card-details">
									<span className="mx-2">
										{t("Credit or Debit Card")}
									</span>
								</div>}
								name="new-card"
								id="new-card"
								checked={!selectedCard && cardType === "OTHERS"}
								onChange={(e) => {
									toggleSavedCard(e, null, "OTHERS")
									setCardType("OTHERS")
									if (e.target.checked) {
										if (document.getElementById("adcb-checkout")) {
											document.getElementById("adcb-pay")?.click()
										}
									}
								}}
								disabled={disabled}
							/>
						</Col> 
						<Collapse in={!selectedCard && cardType === "OTHERS"} className="border-bottom p-4">
							<Col xs={12} className="new-card-container">
								<ADCB
									paymentRequestData={paymentRequestData}
									walletAmount={wallet?.walletAmount}
									paymentDetails={paymentDetails}
									paymentType={paymentType}
									setIsOthers={setIsOthers}
									parcels={parcels}
									addOnServices={addOnServices}
									parentLoading={loading}
									setParentLoading={setLoading}
									walletBalance={walletBalance}
									analyticsEventAttributes={analyticsEventAttributes}
								/>
							</Col>
						</Collapse> 
						*/}
                        </>
                    )}
                    <Col
                        sm={12}
                        disabled={disabled}
                        className={`saved-card-container ${!selectedCard ? 'new-card-option-selected' : ''}`}
                        onClick={(e) => toggleSelectedCardContainer(null, 'AMAZON')}
                    >
                        <Form.Check
                            type="radio"
                            label={
                                <div className="saved-card-details">
                                    <span>
                                        <img src={amazonPay} className="mx-2" />
                                    </span>
                                </div>
                            }
                            name="new-card"
                            id="new-card"
                            checked={!selectedCard && cardType === 'AMAZON'}
                            onChange={(e) => {
                                toggleSavedCard(e, null, 'AMAZON')
                                setCardType('AMAZON')
                            }}
                            disabled={disabled}
                        />
                    </Col>
                    <Collapse in={!selectedCard && cardType === 'AMAZON'}>
                        <Col xs={12} className="new-card-container">
                            <CreditCard
                                setLoading={setLoading}
                                creditCardDetails={creditCardDetails}
                                setCreditCardDetails={setCreditCardDetails}
                                setLoaderMessage={setLoaderMessage}
                            />
                            <AmazonPayButton
                                setLoading={setLoading}
                                creditCardDetails={creditCardDetails}
                                setLoaderMessage={setLoaderMessage}
                                paymentRequestData={paymentRequestData}
                                walletAmount={wallet?.walletAmount}
                                totalPayment={paymentRequestData?.total}
                                parcels={parcels}
                                addOnServices={addOnServices}
                                paymentType={paymentType}
                                paymentDetails={paymentDetails}
                                mobileAuthToken={mobileAuthToken || ''}
                                cardType={cardType}
                                walletBalance={walletBalance}
                                analyticsEventAttributes={analyticsEventAttributes}
                            />
                        </Col>
                    </Collapse>

                    <Col
                        sm={12}
                        disabled={disabled}
                        className={`saved-card-container ${!selectedCard ? 'new-card-option-selected' : ''}`}
                        onClick={(e) => toggleSelectedCardContainer(null, 'PAYPAL')}
                    >
                        <Form.Check
                            type="radio"
                            label={
                                <div className="saved-card-details">
                                    <span>
                                        <img src={payPalHeading} className="mx-2" />
                                    </span>
                                </div>
                            }
                            name="new-card"
                            id="new-card"
                            checked={!selectedCard && cardType === 'PAYPAL'}
                            onChange={(e) => {
                                toggleSavedCard(e, null, 'PAYPAL')
                                setCardType('PAYPAL')
                            }}
                            disabled={disabled}
                        />
                    </Col>
                    <Collapse in={!selectedCard && cardType === 'PAYPAL'}>
                        <Col xs={12} className="new-card-container">
                            <CreditCard
                                setLoading={setLoading}
                                creditCardDetails={creditCardDetails}
                                setCreditCardDetails={setCreditCardDetails}
                                setLoaderMessage={setLoaderMessage}
                            />
                            <PayPalButton
                                setLoading={setLoading}
                                creditCardDetails={creditCardDetails}
                                setLoaderMessage={setLoaderMessage}
                                paymentRequestData={paymentRequestData}
                                walletAmount={wallet?.walletAmount}
                                totalPayment={paymentRequestData?.total}
                                parcels={parcels}
                                addOnServices={addOnServices}
                                paymentType={paymentType}
                                paymentDetails={paymentDetails}
                                mobileAuthToken={mobileAuthToken || ''}
                                walletBalance={walletBalance}
                                analyticsEventAttributes={analyticsEventAttributes}
                            />
                        </Col>
                    </Collapse>
                </Form.Group>
                {paymentType !== 'add-card' && !byPassPayment && (
                    <div className="mt-3 px-3">
                        <PaypalCardPayment
                            paymentDetails={paymentDetails}
                            paymentRequestData={paymentRequestData}
                            walletAmount={wallet?.walletAmount}
                            mobileAuthToken={mobileAuthToken || null}
                            paymentType={paymentType}
                            parcels={parcels}
                            addOnServices={addOnServices}
                            setIsPaypal={setIsPaypal}
                            parentLoading={loading}
                            setParentLoading={setLoading}
                            walletBalance={walletBalance}
                            analyticsEventAttributes={analyticsEventAttributes}
                        />
                    </div>
                )}
            </div>
            {setProceedToPay && (
                <p
                    className="mt-3 tr-cursor blue-color text-center"
                    onClick={async () => {
                        await setSelectedCard(null)
                        await setCreditCard(null)
                        await setIsAmazonPay(false)
                        await setIsPaypal(false)
                        await setIsOthers(false)
                        setProceedToPay(false)
                    }}
                >
                    {i18n.language === 'ar' && <img alt="..." src={BackButtonSvg} className="ml-3 mr-3 rotate-180" />}
                    {i18n.language !== 'ar' && <img alt="..." src={BackButtonSvg} className="ml-3 mr-3" />}
                    <span className="mb-0">{t('Back')}</span>
                </p>
            )}
        </div>
    )
}
