import aramex from '../assets/img/shippingCompanies/aramex.svg'
import dhl from '../assets/img/shippingCompanies/dhl.svg'
import fedex from '../assets/img/shippingCompanies/fedex.svg'
import shopini from '../assets/img/shippingCompanies/shopini.svg'
import ups from '../assets/img/shippingCompanies/ups.svg'
import elite from '../assets/img/shippingCompanies/elite.svg'

export const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value)
}

export const randomIntFromInterval = (min, max) => {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export const getImageSrc = (service) => {
    let imageSrc = ''
    if (service.match('aramex')) imageSrc = aramex
    if (service.match('air express') || service.match('express worldwide')) imageSrc = dhl
    if (service.match('international economy') || service.match('international priority')) imageSrc = fedex
    if (service.match('shopini')) imageSrc = shopini
    if (service.match('air exports')) imageSrc = ups
    if (service.match('elite')) imageSrc = elite
    return imageSrc
}

export const countryNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' })
