import {
    PayPalScriptProvider,
    PayPalHostedFieldsProvider,
    PayPalHostedField,
    usePayPalHostedFields,
    PayPalButtons,
    usePayPalScriptReducer,
} from '@paypal/react-paypal-js'
import { isEmpty, map } from 'lodash'
import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import { PROMOS } from '../../constants/constants'
import { PAYMENT_STATUS, SUB_PAYMENT_RETURN } from '../../constants/navigationConstants'
import { useAuth } from '../../contexts/AuthContext'
import { getData, postData } from '../../utils/api'
import { Loader } from '../common'
import { useHistory } from 'react-router-dom'
import useAnalytics from '../../analytics/useAnalytics'

const style = { layout: 'vertical' }

const ButtonWrapper = ({ totalAmount, loading, onApprove }) => {
    // const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

    // useEffect(() => {
    //     dispatch({
    //         type: "resetOptions",
    //         value: {
    //             ...options
    //         },
    //     });
    // }, [loading]);

    return loading ? (
        // && isPending
        <Loader />
    ) : (
        <PayPalButtons
            style={style}
            forceReRender={[totalAmount, style]}
            createOrder={(data, actions) => {
                const order = actions.order.create({
                    purchase_units: [
                        {
                            amount: {
                                value: totalAmount,
                                currency_code: 'USD',
                            },
                        },
                    ],
                })
                return order
            }}
            onApprove={async (data, actions) => {
                try {
                    const capturedOrder = await actions.order.capture()
                    onApprove(capturedOrder)
                } catch (error) {
                    console.log(error)
                }
                // actions.order.capture()
                // .then(res => onApprove(res)).catch(e => console.log(e))
            }}
            onError={(err) => console.error(err)}
        />
    )
}

const PaypalCardPayment = ({
    paymentDetails = null,
    paymentRequestData = null,
    mobileAuthToken = null,
    paymentType,
    parcels = null,
    addOnServices = null,
    location,
    setIsPaypal,
    parentLoading,
    setParentLoading,
    walletAmount,
    walletBalance,
    analyticsEventAttributes,
}) => {
    const [totalAmount, setTotalAmount] = useState(0)
    const { t, i18n } = useTranslation()
    const [paymentPayload, setPaymentPayload] = useState(null)
    const [redirect, setRedirect] = useState(null)
    const [loading, setLoading] = useState(false)
    const { authToken, userEmail } = useAuth()
    const history = useHistory()
    const analytics = useAnalytics()
    const targetOrigin = useMemo(() => process?.env?.REACT_APP_MOBILE_POST_MESSAGE_TARGET_ORIGIN, [])

    const handleAnalyticsEvent = (requestType, attributes, reason) => {
        if (!!reason) {
            switch (requestType) {
                case 'buy-it': {
                    const { totalAmount, requestId, paymentMethod } = attributes
                    analytics.events.bifmPurchaseRequestPaymentFailure({
                        totalAmount,
                        requestId,
                        paymentMethod,
                        reason,
                    })
                    break
                }
                case 'consolidation': {
                    const { paymentMethod, totalAmount, warehouse, parcelId } = attributes
                    analytics.events.shippingConsolidationPaymentFailure({
                        paymentMethod,
                        totalAmount,
                        warehouse,
                        parcelId,
                        reason,
                    })
                    break
                }
                case 'shipping':
                case 'parcel': {
                    const { paymentMethod, totalAmount, warehouse, parcelIds } = attributes
                    analytics.events.shippingNoConsolidationPaymentFailure({
                        paymentMethod,
                        totalAmount,
                        warehouse,
                        parcelIds,
                        reason,
                    })
                    break
                }
                case 'membership':
                case 'membership-update':
                case 'membership-renewal': {
                    const { paymentMethod, totalAmount, planType, membershipType, source } = attributes
                    analytics.events.membershipPaymentFailure({
                        paymentMethod,
                        totalAmount,
                        planType,
                        membershipType,
                        source,
                    })
                    break
                }
                default:
                    break
            }
        } else {
            switch (requestType) {
                case 'buy-it': {
                    analytics.events.bifmPurchaseRequestPaymentCompleted(attributes)
                    break
                }
                case 'consolidation': {
                    analytics.events.shippingConsolidationPaymentCompleted(attributes)
                    break
                }
                case 'shipping':
                case 'parcel': {
                    analytics.events.shippingNoConsolidationPaymentCompleted(attributes)
                    break
                }
                case 'membership':
                case 'membership-update':
                case 'membership-renewal': {
                    analytics.events.membershipPaymentCompleted(attributes)
                    break
                }
                default:
                    break
            }
        }
    }

    useEffect(() => {
        setIsPaypal(true)
        if (!isEmpty(paymentRequestData) || !isEmpty(paymentDetails)) {
            let tempPaymentPayload =
                paymentType === 'shipping'
                    ? {
                          parcel_ids: map(parcels, 'id'),
                          parcel_uuids: map(parcels, 'uuid'),
                          promo_code: paymentRequestData?.promo_code_discount?.is_valid
                              ? paymentRequestData?.promo_code_discount?.discount_code
                              : paymentRequestData?.promo_code || null,
                          country_id: paymentRequestData?.address?.country,
                          add_on_services: addOnServices,
                          user_address_id: paymentRequestData?.address?.id,
                          total_amount: paymentRequestData?.total,
                          shipping_service: paymentRequestData?.service,
                          shipping_company_id: paymentRequestData?.shipping_company_id,
                          wallet_amount: walletAmount,
                      }
                    : paymentType === 'consolidation'
                      ? {
                            parcel_ids: paymentRequestData?.parcel_ids,
                            parcel_uuids: paymentRequestData?.parcel_uuids,
                            promo_code: paymentRequestData?.promo_code || null,
                            total_amount: paymentRequestData?.amount,
                            shipping_service: paymentRequestData?.shipping_service,
                            shipping_company_id: paymentRequestData?.shipping_company_id,
                            user_address_id: paymentRequestData?.user_address?.id
                                ? paymentRequestData?.user_address?.id
                                : null,
                            country_id: paymentRequestData?.user_address?.country || null,
                            is_consolidation: true,
                            wallet_amount: walletAmount,
                        }
                      : paymentType === 'buy-it'
                        ? {
                              ...paymentRequestData,
                              total_amount: paymentRequestData?.card_amount,
                          }
                        : paymentType === 'membership'
                          ? {
                                total_amount: paymentDetails?.promo_code
                                    ? paymentDetails?.discountedTotal
                                    : paymentDetails?.grandTotal,
                                discount: paymentDetails?.promo_code
                                    ? {
                                          original_amount: paymentDetails?.discount?.original_amount || 0,
                                          discounted_amount: paymentDetails?.discount?.discounted_amount || 0,
                                          promo_code_value: paymentDetails?.discount?.promo_code_value || 0,
                                          promo_code_type: paymentDetails?.discount?.promo_code_type || '',
                                          promo_code: paymentDetails?.promo_code || '',
                                      }
                                    : null,
                                is_yearly: paymentDetails?.is_yearly,
                            }
                          : {
                                total_amount: paymentDetails?.promo_code
                                    ? paymentDetails?.discountedTotal
                                    : paymentDetails?.grandTotal,
                                discount: paymentDetails?.promo_code
                                    ? {
                                          original_amount: paymentDetails?.discount?.original_amount || 0,
                                          discounted_amount: paymentDetails?.discount?.discounted_amount || 0,
                                          promo_code_value: paymentDetails?.discount?.promo_code_value || 0,
                                          promo_code_type: paymentDetails?.discount?.promo_code_type || '',
                                          promo_code: paymentDetails?.promo_code || '',
                                      }
                                    : null,
                                membership_plan_id: paymentRequestData?.membership_plan_id,
                                is_yearly: paymentDetails?.is_yearly,
                            }

            setTotalAmount(tempPaymentPayload?.total_amount?.toFixed(2))
            setPaymentPayload({ ...tempPaymentPayload })
        }
    }, [JSON.stringify(paymentRequestData), JSON.stringify(paymentDetails), walletAmount, walletBalance])

    const onApprove = async (paypalResponse) => {
        try {
            const { status, payer, purchase_units, shipping } = paypalResponse
            const mobileAnalyticsAttributes = {
                payment_method: 'PayPal Card',
                wallet_balance: walletBalance,
                used_wallet_balance: paymentPayload['wallet_amount'],
            }
            if (status === 'COMPLETED') {
                let paymentData = { ...paymentPayload }

                if (analyticsEventAttributes) paymentData['analytics_event'] = analyticsEventAttributes
                paymentData['payment_method'] = 'PayPal Card'
                paymentData['wallet_balance'] = walletBalance ?? 0
                paymentData['card'] = {
                    name: shipping?.name?.full_name,
                }
                paymentData['transaction_id'] = purchase_units[0]?.payments?.captures[0]?.id
                paymentData['payer_id'] = payer?.payer_id
                paymentData['response'] = paypalResponse
                let paymentDataType =
                    paymentType === 'shipping' || paymentType === 'consolidation' ? 'parcel' : paymentType

                let token = authToken && authToken !== '' ? authToken : mobileAuthToken
                setParentLoading(true)
                const response = await postData(
                    'paymentService',
                    '/paypal/by-card?payment_type=' + paymentDataType,
                    paymentData,
                    token,
                )
                if (response.status === true) {
                    if (paymentType === 'buy-it') {
                        let buyItSuccess = false
                        if (response?.payload?.transaction_id) {
                            let transactionRes = await getData(
                                'paymentService',
                                `/transactions/${response?.payload?.transaction_id}`,
                                token,
                            )
                            let paymentRequest = {}
                            if (transactionRes?.status) {
                                paymentRequest = JSON.parse(transactionRes?.payload?.card_details)?.request
                                paymentRequest['transaction_id'] = transactionRes?.payload?.id
                                let walletPaymentResponse = {}
                                if (paymentRequest?.is_payby_wallet) {
                                    walletPaymentResponse = await postData(
                                        'paymentRequestService',
                                        '/v2/PurchaseRequestController/paymentPurchaseRequest',
                                        {
                                            purchase_request_id: paymentRequest.purchase_request_id,
                                            is_payby_wallet: 1,
                                            wallet_amount: paymentRequest?.wallet_amount?.toFixed(2),
                                        },
                                        authToken,
                                    )
                                    paymentRequest['wallet_transaction_id'] = walletPaymentResponse?.payload?.item?.id
                                }
                                if (walletPaymentResponse?.status || paymentRequest?.is_payby_wallet === 0) {
                                    const updateStatus = await postData(
                                        'paymentRequestService',
                                        '/v2/PurchaseRequestController/createPurchaseRequestPayment',
                                        {
                                            ...paymentRequest,
                                        },
                                        authToken,
                                    )
                                    buyItSuccess = updateStatus?.status
                                }
                            }
                        }
                        if (paymentType === 'buy-it' && buyItSuccess) {
                            if (mobileAuthToken && mobileAuthToken !== '') {
                                mobileAnalyticsAttributes['status'] = 'success'
                                window?.webkit?.messageHandlers?.backHomePage?.postMessage(
                                    JSON.stringify(mobileAnalyticsAttributes),
                                    targetOrigin,
                                )
                            } else {
                                if (analyticsEventAttributes)
                                    handleAnalyticsEvent(paymentType, analyticsEventAttributes)
                                setRedirect('success')
                            }
                        } else {
                            if (mobileAuthToken && mobileAuthToken !== '') {
                                mobileAnalyticsAttributes['status'] = 'fail'
                                window?.webkit?.messageHandlers?.backHomePage?.postMessage(
                                    JSON.stringify(mobileAnalyticsAttributes),
                                    targetOrigin,
                                )
                            } else {
                                if (analyticsEventAttributes)
                                    handleAnalyticsEvent(
                                        paymentType,
                                        analyticsEventAttributes,
                                        response.message ?? 'Unknown',
                                    )
                                setRedirect('fail')
                            }
                        }
                    } else {
                        if (mobileAuthToken && mobileAuthToken !== '') {
                            mobileAnalyticsAttributes['status'] = 'success'
                            window?.webkit?.messageHandlers?.backHomePage?.postMessage(
                                JSON.stringify(mobileAnalyticsAttributes),
                                targetOrigin,
                            )
                        }
                        if (paymentType === 'membership') {
                            history.push(SUB_PAYMENT_RETURN + '?paypalCdStatus=success' + '&authToken=' + authToken)
                        } else {
                            setRedirect('success')
                        }
                    }
                }
            } else {
                if (mobileAuthToken && mobileAuthToken !== '') {
                    mobileAnalyticsAttributes['status'] = 'fail'
                    window?.webkit?.messageHandlers?.backHomePage?.postMessage(
                        JSON.stringify(mobileAnalyticsAttributes),
                        targetOrigin,
                    )
                } else {
                    if (paymentType === 'membership') {
                        if (analyticsEventAttributes) handleAnalyticsEvent(paymentType, analyticsEventAttributes)
                        history.push(SUB_PAYMENT_RETURN + '?paypalCdStatus=fail' + '&authToken=' + authToken)
                    } else {
                        if (analyticsEventAttributes)
                            handleAnalyticsEvent(paymentType, analyticsEventAttributes, status ?? 'Unknown')
                        setRedirect('fail')
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
        setParentLoading(false)
    }

    return (
        <>
            {redirect ? (
                <Redirect
                    to={{
                        pathname: PAYMENT_STATUS + '/' + redirect,
                        search: '',
                        state: location && location.state && location.state ? location.state : {},
                    }}
                />
            ) : null}
            {loading ? (
                <Loader />
            ) : (
                <>
                    {i18n?.language === 'ar' ? (
                        <PayPalScriptProvider
                            options={{
                                'client-id': process?.env?.REACT_APP_PAYPAL_CLIENT_ID,
                                components: 'buttons',
                                locale: 'ar_AE',
                            }}
                        >
                            <ButtonWrapper totalAmount={totalAmount} loading={loading} onApprove={onApprove} />
                        </PayPalScriptProvider>
                    ) : null}
                    {i18n?.language === 'en' ? (
                        <PayPalScriptProvider
                            options={{
                                'client-id': process?.env?.REACT_APP_PAYPAL_CLIENT_ID,
                                components: 'buttons',
                                locale: 'en_US',
                            }}
                        >
                            <ButtonWrapper totalAmount={totalAmount} loading={loading} onApprove={onApprove} />
                        </PayPalScriptProvider>
                    ) : null}
                </>
            )}
        </>
    )
}

export default PaypalCardPayment
