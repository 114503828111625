import { Link } from 'react-router-dom'
import useAnalytics from '../../../analytics/useAnalytics'
import { CONTACT_US } from '../../../constants/navigationConstants'
import styles from './styles.module.scss'

const generateCards = (analytics) => [
    {
        icon: require('../images/ecom-business-icon.svg').default,
        title: 'Ecommerce Businesses',
        contents: (
            <>
                <div>You sell and fulfill</div>
                <div>We Ship and Deliver</div>
                <div className="mb-4">Sell cross-border now!</div>
                <div className="d-inline-block">
                    <Link
                        className={`btn btn-outline-primary ${styles['outline-btn-overrides']}`}
                        to={CONTACT_US}
                        onClick={() =>
                            analytics.events.contactUs({
                                source: 'Ecommerce',
                            })
                        }
                    >
                        UPSCALE NOW!
                    </Link>
                </div>
            </>
        ),
    },

    {
        icon: require('../images/retailer-icon.svg').default,
        title: 'Online Retailers',
        contents: (
            <>
                <div>Utilize our vast delivery </div>
                <div>network integrated with our </div>
                <div className="mb-4">Parcelhub platform (SaaS)</div>
                <div className="d-inline-block">
                    <Link
                        className={`btn btn-primary ${styles['btn-overrides']}`}
                        to={CONTACT_US}
                        onClick={() =>
                            analytics.events.contactUs({
                                source: 'Ecommerce',
                            })
                        }
                    >
                        UPSCALE NOW!
                    </Link>
                </div>
            </>
        ),
    },

    {
        icon: require('../images/reseller-icon.svg').default,
        title: 'Resellers',
        contents: (
            <>
                <div>Scale your online sales with us now!</div>
                <div>Special solutions for </div>
                <div className="mb-4">marketplace sellers</div>
                <div className="d-inline-block">
                    <Link
                        className={`btn btn-primary ${styles['btn-overrides']}`}
                        to={CONTACT_US}
                        onClick={() =>
                            analytics.events.contactUs({
                                source: 'Ecommerce',
                            })
                        }
                    >
                        UPSCALE NOW!
                    </Link>
                </div>
            </>
        ),
    },
]

const Card = ({ icon, title, contents }) => {
    return (
        <div className={`${styles['card']}`}>
            <div className={`${styles['card-icon']}`} style={{ backgroundImage: `url(${icon})` }}></div>
            <div className={`${styles['card-title']}`}>{title}</div>
            <div className={`${styles['card-contents']}`}>{contents}</div>
        </div>
    )
}

const OSAS = () => {
    const analytics = useAnalytics()

    return (
        <div className={`${styles['one-solution-all-sizes']}`}>
            <div className={`${styles['header-wrapper']}`}>
                <div className={`${styles['shopini-logistics']}`}>E-COMMERCE SOLUTIONS</div>
                <div className={`${styles['title']}`}>
                    <span className={`${styles['text-blue']}`}>One Solution fits all sizes</span>
                    <span>–</span>
                    <br className="d-inline d-md-none" />
                    <span>SME’s and Large Organizations</span>
                </div>
            </div>
            <div className={`d-flex justify-content-between flex-wrap ${styles['cards']}`}>
                {generateCards(analytics).map((card) => (
                    <Card {...card} />
                ))}
            </div>
        </div>
    )
}

export default OSAS
