import { Alert } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { postResetPassword } from '../../services/userInfoService'

export default (props) => {
    const { t, i18n } = useTranslation()
    const [passwordForm, setPasswordFrom] = useState({
        password: '',
    })
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const [error, setError] = useState(null)

    let name, value
    const handleInputs = (event) => {
        name = event.target.name
        value = event.target.value
        setPasswordFrom({ ...passwordForm, [name]: value })
    }

    const handleConfirmPass = (e) => {
        setConfirmNewPassword(e.target.value)
    }

    useEffect(() => {
        if (confirmNewPassword !== passwordForm.password) {
            setError("Passwords don't match")
        } else {
            setError(null)
        }
    }, [confirmNewPassword])

    async function handlePasswordReset(e) {
        e.preventDefault()
        const passObject = { token: props.resetToken, ...passwordForm }
        try {
            const res = await postResetPassword(passObject)
            if (res?.error) {
                throw new Error(res.error)
            }
            setError(null)
            props.callback()
        } catch (e) {
            setError(e.message)
        }
    }

    return (
        <div className="mt-2">
            <form onSubmit={handlePasswordReset}>
                <div className="form-group">
                    <label htmlFor="resetPassword">{t('New-password')}</label>
                    <input
                        type="password"
                        className="form-control"
                        id="resetPassword"
                        name="password"
                        value={passwordForm.password}
                        onChange={handleInputs}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="resetPasswordConfirm">{t('Confirm-password')}</label>
                    <input
                        type="password"
                        className="form-control"
                        value={confirmNewPassword}
                        onChange={handleConfirmPass}
                        required
                    />
                    <small id="emailHelp" className="form-text text-muted">
                        {t('Password-description')}
                    </small>
                    {!!error && <Alert variant={'danger'}>{error}</Alert>}
                    {/* Password must contain at least 8 characters, including UPPER/Lowercase, special character and a number */}
                </div>
                <div className="row justify-content-end mr-0 ml-0 mt-2">
                    <button type="submit" className="btn btn-primary w-100">
                        {t('Save')}
                    </button>
                </div>
            </form>
        </div>
    )
}
