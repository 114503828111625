import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../contexts/AuthContext'
import { cc_format, expirydate_format } from '../../utils/string'
import master from './master.svg'
import visa from './visa.svg'
import american from './american.svg'
import ShortUniqueId from 'short-unique-id'
import { postData } from '../../utils/api'

const OtherPaymentMethods = ({ creditCardDetails, setCreditCardDetails, showSaveCard, totalPayment, paymentDesc }) => {
    const { t, i18n } = useTranslation()
    const { authToken, userEmail } = useAuth()
    const uid = new ShortUniqueId({ length: 15 })
    const handleInputFocus = (e) => {
        setCreditCardDetails({ ...creditCardDetails, focus: e.target.name })
    }

    const handleInputChange = (e) => {
        let { name, value } = e.target
        if (name !== 'saveCard') {
            if (name === 'number') {
                value = cc_format(value)
            } else if (name === 'expiry') {
                value = expirydate_format(value)
            }
            setCreditCardDetails({ ...creditCardDetails, [name]: value })
        } else {
            setCreditCardDetails({
                ...creditCardDetails,
                [name]: e.target.checked ? 1 : 0,
            })
        }
    }

    // const getImgSrc = () => {
    //     if (creditCardDetails?.number && creditCardDetails?.number !== "") {
    //         switch (creditCardType(creditCardDetails?.number?.replaceAll(" ", ""))) {
    //             case "VISA":
    //                 return visa

    //             case "MASTERCARD":
    //                 return master

    //             default:
    //                 return american;
    //         }
    //     } else {
    //         return visa;
    //     }
    // }

    useEffect(async () => {
        if (
            creditCardDetails?.name &&
            creditCardDetails?.name !== '' &&
            creditCardDetails?.number &&
            creditCardDetails?.number !== '' &&
            creditCardDetails?.cvc &&
            creditCardDetails?.cvc !== '' &&
            creditCardDetails?.expiry &&
            creditCardDetails?.expiry !== ''
        ) {
            //https://sbcheckout.PayFort.com/FortAPI/paymentPage
            //{
            const request_payfort = {
                command: 'AUTHORIZATION',
                access_code: process?.env?.REACT_APP_PAYFORT_ACCESS_CODE,
                merchant_identifier: process?.env?.REACT_APP_PAYFORT_MERCHANT_ID,
                merchant_reference: uid(),
                amount: totalPayment,
                currency: 'USD',
                language: 'en',
                customer_email: userEmail,
                order_description: paymentDesc,
            }
            const pay_fort_response = await postData('paymentService', '/payfort/signature', request_payfort, authToken)
            let request = {
                service_command: 'CREATE_TOKEN',
                access_code: process?.env?.REACT_APP_PAYFORT_ACCESS_CODE,
                merchant_identifier: process?.env?.REACT_APP_PAYFORT_MERCHANT_ID,
                merchant_reference: uid(),
                language: 'en',
                card_number: creditCardDetails?.number?.replaceAll(' ', ''),
                expiry_date: creditCardDetails?.expiry?.split('/')[1],
                card_holder_name: creditCardDetails?.name,
                remember_me: creditCardDetails?.saveCard ? 'YES' : 'NO',
                signature: pay_fort_response?.payload,
                card_security_code: creditCardDetails?.cvc,
            }
            const token_response = await postData(
                '',
                'https://sbcheckout.PayFort.com/FortAPI/paymentPage',
                request_payfort,
                authToken,
                false,
                true,
            )
        }
    }, [
        creditCardDetails?.name,
        creditCardDetails?.number,
        creditCardDetails?.cvc,
        creditCardDetails?.expiry,
        creditCardDetails?.saveCard,
    ])

    return (
        <div>
            <div className="payment-box">
                <Row className="align-items-center justify-content-center">
                    <form action="https://sbcheckout.PayFort.com/FortAPI/paymentPage" target="my-iframe" method="post">
                        <input type="text" name="service_command" value="TOKENIZATION" />
                        <input type="text" name="merchant_identifier" value="d017fd5c" />
                        <input
                            type="text"
                            name="signature"
                            value="76776806f9539c964fa0410cb287af9b59bd87a7f52a2b42439de7d5ea703638"
                        />
                        <input type="text" name="access_code" value="E784zYW8doEkhHI8ZWPm" />
                        <input type="text" name="merchant_reference" value="XYZ9239" />
                        <input type="text" name="language" value="en" />
                        <input type="text" name="return_url" value="https://uat.shopiniworld.com/payment-return" />
                        <input type="submit" value="post" />
                    </form>

                    <iframe name="my-iframe" src="https://sbcheckout.PayFort.com/FortAPI/paymentPage"></iframe>
                    {/* <Col lg={12} className="my-2">
                        <input
                            type="text"
                            autoComplete="off"
                            className="form-control"
                            minLength={2}
                            maxLength={50}
                            name="name"
                            required
                            value={creditCardDetails?.name}
                            placeholder={t("Name on Card")}
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                        />
                    </Col>
                    <Col lg={12} className="my-2">
                        <input
                            type="text"
                            autoComplete="off"
                            className="form-control credit-card-number"
                            maxLength={20}
                            name="number"
                            required
                            value={creditCardDetails?.number}
                            placeholder={t("Card Number")}
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                        />
                    </Col> */}
                    {/* <Col lg={1}>
                        <img src={getImgSrc()}/>
                    </Col> */}
                    {/* <Col lg={3} className="my-2">
                        <input
                            type="text"
                            autoComplete="off"
                            className="form-control"
                            name="expiry"
                            required
                            value={creditCardDetails?.expiry}
                            minLength={7}
                            maxLength={7}
                            placeholder={"03/2023"}
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                        />
                    </Col>
                    <Col lg={3} className="my-2">
                        <input
                            type="password"
                            autoComplete="off"
                            className="form-control"
                            name="cvc"
                            value={creditCardDetails?.cvc}
                            maxLength={4}
                            required
                            placeholder={t("cvc")}
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                        />
                    </Col> */}
                    {/* {showSaveCard
                        ?
                        <Col md={6} className=''>
                            <input type="checkbox" name="saveCard" id="save_card" checked={creditCardDetails?.saveCard} label={t("Save Card")} onChange={handleInputChange} />
                        </Col>
                        : null
                    } */}
                </Row>
            </div>
        </div>
    )
}

export default OtherPaymentMethods
