import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

const LanguagesRow = ({ t, i18n, isMobile, smallNavs }) => {
    return (
        <div
            className={`${
                smallNavs ? 'text-dark d-flex justify-content-between' : `languages-container-${i18n?.language}`
            } ${isMobile ? (smallNavs ? 'border-bottom w-75 pb-3 mx-auto' : 'light-border-bottom') : ''}`}
        >
            <span className="tr-cursor" onClick={() => document?.getElementById('en-lng')?.click()}>
                {t('English')}
            </span>
            <span className={`${!smallNavs ? 'disabled-lang' : 'opacity-40'} tr-cursor`} disabled>
                {t('Arabic')}
            </span>
            <span className={`${!smallNavs ? 'disabled-lang' : 'opacity-40'} tr-cursor`} disabled>
                {t('Français')}
            </span>
            <span className={`${!smallNavs ? 'disabled-lang' : 'opacity-40'} tr-cursor`} disabled>
                {t('Español')}
            </span>
            <span className={`${!smallNavs ? 'disabled-lang' : 'opacity-40'} tr-cursor`} disabled>
                {t('中文')}
            </span>
        </div>
    )
}

const Footer = () => {
    const { t, i18n } = useTranslation()
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' })
    console.log(isMobile)
    return (
        <div
            className={`bg-white ${
                isMobile ? 'd-block px-2' : 'd-flex justify-content-between align-items-center flex-row-reverse px-4'
            } text-center text-muted py-2 text-white`}
        >
            <div className={isMobile ? 'd-flex justify-content-center text-right' : 'flex-grow-1'}>
                <div style={{ maxWidth: '400px' }} className={`${!isMobile ? 'ml-auto' : 'flex-grow-1'}`}>
                    <LanguagesRow t={t} i18n={i18n} isMobile={isMobile} smallNavs />
                </div>
            </div>
            <p className={`copy-rights`}>{t('Copyright')}</p>
        </div>
    )
}

export default Footer
