import React from 'react'
import { Container, Card, Button, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { HOW_IT_WORKS } from '../../../constants/navigationConstants'
import DeliveryImage from './delivery-home.svg'
import ShoppingImage from './shopping-home.svg'
import AddressImage from './address-home.svg'

function ShopAndShipSection() {
    const { t } = useTranslation()
    return (
        <Container id="Shop and ship" fluid className="shop-ship-section-container text-center">
            <Container fluid className="text-center container-width">
                <p className="section-title">{t('How to shop and ship')}</p>
                <Row className="d-flex justify-content-center align-items-start">
                    <Col className="col-lg-4 col-sm-12">
                        {/* register and get you free address card*/}
                        <Card>
                            <Card.Img className="section-img" variant="top" src={AddressImage} />
                            <Card.Body>
                                <Card.Title>{t('Register and get your free address')}</Card.Title>
                                <Card.Text>{t('Sign up description')}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* shop your favorite US brands card*/}
                    <Col className="col-lg-4 col-12">
                        <Card>
                            <Card.Img className="section-img" variant="top" src={ShoppingImage} />
                            <Card.Body>
                                <Card.Title>{t('Shop your favorite brands and enjoy')}</Card.Title>
                                <Card.Text>{t('Shop your favorite brands description')}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* fast worldwide delivery card*/}
                    <Col className="col-lg-4 col-sm-12">
                        <Card>
                            <Card.Img className="section-img" variant="top" src={DeliveryImage} />
                            <Card.Body>
                                <Card.Title>{t('Fast worldwide delivery')}</Card.Title>
                                <Card.Text>{t('Fast worldwide delivery description')}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Link
                    to={HOW_IT_WORKS}
                    onClick={() => {
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth',
                        })
                    }}
                >
                    <Button id="see-how-it-works-button">{t('See how it works')}</Button>
                </Link>
            </Container>
        </Container>
    )
}

export default ShopAndShipSection
