import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './style.scss'

export const PromotionPage = () => {
    const { t, i18n } = useTranslation()
    const alignment = i18n.language === 'ar' ? 'text-right' : ''
    const hrefs = {
        ['1']: 'https://www.partycity.com/',
        ['2']: 'https://www.walmart.com/search?q=Halloween+children+costumes',
        ['3']: 'https://www.ebay.com/sch/i.html?_from=R40&_trksid=p2334524.m570.l1313&_nkw=halloween+costumes&_sacat=0&LH_TitleDesc=0&_odkw=halloween+costume&_osacat=0',
        ['4']: 'https://www.amazon.com/s?k=Halloween+costume+for+children&ref=nb_sb_noss_2',
        ['5']: 'https://www.carters.com/carters-halloween-shop?rdsearchterm=halloween%20costume%20for%20children',
        ['6']: 'https://www.target.com/s?searchTerm=halloween+costumes',
        ['7']: 'https://www.shopdisney.com/halloween-shop/costumes/kids-costumes/',
        ['8']: 'https://www.hannaandersson.com/family-halloween-pajamas/',
        ['9']: 'https://www.etsy.com/search?q=halloween%20costumes&ref=auto-1',
    }
    const [imagesSrc, setImagesSrc] = useState([])
    useEffect(() => {
        const svgs = async () => {
            const reqSvgs = await require.context('../halloween/brands', true, /\.svg$/)
            return reqSvgs
                .keys()
                .map((path) => ({ path, file: reqSvgs(path), href: hrefs[path.replace('./', '').split('.')[0]] }))
        }

        async function getSvgs() {
            const x = await svgs()
            setImagesSrc(x)
        }
        getSvgs()
    }, [setImagesSrc])

    return (
        <>
            <div className="halloween-background">
                <div className={`happy-halloween-container ${alignment}`}>
                    <h1 className="happy-halloween text-white">{t('promotion.Happy Halloween Shopping')}</h1>
                </div>
                <div className="row halloween-row">
                    {imagesSrc.map((imageSrc, index) => (
                        <>
                            <div
                                className={`col-4 d-flex justify-content-${[0, 3, 6].includes(index) ? 'end' : [2, 5, 8].includes(index) ? 'start' : 'center'} align-items-center my-3`}
                            >
                                <a href={imageSrc.href} target="_blank">
                                    <img className="brand" src={imageSrc.file.default} />
                                </a>
                            </div>
                        </>
                    ))}
                </div>
            </div>
        </>
    )
}
