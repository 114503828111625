import './styles.scss'

const CustomizedTab = ({ stores, selectedCategories }) => {
    console.log('selected', selectedCategories)
    return (
        <div className="row p-0 m-0">
            {(stores ?? [])
                .filter((store) => {
                    //return true;
                    return store.categories.some((storeCategory) => selectedCategories.includes(storeCategory))
                })
                .map(({ titleEn, url, image }) => {
                    const capitalizedTitle = `${titleEn[0].toUpperCase()}${titleEn.slice(1)}`
                    return (
                        <div className="col-6 col-sm-4 col-md-3 col-lg-1-5">
                            <a
                                rel={'external'}
                                title={capitalizedTitle}
                                href={url}
                                target="_blank"
                                className="d-block card-container "
                            >
                                <div className="card-image" style={{ backgroundImage: `url(${image})` }}></div>

                                <span className="card-title">{capitalizedTitle}</span>
                            </a>
                        </div>
                    )
                })}
        </div>
    )
}

export default CustomizedTab
