import React from 'react'

function HorizontalCard({ imgSrc, cardTitle, cardDescription }) {
    return (
        <div className="card h-100 w-100">
            <div className="card-horizontal shadow-2 h-100 w-100 row">
                <div className="text-center col-lg-2 col-md-3 col-sm-12">
                    <img src={imgSrc} alt="Service image" className="img-fluid" />
                </div>
                <div className="card-body post-450-start-text-align pre-450-centered-text pb-0 pt-3 col-lg-10 col-md-9 col-sm-12">
                    <h3 className="card-title font-18">{cardTitle}</h3>
                    <p className="card-text font-16-400 center-align-last-line text-grey">{cardDescription}</p>
                </div>
            </div>
        </div>
    )
}
export default HorizontalCard
