import React from 'react'
import { useTranslation } from 'react-i18next'
import AngleDownGreen from '../../sections/navigationBar/angleDownGreen.svg'
import TabBody from './tabBody'

function Tab10() {
    const { t } = useTranslation()

    const questionsAndAnswers = [
        {
            containerId: 'tenone',
            collapseId: 'collapseTenOne',
            question: t('Qtenone'),
            answer: t('Atenone'),
        },
        {
            containerId: 'tentwo',
            collapseId: 'collapseTenTwo',
            question: t('Qtentwo'),
            answer: t('Atentwo'),
        },
        {
            containerId: 'tenthree',
            collapseId: 'collapseTenThree',
            question: t('Qtenthree'),
            answer: t('Atenthree'),
        },
    ]

    return (
        <>
            <div className="accordion" id="accountNavigationQuestions">
                <div className="card align-items-start">
                    <p className="sub-page-section-header mb-show-block text-start">
                        {t('Shipping rates calculations')}
                    </p>
                </div>
                <>
                    <div className="card accordion-question-card">
                        <div className="w-100" id={questionsAndAnswers[0].containerId}>
                            <h2 className="mb-0">
                                <a
                                    className="h-auto nav-buttons-font collapsed d-flex row justify-content-between mr-0 ml-0"
                                    type="a"
                                    data-toggle="collapse"
                                    data-target={`#${questionsAndAnswers[0].collapseId}`}
                                    aria-expanded="true"
                                    aria-controls={questionsAndAnswers[0].collapseId}
                                >
                                    {questionsAndAnswers[0].question}
                                    <img alt="..." className="accordion-arrow" src={AngleDownGreen}></img>
                                </a>
                            </h2>
                        </div>

                        <div
                            id={questionsAndAnswers[0].collapseId}
                            className="collapse w-100"
                            aria-labelledby={questionsAndAnswers[0].containerId}
                            data-parent="#newCustomerQuestions"
                        >
                            <div className="card-body pr-0 pl-0">
                                <p className="p-0 m-0 mb-3">{t('Atenone1')}:</p>
                                <p className="p-0 m-0 text-grey">{t('Atenone2')}</p>
                                <p className="p-0 m-0 mb-3 text-grey">{t('Atenone3')}</p>
                                <p className="p-0 m-0">{t('Atenone4')}</p>
                            </div>
                        </div>
                    </div>
                    {questionsAndAnswers?.slice(1).map((questionAndAnswer) => (
                        <TabBody questionAndAnswer={questionAndAnswer} />
                    ))}
                </>
            </div>
        </>
    )
}

export default Tab10
