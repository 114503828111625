import React, { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Redirect, useParams } from 'react-router-dom'
import useAnalytics from '../../analytics/useAnalytics'
import { useEmailAndPlanChecking } from '../../components/auth'
import { DASHBOARD } from '../../constants/navigationConstants'
import { useAuth } from '../../contexts/AuthContext'
import { postData } from '../../utils/api'
// import ShopUsBrandsSection from "../homepage/shopUsBrandsSection/shopUsBrandsSection"
import success from './success.gif'
const ThankYouPage = (props) => {
    const [loading, setLoading] = useState(true)
    const [alert, setAlert] = useState({})
    const { t, i18n } = useTranslation()
    const { username } = useAuth()
    const { checkIsEmailVerified } = useEmailAndPlanChecking()
    const { verificationToken } = useParams()
    const analytics = useAnalytics()
    useEffect(async () => {
        if (verificationToken && verificationToken !== '') {
            try {
                let response = await postData('userService', `/verify-email/${verificationToken}`, {})

                if (response.status) {
                    setAlert({ ...alert, message: response.message, severity: 'success', code: 201 })
                    // const script = document.createElement("script");
                    // script.innerText = "gtag('event', 'conversion', {'send_to': 'AW-471642540/mDIGCK6l7IADEKzj8uAB'});";
                    // script.id = "GAD"
                    // document.head.appendChild(script);
                    // if (authToken && authToken !== "") {
                    // 	// props.history.push("/dashboard");
                    // 	if (authToken && authToken !== "") {
                    // 		props.history.push(THANK_YOU);
                    // 	}
                    // }
                    analytics.events.verifyEmailCompleted({
                        status: true,
                    })
                } else {
                    setAlert({ ...alert, message: 'Error Message', severity: 'danger' })
                    analytics.events.verifyEmailCompleted({
                        status: false,
                    })
                }
            } catch (error) {
                setAlert({ ...alert, message: 'Error Message', severity: 'danger' })
            }
        }

        setLoading(false)
    }, [verificationToken])
    return (
        <>
            {checkIsEmailVerified.status && <Redirect to={DASHBOARD} />}
            <div className="grey-background py-5 full-height-page-small-nav">
                <div
                    className={
                        'pt-0 text-center container-fluid container-width w-50-mobile-100 white-background ' +
                        (loading ? 'loading' : null)
                    }
                >
                    <img src={success} className="w-25" />
                    <h3 className="mb-4">{t('Thank You') + ' ' + username}</h3>
                    <p>{t('Thank you for completing your registration')}</p>
                    <p>{t('Start your journey below')}</p>

                    <Link
                        to={{
                            pathname: DASHBOARD,
                            search: '',
                            state: props.location && props.location.state ? props.location.state : {},
                        }}
                        disabled={loading}
                    >
                        <button className="mt-3 btn btn-primary rounded">{t('Go to Dashboard')}</button>
                    </Link>
                </div>
            </div>
            {/* <ShopUsBrandsSection whiteBg={true} /> */}
        </>
    )
}

export default ThankYouPage
