import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { MEMBERSHIP_CONSTANTS } from '../../constants/constants'

import * as navigationConstants from '../../constants/navigationConstants'

import { useAuth } from '../../contexts/AuthContext'
import { getUserDetails } from '../../services/userInfoService'

export const useEmailAndPlanChecking = () => {
    const [checkIsPlanPaid, setCheckIsPlanPaid] = useState({})
    const [checkIsEmailVerified, setCheckIsEmailVerified] = useState({})
    const [hasContactDetailes, setHasContactDetailes] = useState(true)
    const [checkisPaymentSkipped, seCheckIsPaymentSkipped] = useState({})
    const [loading, setLoading] = useState(true)

    const {
        authToken,
        isPlanPaid,
        isEmailVerified,
        membershipPlanID,
        isPlanSkipped,
        phone,
        country,
        setCountry,
        setPhone,
        setMembershipPlanID,
    } = useAuth()

    useEffect(() => {
        async function checkPlanAndEmail() {
            if (parseInt(membershipPlanID) !== MEMBERSHIP_CONSTANTS.BASIC) {
                const checkIsplanSkipped = await isPlanSkipped()
                seCheckIsPaymentSkipped(checkIsplanSkipped)
                if (!checkIsplanSkipped.error && !checkIsplanSkipped.status) {
                    const checkPlanIsPaid = await isPlanPaid()
                    setCheckIsPlanPaid(checkPlanIsPaid)
                }
                // else {
                // 	setCheckIsPlanPaid({status: true});
                // }
            } else {
                setCheckIsPlanPaid({ status: true })
            }

            const checkEmailVerification = await isEmailVerified()
            setCheckIsEmailVerified(checkEmailVerification)
            if (!country || country === '' || !phone || phone === '') {
                try {
                    const res = await getUserDetails(authToken)
                    if (!isEmpty(res)) {
                        setCountry(res?.country)
                        setPhone(res?.phone)
                        setMembershipPlanID(res?.membership?.id)
                    } else {
                        setHasContactDetailes(false)
                    }
                } catch (e) {
                    console.log(e)
                    setLoading(false)
                    setHasContactDetailes(false)
                }
            }

            setLoading(false)
        }

        checkPlanAndEmail()
    }, [country, phone])
    return { checkIsPlanPaid, checkIsEmailVerified, hasContactDetailes, checkisPaymentSkipped, loading }
}

export default function AuthedRoute({ component: Component, path, ...rest }) {
    const { authToken, logout } = useAuth()
    const { checkIsPlanPaid, checkIsEmailVerified, hasContactDetailes, checkisPaymentSkipped, loading } =
        useEmailAndPlanChecking()

    if (authToken && authToken !== '' && !loading) {
        if (!hasContactDetailes) {
            return <Redirect to={navigationConstants.COMPLETE_AUTH_REGISTRATION} />
        }

        if (!checkIsPlanPaid.error && !checkisPaymentSkipped.error) {
            if (
                !checkisPaymentSkipped.status &&
                (!checkIsPlanPaid.status || checkIsPlanPaid.status === false || checkIsPlanPaid.status === 'false')
            ) {
                return <Redirect to={navigationConstants.ACCOUNT_PAYMENT} />
            }
        } else {
            logout()
            return (
                <Redirect
                    to={{
                        pathname: navigationConstants.HOMEPAGE,
                        state: { from: 'authedRoute' },
                    }}
                />
            )
        }

        if (!checkIsEmailVerified.error) {
            if (
                !checkIsEmailVerified.status ||
                checkIsEmailVerified.status === 'false' ||
                checkIsEmailVerified.status === false
            ) {
                return <Redirect to={navigationConstants.VERIFY_EMAIL} />
            }
        } else {
            logout()
            return (
                <Redirect
                    to={{
                        pathname: navigationConstants.HOMEPAGE,
                        state: { from: 'authedRoute' },
                    }}
                />
            )
        }
    }

    return (
        <Route
            path={path}
            {...rest}
            render={(props) =>
                authToken !== '' ? <Component {...props} {...rest} /> : <Redirect to={navigationConstants.HOMEPAGE} />
            }
        />
    )
}
