import React from 'react'
import { Link } from 'react-router-dom'
import AngleDownGreen from '../../sections/navigationBar/angleDownGreen.svg'
import { PROHIBITED_MATERIALS } from '../../constants/navigationConstants'
import { UK_GOV } from '../../constants/constants'
const tabBody = ({ questionAndAnswer }) => {
    return (
        <div className="card accordion-question-card">
            <div className="w-100" id={questionAndAnswer?.containerId}>
                <h2 className="mb-0">
                    <a
                        className="h-auto nav-buttons-font collapsed d-flex row justify-content-between mr-0 ml-0"
                        type="a"
                        data-toggle="collapse"
                        data-target={`#${questionAndAnswer.collapseId}`}
                        aria-expanded="true"
                        aria-controls={questionAndAnswer?.collapseId}
                    >
                        {questionAndAnswer?.question}
                        <img alt="..." className="accordion-arrow" src={AngleDownGreen}></img>
                    </a>
                </h2>
            </div>

            <div
                id={questionAndAnswer?.collapseId}
                className="collapse w-100"
                aria-labelledby={questionAndAnswer.containerId}
                data-parent="#newCustomerQuestions"
            >
                <div className="card-body pr-0 pl-0 text-grey">
                    {typeof questionAndAnswer?.answer === 'string' ? (
                        questionAndAnswer?.answer
                    ) : (
                        <ol type="a">
                            {questionAndAnswer?.answer?.map((qa, index) => (
                                <li key={`ans-${index}`}>{qa}</li>
                            ))}
                        </ol>
                    )}
                    {questionAndAnswer?.containerId === 'twotwo' && (
                        <Link to={PROHIBITED_MATERIALS}>www.shopiniworld.com/prohibited-materials</Link>
                    )}
                    {questionAndAnswer?.containerId === 'foursix' && (
                        <Link to={UK_GOV}>https://www.gov.uk/vat-rates</Link>
                    )}
                </div>
            </div>
        </div>
    )
}

export default tabBody
