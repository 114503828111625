import React from 'react'
import { useTranslation } from 'react-i18next'
import { CUSTOMER_CARE_EMAIL } from '../../constants/constants'

function BuyItForMePolicy() {
    const { t, i18n } = useTranslation()
    return (
        <div className="global-section-container flex-col">
            <div className={`${i18n.language === 'ar' ? 'rtl-direction' : 'ltr-direction'} text-start`}>
                <div className="container mb-4 text-start">
                    <h1 className="sub-page-header mt-4">{t('Buying_Assistant')}</h1>
                    <h2 className="sub-page-section-header mt-4">{t('Payment_Terms')}</h2>
                    <p>{t('Payment_Terms_note')}</p>
                    <ul>
                        <li>
                            {t(
                                'If the payment made matches your item price and total cost, we shall proceed with the request',
                            )}
                        </li>
                        <li>
                            {t(
                                'If the item price is less than your payment, the balance would be transferred automatically to your Wallet with us',
                            )}
                        </li>
                        <li>
                            {t('If the item price is higher than your payment, then the below would be considered')}
                            <ol type="a">
                                <li>
                                    {t(
                                        'You have available balance in your Wallet we will ask you to confirm the use of your available balance in the Wallet and proceed',
                                    )}
                                </li>
                                <li>
                                    {t(
                                        'You have no available balance in your Wallet we will return the payment amount to your Wallet and ask you to resubmit your request when total amount is available again',
                                    )}
                                </li>
                            </ol>
                        </li>
                    </ul>

                    <h2 className="sub-page-section-header mt-4">{t('Cancellation Terms')}</h2>

                    <p>{t('Cancellation is not possible once your request is placed and confirmed from your side')}</p>

                    <h2 className="sub-page-section-header mt-4">{t('Returns')}</h2>
                    <p>
                        {t('Please contact our customer service team')}{' '}
                        <a href={`mailto:${CUSTOMER_CARE_EMAIL}`}>{CUSTOMER_CARE_EMAIL}</a>{' '}
                        {t('to direct you to the return process')}
                        <br />
                        <br />
                    </p>
                </div>
            </div>
        </div>
    )
}

export default BuyItForMePolicy
