import React from 'react'
import { Button, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const TrackForm = ({ handleSubmit, parcelId, setParcelId, error, setError }) => {
    const { t, i18n } = useTranslation()
    const onKeyUp = (event) => {
        event.preventDefault()
        if (parcelId && parcelId !== '') {
            handleSubmit(event)
        }
    }

    return (
        <div className="bg-white px-2 py-2 tracking-box">
            <Form className="mx-3 w-100">
                <Form.Group>
                    <Form.Control
                        id="parcelIDTracking"
                        name={'parcelId'}
                        value={parcelId}
                        onChange={(e) => {
                            setError(null)
                            setParcelId(e.target.value)
                        }}
                        className="w-100"
                        placeholder={t('Enter Tracking number')}
                        onKeyPress={(e) => {
                            if (e.charCode === 13 && e?.target?.value) {
                                onKeyUp(e)
                            }
                        }}
                        isInvalid={error}
                    />
                    {!parcelId ||
                        (error && (
                            <Form.Control.Feedback type="invalid">
                                {t(!parcelId || parcelId === '' ? 'Please enter parcel ID to track' : error)}
                            </Form.Control.Feedback>
                        ))}
                </Form.Group>
            </Form>
            <Button
                className="text-white btn-bg-color mx-3"
                onClick={(e) => {
                    handleSubmit(e)
                }}
                disabled={(error && error !== '') || !parcelId || parcelId === ''}
            >
                {t('Track')}
            </Button>
        </div>
    )
}

export default TrackForm
