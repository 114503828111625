import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import './style.scss'
import TrackForm from './trackForm'

const Banner = ({ handleSubmit, parcelId, setParcelId, error, setError }) => {
    const { t, i18n } = useTranslation()
    return (
        <>
            <div className={`consol-banner gray-bg mb-3`}>
                <Row className="justify-content-around alig-items-baseline w-100">
                    <Col xl={4} lg={4} md={4} sm={12}>
                        <p className="text-center text-white track-subtext">{t('Dont just Shop Shop & Ship better')}</p>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={12}>
                        <h1 className="text-center">{t('Track your Shipment')}</h1>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={12} />
                </Row>
                <TrackForm
                    handleSubmit={handleSubmit}
                    parcelId={parcelId}
                    setParcelId={setParcelId}
                    error={error}
                    setError={setError}
                />
            </div>
        </>
    )
}

export default Banner
