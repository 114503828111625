import React, { useState, useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import SuccessIcon from '../../assets/img/icons/success.gif'
import FailedIcon from '../../assets/img/icons/fail.gif'

import * as NAVIGATION_CONSTANTS from '../../constants/navigationConstants'
import useAnalytics from '../../analytics/useAnalytics'

export default (props) => {
    const { t, i18n } = useTranslation()
    const { status } = useParams()

    const [loading, setLoading] = useState(true)
    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')
    const [redirectLink, setRedirectLink] = useState('')
    const [iconSVG, setIconSVG] = useState('')
    const location = useLocation()
    const analytics = useAnalytics()

    useEffect(async () => {
        if (document.getElementById('adcb-checkout')) {
            document.getElementById('adcb-checkout')?.remove()
        }
        if (status === 'success') {
            setTitle(t('Your request was successful'))
            // setMessage(t("Thank you for your payment, you can track your shipment in the active parcels tab in the dashboard"));
            setIconSVG(SuccessIcon)
            if (location.state.membershipPaymentSavedCardEventsAttributes) {
                analytics.events.membershipPaymentCompleted(location.state.membershipPaymentSavedCardEventsAttributes)
            }
            if (location.state.parcelPaymentSavedCardEventsAttributes) {
                analytics.events.shippingNoConsolidationPaymentCompleted(
                    location.state.parcelPaymentSavedCardEventsAttributes,
                )
            }
            if (location.state.consolidationPaymentSavedCardEventsAttributes) {
                analytics.events.shippingConsolidationPaymentCompleted(
                    location.state.consolidationPaymentSavedCardEventsAttributes,
                )
            }
        } else {
            setTitle(t('Payment Failed'))
            setMessage(t('Unfortunately , we couldnt collect payment Please add a valid credit card details'))
            setIconSVG(FailedIcon)
            if (location.state.membershipPaymentSavedCardEventsAttributes) {
                const { paymentMethod, totalAmount, planType, membershipType, source } =
                    location.state.membershipPaymentSavedCardEventsAttributes
                const reason = message || 'Failed'
                analytics.events.membershipPaymentFailure({
                    paymentMethod,
                    totalAmount,
                    planType,
                    membershipType,
                    source,
                    reason,
                })
            }
            if (location.state.parcelPaymentSavedCardEventsAttributes) {
                const reason = message || 'Failed'
                const { paymentMethod, totalAmount, warehouse, parcelIds } =
                    location.state.parcelPaymentSavedCardEventsAttributes
                analytics.events.shippingNoConsolidationPaymentFailure({
                    paymentMethod,
                    totalAmount,
                    warehouse,
                    parcelIds,
                    reason,
                })
            }
            if (location.state.consolidationPaymentSavedCardEventsAttributes) {
                const reason = message || 'Failed'
                const { paymentMethod, totalAmount, warehouse, parcelId } =
                    location.state.consolidationPaymentSavedCardEventsAttributes
                analytics.events.shippingConsolidationPaymentFailure({
                    paymentMethod,
                    totalAmount,
                    warehouse,
                    parcelId,
                    reason,
                })
            }
        }
        setRedirectLink(NAVIGATION_CONSTANTS.DASHBOARD)

        setLoading(false)
    }, [])

    return (
        <div className="d-flex justify-content-center align-items-center global-section-container grey-background full-height-page-small-nav pt-5 pb-5">
            <div
                className={
                    'container-fluid container-width w-50-mobile-100 white-background info-page ' +
                    (loading ? 'loading' : null)
                }
            >
                {loading ? (
                    <div className="spinner-border" role="status" />
                ) : (
                    <div>
                        <img alt="..." src={iconSVG} className="w-25" />
                        <h2 className="">{t(title)}</h2>

                        {message && <p>{t(message)}</p>}

                        <Link
                            to={{
                                pathname: redirectLink,
                                search: '',
                                state: props.location && props.location.state ? props.location.state : {},
                            }}
                        >
                            <button className="btn btn-primary rounded">{t('Return to Dashboard')}</button>
                        </Link>
                    </div>
                )}
            </div>
        </div>
    )
}
