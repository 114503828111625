export const categoriesList = [
    {
        id: "Women's Fashion",
        title: "Women's Fashion",
        titleAr: 'النساء',
        image: require('./icons/w-fashion.svg')?.default,
    },
    {
        id: "Men's Fashion",
        title: "Men's Fashion",
        titleAr: 'الرجال',
        image: require('./icons/m-fashion.svg')?.default,
    },
    {
        id: 'Kids Fashion',
        title: 'Kids Fashion',
        titleAr: 'اﻷطفال',
        image: require('./icons/k-fashion.svg')?.default,
    },
    {
        id: 'Activewear',
        title: 'Activewear',
        titleAr: 'الملابس الرياضية',
        image: require('./icons/activewear.svg')?.default,
    },
    {
        id: 'Home',
        title: 'Home',
        titleAr: 'المنزل',
        image: require('./icons/home.svg')?.default,
    },
    {
        id: 'Department store',
        title: 'Department store',
        titleAr: 'متجر متعدد الاقسام',
        image: require('./icons/depstore.svg')?.default,
    },
    {
        id: 'Technology',
        title: 'Technology',
        titleAr: 'تكنولوجيا',
        image: require('./icons/tech.svg')?.default,
    },
    {
        id: 'Toys',
        title: 'Toys',
        titleAr: 'ألعاب',
        image: require('./icons/toys.svg')?.default,
    },
    {
        id: 'Beauty',
        title: 'Beauty',
        titleAr: 'مستحضرات التجميل',
        image: require('./icons/beauty.svg')?.default,
    },
    {
        id: 'Accessories',
        title: 'Accessories',
        titleAr: 'الاكسسوارات',
        image: require('./icons/acc.svg')?.default,
    },
    {
        id: 'Health and wellness',
        title: 'Health & wellness',
        titleAr: 'المكملات الغذائية',
        image: require('./icons/health.svg')?.default,
    },
]
