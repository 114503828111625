import React from 'react'
import { Col, Modal, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ParcelAccordion from '../../dashboard/parcel/accordion'

const OrderDetails = ({ parcel, show, handleClose, isMobile }) => {
    const { t, i18n } = useTranslation()

    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName={i18n.language + ''}
            aria-labelledby="contained-modal-title-vcenter"
            id={`upload-orders`}
            size={'xl'}
            centered
        >
            <Modal.Header
                className={`border-bottom-0 justify-content-between border-bottom-none bg-white ${i18n?.language === 'ar' ? 'close-ar' : ''}`}
                closeButton
            >
                <p className={`${i18n?.language === 'ar' ? 'text-right' : 'text-left'} mx-4`}>
                    {parcel?.uuid}
                    <br />
                    <small className="text-muted mt-2">
                        {t('Supplier Tracking #')}: &nbsp;{parcel?.purchase_tracking_number}
                    </small>
                </p>
            </Modal.Header>
            <Modal.Body className={`py-0 text-start mx-4`}>
                <Row>
                    <Col md={12} sm={12} className={`accordion-row ${isMobile ? 'px-0' : ''}`}>
                        <Table>
                            <thead>
                                <tr className="fstyle-w500 fs-12 gray-2 line-height-p160 bg-color-gray2">
                                    <th width="20%">{t('Description')}</th>
                                    <th width="20%">{t('Order ID')}</th>
                                    <th width="10%">{t('Shipment qty')}</th>
                                    <th width="10%">{t('Order qty')} </th>
                                    <th width="10%">{t('Received qty')}</th>
                                    <th width="15%">{t('Value usd')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {parcel?.ParcelItems?.map((item, index) => {
                                    return (
                                        <tr className="fs-12" key={item.id}>
                                            <td>
                                                {item?.commodity?.length > 45 ? (
                                                    <OverlayTrigger
                                                        key={'top'}
                                                        placement={'top'}
                                                        overlay={
                                                            <Tooltip id={`tooltip-top`} bsPrefix="tooltip">
                                                                {item?.commodity}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span className="pdngspnrght">
                                                            {`${item?.commodity?.substring(0, 45)} ...`}
                                                        </span>
                                                    </OverlayTrigger>
                                                ) : (
                                                    <span className="pdngspnrght">{item?.commodity}</span>
                                                )}
                                            </td>
                                            <td>
                                                <span className="pdngspnrght">{item.purchase_order_no}</span>
                                            </td>
                                            <td>
                                                <span className="pdngspnrght">{item.actual_received_qty}</span>
                                            </td>
                                            <td>
                                                <span className="pdngspnrght">{item.order_qty}</span>
                                            </td>
                                            <td>
                                                <span className="pdngspnrght">{item.received_qty || 0}</span>
                                            </td>
                                            <td>
                                                <span>{item.amount} $</span>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default OrderDetails
