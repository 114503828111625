import React, { useEffect, useRef } from 'react'
import MainSection from './mainSection/mainSection'
import ShopAndShipSection from './shopAndShipSection/shopAndShipSection'
import ShopUsBrandsSection from './shopUsBrandsSection/shopUsBrandsSection'
import CalculateShippingFees from './calculateShippingFeesSection/calculateShippingFeesSection'
import ServicesSection from './servicesSection/servicesSection'
import { useAuth } from '../../contexts/AuthContext'
import { exportToLocalStorageKey, importFromLocalStorageKey } from '../../utils/localStorageApi'
import { HOMEPAGE } from '../../constants/navigationConstants'

function Homepage({ location }) {
    const { loggedIn } = useAuth()
    useEffect(() => {
        if (!loggedIn) {
            const language = importFromLocalStorageKey('i18nextLng')
            const defaultPromoCode = importFromLocalStorageKey('defaultPromoCode')
            const warehousesListBkup = Number(importFromLocalStorageKey('warehousesList')) || []
            const warehouseBkup = Number(importFromLocalStorageKey('warehouseId')) || 1

            localStorage.clear()
            exportToLocalStorageKey('i18nextLng', language)
            exportToLocalStorageKey('defaultPromoCode', defaultPromoCode)
            exportToLocalStorageKey('warehouseId', warehouseBkup)
            exportToLocalStorageKey('warehousesList', warehousesListBkup)
        }
    }, [loggedIn])

    useEffect(() => {
        if (location?.state?.calculatorLink) {
            document.getElementById('Calculate shipping fees')?.scrollIntoView({
                behavior: 'auto',
            })
        }
    }, [location?.state?.calculatorLink, location?.pathname])

    return (
        <div>
            <MainSection />
            <ShopAndShipSection />
            <ShopUsBrandsSection />
            <CalculateShippingFees />
            <ServicesSection />
        </div>
    )
}

export default Homepage
