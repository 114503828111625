import React, { useEffect, useState } from 'react'
import Tab1 from './tab1'
import Tab2 from './tab2'
import Tab3 from './tab3'
import Tab4 from './tab4'
import Tab5 from './tab5'
import Tab6 from './tab6'
import Tab7 from './tab7'
import Tab8 from './tab8'
import Tab10 from './tab10'
// import Tab9 from "./tab9-bkup";
import { useTranslation } from 'react-i18next'
import BackButtonSvg from '../../assets/img/icons/backButton.svg'
import Tab9 from './tab9'

function FaqPage(props) {
    const [questionsCssClass, setQuestionsCssClass] = useState('')
    const [answersCssClass, setAnswersCssClass] = useState('mb-hide')
    const { t, i18n } = useTranslation()
    const onQuestionClick = () => {
        setQuestionsCssClass('mb-hide')
        setAnswersCssClass('')
    }

    const onBackButtonClick = () => {
        setQuestionsCssClass('')
        setAnswersCssClass('mb-hide')
    }

    useEffect(() => {
        if (props?.location?.state) {
            document.getElementById(props?.location?.state)?.click()
        }
    }, [])

    return (
        <div className="global-section-container flex-col">
            <div className="main-section-container grey-background">
                <div className="container-fluid container-width">
                    <h1 className="sub-page-header ">{t('Frequently asked questions')}</h1>
                    <div className="col-12 col-sm-12 col-md-8  mx-auto mb-hide">
                        <p className="mb-5">{t('Frequently asked questions description')}</p>
                    </div>
                    <div className="row white-background pt-4 pb-4 pl-2 pr-2">
                        <div className={`col-12 col-sm-12 col-md-3 ${questionsCssClass}`}>
                            <div
                                className="nav flex-column nav-pills"
                                id="tab"
                                role="tablist"
                                aria-orientation="vertical"
                            >
                                <a
                                    className="nav-link active faq-link"
                                    id="new-customer-tab"
                                    data-toggle="pill"
                                    href="#new-customer"
                                    role="tab"
                                    aria-controls="new-customer"
                                    aria-selected="true"
                                    onClick={onQuestionClick}
                                >
                                    <div className="row align-items-center flex-nowrap">
                                        <span
                                            className="badge mr-2 ml-2"
                                            data-icon="bx-bx-credit-card"
                                            data-inline="false"
                                        >
                                            <i className="bx bx-question-mark font-size-16 margin-top-25-percent"></i>
                                        </span>
                                        <p className="mb-0"> {t('How to get started')}</p>
                                    </div>
                                </a>
                                <a
                                    className="nav-link faq-link"
                                    id="payments-tab"
                                    data-toggle="pill"
                                    href="#payments"
                                    role="tab"
                                    aria-controls="payments"
                                    aria-selected="false"
                                    onClick={onQuestionClick}
                                >
                                    <div className="row align-items-center flex-nowrap">
                                        <span
                                            className="badge mr-2 ml-2"
                                            data-icon="bx-bx-credit-card"
                                            data-inline="false"
                                        >
                                            <i className="bx bx-credit-card font-size-16 margin-top-25-percent"></i>
                                        </span>
                                        <p className="mb-0">{t('Prohibited Items')}</p>
                                    </div>
                                </a>
                                <a
                                    className="nav-link faq-link"
                                    id="general-questions-tab"
                                    data-toggle="pill"
                                    href="#general-questions"
                                    role="tab"
                                    aria-controls="general-questions"
                                    aria-selected="false"
                                    onClick={onQuestionClick}
                                >
                                    <div className="row align-items-center flex-nowrap">
                                        <span
                                            className="badge mr-2 ml-2"
                                            data-icon="bx-bx-credit-card"
                                            data-inline="false"
                                        >
                                            <i className="bx bx-info-circle font-size-16 margin-top-25-percent"></i>
                                        </span>
                                        <p className="mb-0">{t('Payment Processing')}</p>
                                    </div>
                                </a>

                                <a
                                    className="nav-link faq-link"
                                    id="about-customs-tab"
                                    data-toggle="pill"
                                    href="#about-customs"
                                    role="tab"
                                    aria-controls="about-customs"
                                    aria-selected="false"
                                    onClick={onQuestionClick}
                                >
                                    <div className="row align-items-center flex-nowrap">
                                        <span
                                            className="badge mr-2 ml-2"
                                            data-icon="bx-bx-credit-card"
                                            data-inline="false"
                                        >
                                            <i className="bx bx-message-square-edit font-size-16 margin-top-25-percent"></i>
                                        </span>
                                        <p className="mb-0">{t('Customs and Taxes')}</p>
                                    </div>
                                </a>
                                <a
                                    className="nav-link faq-link"
                                    id="our-services-tab"
                                    data-toggle="pill"
                                    href="#our-services"
                                    role="tab"
                                    aria-controls="our-services"
                                    aria-selected="false"
                                    onClick={onQuestionClick}
                                >
                                    <div className="row align-items-center flex-nowrap">
                                        <span
                                            className="badge mr-2 ml-2"
                                            data-icon="bx-bx-credit-card"
                                            data-inline="false"
                                        >
                                            <i className="bx bx-archive-in font-size-16 margin-top-25-percent"></i>
                                        </span>
                                        <p className="mb-0">{t('Consolidation')}</p>
                                    </div>
                                </a>
                                <a
                                    className="nav-link faq-link"
                                    id="claims-refunds-tab"
                                    data-toggle="pill"
                                    href="#claims-refunds"
                                    role="tab"
                                    aria-controls="claims-refunds"
                                    aria-selected="false"
                                    onClick={onQuestionClick}
                                >
                                    <div className="row align-items-center flex-nowrap">
                                        <span
                                            className="badge mr-2 ml-2"
                                            data-icon="bx-bx-credit-card"
                                            data-inline="false"
                                        >
                                            <i className="bx bx-money font-size-16 margin-top-25-percent"></i>
                                        </span>
                                        <p className="mb-0">{t('Claims and refunds')}</p>
                                    </div>
                                </a>
                                <a
                                    className="nav-link faq-link"
                                    id="packages-tab"
                                    data-toggle="pill"
                                    href="#packages"
                                    role="tab"
                                    aria-controls="packages"
                                    aria-selected="false"
                                    onClick={onQuestionClick}
                                >
                                    <div className="row align-items-center flex-nowrap">
                                        <span
                                            className="badge mr-2 ml-2"
                                            data-icon="bx-bx-credit-card"
                                            data-inline="false"
                                        >
                                            <i className="bx bx-package font-size-16 margin-top-25-percent"></i>
                                        </span>
                                        <p className="mb-0">{t('Package details')}</p>
                                    </div>
                                </a>
                                <a
                                    className="nav-link faq-link"
                                    id="buy-it-for-me-tab"
                                    data-toggle="pill"
                                    href="#buy-it-for-me"
                                    role="tab"
                                    aria-controls="buy-it-for-me"
                                    aria-selected="false"
                                    onClick={onQuestionClick}
                                >
                                    <div className="row align-items-center flex-nowrap">
                                        <span
                                            className="badge mr-2 ml-2"
                                            data-icon="bx-bx-credit-card"
                                            data-inline="false"
                                        >
                                            <i className="bx bx-user-pin font-size-16 margin-top-25-percent"></i>
                                        </span>
                                        <p className="mb-0">{t('Buy it for me service')?.replaceAll(':', '')}</p>
                                    </div>
                                </a>
                                <a
                                    className="nav-link faq-link"
                                    id="refer-a-friend-tab"
                                    data-toggle="pill"
                                    href="#refer-a-friend"
                                    role="tab"
                                    aria-controls="refer-a-friend"
                                    aria-selected="false"
                                    onClick={onQuestionClick}
                                >
                                    <div className="row align-items-center flex-nowrap">
                                        <span
                                            className="badge mr-2 ml-2"
                                            data-icon="bx-bx-credit-card"
                                            data-inline="false"
                                        >
                                            <i className="bx bx-user-pin font-size-16 margin-top-25-percent"></i>
                                        </span>
                                        <p className="mb-0">{t('Refer a Friend')}</p>
                                    </div>
                                </a>
                                <a
                                    className="nav-link faq-link"
                                    id="shipping-rates-calculations-tab"
                                    data-toggle="pill"
                                    href="#shipping-rates-calculations"
                                    role="tab"
                                    aria-controls="shipping-rates-calculations"
                                    aria-selected="false"
                                    onClick={onQuestionClick}
                                >
                                    <div className="row align-items-center flex-nowrap">
                                        <span
                                            className="badge mr-2 ml-2"
                                            data-icon="bx-bx-credit-card"
                                            data-inline="false"
                                        >
                                            <i className="bx bx-user-pin font-size-16 margin-top-25-percent"></i>
                                        </span>
                                        <p className="mb-0">{t('Shipping rates calculations')}</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className={`col-12 col-sm-12 col-md-9 text-start ${answersCssClass}`}>
                            <div className="display-none-over-768">
                                <a onClick={onBackButtonClick} className="row align-items-center mb-2">
                                    {i18n.language === 'ar' && (
                                        <img src={BackButtonSvg} className="ml-3 mr-3 rotate-180"></img>
                                    )}
                                    {i18n.language !== 'ar' && (
                                        <img alt="..." src={BackButtonSvg} className="ml-3 mr-3"></img>
                                    )}
                                    <p className="mb-0 text-start description">{t('Faq')}</p>
                                </a>
                            </div>
                            <div className="tab-content" id="tabContent">
                                <div
                                    className="tab-pane fade show active"
                                    id="new-customer"
                                    role="tabpanel"
                                    aria-labelledby="new-customer-tab"
                                >
                                    <Tab1 />
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="payments"
                                    role="tabpanel"
                                    aria-labelledby="payments-tab"
                                >
                                    <Tab2 />
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="general-questions"
                                    role="tabpanel"
                                    aria-labelledby="general-questions-tab"
                                >
                                    <Tab3 />
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="about-customs"
                                    role="tabpanel"
                                    aria-labelledby="about-customs-tab"
                                >
                                    <Tab4 />
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="our-services"
                                    role="tabpanel"
                                    aria-labelledby="our-services-tab"
                                >
                                    <Tab5 />
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="claims-refunds"
                                    role="tabpanel"
                                    aria-labelledby="claims-refunds-tab"
                                >
                                    <Tab6 />
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="packages"
                                    role="tabpanel"
                                    aria-labelledby="packages-tab"
                                >
                                    <Tab7 />
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="buy-it-for-me"
                                    role="tabpanel"
                                    aria-labelledby="buy-it-for-me-tab"
                                >
                                    <Tab8 />
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="refer-a-friend"
                                    role="tabpanel"
                                    aria-labelledby="refer-a-friend-tab"
                                >
                                    <Tab9 />
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="shipping-rates-calculations"
                                    role="tabpanel"
                                    aria-labelledby="shipping-rates-calculations-tab"
                                >
                                    <Tab10 />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FaqPage
