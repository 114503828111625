import React, { useState, cloneElement } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { copyTextToClipboard } from '../../../utils/string'
import './customized-tooltip.scss'

export default ({ children, textToBeCopied }) => {
    const { t } = useTranslation()
    const [isClicked, setIsClicked] = useState(false)

    return (
        <OverlayTrigger
            key={'top'}
            placement={'top'}
            onExited={() => setIsClicked(false)}
            overlay={
                <Tooltip id={`tooltip-top`} bsPrefix="tooltip">
                    {isClicked ? (
                        <span>
                            <img
                                className={`copy-all-icon`}
                                src={require('../../common/TextCopyOverlay/copy-icon.svg').default}
                                alt=""
                            />
                            <span>{t('Copied to clipboard')}</span>
                        </span>
                    ) : (
                        <span>
                            <img
                                className={`copy-all-icon`}
                                src={require('../../common/TextCopyOverlay/copy-icon.svg').default}
                                alt=""
                            />
                            <span>{t('Click to Copy')}</span>
                        </span>
                    )}
                </Tooltip>
            }
        >
            {cloneElement(children, {
                onClick: () => {
                    copyTextToClipboard(textToBeCopied).then(() => setIsClicked(true))
                },
            })}
        </OverlayTrigger>
    )
}
