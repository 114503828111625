import React from 'react'
import { useTranslation } from 'react-i18next'

function ShippingBenefitsSectionOne() {
    const { t, i18n } = useTranslation()

    return (
        <div className="main-section-container">
            <div className="container-fluid container-width">
                <h2 className="sub-page-section-header">{t('Our shipping benefits')}</h2>
                <p className="mb-5">
                    {t('Our shipping benefits description 1')}
                    <br />
                    {t('Our shipping benefits description 11')}
                </p>

                <div className="row justify-content-between">
                    <div className="col-12 col-sm-9 col-md-5 flex-grow-1 mb-4 post-450-start-text-align">
                        <h2 className="sub-section-title">{t('Guaranteed pricing no hidden fees')}</h2>
                        <p className="text-justify">{t('Guaranteed pricing no hidden fees description')}</p>
                    </div>
                    <div className="col-12 col-sm-9 col-md-5 flex-grow-1 mb-4  post-450-start-text-align">
                        <h2 className="sub-section-title">{t('Superior packing and personal care')}</h2>
                        <p className="text-justify">{t('Superior packing and personal care description')}</p>
                    </div>
                    <div className="col-12 col-sm-9 col-md-5 flex-grow-1 mb-4  post-450-start-text-align">
                        <h2 className="sub-section-title">{t('Discounted shipping rates')}</h2>
                        <p className="text-justify">{t('Discounted shipping rates description')}</p>
                    </div>
                    <div className="col-12 col-sm-9 col-md-5 flex-grow-1 mb-4  post-450-start-text-align">
                        <h2 className="sub-section-title">{t('Package consolidation')}</h2>
                        <p className="text-justify">{t('Package consolidation description 2')}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShippingBenefitsSectionOne
