import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { head } from 'lodash'
import Header from './header'
import WhyShopini from './why-shopini'
import SeamlessTech from './seamless-tech'
import Solutions from './solutions'
import styles from './styles.module.scss'
import Partners from './partners'
import SmartTracking from './smart-tracking'
import ScheduleACall from './schedule-call'
import InternationalShipping from './international-shipping'
import OneSolutionAllSizes from './one-solution-all-sizes'
import CustomerSupport from './customer-support'
import Footer from './footer'
import ScrollTop from './scroll-top'

const ContactPage = () => {
    const { i18n } = useTranslation()

    useEffect(() => {
        if (localStorage.getItem('i18nextLng') !== 'en') {
            localStorage.setItem('i18nextLng', 'en')
            i18n.changeLanguage('en')
            head(document.getElementsByTagName('html')).setAttribute('lang', 'en')
            head(document.getElementsByTagName('html')).setAttribute('dir', 'ltr')
            console.log(i18n.language, document.getElementsByTagName('html'))
        }
    }, [i18n.language])

    return (
        <div className={`${styles['contact-page']}`}>
            <ScrollTop />
            <Header />
            <WhyShopini />
            <SeamlessTech />
            <Solutions />
            <Partners />
            <SmartTracking />
            <ScheduleACall />
            <InternationalShipping />
            <OneSolutionAllSizes />
            <CustomerSupport />
            <Footer />
        </div>
    )
}

export default ContactPage
