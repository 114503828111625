import _ from 'lodash'

import { PARCEL_CONSTANTS } from '../constants/constants.js'

export const generateCostAndCustomFee = (parcels, paymentDetails) => {
    let charges = []
    let customs = []
    _.forEach(parcels, (p, i) => {
        _.forEach(p.rates, (rates, rateIndex) => {
            let rate
            if (rates.length) {
                rate = _.find(rates, {
                    service: paymentDetails.service,
                })
            } else if (rates.service.toString() === paymentDetails.service.toString()) {
                rate = rates
            }

            if (rate) {
                charges.push(rate.amount)
                customs.push(rate.custom_duties || 0)
            }
        })
    })
    return {
        charges,
        customs,
    }
}

export const calculateSumTotal = (paymentDetails) => {
    const total = parseFloat(paymentDetails.totalAmount || 0)
    const customs = parseFloat(paymentDetails.totalCustomFee || 0)
    const insurance = parseFloat(paymentDetails[PARCEL_CONSTANTS.SERVICE_INSURANCE] || 0)
    const repacking = parseFloat(paymentDetails[PARCEL_CONSTANTS.SERVICE_REPACKING] || 0)

    let consolidation = 0
    let discount = 0

    if (paymentDetails.consolidationType && paymentDetails.consolidationType !== '') {
        consolidation = parseFloat(paymentDetails.consolidateFee || 0)
        discount = parseFloat(paymentDetails.consolidation_discount || 0)
    }

    const grandTotal = total + customs + insurance + repacking + consolidation - discount

    return grandTotal.toFixed(2)
}
