import { useMemo } from 'react'

const useVoid = () => {
    const events = useMemo(
        () => ({
            signupButton: () => {},
            signinButton: () => {},
            membershipSelected: () => {},
            userLogin: () => {},
            userLoggedIn: () => {},
            userLogout: () => {},
            registrationCompleted: () => {},
            membershipPromoApplyCompleted: () => {},
            membershipPromoCancelButton: () => {},
            membershipPromoSubscribeButton: () => {},
            membershipPaymentStarted: () => {},
            membershipPaymentCompleted: () => {},
            membershipPaymentFailure: () => {},
            verifyEmailCompleted: () => {},
            emailVerificationResendButton: () => {},
            forgotPasswordButton: () => {},
            sendResetLinkButton: () => {},
            shippingShipSelectedParcelsButton: () => {},
            shippingApplyPromoCompleted: () => {},
            shippingCancelPromoButton: () => {},
            shippingNoConsolidationCreditedCompleted: () => {},
            shippingNoConsolidationPayNowButton: () => {},
            shippingNoConsolidationPaymentStarted: () => {},
            shippingNoConsolidationPaymentCompleted: () => {},
            shippingNoConsolidationPaymentFailure: () => {},
            shippingConsolidationRequestCompleted: () => {},
            shippingConsolidationCreditedCompleted: () => {},
            shippingConsolidationPayButton: () => {},
            shippingConsolidationPayNowButton: () => {},
            shippingConsolidationPaymentStarted: () => {},
            shippingConsolidationPaymentCompleted: () => {},
            shippingConsolidationPaymentFailure: () => {},
            dashboardBifmSubmitButton: () => {},
            bifmButton: () => {},
            bifmNewPurchaseRequestButton: () => {},
            bifmCancelPurchaseRequestButton: () => {},
            bifmPurchaseRequestCompleted: () => {},
            bifmPurchaseRequestUpdateCompleted: () => {},
            bifmPurchaseRequestPayButton: () => {},
            bifmPurchaseRequestPaymentStarted: () => {},
            bifmPurchaseRequestPaymentCompleted: () => {},
            bifmPurchaseRequestPaymentFailure: () => {},
            upgradeNowButton: () => {},
            languageButton: () => {},
            rateCalculatorButton: () => {},
            calculateShippingCostButton: () => {},
            trackShipmentButton: () => {},
            trackShipmentCompleted: () => {},
            walletButton: () => {},
            helpFaqButton: () => {},
            helpProhibitedMaterialsButton: () => {},
            helpShoppingDirectoryButton: () => {},
            helpTrueWeightButton: () => {},
            servicesPackageConsolidationButton: () => {},
            servicesItemConsolidationButton: () => {},
            servicesOtherServicesButton: () => {},
            servicesBifmButton: () => {},
            contactUsSubmitCompleted: () => {},
            eCommerceButton: () => {},
            contactUs: () => {},
            appleDownloadButton: () => {},
            googleDownloadButton: () => {},
            facebookButton: () => {},
            igButton: () => {},
            linkedInButton: () => {},
            twitterButton: () => {},
            rateUsButton: () => {},
            helpSupportButton: () => {},
            dashboardReferralCopyButton: () => {},
            dashboardRenewButton: () => {},
            dashboardAddCardButton: () => {},
            screenVisited: () => {},
        }),
        [],
    )

    const attributes = useMemo(
        () => ({
            setAttributes: () => {},
        }),
        [],
    )

    return useMemo(
        () => ({
            events,
            attributes,
        }),

        [attributes, events],
    )
}

export default useVoid
