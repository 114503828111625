import * as ApiHandler from './apiRequestHandlers'
import _ from 'lodash'
import { postData, getData } from '../utils/api'

/**
 *
 * @param authToken
 * @returns {Promise<*>}
 */
export const getUserAddress = async (authToken, isSourceAddresses) => {
    let query = `?isSourceAddress=${isSourceAddresses ? 1 : 0}`
    const data = await ApiHandler.getApi(`/account/address${query}`, authToken, null)
    if (data.error) {
        throw new Error(data.error)
    }
    return data?.payload.data
}

/**
 *
 * @param authToken
 * @param fields
 * @returns {Promise<*>}
 */
export const postNewUserAddress = async (authToken, fields) => {
    const data = await ApiHandler.postApi('/account/address', authToken, fields)
    if (data.error) {
        throw new Error(data.error)
    }
    return data
}

/**
 *
 * @param authToken
 * @param fields
 * @returns {Promise<*>}
 */
export const postMakeAddressPrimary = async (authToken, fields) => {
    const data = await ApiHandler.postApi(`/account/primary-address/${fields.id}`, authToken, fields)
    if (data.error) {
        throw new Error(data.error)
    }
    return data
}

/**
 *
 * @param authToken
 * @param fields
 * @returns {Promise<*>}
 */
export const postUpdateUserAddress = async (authToken, fields) => {
    const fieldsWithoudId = _.omit(fields, 'id')
    const data = await ApiHandler.postApi(`/v2/account/address/${fields.id}`, authToken, fieldsWithoudId)
    if (data.error) {
        throw new Error(data.error)
    }
    return data
}

/**
 *
 * @param authToken
 * @param fields
 * @returns {Promise<*>}
 */
export const postDeleteUserAddress = async (authToken, fields) => {
    const data = await ApiHandler.postApi(`/account/delete-address/${fields.id}`, authToken)
    if (data.error) {
        throw new Error(data.error)
    }
    return data
}

/**
 *
 * @param fields
 * @returns {Promise<*>}
 */
export const postForgetPassword = async (fields) => {
    const data = await ApiHandler.postApi('/forgot-password', null, fields)
    if (data.error) {
        throw new Error(data.error)
    }
    return data
}

/**
 *
 * @param fields
 * @returns {Promise<*>}
 */
export const postUpdatePassword = async (fields) => {
    const data = await ApiHandler.postApi('/update-password', null, fields)
    if (data.error) {
        throw new Error(data.error)
    }
    return data
}

/**
 *
 * @param fields
 * @returns {Promise<*>}
 */
export const postResetPassword = async (fields) => {
    const data = await ApiHandler.postApi('/reset-password', null, fields)
    if (data.error) {
        throw new Error(data.error)
    }
    return data
}

/**
 *
 * @param authToken
 * @param fields
 * @returns {Promise<*>}
 */
export const postUpdatePhoneNumber = async (authToken, fields) => {
    const data = await ApiHandler.postApi('/account/phone', authToken, fields)
    if (data.error) {
        throw new Error(data.error)
    }
    return data
}

/**
 *
 * @param authToken
 * @param fields
 * @returns {Promise<*>}
 */
export const postUploadPassport = async (authToken, fields) => {
    const fieldsWithoudType = _.omit(fields, 'document_type')
    const data = await ApiHandler.postApi(`/account/upload/${fields.document_type}`, authToken, fieldsWithoudType)
    if (data.error) {
        throw new Error(data.error)
    }
    return data
}

/**
 *
 * @param authToken
 * @param cardId
 * @returns {Promise<*>}
 */
export const postDeleteUserCard = async (authToken, cardId) => {
    const data = await postData(
        'paymentService',
        '/remove-card',
        {
            cardId,
        },
        authToken,
        true,
    )

    if (data.error) {
        throw new Error(data.error)
    }

    return data
}

/**
 *
 * @param authToken
 * @param cardId
 * @returns {Promise<*>}
 */
export const postMakeCardDefault = async (authToken, cardId) => {
    const data = await postData(
        'paymentService',
        '/make-card-default',
        {
            cardId,
        },
        authToken,
        true,
    )

    if (data.error) {
        throw new Error(data.error)
    }
    return data
}

/**
 *
 * @returns {Promise<*>}
 */
export const getCountries = async () => {
    const data = await ApiHandler.getApi('/location/country', null, null)
    return data?.payload
}

/**
 *
 * @param authToken
 * @returns {Promise<*>}
 */
export const getUserDetails = async (authToken) => {
    const data = await ApiHandler.getApi('/details', authToken, null)
    return data?.payload
}

/**
 *
 * @param country_code
 * @returns {Promise<*>}
 */
export const getStates = async (country_code) => {
    const data = await ApiHandler.getApi(`/location/states/${country_code}`, null, null)
    return data?.payload
}

/**
 *
 * @param country_code
 * @param state_code
 * @returns {Promise<*>}
 */
export const getCities = async (country_code, state_code) => {
    const data = await ApiHandler.getApi(`/location/cities/${country_code}/${state_code}`, null, null)
    return data?.payload
}

/**
 *
 * @param fields
 * @param fullResponse
 * @returns {Promise<*>}
 */
export const postRateCalculation = async (fields, warehouseId, fullResponse = false) => {
    // const response = await postData("shippingService", "/rates", {...fields});
    const response = await postData('shippingService', `/rates-v3?warehouse_id=${warehouseId}`, { ...fields })

    if (fullResponse) {
        return response
    }

    return response?.payload
}

/**
 *
 * @param fields
 * @param fullResponse
 * @returns {Promise<*>}
 */
export const postCalculatorRate = async (fields, warehouseId, fullResponse = false) => {
    const response = await postData('pricingService', `/rate/calculator/v1?warehouse_id=${warehouseId}`, { ...fields })
    if (fullResponse) {
        return response
    }

    return response?.payload
}

// when the server returns false as a response the not verified page will show no matter if the email was verified or not

/**
 *
 * @param authToken
 * @param fields
 * @returns {Promise<*>}
 */
export const postIsPlanPaid = async (authToken, fields) => {
    try {
        const response = await ApiHandler.postApi('/check-plan-payment', authToken, fields)
        if (response) {
            if (!response.message && response.error) {
                throw new Error(response.error)
            } else {
                return { status: response.status }
            }
        } else {
            throw new Error('Something went wrong! Please try to log in later.')
        }
    } catch (error) {
        return { status: false, error: error.message }
    }
}

/**
 *
 * @param authToken
 * @param fields
 * @returns {Promise<*>}
 */
export const postIsVerifiedUser = async (authToken, fields) => {
    try {
        const response = await ApiHandler.postApi('/check-email-verification', authToken, fields)
        if (response) {
            if (!response.message && response.error) {
                throw new Error(response.error)
            } else {
                return { status: response.status }
            }
        } else {
            throw new Error('Something went wrong! Please try to log in later.')
        }
    } catch (error) {
        return { status: false, error: error.message }
    }
}

/**
 *
 * @param authToken
 * @param fields
 * @returns {Promise<*>}
 */
export const postPlanSkipped = async (authToken, fields) => {
    try {
        const response = await ApiHandler.postApi('/check-skipped-payment', authToken, fields)
        if (response) {
            if (!response.message && response.error) {
                throw new Error(response.error)
            } else {
                return { status: response.status }
            }
        } else {
            throw new Error('Something went wrong! Please try to log in later.')
        }
    } catch (error) {
        return { status: false, error: error.message }
    }
}
