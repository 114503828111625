import React from 'react'
import CampaignAR1 from '../../../assets/img/campaigns/campaign-1-ar.svg'
import CampaignAR2 from '../../../assets/img/campaigns/campaign-2-ar.svg'
import CampaignEN1 from '../../../assets/img/campaigns/campaign-1-en.svg'
import CampaignEN2 from '../../../assets/img/campaigns/campaign-2-en.svg'

import { randomIntFromInterval } from '../../../utils/commonFunc'
import { useTranslation } from 'react-i18next'

export default ({ loading }) => {
    const { i18n } = useTranslation()
    const campaignsEN = [CampaignEN1, CampaignEN2]
    const campaignsAR = [CampaignAR1, CampaignAR2]
    const campaigns = i18n?.language === 'ar' ? campaignsAR : campaignsEN

    return loading ? null : (
        <>
            <img className="w-100" src={campaigns[randomIntFromInterval(0, 1)]} />
        </>
    )
}
