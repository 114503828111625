import React, { useRef, memo } from 'react'
import { Modal } from 'react-bootstrap'
import successIcon from '../../assets/img/icons/success.gif'
import failIcon from '../../assets/img/icons/fail.gif'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const ResultsModal = ({ showModal, setShowModal, onSuccess = null, redirectLink = null }) => {
    const { t, i18n } = useTranslation()
    const history = useHistory()
    const closeTimeoutRef = useRef(undefined)

    const handleClose = () => {
        if (closeTimeoutRef) {
            clearTimeout(closeTimeoutRef.current)
        }
        setShowModal({
            show: 0,
            status: false,
        })
        if (showModal?.status) {
            if (onSuccess) {
                onSuccess()
            } else {
                if (redirectLink) {
                    history.push(redirectLink)
                }
            }
        }
    }

    const handleOnShow = () => {
        closeTimeoutRef.current = setTimeout(() => {
            handleClose()
        }, 4000)
    }

    return (
        <Modal
            show={showModal?.show === 1}
            onShow={handleOnShow}
            onHide={handleClose}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            id="purchase-modal"
            className="dark-bg"
            centered
        >
            <Modal.Header className="border-bottom-0" closeButton></Modal.Header>
            <Modal.Body className="text-center">
                <img src={showModal?.status ? successIcon : failIcon} className="d-block w-50 mx-auto mb-3" />
                <h5>
                    {showModal?.message
                        ? t(showModal?.message)
                        : showModal?.status
                          ? t('Your request was successful')
                          : t('Your request was unsuccessful')}
                </h5>
            </Modal.Body>
        </Modal>
    )
}

const areEqual = (prevProps, afterProps) => {
    return prevProps.show === afterProps.show && prevProps.status === prevProps.status
}

export default ResultsModal
