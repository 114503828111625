import React, { useEffect, useState } from 'react'
import { Button, Col, Collapse, Form } from 'react-bootstrap'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import PromoDiscountIcon from '../../assets/img/icons/promo-discount.svg'
import removePromoIcon from './remove-promocode.svg'

import './style.scss'
import { SERVICES_NO_PROMOCODE } from '../../constants/constants'
import useAnalytics from '../../analytics/useAnalytics'

const ShipmentPromoCode = ({ paymentSummary, setPromoCode, promoCode }) => {
    const { t, i18n } = useTranslation()
    const floater = i18n.language === 'ar' ? 'float-left' : 'float-right'
    const alignment = i18n.language === 'ar' ? 'mr-auto' : 'ml-auto'
    const [isOpen, setIsOpen] = useState(true)
    const [promo, setPromo] = useState('')
    const [promoCodeState, setPromoCodeState] = useState(true)
    const [showPromocodeDiscount, setShowPromocodeDiscount] = useState(false)
    const analytics = useAnalytics()

    const clearPromoCode = () => {
        analytics.events.shippingCancelPromoButton({ promoCode })
        setPromoCode('')
        setPromo('')
        setIsOpen(true)
    }

    useEffect(() => {
        if (promoCode) {
            setPromo(promoCode)
        }
    }, [promoCode])

    // useEffect(() => {
    // 	if ((promo && promo !== "") && (paymentSummary?.promo_code_discount && paymentSummary?.promo_code_discount?.is_valid === false)) {
    // 		setPromoCodeState(false);
    // 		setShowPromocodeDiscount(false);
    // 		setIsOpen(true);
    // 	} else {
    // 		setPromoCodeState(true);
    // 		// setIsOpen(false);
    // 	}
    // }, [promoCode, paymentSummary?.promo_code_discount?.is_valid])

    useEffect(() => {
        if (
            paymentSummary?.applied_discount_type &&
            paymentSummary?.promo_code_discount?.discount_value > 0 &&
            paymentSummary?.applied_discount_type === 'membership'
        ) {
            setShowPromocodeDiscount(true)
        } else {
            setShowPromocodeDiscount(false)
        }
    }, [
        paymentSummary?.applied_discount_type,
        paymentSummary?.promo_code_discount?.discount_value,
        paymentSummary?.promo_code_discount?.discount_code,
    ])

    return (
        <div className="promo-code-container">
            {(!setPromoCode && !paymentSummary?.promo_code_discount?.discount_code) ||
            SERVICES_NO_PROMOCODE?.includes(paymentSummary?.shipping_company_id) ? null : promoCode &&
              paymentSummary?.applied_discount_type !== 'membership' ? (
                <div className="promo-banner">
                    <img src={PromoDiscountIcon} alt={''} />
                    {`${promoCode !== '' && promoCode} ${t('Coupon Applied')}`}
                    <img
                        src={removePromoIcon}
                        className={`pointer ${floater} ${alignment} mt-0 mb-0`}
                        onClick={() => {
                            clearPromoCode()
                            setShowPromocodeDiscount(false)
                        }}
                    />
                </div>
            ) : (
                <>
                    <span className="anchor-link" onClick={() => setIsOpen(!isOpen)}>
                        {t('Have a Promo Code?')}
                        <span className={floater}>{isOpen ? <BsChevronUp /> : <BsChevronDown />}</span>
                    </span>
                    <Collapse in={isOpen}>
                        <div>
                            <Form.Row>
                                <Col sm={7}>
                                    <Form.Control
                                        className="text-uppercase"
                                        placeholder={t('Enter Promo Code')}
                                        onChange={(e) => setPromo(e.target.value?.trim()?.toUpperCase())}
                                    />
                                </Col>
                                <Col sm={5}>
                                    <Button
                                        onClick={() => {
                                            setPromoCode(promo)
                                        }}
                                    >
                                        {t('Apply')}
                                    </Button>
                                </Col>
                            </Form.Row>
                        </div>
                    </Collapse>
                    {showPromocodeDiscount ? (
                        <span className="color-red font-15 pb-3">
                            **&nbsp;{t('Promo code')}&nbsp; ({paymentSummary?.promo_code_discount?.discount_code})
                            &nbsp;{t('is less than the Discount')}
                        </span>
                    ) : null}
                </>
            )}
            {!promoCodeState ? (
                <span id="promo-error" className="has-error">
                    {t('Invalid promo')}
                </span>
            ) : null}
        </div>
    )
}

export default ShipmentPromoCode
