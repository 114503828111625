import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ACCOUNT_SETTINGS, CONTACT_US } from '../../constants/navigationConstants'
import { Link } from 'react-router-dom'
import BackButtonSvg from '../../assets/img/icons/backButton.svg'
import { useAuth } from '../../contexts/AuthContext'
import {
    postUpdatePhoneNumber,
    getUserDetails,
    postUploadPassport as postUploadIdentificationDocument,
} from '../../services/userInfoService'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import UpdatePassword from '../updatePasswordForm'
import { Alert } from 'react-bootstrap'
import * as moment from 'moment'
import { MEMBERSHIP_CONSTANTS } from '../../constants/constants'
import useAnalytics from '../../analytics/useAnalytics'
import { screenEvent } from '../../analytics/constants'

const AccountBackButton = () => {
    const { t, i18n } = useTranslation()
    return (
        <Link to={ACCOUNT_SETTINGS} className="row align-items-center mb-2">
            {i18n.language === 'ar' && <img alt="..." src={BackButtonSvg} className="ml-3 mr-3 rotate-180" />}
            {i18n.language !== 'ar' && <img alt="..." src={BackButtonSvg} className="ml-3 mr-3" />}
            <p className="mb-0 text-start">{t('Account')}</p>
        </Link>
    )
}

const UpdatePhoneForm = (props) => {
    const { t, i18n } = useTranslation()
    const classNameForPhoneInput = i18n.language === 'ar' ? 'ltr-direction w-100' : 'w-100'
    const [phone, setPhone] = useState({ phone: props.phone || '' })
    const [error, setError] = useState(null)
    const { authToken } = useAuth()

    const handlePhoneInput = (phoneValue) => {
        setPhone({ phone: phoneValue })
    }

    async function handlePhoneUpdate(e) {
        e.preventDefault()
        try {
            const res = await postUpdatePhoneNumber(authToken, phone)
            if (res?.error) {
                throw new Error(res.error)
            }
            setError(null)
            props.setSuccessInfo(t('Successfuly updated Phone Number'))
            props.hideForm()
        } catch (e) {
            setError(e.message)
        }
    }

    return (
        <form onSubmit={handlePhoneUpdate}>
            <PhoneInput
                country={props.country?.toLowerCase()}
                inputProps={{
                    name: 'phone',
                    required: true,
                }}
                id="updateMobileInput"
                onChange={handlePhoneInput}
                inputClass="form-control w-100"
                value={phone.phone}
                containerClass={classNameForPhoneInput}
            />

            <div className="row justify-content-end mr-0 ml-0 mt-2">
                <button className="btn btn-light mr-2 ml-2" onClick={props.hideForm}>
                    {t('Cancel')}
                </button>
                <button type="submit" className="btn btn-primary">
                    {t('Save')}
                </button>
            </div>
            {!!error && <Alert variant={'danger'}>{error}</Alert>}
        </form>
    )
}

const UpdateProofForm = (props) => {
    const { t } = useTranslation()
    const [error, setError] = useState(null)
    const { authToken } = useAuth()
    const [identificationProof, setIdentificationProof] = useState({
        document_type: '',
        number: '',
        file: null,
    })

    let name, value
    const handleInputs = (event) => {
        name = event.target.name
        value = event.target.value

        setIdentificationProof({ ...identificationProof, [name]: value })
    }

    const handleFile = (event) => {
        try {
            let files = event.target.files
            let reader = new FileReader()
            reader.readAsDataURL(files[0])

            reader.onload = (e) => {
                setIdentificationProof({
                    ...identificationProof,
                    file: e.target.result,
                })
            }
        } catch (e) {
            setError(e.message)
        }
    }

    async function handleSubmit(event) {
        event.preventDefault()

        try {
            const res = await postUploadIdentificationDocument(authToken, identificationProof)

            if (res?.error) {
                throw new Error(res.error)
            }
            setError(null)
            props.setSuccessInfo(t('Successfuly updated Identification Proof'))
            props.hideForm()
        } catch (e) {
            console.log(e.message)
            setError(e.message)
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="row mr-0 ml-0">
                    <div className="col-12 col-sm-6 col-md-6 d-flex flex-column pl-0 ">
                        <label htmlFor="documentTypeProof">{t('Passport or id')}</label>
                        <select
                            id="documentTypeProof"
                            name="document_type"
                            value={identificationProof.document_type}
                            onChange={handleInputs}
                            className="form-control"
                        >
                            <option value="">{t('Select your ID')}</option>
                            <option value="idcard">{t('National ID')}</option>
                            <option selected="" value="passport">
                                {t('Passport')}
                            </option>
                        </select>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 d-flex flex-column pr-0">
                        <label htmlFor="numberProof">{t('Id number')}</label>
                        <input
                            id="numberProof"
                            name="number"
                            value={identificationProof.number}
                            onChange={handleInputs}
                            placeholder={t('Type your id')}
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="row mr-0 ml-0 mt-3">
                    <label htmlFor="fileProof" className="description">
                        {t('Image')}
                    </label>
                    <small>{t('Image proof description')}</small>
                    <input
                        id="fileProof"
                        name="file"
                        type="file"
                        onChange={handleFile}
                        className="form-control-file upload-button mt-3"
                    />
                </div>

                <div className="row justify-content-end mr-0 ml-0 mt-2">
                    <button className="btn btn-light mr-2 ml-2" onClick={props.hideForm}>
                        {t('Cancel')}
                    </button>
                    <button type="submit" className="btn btn-primary">
                        {t('Save')}
                    </button>
                </div>
                {!!error && <Alert variant={'danger'}>{error}</Alert>}
            </form>
        </>
    )
}

export default () => {
    const { t, i18n } = useTranslation()
    const [showUpdatePassword, setShowUpdatePassword] = useState(false)
    const [showUpdatePhone, setShowUpdatePhone] = useState(false)
    const [showUpdateProof, setShowUpdatedProof] = useState(false)
    const { username, userEmail, userPin, authToken } = useAuth()
    const [phone, setPhone] = useState(0)
    const [country, setCountry] = useState(null)
    const [proof, setProof] = useState(null)
    const [successInfo, setSuccessInfo] = useState(null)
    const analytics = useAnalytics()

    const togglePasswordForm = () => {
        setShowUpdatePassword(!showUpdatePassword)
    }

    const togglePhoneForm = () => {
        setShowUpdatePhone(!showUpdatePhone)
        retrieveUserDetails()
    }

    const toggleProofForm = () => {
        setShowUpdatedProof(!showUpdateProof)
        retrieveUserDetails()
    }

    const handleSetUserAnalyticsAttributes = async (userDetailsRes) => {
        const { expiry_at, is_yearly } = userDetailsRes?.plan_data ?? {}
        const userAttributes = {
            firstName: userDetailsRes.fname ?? '',
            lastName: userDetailsRes.lname ?? '',
            phone: userDetailsRes.phone ?? '',
            email: userDetailsRes.email ?? '',
            country: userDetailsRes.country ?? '',
            membershipType: userDetailsRes.membership_plan_id
                ? MEMBERSHIP_CONSTANTS[`${userDetailsRes.membership_plan_id}`]
                : '',
            planType: !!is_yearly ? 'Yearly' : 'Monthly',
            language: `${i18n.language}`.toUpperCase() ?? '',
            membershipExpiryDate: expiry_at ? moment(expiry_at).toDate() : undefined,
            referralCode: userDetailsRes.referral_code ?? '',
            verificationDate: userDetailsRes.email_verified_at
                ? moment(userDetailsRes.email_verified_at).toDate()
                : undefined,
            signupDate: userDetailsRes.created_at ? moment(userDetailsRes.created_at).toDate() : undefined,
            receiveWhatsappUpdates: !!userDetailsRes.receive_whatsapp,
            receiveEmailUpdates: !!userDetailsRes.receive_emails,
        }
        analytics.attributes.setAttributes(userAttributes)
    }

    async function retrieveUserDetails() {
        try {
            const res = await getUserDetails(authToken)
            setPhone(res?.phone || '')
            setCountry(res?.country)
            setProof(res?.file_identity)
            if (res) handleSetUserAnalyticsAttributes(res)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        retrieveUserDetails()
    }, [])

    useEffect(() => {
        analytics.events.screenVisited({ screen: screenEvent.PROFILE })
    }, [])

    return (
        <div className="global-section-container flex-col grey-background pt-5 pb-5 align-items-center">
            <div className="container white-background text-start row pt-3 pb-3">
                <div className="col-12 col-sm-12 col-md-8">
                    <AccountBackButton />
                    <h1 className="sub-page-section-header">{t('Personal-info')}</h1>

                    <div className="row">
                        {/* name */}
                        <div className="col-12">
                            <label htmlFor="name" className="description">
                                {t('Name')}
                            </label>
                            <div id="name" className="">
                                {username}
                            </div>
                            <hr />
                        </div>
                        {/* email */}
                        <div className="col-12">
                            <label htmlFor="email" className="description">
                                {t('Email-address')}
                            </label>
                            <div id="email" className="">
                                {userEmail}
                            </div>
                            <hr />
                        </div>
                        {/* user pin */}
                        <div className="col-12">
                            <label htmlFor="userPin" className="description">
                                {t('Account')}
                            </label>
                            <div id="userPin" className="">
                                {userPin}
                            </div>
                            <hr />
                        </div>
                        {/* phone */}
                        <div className="col-12">
                            <div className="row justify-content-between mr-0 ml-0">
                                <div>
                                    <label htmlFor="phone" className="description">
                                        {t('Phone')}
                                    </label>
                                    {!showUpdatePhone ? (
                                        <div id="phone" className="">
                                            {phone}
                                        </div>
                                    ) : null}
                                </div>
                                {!showUpdatePhone && !!phone && (
                                    <div>
                                        <a onClick={togglePhoneForm} className="font-18">
                                            {t('Change')}
                                        </a>
                                    </div>
                                )}
                                {!showUpdatePhone && !phone && (
                                    <div>
                                        <a onClick={togglePhoneForm} className="font-18">
                                            {t('Add')}
                                        </a>
                                    </div>
                                )}
                            </div>
                            <div>
                                {showUpdatePhone ? (
                                    <UpdatePhoneForm
                                        hideForm={togglePhoneForm}
                                        country={country}
                                        phone={phone}
                                        setSuccessInfo={setSuccessInfo}
                                    />
                                ) : null}
                            </div>
                            <hr />
                        </div>

                        {/* identification proof */}
                        <div className="col-12">
                            <div className="row justify-content-between mr-0 ml-0">
                                <div>
                                    <label htmlFor="proof" className="description">
                                        {t('Identification Proof')}
                                    </label>
                                    {!showUpdateProof && !!proof ? (
                                        <div id="proof" className="">
                                            {t('Successfully Updated')}
                                        </div>
                                    ) : null}
                                    {!showUpdateProof && !proof ? (
                                        <div id="proof" className="">
                                            {t('Not provided required')}
                                        </div>
                                    ) : null}
                                </div>
                                {!showUpdateProof ? (
                                    <div>
                                        <a onClick={toggleProofForm} className="font-18">
                                            {t('Add')}
                                        </a>
                                    </div>
                                ) : null}
                            </div>
                            <div>
                                {showUpdateProof ? (
                                    <UpdateProofForm
                                        hideForm={toggleProofForm}
                                        country={country}
                                        phone={phone}
                                        setSuccessInfo={setSuccessInfo}
                                    />
                                ) : null}
                            </div>
                            <hr />
                        </div>
                        {/* password */}
                        <div className="col-12">
                            <div className="row justify-content-between mr-0 ml-0">
                                <div>
                                    <label htmlFor="email" className="description">
                                        {t('Password')}
                                    </label>
                                    {!showUpdatePassword ? (
                                        <div id="email" className="">
                                            **********
                                        </div>
                                    ) : null}
                                </div>
                                {!showUpdatePassword ? (
                                    <div>
                                        <a onClick={togglePasswordForm} className="font-18">
                                            {t('Change')}
                                        </a>
                                    </div>
                                ) : null}
                            </div>
                            <div>
                                {showUpdatePassword ? (
                                    <UpdatePassword callback={togglePasswordForm} setSuccessInfo={setSuccessInfo} />
                                ) : null}
                            </div>
                        </div>
                        {!!successInfo && (
                            <div className="col-12 mt-4">
                                <Alert variant={'success'}>{successInfo}</Alert>
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-4">
                    <div className="card">
                        <div className="card-body grey-background border border-radius-10">
                            <h5 className="card-title">
                                <i className="bx bx-info-circle color-green"></i>{' '}
                                <span className="pdlft5">{t('Tips')}</span>
                            </h5>
                            <ul className="font16-gry1 personal-ul">
                                <li>
                                    {t('For changing your name or email, please')}{' '}
                                    <Link to={CONTACT_US}>{t('Contact Us')}</Link>
                                </li>
                                <li>{t('Please make sure to provide us with your ID document or passport')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
