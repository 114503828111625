import React from 'react'
import stepOneSvg from './step1.svg'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { MEMBERSHIP_PLANS } from '../../constants/navigationConstants'

function StepOne() {
    const { t, i18n } = useTranslation()
    return (
        <div className="main-section-container grey-background">
            <div className="container-fluid container-width">
                <h1 className="sub-page-header">{t('How it works header')}</h1>
                <div className="col-12 col-sm-12 col-md-8  mx-auto">
                    <p className="mb-5">{t('How it works description')}</p>
                </div>
                <div className="row justify-content-between justify-content-around-under-768">
                    <div className="col-12 col-sm-9 col-md-5 flex-grow-1 mb-4 align-self-center text-center">
                        <img className="width-100 big-section-img" src={stepOneSvg} alt={''} />
                    </div>
                    <div className="col-12 col-sm-9 col-md-5 flex-grow-1 mb-4 post-450-start-text-align">
                        <p className="description step-color-orange">{t('Step one')}</p>
                        <h2 className="sub-page-section-header">{t('Register and get free address')}</h2>
                        <p className="text-grey">{t('How it works header li description')}</p>
                        <ul className="text-grey">
                            <li>{t('How it works header li 1')}</li>
                            <li>{t('How it works header li 2')}</li>
                            <li>{t('How it works header li 3')}</li>
                        </ul>
                        <Link
                            to={MEMBERSHIP_PLANS}
                            onClick={() => {
                                window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth',
                                })
                            }}
                        >
                            <button type="button" id="register-now" className="btn btn-primary">
                                {t('Register now')}
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StepOne
