import React from 'react'

import { Spinner } from 'react-bootstrap'

import { Loader } from './style'

export default () => {
    return (
        <Loader>
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </Loader>
    )
}
