import React, { useEffect } from 'react'
import './assets/css/index.scss'
import { useTranslation } from 'react-i18next'
import { Container } from 'react-bootstrap'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import * as navigationConstants from './constants/navigationConstants'
import './App.css'
import NavigationBar from './sections/navigationBar/navigationBar'
import ShopiniFooter from './sections/shopiniFooter/shopiniFooter'
import CopyrightFooter from './sections/copyrightFooter/copyrightFooter'
import Homepage from './pages/homepage/homepage'
import { PromotionPage as AmChamPage } from './pages/promotionPages/amcham'
import { PromotionPage as HalloweenPage } from './pages/promotionPages/halloween'
import HowItWorksPage from './pages/howItWorksPage/howItWorksPage'
import OurServicesPage from './pages/ourServicesPage/ourServicesPage'
import WhyChooseUsPage from './pages/whyChooseUsPage/whyChooseUsPage'
import ContactUsPage from './pages/contactUsPage/contactUsPage'
import ProhibitedMaterials from './pages/prohibitedMaterialPage/prohibitedMaterialPage'
import PrivacyPolicy from './pages/privacyPolicyPage/privacyPolicy'
import BuyItForMePolicy from './pages/buyItForMePolicyPage/buyItForMePolicy'
import FaqPage from './pages/faqPage/faqPage'
import TermsConditions from './pages/termsConditionsPage/termsConditionsPage'
import MembershipPage from './pages/membershipPage/membershipPage'
import RegistrationForm from './sections/registrationForm/registrationForm'
import LoginPage from './sections/loginForm/loginFormPage'
// import RegistrationAddressForm from "./sections/registrationForm/registrationAddressForm";
import AccountPayment from './sections/registrationForm/payment'
import VerifyEmail from './pages/emailVerificationPage'
import AccountSettings from './components/accountSettings'
import AccountAddressBook from './components/accountAddressBook'
import AccountPersonalInfo from './components/accountPersonalInfo'
import AccountMembershipProfile from './components/accountMembershipProfile'
import AccountPaymentOptions from './components/accountPaymentOptions'
import AccountNewCard from './components/accountPaymentOptions/accountNewCard'
import Dashboard from './components/dashboard'
import ShipmentOptions from './components/shipmentOptions'
import ShippingPayment from './components/shippingPayment'
import PaymentStatus from './components/paymentStatus'
import AmazonPaymentStatus from './components/AmazonPay/PaymentStatus'
import ReferralLandingPage from './pages/ReferralLandingPage'
import ForgetPasswordPage from './pages/forgetPasswordPage'
import _ from 'lodash'
import { exportToLocalStorageKey, importFromLocalStorageKey } from './utils/localStorageApi'
import ConsolidatedParcelPayment from './components/consolidatedParcelPayment'
import AuthedRoute from './components/auth'
import { useAuth } from './contexts/AuthContext'
import SocialAuthCompleteRegistrationForm from './sections/registrationForm/SocialAuthCompleteRegistrationForm'
import { getData } from './utils/api'
import PurchaseRequest from './components/purchaseRequest'
import Wallet from './components/wallet'
import BuyItForMe from './pages/BuyItForMe'
import Consolidation from './pages/Consolidation'
import accountAmazonPayAccount from './components/accountPaymentOptions/accountAmazonPayAccount'
import MobilePayment from './components/MobilePayment'
import MobileAddCard from './components/MobilePayment/MobileAddCard'
import AboutUs from './pages/AboutUs'
import accountPayPalAccount from './components/accountPaymentOptions/accountPayPalAccount'
// import UKWarehouse from "./pages/UKWarehouse";
import USWarehouse from './pages/USWarehouse'
import Calculator from './pages/Calculator'
import ThankYouPage from './pages/ThankYouPage'
import OneTimeEmailVerification from './pages/oneTimeEmailVerification'
import SubPaymentStatus from './components/AmazonPay/SubPaymentStatus'
import incomingOrders from './components/incomingOrders'
import Directory from './pages/directory'
import myRates from './components/myRates'
import TrackShipments from './pages/TrackShipments'
import ContactPage from './pages/contact'
import ThankYouForContactingUs from './pages/ThankYouForContactingUs'
import TrueWeight from './pages/TrueWeight'
import {
    ALLOWED_FRANCE_CUSTOMERS,
    MEMBERSHIP_CONSTANTS,
    SPAIN_CUSTOMERS,
    VIRTUAL_WAREHOUSES,
} from './constants/constants'
import useAnalytics from './analytics/useAnalytics'
import { getUserDetails } from './services/userInfoService'
import * as moment from 'moment'
import ManifestPage from './pages/ManifestPage'

function App() {
    const { i18n } = useTranslation()
    const { userEmail, userPin, loggedIn, username, setWarehousesList } = useAuth()
    const mainContainerClasses = `main-container ${i18n.language}`
    let language = importFromLocalStorageKey('i18nextLng') || ''
    const analytics = useAnalytics()
    useEffect(() => {
        try {
            if (language === 'ar') {
                exportToLocalStorageKey('i18nextLng', 'ar')
                _.head(document.getElementsByTagName('html')).setAttribute('lang', 'ar')
                _.head(document.getElementsByTagName('html')).setAttribute('dir', 'rtl')
            } else {
                exportToLocalStorageKey('i18nextLng', 'en')
                _.head(document.getElementsByTagName('html')).setAttribute('lang', 'en')
                _.head(document.getElementsByTagName('html')).setAttribute('dir', 'ltr')
            }
        } catch (e) {}
    }, [language])

    const handleSetUserAnalyticsAttributes = async (token) => {
        const res = await getUserDetails(token)
        if (!res) return
        const { expiry_at, is_yearly } = res?.plan_data ?? {}
        const userAttributes = {
            firstName: res.fname ?? '',
            lastName: res.lname ?? '',
            phone: res.phone ?? '',
            email: res.email ?? '',
            country: res.country ?? '',
            membershipType: res.membership_plan_id ? MEMBERSHIP_CONSTANTS[`${res.membership_plan_id}`] : '',
            planType: !!is_yearly ? 'Yearly' : 'Monthly',
            language: `${i18n.language}`.toUpperCase() ?? '',
            membershipExpiryDate: expiry_at ? moment(expiry_at).toDate() : undefined,
            referralCode: res.referral_code ?? '',
            verificationDate: res.email_verified_at ? moment(res.email_verified_at).toDate() : undefined,
            signupDate: res.created_at ? moment(res.created_at).toDate() : undefined,
            receiveWhatsappUpdates: !!res.receive_whatsapp,
            receiveEmailUpdates: !!res.receive_emails,
        }
        analytics.attributes.setAttributes(userAttributes)
    }

    useEffect(() => {
        if (process?.env?.REACT_APP_NODE_ENV === 'production') {
            ;(() => {
                var w = window
                var ic = w.Intercom
                if (typeof ic === 'function') {
                    ic('reattach_activator')
                    ic('update', w.intercomSettings)
                } else {
                    var d = document
                    var i = function () {
                        i.c(arguments)
                    }
                    i.q = []
                    i.c = function (args) {
                        i.q.push(args)
                    }
                    w.Intercom = i
                    var l = function () {
                        var s = d.createElement('script')
                        s.type = 'text/javascript'
                        s.async = true
                        s.src = `https://widget.intercom.io/widget/${process?.env?.REACT_APP_INTERCOM_APP_ID}`
                        var x = d.getElementsByTagName('script')[0]
                        x.parentNode.insertBefore(s, x)
                    }
                    if (w.attachEvent) {
                        w.attachEvent('onload', l)
                    } else {
                        w.addEventListener('load', l, false)
                    }
                }
            })()
        }
    }, [])

    useEffect(() => {
        let intercomSettings = {
            app_id: process?.env?.REACT_APP_INTERCOM_APP_ID,
        }
        if (loggedIn) {
            intercomSettings = {
                ...intercomSettings,
                user_id: userPin,
                email: userEmail,
            }
        }
        if (process?.env?.REACT_APP_NODE_ENV === 'production') {
            window?.Intercom('boot', {
                ...intercomSettings,
                // other settings you'd like to use to initialize Intercom
            })
            window?.Intercom('update', {
                language_override: i18n.language,
            })
            window?.Intercom('onShow', () => {
                analytics.events.helpSupportButton()
            })
        }
    }, [i18n.language, loggedIn, userEmail, userPin])

    useEffect(async () => {
        // try {
        let query = ''
        if (userPin && userPin !== '') {
            query = `?user_pin=${userPin}`
        }
        const res = await getData('userService', `/location/warehouses${query}`, null)
        if (res?.status) {
            let warehouses = res?.payload?.data ?? []
            const filteredWarehouses = warehouses.filter(
                (warehouse) => ALLOWED_FRANCE_CUSTOMERS.includes(userPin) || warehouse.country !== 'FR',
            )
            await setWarehousesList(filteredWarehouses)
        }
        // } catch (error) {
        // console.error(error);
        // }
    }, [userPin])

    useEffect(() => {
        i18n.on('languageChanged', function (lng) {
            analytics.events.languageButton({ language: lng.toUpperCase() })
            analytics.attributes.setAttributes({ language: lng.toUpperCase() })
        })
        return () => {
            i18n.off('languageChanged')
        }
    }, [analytics, i18n])

    useEffect(async () => {
        const userPinFromLocalStorage = importFromLocalStorageKey('userPin')
        const authTokenFromLocalStorage = importFromLocalStorageKey('accessToken')
        const analyticsLoggedInBefore = importFromLocalStorageKey('analyticsLoggedInBefore')
        if (userPinFromLocalStorage && authTokenFromLocalStorage && !analyticsLoggedInBefore) {
            await analytics.events.userLogin({ userPin: userPinFromLocalStorage })
            localStorage.setItem('analyticsLoggedInBefore', true)
            await handleSetUserAnalyticsAttributes(authTokenFromLocalStorage)
        }
    }, [])

    const nonHeaderOrFooterRegex = new RegExp(
        `^(?!.*(${[...navigationConstants.STAND_ALONE_PAGES, 'mobile'].join('|')}))`,
    )

    return (
        <Router>
            <Container fluid className={mainContainerClasses}>
                <Route
                    path={nonHeaderOrFooterRegex}
                    render={(props) => <NavigationBar {...{ ...props, language }} />}
                />
                <Switch>
                    <Route path={navigationConstants.AMCHAM_PROMOTION} component={AmChamPage} />

                    <Route path={navigationConstants.HALLOWEEN_PROMOTION} component={HalloweenPage} />

                    <Route path={navigationConstants.HOW_IT_WORKS} component={HowItWorksPage} />
                    <Route path={navigationConstants.OUR_SERVICES} component={OurServicesPage} />

                    <Route path={navigationConstants.WHY_CHOOSE_US} component={WhyChooseUsPage} />

                    <Route path={navigationConstants.CONTACT_US} component={ContactUsPage} />

                    <Route path={navigationConstants.PROHIBITED_MATERIALS} component={ProhibitedMaterials} />
                    <Route path={navigationConstants.PRIVACY_POLICY} component={PrivacyPolicy} />
                    <Route path={navigationConstants.BUYITFORME_POLICY} component={BuyItForMePolicy} />

                    <Route path={navigationConstants.FAQ} component={FaqPage} />
                    <Route path={navigationConstants.FAQ_W_LANG} component={FaqPage} />

                    <Route path={navigationConstants.TERMS_CONDITIONS} component={TermsConditions} />

                    <Route path={navigationConstants.MEMBERSHIP_PLANS} component={MembershipPage} />

                    <Route path={navigationConstants.REGISTRATION_FORM} component={RegistrationForm} />

                    <Route path={navigationConstants.REGISTRATION_FORM + '/:planId'} component={RegistrationForm} />

                    {/* <Route
                        path={navigationConstants.REGISTRATION_ADDRESS}
                        component={RegistrationAddressForm}
                    /> */}

                    <Route path={navigationConstants.ACCOUNT_PAYMENT} component={AccountPayment} />

                    {/* <Route
                        path={navigationConstants.VERIFY_EMAIL + "/:verificationToken"}
                        component={VerifyEmail}
                    /> */}

                    <Route path={navigationConstants.VERIFY_EMAIL} component={VerifyEmail} />

                    <Route path={navigationConstants.ONE_TIME_VERIFY_EMAIL} component={OneTimeEmailVerification} />

                    <Route path={navigationConstants.FORGOT_PASSWORD + '/:resetToken'} component={ForgetPasswordPage} />

                    <Route
                        path={navigationConstants.COMPLETE_AUTH_REGISTRATION}
                        component={SocialAuthCompleteRegistrationForm}
                    />

                    <Route path={navigationConstants.FORGOT_PASSWORD} component={ForgetPasswordPage} />

                    <Route path={navigationConstants.BUY_IT_FOR_ME} component={BuyItForMe} />

                    <Route path={navigationConstants.CONSOLIDATION} component={Consolidation} />

                    <Route path={navigationConstants.ABOUT_US} component={AboutUs} />

                    <AuthedRoute path={navigationConstants.ACCOUNT_SETTINGS} component={AccountSettings} />

                    <AuthedRoute path={navigationConstants.ACCOUNT_PERSONAL_INFO} component={AccountPersonalInfo} />

                    <AuthedRoute path={navigationConstants.ACCOUNT_ADDRESS_BOOK} component={AccountAddressBook} />

                    <AuthedRoute
                        path={navigationConstants.ACCOUNT_SHIPPER_ADDRESS_BOOK}
                        component={AccountAddressBook}
                        isSourceAddresses={true}
                    />

                    <AuthedRoute
                        path={navigationConstants.ACCOUNT_MEMBERSHIP_PROFILE}
                        component={AccountMembershipProfile}
                    />

                    <AuthedRoute path={navigationConstants.ACCOUNT_PAYMENT_OPTIONS} component={AccountPaymentOptions} />

                    <AuthedRoute path={navigationConstants.ACCOUNT_AMAZON_PAY} component={accountAmazonPayAccount} />

                    <AuthedRoute path={navigationConstants.ACCOUNT_PAY_PAL} component={accountPayPalAccount} />

                    <Route path={navigationConstants.LOGIN_PAGE} component={LoginPage} />

                    <Route path={navigationConstants.HOMEPAGE} component={Homepage} />

                    <AuthedRoute path={navigationConstants.DASHBOARD} component={Dashboard} />

                    <AuthedRoute path={navigationConstants.SHIPMENT_OPTIONS} component={ShipmentOptions} />

                    <AuthedRoute path={navigationConstants.SHIPPING_PAYMENT} component={ShippingPayment} />

                    <AuthedRoute
                        path={navigationConstants.CONSOLIDATED_PARCEL_PAYMENT}
                        component={ConsolidatedParcelPayment}
                    />

                    <AuthedRoute path={navigationConstants.PAYMENT_STATUS + '/:status'} component={PaymentStatus} />
                    <Route path={navigationConstants.PAYMENT_RETURN} component={AmazonPaymentStatus} />

                    <Route path={navigationConstants.SUB_PAYMENT_RETURN} component={SubPaymentStatus} />

                    <Route path={navigationConstants.MOBILE_PAYMENT} component={MobilePayment} />

                    <Route path={navigationConstants.MOBILE_ADD_CARD} component={MobileAddCard} />

                    <Route path={navigationConstants.REFERRAL_PAGE} component={ReferralLandingPage} />

                    {/* <Route
                        path={navigationConstants.UK_WAREHOUSE}
                        component={UKWarehouse}
                    /> */}

                    <Route path={navigationConstants.US_WAREHOUSE} component={USWarehouse} />

                    <Route path={navigationConstants.CALCULATOR} component={Calculator} />

                    <Route path={navigationConstants.THANK_YOU + '/:verificationToken'} component={ThankYouPage} />

                    <AuthedRoute path={navigationConstants.PURCHASE_REQUEST} component={PurchaseRequest} />

                    <AuthedRoute path={navigationConstants.WALLET} component={Wallet} />

                    <AuthedRoute path={navigationConstants.INCOMING_ORDERS} component={incomingOrders} />

                    <AuthedRoute path={navigationConstants.MY_RATES} component={myRates} />

                    {/** hidden page */}

                    <AuthedRoute path={navigationConstants.HIDDEN_PAGES.MANIFEST} component={ManifestPage} />

                    <Route path={navigationConstants.DIRECTORY} component={Directory} />
                    <Route path={navigationConstants.TRACK_SHIPMENTS} component={TrackShipments} />
                    <Route path={navigationConstants.CONTACT} component={ContactPage} />
                    <Route path={navigationConstants.THANK_YOU_FOR_CONTACTING_US} component={ThankYouForContactingUs} />
                    <Route path={navigationConstants.TRUE_WEIGHT} component={TrueWeight} />
                    <Route path="/" component={Homepage} />
                </Switch>
                <Route path={nonHeaderOrFooterRegex} component={ShopiniFooter} />
            </Container>
        </Router>
    )
}

export default App
