import React from 'react'
import { useTranslation } from 'react-i18next'
import './styles.scss'

const AddressFormat1 = ({ fullName, addressLine1, addressLine2, city, state, country, postCode, tel }) => {
    const { t, i18n } = useTranslation()
    const alignment = i18n.language === 'en' ? 'ltr' : 'rtl'

    return (
        <div
            className={`address1-card-wrapper mx-auto ${
                alignment === 'rtl' ? 'ml-xl-0 mr-xl-auto' : 'mr-xl-0 ml-xl-auto'
            }`}
        >
            <div className={`contents`}>
                <div className="mb-2 mb-sm-3">
                    <div className="custom-fs-2 text-center blue-color font-weight-bold">
                        {t('US ADDRESS FORMAT 1')}
                    </div>
                </div>
                <div>
                    <div className="custom-fs-3">
                        <span className="blue-color font-weight-bold">Full Name</span>
                        <span>{fullName}</span>
                    </div>
                </div>
                <div>
                    <div className="custom-fs-3">
                        <span className="blue-color font-weight-bold">Address Line 1:&nbsp;</span>
                        <span>{addressLine1}</span>
                    </div>
                    <div className="custom-fs-3">
                        <span className="blue-color font-weight-bold">Address Line 2:&nbsp;</span>
                        <span>{addressLine2}</span>
                    </div>
                    <div className="custom-fs-3">
                        <span className="blue-color font-weight-bold">City/Town:&nbsp;</span>
                        <span>{city}</span>
                    </div>
                    <div className="custom-fs-3">
                        <span className="blue-color font-weight-bold">State/Province:&nbsp;</span>
                        <span>{state}</span>
                    </div>
                    <div className="custom-fs-3">
                        <span className="blue-color font-weight-bold">Country:&nbsp;</span>
                        <span>{country}</span>
                    </div>
                    <div className="custom-fs-3">
                        <span className="blue-color font-weight-bold">Post Code:&nbsp;</span>
                        <span>{postCode}</span>
                    </div>
                    <div className="custom-fs-3">
                        <span className="blue-color font-weight-bold">Tel:&nbsp;</span>
                        <span>{tel}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddressFormat1
