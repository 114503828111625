import { useTranslation } from 'react-i18next'
import './styles.scss'

const CustomTab = ({ elements }) => {
    const { t, i18n } = useTranslation()

    return (
        <div className="row p-0 m-0">
            {(elements ?? []).map(({ title, description, image }) => (
                <div className="card-container col-12 col-sm-4">
                    <div className="card-image" style={{ backgroundImage: `url(${image})` }}></div>
                    <div className={`card-title ${i18n.language === 'ar' && 'ar'}`}>{t(title)}</div>
                    <div className="card-description">{`${t(description)}.`}</div>
                </div>
            ))}
        </div>
    )
}

export default CustomTab
