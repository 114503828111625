import { MenuItem, TextField, withStyles } from '@material-ui/core'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Button, Col, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ItemsIcon from './itemsIcon.svg'
import DeleteIcon from './deleteIcon.svg'
import ShortUniqueId from 'short-unique-id'
import { checkStrValidity, notAllowAR } from '../../../../utils/string'
import { isUndefined } from 'lodash'
import Autocomplete from '@material-ui/lab/Autocomplete'
import './styles.scss'
import { styled } from '@material-ui/core'
import useAnalytics from '../../../../analytics/useAnalytics'
import { screenEvent } from '../../../../analytics/constants'
import { useRef } from 'react'
import Resizer from 'react-image-file-resizer'
import BrowseFileIcon from './browse.svg'
import DownloadIcon from './download.svg'
import { useAuth } from '../../../../contexts/AuthContext'

const CustomizedTextField = styled((props) => <TextField {...props} />)(({ theme, ar }) => ({
    '& .MuiAutocomplete-inputRoot': {
        padding: '0 12px !important',
    },
    '& .MuiFilledInput-input': {
        padding: '14px 12px 0 !important',
    },
    '& .MuiAutocomplete-input': {
        padding: '0.9rem 0 0 0 !important',
    },
    '& .MuiFilledInput-root': {
        border: '1px solid #e2e2e1',
        overflow: 'hidden',
        borderRadius: 4,
        backgroundColor: 'white',
        transition: theme.transitions.create(['border-color']),
        '&.Mui-focused': {
            borderColor: '#0000FF',
        },
    },
    input: {
        padding: '0',
    },
    '& .MuiInputLabel-formControl': ar
        ? {
              left: 'auto',
              right: '0',
          }
        : {},
    '& .MuiInputLabel-shrink': ar
        ? {
              transformOrigin: 'top right',
          }
        : {},
    '& .MuiInputLabel-root': ar
        ? {
              transformOrigin: 'top right',
          }
        : {},
    '& .MuiInputLabel-filled.MuiInputLabel-shrink': ar
        ? {
              transform: 'translate(-12px, 10px) scale(0.75)',
          }
        : {},
    '& .MuiInputLabel-filled': ar
        ? {
              transform: 'translate(-12px, 20px) scale(1)',
          }
        : {},
    '& .MuiAutocomplete-endAdornment': {
        position: ar ? 'unset !important' : 'absolute !important',
        'padding-top': '3px !important',
    },
}))

export default ({ show, handleClose, parcel, isMobile, suggestions, suppliers, handleSubmit }) => {
    const { t, i18n } = useTranslation()
    const { uploadFiles } = useAuth()
    const [parcelItems, setParcelItems] = useState([...(parcel?.ParcelItems || [])])
    const [supplier, setSupplier] = useState({ id: null, name: '' })
    const uid = new ShortUniqueId({ length: 8 })
    const [deletedItems, setDeletedItems] = useState([])
    const [isDisabled, setIsDisabled] = useState(false)
    const [error, setError] = useState(null)
    const [filesData, setFilesData] = useState([])
    const analytics = useAnalytics()

    useEffect(() => {
        if (parcel && parcel?.id) {
            let tempParcelItems = parcel?.ParcelItems?.map((pi) => {
                return {
                    ...pi,
                    uuid: uid(),
                }
            })
            setParcelItems([...(tempParcelItems || [])])
        } else {
            setParcelItems([{ name: '', quantity: 0, value: 0 }])
        }
        if ((parcel?.website_id || parcel?.website_id !== 0) && parcel?.supplier && parcel?.supplier !== '') {
            setSupplier({ id: parcel.website_id, name: parcel.supplier })
        }
    }, [JSON.stringify(parcel)])

    useEffect(() => {
        const disabled = !isUndefined(
            parcelItems?.find(
                (pi) =>
                    pi?.commodity === '' ||
                    pi?.quantity == 0 ||
                    pi?.amount == 0 ||
                    !pi?.commodity ||
                    !pi?.quantity ||
                    !pi?.amount,
            ),
        )
        setIsDisabled(disabled)
    }, [JSON.stringify(parcelItems)])

    const handleOnChange = (name, value, itemUuid, index, extraKeyName = null, extraKeyVal = null) => {
        let tempVal = notAllowAR(value)
        let tempPIs = [...parcelItems]
        let extra = {}
        if ((name === 'item_name' || name === 'hs_code') && extraKeyName && extraKeyVal) {
            extra[extraKeyName] = extraKeyVal
        }
        let updatedPI = tempPIs?.find((pi) => pi?.uuid === itemUuid)
        tempPIs.splice(index, 1, {
            ...updatedPI,
            [name]: tempVal,
            ...extra,
        })
        setParcelItems([...tempPIs])
    }

    const HandleBrowseClick = (e, itemUuid) => {
        if (e) {
            let itemBarcodeUploadBtn = document?.getElementById(`${itemUuid}-fileCustomBrowse`)
            itemBarcodeUploadBtn.click()
        }
    }

    const fileToBase64 = (files) => {
        return new Promise((resolve) => {
            let fileToLoad = null
            if (files.length > 0) {
                fileToLoad = files[0]
                let fileReader = new FileReader()
                fileReader.onload = (fileLoadedEvent) => {
                    resolve(fileLoadedEvent.target.result)
                }
                fileReader.readAsDataURL(fileToLoad)
            }
        })
    }

    const handleFile = async (e, itemUuid, index) => {
        try {
            console.log(index)
            let files = e.target.files
            let tempPIs = [...parcelItems]
            let fileName = files[0]?.name || ''
            let base64File = await fileToBase64(files, fileName)
            let updatedPI = tempPIs?.find((pi) => pi?.uuid === itemUuid)
            tempPIs.splice(index, 1, {
                ...updatedPI,
                itemBarcode: base64File,
                itemFileName: fileName,
            })
            setParcelItems([...tempPIs])
        } catch (e) {
            setError(e.message)
        }
    }

    const HandleDownloadClick = (e, itemUuid) => {
        if (e) {
            let itemBarcodeDownloadBtn = document?.getElementById(`${itemUuid}-barcodeDownload`)
            itemBarcodeDownloadBtn.click()
        }
    }

    return (
        <Modal
            show={show && parcel?.id}
            onHide={() => {
                handleClose()
                setDeletedItems([])
            }}
            onClose={() => {
                handleClose()
                setDeletedItems([])
            }}
            dialogClassName={i18n.language + ''}
            aria-labelledby="contained-modal-title-vcenter"
            id={`${parcel?.uuid}-itemsModal`}
            size={'lg'}
            centered
            className="parcel-modal"
            onShow={() => analytics.events.screenVisited({ screen: screenEvent.UPDATE_PARCEL_DETAILS })}
        >
            <Modal.Header
                className={`justify-content-between border-bottom-none bg-white ${i18n?.language === 'ar' ? 'close-ar' : ''}`}
                closeButton
            >
                <p className={`${i18n?.language === 'ar' ? 'text-right' : 'text-left'} mx-4`}>
                    {parcel?.uuid} - {parcelItems?.length > 0 ? t('Update Parcel Items') : t('Add Parcel Items')}
                    <br />
                    <small className="text-muted mt-2">
                        {t('Air waybill')}: &nbsp;{parcel?.awb}
                    </small>
                </p>
            </Modal.Header>
            <Modal.Body className={`py-0 text-center ${parcelItems?.length > 0 ? 'gray-bg' : ''}`}>
                {parcelItems?.length > 0 ? (
                    <div id={`items-${parcel?.id}`}>
                        <div className="row my-3 px-4">
                            <div className="col-12">
                                <Autocomplete
                                    className="auto-complete-no-padding"
                                    id={`supplier`}
                                    freeSolo
                                    options={suppliers}
                                    inputValue={supplier?.name ?? ''}
                                    getOptionSelected={(option, value) => option.id == value.id}
                                    getOptionLabel={(option) => option?.name || ''}
                                    onChange={(e, value) => {
                                        setSupplier({ name: value?.name || '', id: value?.id || null })
                                    }}
                                    onInputChange={(e, value, reason) => {
                                        if (reason === 'reset') return
                                        if (reason === 'clear' || !value) {
                                            setSupplier(null)
                                            return
                                        }
                                        const id = suppliers.find((supplier) => supplier?.name === value)?.id || null
                                        setSupplier({ id, name: value })
                                    }}
                                    renderInput={(params) => (
                                        <CustomizedTextField
                                            ar={i18n.language === 'ar'}
                                            variant="filled"
                                            label={t('Supplier')}
                                            name="supplier"
                                            type="text"
                                            fullWidth
                                            {...params}
                                            InputProps={{
                                                ...params.InputProps,
                                                disableUnderline: true,
                                                maxLength: 75,
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <Row className="my-3">
                            <Col md={12} sm={12} className={`text-start`}>
                                <p className="mx-4 text-grey-3">
                                    {t('Add Item description, Quantity, Value, HS Code and Category')}
                                </p>
                                {parcelItems?.map((i, index) => {
                                    return (
                                        <Row
                                            className="my-3 mx-4 align-items-center rounded bg-white py-2"
                                            key={i?.uuid}
                                        >
                                            <Col lg={8} sm={12} className="pt-2">
                                                <CustomizedTextField
                                                    ar={i18n.language === 'ar'}
                                                    label={
                                                        <>
                                                            {t('Item description')}
                                                            &nbsp;<span className="color-red">*</span>
                                                        </>
                                                    }
                                                    value={i?.commodity}
                                                    name="commodity"
                                                    variant={'filled'}
                                                    type="text"
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        maxLength: 75,
                                                    }}
                                                    fullWidth
                                                    id={`${i?.uuid}-description`}
                                                    onChange={(e) =>
                                                        handleOnChange(
                                                            e?.target?.name,
                                                            e?.target?.value,
                                                            i?.uuid,
                                                            index,
                                                        )
                                                    }
                                                />
                                            </Col>
                                            <Col lg={2} sm={12} className="pt-2">
                                                <CustomizedTextField
                                                    ar={i18n.language === 'ar'}
                                                    label={
                                                        <>
                                                            {t('Quantity')}
                                                            &nbsp;<span className="color-red">*</span>
                                                        </>
                                                    }
                                                    value={i?.quantity}
                                                    name="quantity"
                                                    variant={'filled'}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        inputProps: { min: 0 },
                                                    }}
                                                    type="number"
                                                    id={`${i?.uuid}-qty`}
                                                    onChange={(e) =>
                                                        handleOnChange(
                                                            e?.target?.name,
                                                            e?.target?.value,
                                                            i?.uuid,
                                                            index,
                                                        )
                                                    }
                                                    fullWidth
                                                />
                                            </Col>
                                            <Col lg={2} sm={12} className="pt-2">
                                                <CustomizedTextField
                                                    ar={i18n.language === 'ar'}
                                                    label={
                                                        <>
                                                            {t('Item Value')}
                                                            &nbsp;<span className="color-red">*</span>
                                                        </>
                                                    }
                                                    value={i?.amount}
                                                    name="amount"
                                                    variant={'filled'}
                                                    type="number"
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        inputProps: { min: 0 },
                                                    }}
                                                    id={`${i?.uuid}-amount`}
                                                    onChange={(e) =>
                                                        handleOnChange(
                                                            e?.target?.name,
                                                            e?.target?.value,
                                                            i?.uuid,
                                                            index,
                                                        )
                                                    }
                                                    fullWidth
                                                />
                                            </Col>
                                            <Col lg={6} sm={12} className="pt-2">
                                                <Autocomplete
                                                    className="auto-complete-no-padding"
                                                    id={`${i?.uuid}-category`}
                                                    inputValue={i.item_name || ''}
                                                    freeSolo
                                                    options={suggestions.map((option) => option.category)}
                                                    onChange={(e, value) => {
                                                        if (!e && !value) return
                                                        handleOnChange(
                                                            'item_name',
                                                            value,
                                                            i?.uuid,
                                                            index,
                                                            'hs_code',
                                                            suggestions?.find((element) => element?.category === value)
                                                                ?.hs_code || '',
                                                        )
                                                    }}
                                                    onInputChange={(e, value) => {
                                                        if (!e && !value) return
                                                        handleOnChange(
                                                            'item_name',
                                                            value,
                                                            i?.uuid,
                                                            index,
                                                            'hs_code',
                                                            suggestions?.find((element) => element?.category === value)
                                                                ?.hs_code || '',
                                                        )
                                                    }}
                                                    renderInput={(params) => (
                                                        <CustomizedTextField
                                                            ar={i18n.language === 'ar'}
                                                            variant="filled"
                                                            label={t('Category')}
                                                            name="item_name"
                                                            type="text"
                                                            fullWidth
                                                            {...params}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                disableUnderline: true,
                                                                maxLength: 75,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Col>
                                            <Col lg={6} sm={12} className="pt-2 d-flex justify-content-between">
                                                <Autocomplete
                                                    className="auto-complete-no-padding"
                                                    id={`${i?.uuid}-hscode`}
                                                    inputValue={i.hs_code || ''}
                                                    freeSolo
                                                    options={suggestions.map((option) => option.hs_code)}
                                                    fullWidth
                                                    onChange={(e, value) => {
                                                        if (!e && !value) return
                                                        handleOnChange(
                                                            'hs_code',
                                                            value,
                                                            i?.uuid,
                                                            index,
                                                            'item_name',
                                                            suggestions?.find((element) => element?.hs_code === value)
                                                                ?.category || '',
                                                        )
                                                    }}
                                                    onInputChange={(e, value) => {
                                                        if (!e && !value) return
                                                        handleOnChange(
                                                            'hs_code',
                                                            value,
                                                            i?.uuid,
                                                            index,
                                                            'item_name',
                                                            suggestions?.find((element) => element?.hs_code === value)
                                                                ?.category || '',
                                                        )
                                                    }}
                                                    renderInput={(params) => (
                                                        <CustomizedTextField
                                                            ar={i18n.language === 'ar'}
                                                            variant="filled"
                                                            label={t('HS Code')}
                                                            name="hs_code"
                                                            type="text"
                                                            fullWidth
                                                            {...params}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                disableUnderline: true,
                                                                maxLength: 75,
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <img
                                                    src={DeleteIcon}
                                                    className={`tr-cursor ${i18n.language === 'ar' ? 'mr-2' : 'ml-2'}`}
                                                    onClick={() => {
                                                        if (i?.id && !deletedItems.includes(i.id)) {
                                                            setDeletedItems((oldDeletedItems) => [
                                                                ...oldDeletedItems,
                                                                i.id,
                                                            ])
                                                        }
                                                        setParcelItems(
                                                            parcelItems?.filter((pi) => pi?.uuid !== i?.uuid),
                                                        )
                                                    }}
                                                />
                                                {parseInt(uploadFiles) === 1 && !i?.barcode_link && (
                                                    <OverlayTrigger
                                                        key={'top'}
                                                        placement={'top'}
                                                        overlay={
                                                            <Tooltip id={`tooltip-top`} bsPrefix="tooltip">
                                                                {t('Click to upload barcode')}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <img
                                                            src={BrowseFileIcon}
                                                            className={`tr-cursor ${i18n.language === 'ar' ? 'mr-2' : 'ml-2'}`}
                                                            onClick={(e) => HandleBrowseClick(e, i?.uuid)}
                                                        />
                                                    </OverlayTrigger>
                                                )}
                                                {i?.barcode_link && (
                                                    <>
                                                        <img
                                                            src={DownloadIcon}
                                                            className={`tr-cursor ${i18n.language === 'ar' ? 'mr-2' : 'ml-2'}`}
                                                            onClick={(e) => HandleDownloadClick(e, i?.uuid)}
                                                        />
                                                        <a
                                                            target="_blank"
                                                            id={`${i?.uuid}-barcodeDownload`}
                                                            href={i?.barcode_link}
                                                            download
                                                        />
                                                    </>
                                                )}
                                            </Col>
                                            {i?.itemBarcode && (
                                                <Col lg={12} sm={12} className="pt-2 d-flex">
                                                    <CustomizedTextField
                                                        ar={i18n.language === 'ar'}
                                                        label={<>{t('Barcode')}</>}
                                                        value={i?.itemFileName}
                                                        name="barcode"
                                                        variant={'filled'}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                        }}
                                                        type="text"
                                                        readonly={true}
                                                        id={`${i?.uuid}-file-name`}
                                                        fullWidth
                                                    />
                                                    <img
                                                        src={DeleteIcon}
                                                        className={`tr-cursor ${i18n.language === 'ar' ? 'mr-2' : 'ml-2'}`}
                                                        onClick={() => {
                                                            let tempPIs = [...parcelItems]
                                                            let updatedPI = tempPIs?.find((pi) => pi?.uuid === i?.uuid)
                                                            tempPIs.splice(index, 1, {
                                                                ...updatedPI,
                                                                itemBarcode: null,
                                                                itemFileName: null,
                                                            })
                                                            setParcelItems([...tempPIs])
                                                        }}
                                                    />
                                                </Col>
                                            )}
                                            <Col className="d-none">
                                                <input
                                                    id={`${i?.uuid}-fileCustomBrowse`}
                                                    name="file"
                                                    type="file"
                                                    accept={'.pdf'}
                                                    onChange={(e) => handleFile(e, i?.uuid, index)}
                                                    className="form-control-file upload-button"
                                                />
                                            </Col>
                                        </Row>
                                    )
                                })}
                            </Col>
                        </Row>
                    </div>
                ) : (
                    <div>
                        <img src={ItemsIcon} />
                        <h4>{t('No Items Available')}</h4>
                    </div>
                )}
                <Row className="justify-content-center mt-3 mb-4 mx-2">
                    <a
                        className={`mx-3 btn-add-items w-100 p-2 light-blue-bg`}
                        onClick={() =>
                            setParcelItems([
                                ...parcelItems,
                                {
                                    item_name: '',
                                    quantity: 0,
                                    amount: 0,
                                    uuid: uid(),
                                },
                            ])
                        }
                    >
                        +&nbsp;{t('Add items now')}
                    </a>
                </Row>
            </Modal.Body>
            {parcelItems?.length > 0 && (
                <Modal.Footer className="bg-white">
                    <Row>
                        <Col xs={10} className="d-flex">
                            <a
                                className="text-center btn d-flex flex-row justify-content-center blue-color light-blue-bg rounded mx-3"
                                onClick={() => handleClose()}
                            >
                                {t('Cancel')}
                            </a>
                            <a
                                className="text-center btn d-flex flex-row justify-content-center text-white rounded btn-bg-color"
                                style={{
                                    opacity: isDisabled ? 0.4 : 1,
                                    pointerEvents: isDisabled ? 'none' : 'initial',
                                }}
                                onClick={() => {
                                    const payload = {
                                        items: parcelItems?.map((pi) => {
                                            const parcelItemData = {
                                                id: pi?.id || null,
                                                price: pi?.amount,
                                                quantity: pi?.quantity,
                                                name: pi?.commodity,
                                                item_barcode: pi?.itemBarcode,
                                            }
                                            if (pi.item_name) parcelItemData['item_name'] = pi.item_name
                                            if (pi.hs_code) parcelItemData['hs_code'] = pi.hs_code
                                            return parcelItemData
                                        }),
                                        deletedItem: [...deletedItems],
                                    }
                                    if (supplier?.name) {
                                        if (supplier.id || supplier.id == 0) {
                                            payload.supplier = supplier
                                        } else {
                                            payload.supplier = {
                                                name: supplier.name,
                                            }
                                        }
                                    }
                                    handleSubmit(payload)
                                }}
                            >
                                {t('Save')}
                            </a>
                        </Col>
                    </Row>
                </Modal.Footer>
            )}
        </Modal>
    )
}
