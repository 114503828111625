import React from 'react'
import Plans from './plans'
import MembershipFeatures from './membershipFeatures'
import Differentiators from './differentiators'
import { useTranslation } from 'react-i18next'

function WhyChooseUs() {
    const { t, i18n } = useTranslation()

    return (
        <div className="global-section-container flex-col grey-background pt-4">
            <p className="section-title font-weight-300 mb-1">{t('Choose a Membership Plan that is')}</p>
            <p className="section-title mb-0">{t('Suitable for You')}</p>
            <Plans />
            {/* <MembershipFeatures /> */}
            {/* <Differentiators /> */}
        </div>
    )
}

export default WhyChooseUs
