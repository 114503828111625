import styles from './styles.module.scss'

const internationalPartners = [
    {
        title: 'Aramex',
        image: require('../images/partners/aramex.svg').default,
    },
    { title: 'FedEx', image: require('../images/partners/fedex.svg').default },
    { title: 'DHL', image: require('../images/partners/dhl.svg').default },
    {
        title: 'Shopini',
        image: require('../images/partners/shopini.svg').default,
    },
    { title: 'UPS', image: require('../images/partners/ups.svg').default },
]

const domesticPartners = [
    {
        title: 'Aramex',
        image: require('../images/partners/aramex.svg').default,
    },
    { title: 'Naqel', image: require('../images/partners/naqel.svg').default },
    {
        title: 'Jeebly',
        image: require('../images/partners/jeebly.svg').default,
    },
    { title: 'Elite', image: require('../images/partners/elite.svg').default },
    { title: 'Lyve', image: require('../images/partners/lyve.svg').default },
    { title: 'Saee', image: require('../images/partners/saee.svg').default },
    {
        title: 'Thabit',
        image: require('../images/partners/thabit.svg').default,
    },
    { title: 'ESNAD', image: require('../images/partners/esnad.svg').default },
]

const Partners = () => {
    return (
        <div className={styles['partners']}>
            <div className={styles['title']}>Our Shipping Partners</div>
            <div className={styles['sub-title']}>International Partners</div>

            <div
                className={`mx-auto d-flex justify-content-between flex-column  flex-sm-row flex-wrap ${styles['international-p']}`}
            >
                {internationalPartners.map((partner, index, array) => (
                    <img className="mb-2" key={index} src={partner.image} alt={partner.title} />
                ))}
            </div>

            <div className={styles['sub-title']}>Domestic Partners</div>

            <div
                className={`mx-auto d-flex justify-content-between flex-column flex-sm-row flex-wrap ${styles['domestic-p']}`}
            >
                {domesticPartners.map((partner, index, array) => {
                    console.log(partner.imageUrl)
                    return <img className="mb-2" key={index} src={partner.image} alt={partner.title} />
                })}
            </div>
        </div>
    )
}

export default Partners
