import React from 'react'
import { useTranslation } from 'react-i18next'
import { Markup } from 'interweave'
import TabBody from './tabBody'

function Tab4() {
    const { t } = useTranslation()
    const questionsAndAnswers = [
        { containerId: 'fourone', collapseId: 'collapseFourOne', question: t('Qfourone'), answer: t('Afourone') },
        { containerId: 'fourtwo', collapseId: 'collapseFourTwo', question: t('Qfourtwo'), answer: t('Afourtwo') },
        {
            containerId: 'fourthree',
            collapseId: 'collapseFourThree',
            question: t('Qfourthree'),
            answer: t('Afourthree'),
        },
        { containerId: 'fourfour', collapseId: 'collapseFourFour', question: t('Qfourfour'), answer: t('Afourfour') },
        { containerId: 'fourfive', collapseId: 'collapseFourFive', question: t('Qfourfive'), answer: t('Afourfive') },
        //	{ containerId: "foursix", collapseId: "collapseFourSix", question: t("Qfoursix"), answer: t("Afoursix") },
        {
            containerId: 'fourseven',
            collapseId: 'collapseFourSeven',
            question: t('Qfourseven'),
            answer: t('Afourseven'),
        },
    ]
    return (
        <>
            <div className="accordion" id="aboutCustomsQuestions">
                <div className="card align-items-start">
                    <p className="sub-page-section-header mb-show-block text-start">{t('Customs and Taxes')}</p>
                </div>
                {questionsAndAnswers?.map((questionAndAnswer) => (
                    <TabBody questionAndAnswer={questionAndAnswer} />
                ))}
            </div>
        </>
    )
}

export default Tab4
