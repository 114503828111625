import React, { useState, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { Nav } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { head } from 'lodash'
import qs from 'query-string'
import Requests from './requests'
import { REQUESTS_CONSTANTS, TOGGLES } from '../../constants/constants.js'
import { useAuth } from '../../contexts/AuthContext'
import { useMediaQuery } from 'react-responsive'

const NavItem = ({ isMobile, nav, addQueryParam, setActiveTab, isEnglish, counts }) => {
    return (
        <Nav.Item id={`nav-${nav.key}-parcels-tab`} className={`${isEnglish ? 'mr-lg-3 mr-md-2' : 'ml-lg-3 ml-md-2'}`}>
            <Nav.Link
                className={`${isMobile && nav.key === REQUESTS_CONSTANTS.ALL && 'w-100'}`}
                eventKey={nav.key}
                onClick={(e) => {
                    addQueryParam('status', nav.key)
                    setActiveTab(nav.key)
                }}
            >
                {nav.value}{' '}
                <span>
                    <span
                        className={`roundcrl 
                ${nav.iconClass} ${
                    isMobile && nav.key === REQUESTS_CONSTANTS.ALL
                        ? isEnglish
                            ? 'mobile-count-margin-left'
                            : 'mobile-count-margin-right'
                        : ''
                }`}
                    >
                        {counts[nav.key]}
                    </span>
                </span>
            </Nav.Link>
        </Nav.Item>
    )
}

export default (props) => {
    const { t, i18n } = useTranslation()
    const history = useHistory()
    const { authToken } = useAuth()
    const isEnglish = useMemo(() => i18n.language === 'en', [i18n.language])
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const navTabs = [
        {
            key: REQUESTS_CONSTANTS.ALL,
            value: t(REQUESTS_CONSTANTS.ALL_REQUESTS),
            iconClass: 'gray-icon',
        },
        {
            key: REQUESTS_CONSTANTS.PENDING_VAL,
            value: t(REQUESTS_CONSTANTS.PENDING),
            iconClass: 'hollow orng',
        },
        {
            key: REQUESTS_CONSTANTS.COMPLETED_VAL,
            value: t(REQUESTS_CONSTANTS.COMPLETED),
            iconClass: 'hollow red',
        },
    ]

    const [activeTab, setActiveTab] = useState(props?.status)

    const addQueryParam = (key, value) => {
        const queryParam = qs.parse(window.location.search)
        if (value && value !== '') {
            queryParam[key] = value
        } else {
            delete queryParam[key]
        }
        history.push({ search: qs.stringify(queryParam) })
    }

    useEffect(() => {
        if (!activeTab || activeTab === '') {
            setActiveTab('all')
        }
        setActiveTab(props.status)
        addQueryParam('status', props.status)
    }, [])

    const onRequest = (data, type) => {
        props.onRequestClick(data, type)
    }

    return (
        <div className="widget widget_packages">
            <div className="widget__inner">
                <div className="widget__content" id="mparcels">
                    <Nav
                        variant="tabs"
                        activeKey={activeTab}
                        className={`dashboard-nav-items-link ${isMobile && 'px-2'}`}
                    >
                        {navTabs?.map((nav, index) => {
                            return (
                                <NavItem
                                    isMobile={isMobile}
                                    key={nav.key}
                                    counts={props?.counts}
                                    isEnglish={isEnglish}
                                    addQueryParam={addQueryParam}
                                    setActiveTab={setActiveTab}
                                    nav={nav}
                                />
                            )
                        })}
                    </Nav>
                    <div
                        className={`${
                            props?.counts.category === 0 ? 'justify-content-center align-items-center d-flex' : ''
                        } tab-content 
              ${isMobile ? 'mobile_requests' : 'parcel_minheight desktop_requests'} 
            `}
                        id="nav-tabContent"
                    >
                        {navTabs?.map((nav, index) => {
                            return (
                                <>
                                    <div
                                        className={`tab-pane fade ${activeTab === nav.key ? 'show active' : ''}`}
                                        role="tabpanel"
                                        key={nav.value}
                                        id={`nav-${nav.key}-parcels-tab-content`}
                                    >
                                        <Requests
                                            {...props}
                                            addQueryParam={addQueryParam}
                                            category={nav.key}
                                            authToken={authToken}
                                            isMobile={isMobile}
                                            isEnglish={isEnglish}
                                            onRequest={onRequest}
                                            setParcelData={
                                                nav.key === REQUESTS_CONSTANTS.COMPLETED_VAL
                                                    ? props?.setCompletedRequestData
                                                    : nav?.key === REQUESTS_CONSTANTS.PENDING_VAL
                                                      ? props?.setPendingRequestData
                                                      : props?.setAllRequestData
                                            }
                                            parcelData={
                                                nav.key === REQUESTS_CONSTANTS.COMPLETED_VAL
                                                    ? props?.completedRequestData
                                                    : nav.key === REQUESTS_CONSTANTS.PENDING_VAL
                                                      ? props?.pendingRequestData
                                                      : props?.allRequestData
                                            }
                                        />
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
