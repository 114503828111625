import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useAuth } from '../../../contexts/AuthContext'
import { useTranslation } from 'react-i18next'
import { parcelTableDateFormat } from '../../../utils/moment'
import TableList from './tableList'
import { Loader } from '../../common'
import NoRecordsIcon from './no-records.svg'
import { getData } from '../../../utils/api'
import { INCOMING_ORDERS_CONSTANTS } from '../../../constants/constants'
import qs from 'query-string'
import { isNull } from 'lodash'

export default (props) => {
    const { t, i18n } = useTranslation()

    const { authToken, warehouseId, warehousesList } = useAuth()
    const queryParams = qs.parse(window.location.search)
    let { parcelsSearch = '', from = '', to = '', category, listsSearch = '' } = queryParams
    let isIncomingOrdersTab = props?.category === INCOMING_ORDERS_CONSTANTS.INCOMING_ORDERS_VAL
    const [loading, setLoading] = useState(true)
    const [count, setCount] = useState(0)
    const ordersColumns = [
        {
            dataField: 'created_at',
            text: t('Uploaded On'),
            classes: 'w-250-px date-format',
            formatter: (cell, row) => {
                // const dateAndTime = parcelTableDateFormat(cell, true)?.split(",")
                // const date = dateAndTime[0].trim()
                // const time = dateAndTime[1].trim()
                // return <>
                //     <span className="d-block">{date}</span>
                //     <small className="text-muted">{time}</small>
                // </>
                return cell ? parcelTableDateFormat(cell) : '-'
            },
        },
        {
            dataField: 'file_name',
            text: t('File Name'),
        },
        {
            dataField: 'parcels',
            text: t('Total Parcels'),
            formatter: (cell, row) => {
                return cell?.length || 0
            },
        },

        {
            dataField: 'received_parcels',
            text: t('Parcels Recieved'),
            formatter: (cell, row) => {
                return isNull(cell) ? '' : cell
            },
        },

        {
            dataField: 'awb_status',
            text: t('AWB Status'),
            formatter: (cell, row) => {
                let colorClass = ''
                if (cell === 3) {
                    colorClass = 'status-reject'
                } else if (cell === 1) {
                    colorClass = 'status-in-progress'
                } else if (cell === 2) {
                    colorClass = 'status-completed'
                } else if (cell === 4) {
                    colorClass = 'status-ready'
                } else {
                    colorClass = 'status-reject'
                }

                return (
                    <div className="status">
                        <div className="d-inline">
                            <span className={`status-icon ${colorClass} `}></span>

                            <span className={`status-text ${colorClass} mx-2`}>{t(`${cell}-awbStatus`)}</span>
                        </div>
                    </div>
                )
            },
        },
    ]

    const parcelsColumns = [
        {
            dataField: 'purchase_order_number',
            text: t('Order ID'),
            classes: 'font-11',
        },
        {
            dataField: 'uuid',
            text: t('Parcel ID'),
            classes: 'font-11',
        },
        {
            dataField: 'created_at',
            text: t('Record Created'),
            classes: 'font-11',
            formatter: (cell, row) => {
                return parcelTableDateFormat(cell)
            },
        },
        {
            dataField: 'ParcelHasStatuses',
            text: t('Recieved Date'),
            classes: 'font-11',
            formatter: (cell, row) => {
                // const warehouseCountry = warehousesList?.find(w => w?.id === (row.warehouse_id || 1))?.country || "AE"
                let dateAndTime, date, time
                if (cell && cell?.length > 0) {
                    dateAndTime = parcelTableDateFormat(cell[0]?.created_at, true, 'US')?.split(',')
                    date = dateAndTime[0].trim()
                    time = dateAndTime[1].trim()
                }
                return cell && cell?.length > 0 ? (
                    <>
                        <span className="d-block">{date}</span>
                        <small className="text-muted">{time}</small>
                    </>
                ) : (
                    '-'
                )
            },
        },
        {
            dataField: 'line_haul_tracking_number',
            text: t('Line Haul AWB'),
            classes: 'font-11',
            formatter: (cell, row) => {
                return cell && cell !== '' ? cell : '-'
            },
        },
        {
            dataField: 'ParcelLogs',
            text: t('Line Haul status'),
            formatter: (cell, row) => {
                let colorClass = ''
                if (cell && cell?.length > 0) {
                    if ([20, 23, 39].includes(cell[0]?.parcel_status_id)) {
                        colorClass = 'status-reject'
                    } else if ([22, 14, 9, 34, 38].includes(cell[0]?.parcel_status_id)) {
                        colorClass = 'status-completed'
                    } else if ([1, 3, 4].includes(cell[0]?.parcel_status_id)) {
                        colorClass = 'status-ready'
                    } else {
                        colorClass = 'status-in-progress'
                    }
                }

                return cell && cell?.length > 0 ? (
                    <div className="status">
                        <div className="d-inline">
                            <span className={`status-icon ${colorClass} `}></span>

                            <span className={`font-11 status-text ${colorClass} mx-2`}>
                                {t(`${cell[0]?.ParcelStatus?.name || ''}`)}
                            </span>
                        </div>
                    </div>
                ) : (
                    '-'
                )
            },
        },
        {
            dataField: 'ParcelLogs',
            text: t('LH Date'),
            classes: 'font-11',
            formatter: (cell, row) => {
                return cell && cell?.length > 0 ? parcelTableDateFormat(cell[0]?.created_at) : '-'
            },
        },
        {
            dataField: 'new_awb_number',
            text: t('L.M AWB'),
            classes: 'font-11',
            formatter: (cell, row) => {
                return cell && cell !== '' ? cell : '-'
            },
        },
        {
            dataField: 'lastmile_status',
            text: t('Last Mile Status'),
            formatter: (cell, row) => {
                let colorClass = ''
                if (cell) {
                    if ([10, 11, 12, 14, 15].includes(cell.status_id)) {
                        colorClass = 'status-reject'
                    } else if ([1, 2, 3, 16].includes(cell.status_id)) {
                        colorClass = 'status-in-progress'
                    } else if ([4, 5, 6].includes(cell.status_id)) {
                        colorClass = 'status-ready'
                    } else if ([7, 8, 9].includes(cell.status_id)) {
                        colorClass = 'status-completed'
                    }
                } else {
                    colorClass = 'status-reject'
                }

                return cell ? (
                    <div className="status">
                        <div className="d-inline">
                            <span className={`status-icon ${colorClass} `}></span>

                            <span className={`font-11 status-text ${colorClass} mx-2`}>
                                {t(
                                    `${
                                        i18n.language === 'ar'
                                            ? cell.TrackingStatus?.message_ar
                                            : cell.TrackingStatus?.message_en
                                    }`,
                                )}
                            </span>
                        </div>
                    </div>
                ) : (
                    '-'
                )
            },
        },
        {
            dataField: 'lastmile_status',
            text: t('LM Date'),
            classes: 'font-11',
            formatter: (cell, row) => {
                return cell ? parcelTableDateFormat(cell?.created_at) : '-'
            },
        },
        {
            dataField: 'cod',
            text: t('COD Amount'),
            classes: 'font-11',
            formatter: (cell, row) => {
                return cell ? (parseFloat(row?.ParcelPayment?.charges) || 0)?.toFixed(2) : '-'
            },
        },
        {
            dataField: 'purchase_tracking_number',
            text: t('Purchase Tracking Number'),
            classes: 'font-11',
            formatter: (cell, row) => {
                return cell && cell !== '' ? cell : '-'
            },
        },
        {
            dataField: 'pod_link',
            text: t('Proof of Delivery'),
            classes: 'font-11',
            formatter: (cell, row) => {
                return cell && cell !== '' ? (
                    <a target="_blank" href={cell}>
                        {t('View')}
                    </a>
                ) : (
                    '-'
                )
            },
        },
    ]

    const loadIncomingOrders = async () => {
        try {
            let tempParcelData
            let tablePage = isIncomingOrdersTab ? 'lists' : 'parcels'
            setLoading(true)
            let search =
                parcelsSearch?.length > 0 && !isIncomingOrdersTab
                    ? parcelsSearch
                    : isIncomingOrdersTab
                      ? listsSearch
                      : null
            let url = `/v2/parcels-lists${isIncomingOrdersTab ? '' : '/parcels'}?page=${props?.options?.page[tablePage] || 1}&size=${props?.options?.sizePerPage}&warehouse_id=${warehouseId}${search && search !== '' ? `&keyword=${search}` : ''}${from && from !== '' ? `&start_date=${from}` : ''}${to && to !== '' ? `&end_date=${to}` : ''}`
            const incomingOrdersRes = await getData('parcelService', url, authToken)
            tempParcelData =
                !incomingOrdersRes?.status || incomingOrdersRes?.error
                    ? []
                    : isIncomingOrdersTab
                      ? incomingOrdersRes?.payload?.orders_lists
                      : incomingOrdersRes?.payload?.parcels
            // tempParcelData = tempParcelData?.length > 0 && isIncomingOrdersTab ? tempParcelData?.map(pdata => {
            //     return {
            //         ...pdata,
            //         parcels: pdata.parcels?.sort((a, b) => a.purchase_order_number - b.purchase_order_number)
            //     }
            // }) : tempParcelData
            props.setParcelData([...tempParcelData])
            props.setOptions({
                ...props?.options,
                page: {
                    ...props?.options?.page,
                    [isIncomingOrdersTab ? 'lists' : 'parcels']: incomingOrdersRes?.payload?.page,
                },
                pageSize: incomingOrdersRes?.payload?.page_size,
            })
            setCount(parseInt(incomingOrdersRes?.payload?.total) || 0)

            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    useEffect(async () => {
        await loadIncomingOrders()
    }, [props?.reload, warehouseId, props?.options?.page[isIncomingOrdersTab ? 'lists' : 'parcels'], from, to])

    return loading ? (
        <Loader />
    ) : (
        <Row className="incoming-orders-request-container">
            <Col lg={'12'}>
                {props?.parcelData?.length > 0 ? (
                    <TableList
                        {...props}
                        columns={
                            props?.category === INCOMING_ORDERS_CONSTANTS.PARCELS_VAL ? parcelsColumns : ordersColumns
                        }
                        buttonText={''}
                        exportButton={true}
                        setShowModal={props?.setShowModal}
                        options={props?.options}
                        setOptions={props?.setOptions}
                        count={count}
                    />
                ) : (
                    <div className="text-center">
                        <img src={NoRecordsIcon} />
                        <p className="blue-color font-wegiht-500 my-2">{t('No Orders Added')}</p>
                        <span>{t('Click on upload orders to add orders')}</span>
                    </div>
                )}
            </Col>
        </Row>
    )
}
