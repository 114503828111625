import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { PURCHASE_REQUEST } from '../../../../../constants/navigationConstants'
import FailedIcon from '../../../../../assets/img/icons/fail.gif'

export default ({ update = null, modalDataClear }) => {
    const location = useLocation()
    const { t, i18n } = useTranslation()
    return (
        <div className="container text-center">
            <img src={FailedIcon} className="w-25 my-5" />
            <h4>{t('Request_Payment_Failed')}</h4>
            <p>{t('Unfortunately , we couldnt collect payment Please add a valid credit card details')}</p>
            <Link
                to={{
                    pathname: PURCHASE_REQUEST,
                    search: '',
                    state: location && location.state ? location.state : {},
                }}
            >
                <button onClick={() => modalDataClear(update)} className="btn btn-primary rounded text-capitalize">
                    {t('Return to Purchase request')}
                </button>
            </Link>
        </div>
    )
}
