import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { getMaximumWeight, WEIGHT_TO_DIMENSION } from '../../constants/constants'
import './style.scss'
import './form.scss'
import useCountries from '../../utils/useCountries'
import ReactFlagsSelect from 'react-flags-select'
import { useAuth } from '../../contexts/AuthContext'
import DhlImg from './DHL.svg'
import FedexImg from './fedex.svg'
import AramexImg from './aramex.svg'

import { Accordion, Button, Card, Col, Container, Form, Row, Table, useAccordionToggle } from 'react-bootstrap'

const { KG, LB, CM, INCH } = WEIGHT_TO_DIMENSION

const Process = () => {
    const [formValues, setFormValues] = useState([{ weight: '', weight_unit: '' }])
    const { t, i18n } = useTranslation()
    const alignment = i18n.language === 'en' ? 'ltr' : 'rtl'
    const { warehousesList, setWarehouseId, warehouseId } = useAuth()
    const [validationInput, setValidationInput] = useState('')
    const { countries } = useCountries()

    const validateInputs = () => {
        setValidationInput('rate-calculator-input-validation')
    }

    const handleWeightChange = (event) => {
        name = event.target.name
        value = event.target.value
        event.target.value === KG
            ? setRateCalculatorData({
                  ...rateCalculatorData,
                  dimension_unit: CM,
                  [name]: value,
              })
            : setRateCalculatorData({
                  ...rateCalculatorData,
                  dimension_unit: INCH,
                  [name]: value,
              })
    }
    const [rateCalculatorData, setRateCalculatorData] = useState({
        country_id: 0,
        weight: '',
        height: '',
        width: '',
        length: '',
        weight_unit: 'lb',
        dimension_unit: 'in',
    })
    let name, value
    const handleInputs = (event) => {
        name = event.target.name
        value = event.target.value
        setRateCalculatorData({ ...rateCalculatorData, [name]: value })
    }
    const createWarehousesLabels = () => {
        const customLabels = {}
        warehousesList?.forEach(
            (w) =>
                (customLabels[w.country] = {
                    primary: w.name,
                    secondary: w.active ? '' : <div className="mx-1 coming-soon orange-bg">{t('Coming soon')}</div>,
                }),
        )
        return customLabels
    }

    let handleChange = (i, e) => {
        let newFormValues = [...formValues]
        newFormValues[i][e.target.name] = e.target.value
        //alert(JSON.stringify(newFormValues));
        setFormValues(newFormValues)
    }

    let addFormFields = () => {
        setFormValues([...formValues, { weight: '', weight_unit: '' }])
    }

    let removeFormFields = (i) => {
        let newFormValues = [...formValues]
        newFormValues.splice(i, 1)
        setFormValues(newFormValues)
    }

    let handleSubmit = (event) => {
        event.preventDefault()
        alert(JSON.stringify(formValues))
    }

    return (
        <div
            id="Calculate shipping fees"
            class="smooth-scroll shop-ship-section-container gray-bg container-fluid calculator-page"
        >
            <div className="card gray-bg">
                <Col md={12}>
                    <p class="box-tile-main text-center">{t('Shipping Made Simple')}</p>
                    <p class="font-size-17 pt-20 box-sub-tile-main text-center">
                        {t('Where you want to send package')}
                    </p>
                </Col>

                <Form className="container-width-cal gray-bg mb-white-background" onSubmit={validateInputs}>
                    <Row className="col-md-12 calculater waite-bg row-format">
                        <Col md="6">
                            <Form.Group as={Col} controlId="formShipParcelFrom">
                                <Form.Label className={`required ${alignment}`}>{t('Ship Parcel from')}</Form.Label>
                                <ReactFlagsSelect
                                    countries={warehousesList?.map((warehouse) => warehouse.country) || 'US'}
                                    customLabels={createWarehousesLabels()}
                                    className={`ltr-direction text-muted`}
                                    selected={warehousesList?.find((w) => w.id === warehouseId)?.country}
                                    onSelect={(code) => {
                                        if (warehousesList?.find((w) => w.country === code)?.active) {
                                            setWarehouseId(warehousesList?.find((w) => w.country === code)?.id)
                                        } else {
                                            setWarehouseId(1)
                                        }
                                    }}
                                    onFocus={(e) => {
                                        if (e.target.autocomplete) {
                                            e.target.autocomplete = 'noautocomplete'
                                        }
                                    }}
                                    showSelectedLabel
                                    placeholder={'Select a warehouse'}
                                    alignOptionsToRight
                                    fullWidth
                                />
                            </Form.Group>
                        </Col>

                        <Col md="6">
                            <Form.Group as={Col} controlId="formShipParcelTo" className="w-100">
                                <Form.Label className={`required ${alignment}`}>{t('Ship parcel to')}</Form.Label>
                                <select
                                    className={`form-control ${validationInput}`}
                                    name="country_id"
                                    onChange={handleInputs}
                                    value={rateCalculatorData.country}
                                    required
                                >
                                    <option value="">{t('Select a country')}</option>
                                    {countries?.map((country) => (
                                        <option key={country.id} value={country.id}>
                                            {country.name}
                                        </option>
                                    ))}
                                </select>
                            </Form.Group>
                        </Col>
                    </Row>
                    {formValues.map((element, index) => (
                        <div className="row col-lg-12 col-md-12 form-body row-format waite-bg">
                            <div key={index} class="form-row form-row-dispay col-md-12">
                                <Form.Group controlId="parcelWeight" className="w-12pr">
                                    <Form.Label className={`required ${alignment}`}>{t('parcel weight')}</Form.Label>
                                    <input
                                        type="number"
                                        step="0.01"
                                        className={`form-control ${validationInput}`}
                                        name="weight"
                                        value={element.weight || ''}
                                        onChange={(e) => handleChange(index, e)}
                                        min={10}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="wightUnit" className="w-7pr">
                                    <Form.Label className={`required ${alignment}`}>{t('Unit')}</Form.Label>
                                    <select
                                        className={`form-control ${validationInput}`}
                                        name="weight_unit"
                                        value={element.weight_unit || ''}
                                        onChange={(e) => handleChange(index, e)}
                                        required
                                    >
                                        <option value="kg">{t('Kg')}</option>
                                        <option value="lb">{t('Lb')}</option>
                                    </select>
                                </Form.Group>

                                <Form.Group controlId="no_of_parcels" className="w-12pr">
                                    <Form.Label className={`required ${alignment}`}>{t('No of Parcels')}</Form.Label>
                                    <input
                                        type="number"
                                        step="1"
                                        className={`form-control ${validationInput}`}
                                        name="no_of_parcels"
                                        value={element.no_of_parcels || ''}
                                        onChange={(e) => handleChange(index, e)}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="length" className="w-12pr length-label">
                                    <Form.Label>{t('Length')}</Form.Label>
                                    <input
                                        type="number"
                                        step="1"
                                        className={`form-control ${validationInput}`}
                                        name="length"
                                        value={element.length || ''}
                                        onChange={(e) => handleChange(index, e)}
                                    />
                                </Form.Group>

                                <Form.Group controlId="width" className="w-12pr">
                                    <Form.Label>{t('Width')}</Form.Label>
                                    <input
                                        type="number"
                                        step="1"
                                        className={`form-control ${validationInput}`}
                                        name="width"
                                        value={element.width || ''}
                                        onChange={(e) => handleChange(index, e)}
                                    />
                                </Form.Group>

                                <Form.Group controlId="width" className="w-12pr">
                                    <Form.Label>{t('Height')}</Form.Label>
                                    <input
                                        type="number"
                                        step="1"
                                        className={`form-control ${validationInput}`}
                                        name="height"
                                        value={element.height || ''}
                                        onChange={(e) => handleChange(index, e)}
                                    />
                                </Form.Group>

                                <Form.Group controlId="dims" className="w-12pr">
                                    <Form.Label className={`required ${alignment}`}>{t('Dims')}</Form.Label>
                                    <select
                                        className={`form-control ${validationInput}`}
                                        name="dims"
                                        value={element.dims || ''}
                                        onChange={(e) => handleChange(index, e)}
                                        required
                                    >
                                        <option value="cm">{t('CM')}</option>
                                        <option value="inch">{t('INCH')}</option>
                                    </select>
                                </Form.Group>

                                {index ? (
                                    <button type="button" className="remove close float-right">
                                        <i class="bx bx-x" onClick={() => removeFormFields(index)}></i>
                                    </button>
                                ) : null}
                            </div>
                        </div>
                    ))}

                    <div className="button-section text-right">
                        <Row className={`label-direction-${alignment}`}>
                            <span className="add-button add" type="button" onClick={() => addFormFields()}>
                                {t('Add another package')} +
                            </span>
                        </Row>
                        <Row>
                            <button className="btn btn-primary btn-block submit" type="submit">
                                {t('Calculate shipping cost button')}
                            </button>
                        </Row>
                    </div>
                </Form>
            </div>

            <Col md={12} className="waite-bg card card-text pading-zero">
                <div class="container-width-cal mb-white-background waite-bg row pb-20">
                    <Col md={4} sm={4} className="pading-2px">
                        <Col md={12} className="resuilts-box">
                            <Col md={12} className={`margin-zero ${alignment}`}>
                                <span>{t('Actual weight')}</span>
                            </Col>

                            <Col md={12} className={`margin-zero ${alignment}`}>
                                <span className="resuilts-box-w">100 {t('kg')}</span>
                            </Col>
                        </Col>
                    </Col>
                    <Col md={4} sm={4} className="pading-2px">
                        <Col md={12} className="resuilts-box">
                            <Col md={12} className={`margin-zero ${alignment}`}>
                                <span>{t('Volumetrice weight')}</span>
                            </Col>

                            <Col md={12} className={`margin-zero ${alignment}`}>
                                <span className="resuilts-box-w">85 {t('kg')}</span>
                            </Col>
                        </Col>
                    </Col>
                    <Col md={4} sm={4} className="pading-2px">
                        <Col md={12} className="resuilts-box">
                            <Col md={12} className={`margin-zero ${alignment}`}>
                                <span>{t('Chargable weight')}</span>
                            </Col>

                            <Col md={12} className={`margin-zero ${alignment}`}>
                                <span className="resuilts-box-w">90 {t('kg')}</span>
                            </Col>
                        </Col>
                    </Col>
                </div>
                <p class="section-title text-center">{t('Your Estimated Shipping Cost')}</p>

                <Col md={12} className="container-width-cal pading-zero">
                    <Col md={12} className="gray-bg p-25 mb-20">
                        <Row>
                            <Col md={1} sm={1}>
                                <img className="shipping-c-img" src={DhlImg} />
                            </Col>

                            <Col md={5} sm={6}>
                                <Col md={12} className={`pb-10 shop-cost-title ${alignment}`}>
                                    {t('Express worldwide ')}
                                </Col>

                                <Col md={9} className={`shop-cost-body ${alignment}`}>
                                    {t(
                                        'Package Count Limit 50 Weight Limit 150 lbs Full Shipment Tracking No hidden fees',
                                    )}
                                </Col>
                            </Col>

                            <Col md={6} sm={5} className="text-align-end">
                                <Col md={12}>
                                    <p className="price-total pm-zero">419.35 $</p>
                                </Col>

                                <Col md={12}>
                                    <p className="total-days">2-4 working days</p>
                                </Col>
                            </Col>
                        </Row>
                    </Col>

                    <Col md={12} className=" p-25 mb-20">
                        <Row>
                            <Col md={1} sm={1}>
                                <img className="shipping-c-img" src={FedexImg} />
                            </Col>

                            <Col md={5} sm={6}>
                                <Col md={12} className={`pb-10 shop-cost-title ${alignment}`}>
                                    {t('International Economy')}
                                </Col>

                                <Col md={9} className={`shop-cost-body ${alignment}`}>
                                    {t('Package Count Limit 50 Full Shipment Tracking No hidden fees')}
                                </Col>
                            </Col>

                            <Col md={6} sm={5} className="text-align-end">
                                <Col md={12}>
                                    <p className="price-total pm-zero">366.63 $</p>
                                </Col>

                                <Col md={12}>
                                    <p className="total-days">2-4 working days</p>
                                </Col>
                            </Col>
                        </Row>
                    </Col>

                    <Col md={12} className="gray-bg p-25 mb-20">
                        <Row>
                            <Col md={1} sm={1}>
                                <img className="shipping-c-img" src={AramexImg} />
                            </Col>

                            <Col md={5} sm={6}>
                                <Col md={12} className={`pb-10 shop-cost-title ${alignment}`}>
                                    {t('Aramex')}
                                </Col>

                                <Col md={9} className={`shop-cost-body ${alignment}`}>
                                    {t(
                                        'Package Count Limit 50 Weight Limit 150 lbs Full Shipment Tracking No hidden fees',
                                    )}
                                </Col>
                            </Col>

                            <Col md={6} sm={5} className="text-align-end">
                                <Col md={12}>
                                    <p className="price-total pm-zero">439.71 $</p>
                                </Col>

                                <Col md={12}>
                                    <p className="total-days">2-4 working days</p>
                                </Col>
                            </Col>
                        </Row>
                    </Col>
                </Col>
            </Col>
        </div>
    )
}

export default Process
