import React, { useState, useEffect, useMemo } from 'react'
import { useHistory, Link } from 'react-router-dom'

import { Nav, Alert } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Requests from './requests'
import { INCOMING_ORDERS_CONSTANTS } from '../../constants/constants.js'
import { useAuth } from '../../contexts/AuthContext'
import { useMediaQuery } from 'react-responsive'
import { DASHBOARD } from '../../constants/navigationConstants'
import BackButtonSvg from '../../assets/img/icons/backButton.svg'
import UploadOrders from './requests/uploadOrders'
import ResultsModal from '../resultsModal'
import SearchForm from './requests/searchForm'
import qs from 'query-string'
import axios from 'axios'
import UploadTrackingNoIconWhite from './requests/uploadtcno-white.svg'
import UploadTrackingNoIcon from './requests/uploadtcno.svg'
import ExportOrdersIconWhite from './requests/exportorders-white.svg'
import ExportOrdersIcon from './requests/exportorders.svg'

const NavItem = ({ isMobile, nav, addQueryParam, removeQueryParam, setActiveTab, isEnglish, counts }) => {
    return (
        <Nav.Item id={`nav-${nav.key}-parcels-tab`} className={`${isEnglish ? 'mr-lg-3 mr-md-2' : 'ml-lg-3 ml-md-2'}`}>
            <Nav.Link
                className={`w-100`}
                eventKey={nav.key}
                onClick={(e) => {
                    // removeQueryParam(["search", "from", "to"])
                    addQueryParam('category', nav.key)
                    setActiveTab(nav.key)
                }}
            >
                {nav.value}{' '}
                <span>
                    <span
                        className={`roundcrl 
                ${nav.iconClass}`}
                    >
                        {counts[nav.key]}
                    </span>
                </span>
            </Nav.Link>
        </Nav.Item>
    )
}

export default (props) => {
    const { t, i18n } = useTranslation()
    const history = useHistory()
    const { authToken, warehouseId } = useAuth()
    const [loading, setLoading] = useState(false)
    const [uploadIcon, setUploadIcon] = useState(UploadTrackingNoIcon)
    const [exportIcon, setExportIcon] = useState(ExportOrdersIcon)

    const isEnglish = useMemo(() => i18n.language === 'en', [i18n.language])
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
    let search = window.location.search
    let params = new URLSearchParams(search)
    let category = params.get('category')
    let parcelsKeyword = params.get('parcelsSearch')
    let listsKeyword = params.get('listsSearch')
    let from = params.get('from')
    let to = params.get('to')
    let { INCOMING_ORDERS_VAL, PARCELS_VAL, INCOMING_ORDERS, PARCELS } = INCOMING_ORDERS_CONSTANTS
    const [options, setOptions] = useState({
        custom: true,
        totalSize: {
            parcels: 0,
            lists: 0,
        },
        page: {
            parcels: 1,
            lists: 1,
        },
        sizePerPage: category === INCOMING_ORDERS_CONSTANTS.INCOMING_ORDERS_VAL ? 10 : 25,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        showTotal: false,
        prePageText: t('Previous'),
        nextPageText: t('Next'),
    })

    const [counts, setCounts] = useState({
        [INCOMING_ORDERS_VAL]: 0,
        [PARCELS_VAL]: 0,
    })
    const navTabs = [
        {
            key: INCOMING_ORDERS_VAL,
            value: t(INCOMING_ORDERS),
            iconClass: 'hollow blue',
        },
        {
            key: PARCELS_VAL,
            value: t(PARCELS),
            iconClass: 'hollow orng',
        },
    ]

    const [activeTab, setActiveTab] = useState(category)
    const [alertMsg, setAlertMsg] = useState(null)

    let isOrdersOrParcelsListNotEmpty =
        (props?.orders?.length > 0 && activeTab === INCOMING_ORDERS_VAL) ||
        (props?.parcels?.length > 0 && activeTab === PARCELS_VAL)

    const addQueryParam = (key, value) => {
        const queryParam = qs.parse(window.location.search)
        if (value && value !== '') {
            queryParam[key] = value
        } else {
            delete queryParam[key]
        }
        history.push({ search: qs.stringify(queryParam) })
    }

    const removeQueryParam = (keys) => {
        const queryParam = qs.parse(window.location.search)
        keys.forEach((k) => {
            if (queryParam[k]) {
                delete queryParam[k]
            }
        })
        history.push({ search: qs.stringify(queryParam) })
    }

    useEffect(() => {
        if (!activeTab || activeTab === '') {
            category = '1'
        }
        setActiveTab(category)
        addQueryParam('category', category)
    }, [])

    const onRequest = (data, type) => {
        props.onRequestClick(data, type)
    }

    const [showModal, setShowModal] = useState(false)
    const [showResultsModal, setShowResultsModal] = useState({
        show: 0,
        status: false,
        message: null,
    })

    const ExportToExcel = async (btnId) => {
        try {
            setLoading(true)
            const defaultOptions = {
                headers: {
                    Authorization: authToken ? `Bearer ${authToken}` : '',
                },
            }
            let requestUrl = '',
                extra = ''
            switch (btnId) {
                case 'parcelsExport':
                    extra = `${parcelsKeyword && parcelsKeyword !== '' ? `&keyword=${parcelsKeyword}` : ''}${from && from !== '' ? `&start_date=${from}` : ''}${to && to !== '' ? `&end_date=${to}` : ''}`
                    requestUrl = `/api/parcels-lists/parcels/export?warehouse_id=${warehouseId}${extra}`
                    break
                case 'ordersExport':
                    extra = `${listsKeyword && listsKeyword !== '' ? `&keyword=${listsKeyword}` : ''}`
                    requestUrl = `/api/parcels-lists/export?warehouse_id=${warehouseId}${extra}`
                    break
                default:
                    break
            }
            let { data } = await axios.get(`${process.env.REACT_APP_PARCEL_SERVICES}${requestUrl}`, defaultOptions)
            if (data && data !== '') {
                const outputFilename = `${Date.now()}.csv`
                const aElement = document.createElement('a')
                aElement.setAttribute('download', outputFilename)
                const href = URL.createObjectURL(new Blob([data]))
                aElement.href = href
                aElement.setAttribute('target', '_blank')
                aElement.click()
                URL.revokeObjectURL(href)
                setAlertMsg({ error: false, msg: 'Data exported successfully' })
            } else {
                setAlertMsg({ error: true, msg: 'No data found' })
            }
            setLoading(false)
        } catch (error) {
            console.log(error)
            setAlertMsg({ error: true, msg: 'No data found' })
            setLoading(false)
        }
    }

    useEffect(() => {
        if (alertMsg?.msg) {
            setTimeout(() => {
                setAlertMsg(null)
            }, 4000)
        }
    }, [JSON.stringify(alertMsg)])

    return (
        <div className="widget widget_packages">
            <div className="widget__inner rounded">
                <div className="widget__content" id="incoming-orders">
                    {alertMsg && alertMsg.msg ? (
                        <Alert close variant={alertMsg?.error ? 'danger' : 'success'}>
                            {t(alertMsg?.msg)}
                        </Alert>
                    ) : null}
                    <Nav
                        variant="tabs"
                        activeKey={activeTab}
                        className={`dashboard-nav-items-link ${isMobile && 'px-2'}`}
                    >
                        {navTabs?.map((nav, index) => {
                            return (
                                <NavItem
                                    isMobile={isMobile}
                                    key={nav.key}
                                    counts={counts}
                                    isEnglish={isEnglish}
                                    addQueryParam={addQueryParam}
                                    removeQueryParam={removeQueryParam}
                                    setActiveTab={setActiveTab}
                                    nav={nav}
                                />
                            )
                        })}
                    </Nav>
                    <div
                        className={`${isMobile ? 'd-block' : 'd-flex align-items-center justify-content-between my-3'}`}
                    >
                        <SearchForm
                            category={activeTab}
                            addQueryParam={addQueryParam}
                            placeholder={'Search'}
                            setReload={props?.setReload}
                            reload={props?.reload}
                            removeQueryParam={removeQueryParam}
                            options={options}
                            setOptions={setOptions}
                        />
                        {activeTab === INCOMING_ORDERS_VAL ? (
                            <>
                                {Number(counts[INCOMING_ORDERS_VAL]) > 0 ? (
                                    <>
                                        <button
                                            id="ordersExport"
                                            onClick={(e) => {
                                                ExportToExcel(e.target.id)
                                            }}
                                            onMouseOver={() => {
                                                setExportIcon(ExportOrdersIconWhite)
                                            }}
                                            onMouseOut={() => {
                                                setExportIcon(ExportOrdersIcon)
                                            }}
                                            className="white-space-nowrap d-flex align-items-center light-blue-button grey-hover rounded px-4 py-2"
                                        >
                                            {loading ? (
                                                <div class="spinner-border text-light" role="status"></div>
                                            ) : (
                                                <>
                                                    <img className="d-inline" src={exportIcon} />
                                                    &nbsp;&nbsp;
                                                    {t('Pending Parcels')}
                                                </>
                                            )}
                                        </button>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <button
                                            onClick={() => {
                                                // setReqId(row?.list_id)
                                                setShowModal('uploadTCNo')
                                            }}
                                            onMouseOver={() => {
                                                setUploadIcon(UploadTrackingNoIconWhite)
                                            }}
                                            onMouseOut={() => {
                                                setUploadIcon(UploadTrackingNoIcon)
                                            }}
                                            className="white-space-nowrap d-flex align-items-center light-blue-button rounded px-4 py-2"
                                        >
                                            <img className="d-inline" src={uploadIcon} />
                                            &nbsp;&nbsp;<span>{t('Upload Tracking number')}</span>
                                        </button>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                    </>
                                ) : null}
                                <button
                                    onClick={() => setShowModal(true)}
                                    className="white-space-nowrap text-white text-capitalize border-radius-5 btn-bg-color d-flex flex-row justify-content-center text-decoration-none px-4 py-2"
                                >
                                    {t('Upload Orders')}
                                </button>
                            </>
                        ) : (
                            //
                            <button
                                id="parcelsExport"
                                onClick={(e) => ExportToExcel(e.target.id)}
                                className="color-inverse-default-button rounded px-4 py-2"
                                disabled={loading}
                            >
                                {loading ? (
                                    <div class="spinner-border text-light" role="status"></div>
                                ) : (
                                    t('Export Excel')
                                )}
                            </button>
                        )}
                    </div>
                    <div
                        className={`${isOrdersOrParcelsListNotEmpty ? '' : 'justify-content-center align-items-center d-flex w-100'}
              ${isMobile ? 'mobile_requests' : 'parcel_minheight desktop_requests'} 
            `}
                    >
                        {navTabs?.map((nav, index) => {
                            return (
                                <>
                                    <div
                                        className={`tab-pane fade ${activeTab === nav.key ? 'show active' : ''}`}
                                        role="tabpanel"
                                        id={`nav-${nav.key}-parcels-tab-content`}
                                    >
                                        <Requests
                                            {...props}
                                            authToken={authToken}
                                            isMobile={isMobile}
                                            isEnglish={isEnglish}
                                            onRequest={onRequest}
                                            counts={counts}
                                            setCounts={setCounts}
                                            category={nav.key}
                                            setParcelData={
                                                nav.key === INCOMING_ORDERS_VAL ? props?.setOrders : props?.setParcels
                                            }
                                            parcelData={
                                                nav.key === INCOMING_ORDERS_VAL ? props?.orders : props?.parcels
                                            }
                                            options={options}
                                            setOptions={setOptions}
                                        />
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
            </div>
            <UploadOrders
                show={['uploadTCNo', true].includes(showModal)}
                handleClose={() => setShowModal(false)}
                uploadTracking={showModal === 'uploadTCNo'}
                setShowResultsModal={setShowResultsModal}
            />
            <ResultsModal
                showModal={showResultsModal}
                setShowModal={setShowResultsModal}
                onSuccess={() => props?.setReload(!props?.reload)}
            />
        </div>
    )
}
