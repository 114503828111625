import Card from './card'
import styles from './styles.module.scss'

const Locations = () => (
    <Card iconUrl={require('../../images/locations.svg').default}>
        <div>Global Locations</div>
        <div className={styles['fs-16']}>US, UAE & Turkey</div>
    </Card>
)

const Order = () => (
    <Card iconUrl={require('../../images/order.svg').default}>
        <div>Order</div>
        <div>Fulfillment</div>
    </Card>
)

const MultiShipping = () => (
    <Card iconUrl={require('../../images/multi-shipping.svg').default}>
        <div>Multi-Shipping</div>
        <div>Options</div>
    </Card>
)

const ECommerce = () => (
    <Card iconUrl={require('../../images/e-com.svg').default}>
        <div>E-Commerce</div>
        <div>Import Services</div>
    </Card>
)

const WhyShopiniList = () => {
    return (
        <div className={`d-flex justify-content-around flex-wrap`}>
            <Locations />
            <Order />
            <MultiShipping />
            <ECommerce />
        </div>
    )
}

export default WhyShopiniList
