import React from 'react'
import stepTwoSvg from './step2.svg'
import { useTranslation } from 'react-i18next'

function StepTwo() {
    const { t, i18n } = useTranslation()
    return (
        <div className="main-section-container">
            <div className="container-fluid container-width">
                <div className="row justify-content-between justify-content-around-under-768">
                    <div className="col-12 col-sm-9 col-md-5 flex-grow-1 mb-4 post-450-start-text-align">
                        <p className="description step-color-orange">{t('Step two')}</p>
                        <h2 className="sub-page-section-header">{t('Shop from your favorite stores')}</h2>
                        <p className="text-grey">{t('Shop from your favorite stores description 1')}</p>
                        <p className="text-grey">{t('Shop from your favorite stores description 2')}</p>
                    </div>
                    <div className="col-12 col-sm-9 col-md-5 flex-grow-1 mb-4 align-self-center text-center">
                        <img alt="..." className="width-100 big-section-img" src={stepTwoSvg} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StepTwo
