import React from 'react'
import { useTranslation } from 'react-i18next'
import './style.scss'
import { Link } from 'react-router-dom'
import { MEMBERSHIP_PLANS } from '../../constants/navigationConstants'

const Banner = ({}) => {
    const { t, i18n } = useTranslation()

    return (
        <>
            <div className={`consol-banner-${i18n.language}`}>
                <span className="d-block consolidate">
                    {t('Its like ABC')}
                    <br />
                    {t('Shop Consolidate & Ship')}
                </span>
                <span className="service-text">
                    {t('Save on your Shipping Fee with our')}
                    <br />
                    <strong>{t('Consolidation Service')}</strong>
                </span>
                <Link
                    id=""
                    to={MEMBERSHIP_PLANS}
                    className={`signup-btn text-center text-white text-uppercase border-radius-5 btn-bg-color text-decoration-none px-4 py-2`}
                >
                    {t('Sign up now')}
                </Link>
            </div>
        </>
    )
}

export default Banner
