import React from 'react'
import { useTranslation } from 'react-i18next'
import { Markup } from 'interweave'
import TabBody from './tabBody'

function Tab5() {
    const { t } = useTranslation()
    const questionsAndAnswers = [
        { containerId: 'fiveone', collapseId: 'collapseFiveOne', question: t('Qfiveone'), answer: t('Afiveone') },
        { containerId: 'fivetwo', collapseId: 'collapseFiveTwo', question: t('Qfivetwo'), answer: t('Afivetwo') },
        {
            containerId: 'fivethree',
            collapseId: 'collapseFiveThree',
            question: t('Qfivethree'),
            answer: t('Afivethree'),
        },
        { containerId: 'fivefour', collapseId: 'collapseFiveFour', question: t('Qfivefour'), answer: t('Afivefour') },
    ]
    return (
        <>
            <div className="accordion" id="ourServicesQuestions">
                <div className="card align-items-start">
                    <p className="sub-page-section-header mb-show-block text-start">{t('Consolidation')}</p>
                </div>
                {questionsAndAnswers?.map((questionAndAnswer) => (
                    <TabBody questionAndAnswer={questionAndAnswer} />
                ))}
            </div>
        </>
    )
}

export default Tab5
