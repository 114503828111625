import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import useAnalytics from '../../../../analytics/useAnalytics'
import { CONTACT_US, HOMEPAGE } from '../../../../constants/navigationConstants'
import styles from './styles.module.scss'

const HeaderBar = () => {
    const { t } = useTranslation()
    const analytics = useAnalytics()

    return (
        <div className="d-flex justify-content-between align-items-center">
            <Link className={styles['shopini-icon']} to={HOMEPAGE} />
            <div>
                <Link
                    className={`btn btn-primary signup-btn ${styles['btn-overrides']}`}
                    to={CONTACT_US}
                    onClick={() => analytics.events.contactUs({ source: 'Ecommerce' })}
                >
                    {t('Contact')}
                </Link>
            </div>
        </div>
    )
}

export default HeaderBar
