import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { PARCEL_CONSTANTS, MEMBERSHIP_CONSTANTS, REFERRAL_PREFIX } from '../../constants/constants.js'
import {
    ACCOUNT_PAYMENT,
    ACCOUNT_AMAZON_PAY,
    REFERRAL_PAGE,
    ACCOUNT_PAYMENT_OPTIONS,
    MEMBERSHIP_PLANS,
    PURCHASE_REQUEST,
} from '../../constants/navigationConstants.js'
import buyItRightIllustrator from './right.svg'
import buyItLeftIllustrator from './left.svg'
import buyItRightArIllustrator from './right-ar.svg'
import buyItLeftArIllustrator from './left-ar.svg'
import referralRightIllustrator from './refer-right.svg'
import referralLeftIllustrator from './refer-left.svg'
import { Carousel } from 'react-bootstrap'
import { copyTextToClipboard } from '../../utils/string.js'
import useAnalytics from '../../analytics/useAnalytics.js'

const Notifications = ({
    membershipPlanID,
    loading,
    counts,
    isEnglish,
    activeTab,
    isMobile,
    expirationDate,
    messageKey,
    t,
    addQueryParam,
    setActiveTab,
    aramcoUser,
    setShow,
    friendsCount,
    userPin,
}) => {
    const [showPurchaseRequest, setShowPurchaseRequest] = useState(true)
    const [showWelcomeMsg, setShowWelcomeMsg] = useState(true)
    const analytics = useAnalytics()

    return (
        <>
            {showPurchaseRequest && (
                <Carousel className="mb-3">
                    <Carousel.Item interval={5000} className="">
                        <div className="notification-slide row bg-white pb-3 w-100 mx-0 overflow-hidden">
                            <div className="col-3 d-flex justify-content-start">
                                <img
                                    className="left-illustrator position-absolute"
                                    src={isEnglish ? buyItLeftIllustrator : buyItLeftArIllustrator}
                                />
                            </div>
                            <div className="col-6 text-center py-2">
                                <h5 className="my-3 buy_heading">{t('Buying_Assistant')}</h5>
                                <p className="my-2 font-1 buy_description">
                                    {t(
                                        "Tell us what you want to buy by pasting a link, We'll make the purchase for you & deliver to your door worldwide!",
                                    )}
                                </p>
                                <Link
                                    className="btn color-inverse-default-button text-center mt-2 submit-request-button"
                                    to={`${PURCHASE_REQUEST}?status=all&modal=1`}
                                    onClick={analytics.events.dashboardBifmSubmitButton}
                                >
                                    {t('Submit Request')}
                                </Link>
                            </div>
                            <div className="col-3 d-flex justify-content-end">
                                <img
                                    className="right-illustrator position-absolute"
                                    src={isEnglish ? buyItRightIllustrator : buyItRightArIllustrator}
                                />
                            </div>
                            {/* <button
						id={``}
						type="button"
						onClick={() => setShowPurchaseRequest(!showPurchaseRequest)}
						className={`close position-absolute close-btn-position`}
						aria-label="Close"
					>
						<i className="bx bx-x font-40" />
					</button> */}
                        </div>
                    </Carousel.Item>
                    <Carousel.Item interval={5000} className="">
                        <div className="notification-slide row bg-white pb-3 w-100 mx-0 overflow-hidden">
                            <div className="col-sm-2 col-md-3 d-flex justify-content-start">
                                <img
                                    className="left-referral-illustrator position-absolute"
                                    src={isEnglish ? referralLeftIllustrator : referralRightIllustrator}
                                />
                            </div>
                            <div className="col-sm-8 col-md-6 text-center py-2">
                                <h5 className="my-3 buy_heading">{t('Refer a friend')}</h5>
                                <p className="my-2 font-1 buy_description">
                                    {t('Join our Refer-A-Friend program & make a win for both')}
                                </p>
                                <p className={isMobile ? 'font-2' : 'font-18'}>
                                    {t('Click')} &nbsp;
                                    <Link className={isMobile ? 'font-2' : 'font-18'} to={REFERRAL_PAGE}>
                                        {t('here')}
                                    </Link>
                                    &nbsp; {t('to know more')}
                                </p>
                                <span
                                    className={`${isMobile ? 'font-2' : 'font-18'} referral-code mt-2 py-2 px-2 w-100 mx-0 tr-cursor`}
                                    id="copy-referral"
                                    onClick={() => {
                                        copyTextToClipboard(`${REFERRAL_PREFIX}${userPin}`)
                                        document.getElementById('copy-referral')?.classList?.remove('referral-code')
                                        document.getElementById('copy-referral')?.classList?.add('referral-code-copied')
                                        setTimeout(() => {
                                            if (
                                                document
                                                    .getElementById('copy-referral')
                                                    ?.classList?.contains('referral-code-copied')
                                            )
                                                document
                                                    .getElementById('copy-referral')
                                                    ?.classList?.add('referral-code')
                                            document
                                                .getElementById('copy-referral')
                                                ?.classList?.remove('referral-code-copied')
                                        }, 5000)
                                        analytics.events.dashboardReferralCopyButton()
                                    }}
                                >
                                    {`${REFERRAL_PREFIX}${userPin}`}
                                    &nbsp;<i className="copy-btn fas fa-clone"></i>&nbsp;
                                </span>
                                <span
                                    className={`${!isMobile ? (isEnglish ? 'ml-5' : 'mr-5') : 'mt-4 d-block font-2'}`}
                                >
                                    <i className="fas fa-check-circle mx-2 check-icon"></i>
                                    {t('Friends referred')}: &nbsp;&nbsp; <strong>{friendsCount}</strong>
                                </span>
                            </div>
                            <div className="col-sm-2 col-md-3 d-flex justify-content-end">
                                <img
                                    className="right-referral-illustrator position-absolute "
                                    src={isEnglish ? referralRightIllustrator : referralLeftIllustrator}
                                />
                            </div>
                            {/* <button
						id={``}
						type="button"
						onClick={() => setShowPurchaseRequest(!showPurchaseRequest)}
						className={`close position-absolute close-btn-position`}
						aria-label="Close"
					>
						<i className="bx bx-x font-40" />
					</button> */}
                        </div>
                    </Carousel.Item>
                </Carousel>
            )}
            {showWelcomeMsg ? (
                isMobile ? (
                    <div
                        className={`row ${isEnglish ? 'text-left' : 'text-right'} pay-notification-bg mx-2 mb-3 rounded py-3`}
                    >
                        <div className={`col-1 px-2`}>
                            <span className={`roundcrl orng`}>!</span>
                        </div>
                        <div className="col-10">
                            <span className={`font-12 d-block mb-1`}>{t('WelcomeMsg.part1')}</span>
                            <span className={`font-12 d-block my-1`}>{t('WelcomeMsg.part2')}</span>
                            {/* <span className={`font-12 d-block my-1`}>{t("WelcomeMsg.part3.ListTitle")}</span>
						<ul className={`font-12 mx-1 my-1`}>
							<li className={`my-1`}>
								{t("WelcomeMsg.part3.item1")}
							</li>
							<li className={`my-1`}>
								{t("WelcomeMsg.part3.item2")}
							</li>
						</ul> */}
                        </div>
                        <div className={`col-1 text-nowrap d-flex align-items-start justify-content-end px-3`}>
                            <button className="close" onClick={() => setShowWelcomeMsg(false)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                ) : (
                    <p
                        className={`py-2 ${isEnglish ? 'text-left' : 'text-right'} pay-notification-bg w-100 mx-0 rounded d-flex justify-content-between align-items-start px-3`}
                    >
                        <div className="font-1vw d-flex align-items-start">
                            <span className={`roundcrl orng ml-0 mt-1`}>!</span>
                            &nbsp;&nbsp;
                            <p className="w-80">
                                <span className={`d-block mb-1`}>{t('WelcomeMsg.part1')}</span>
                                <span className={`d-block my-1`}>{t('WelcomeMsg.part2')}</span>
                                {/* <span className={`d-block my-1`}>{t("WelcomeMsg.part3.ListTitle")}</span>
							<ul className={`mx-1 my-1`}>
								<li className={`my-1`}>
									{t("WelcomeMsg.part3.item1")}
								</li>
								<li className={`my-1`}>
									{t("WelcomeMsg.part3.item2")}
								</li>
							</ul> */}
                            </p>
                        </div>
                        <button className="close" onClick={() => setShowWelcomeMsg(false)}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </p>
                )
            ) : null}
            {loading ? null : (
                <div>
                    {![2, 3].includes(Number(membershipPlanID)) ||
                    !messageKey ||
                    messageKey?.toLowerCase() === 'ok' ? null : isMobile ? (
                        <div
                            className={`row ${isEnglish ? 'text-left' : 'text-right'} pay-notification-bg mx-2 mb-3 rounded py-3`}
                        >
                            <div className={`col-1 px-2`}>
                                <span className={`roundcrl orng`}>!</span>
                            </div>
                            <div className="col-7">
                                <strong className={`d-block font-14`}>
                                    {messageKey === 'account-expired'
                                        ? t('Your subscription has expired')
                                        : messageKey === 'no-card'
                                          ? t('To Ship parcels')
                                          : t('Your subscription')}
                                    &nbsp;
                                </strong>
                                <span className={`font-12 text-muted`}>
                                    {messageKey === 'account-expired' ? (
                                        t('To keep your account active')
                                    ) : messageKey === 'no-card' ? null : (
                                        <>
                                            &nbsp;
                                            {aramcoUser ? (
                                                t('Lifetime')
                                            ) : (
                                                <>
                                                    {t('will expire on')}
                                                    &nbsp;
                                                    <strong className="orange">{expirationDate},</strong>
                                                    &nbsp;
                                                    {t('To avoid disruption')}
                                                </>
                                            )}
                                        </>
                                    )}
                                </span>
                            </div>
                            <div className={`col-4 text-nowrap d-flex align-items-center justify-content-end px-3`}>
                                <Link
                                    to={
                                        ['account-expired', 'account-expiring'].includes(messageKey)
                                            ? MEMBERSHIP_PLANS
                                            : ACCOUNT_PAYMENT_OPTIONS
                                    }
                                    className={`greenish-button text-decoration-none px-3 py-2`}
                                    onClick={
                                        ['account-expired', 'account-expiring'].includes(messageKey)
                                            ? analytics.events.dashboardRenewButton
                                            : analytics.events.dashboardAddCardButton
                                    }
                                >
                                    {['account-expired', 'account-expiring'].includes(messageKey)
                                        ? t('Renew')
                                        : t('Add Card')}
                                </Link>
                            </div>
                        </div>
                    ) : (
                        <p
                            className={`py-2 ${isEnglish ? 'text-left' : 'text-right'} pay-notification-bg row w-100 mx-0 rounded d-flex justify-content-between align-items-center px-3`}
                        >
                            <div className="font-1vw">
                                <span className={`roundcrl orng ml-0`}>!</span>
                                <strong>
                                    &nbsp;&nbsp;
                                    {messageKey === 'account-expired'
                                        ? t('Your subscription has expired')
                                        : messageKey === 'no-card'
                                          ? t('To Ship parcels')
                                          : t('Your subscription')}
                                    &nbsp;
                                </strong>
                                {messageKey === 'account-expired' ? (
                                    t('To keep your account active')
                                ) : messageKey === 'no-card' ? null : (
                                    <>
                                        &nbsp;
                                        {aramcoUser ? (
                                            ', ' + t('Lifetime')
                                        ) : (
                                            <>
                                                {t('will expire on')}
                                                &nbsp;
                                                <strong className="orange">{expirationDate},</strong>
                                                &nbsp;
                                                {t('To avoid disruption')}
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                            <div>
                                <Link
                                    to={
                                        ['account-expired', 'account-expiring'].includes(messageKey)
                                            ? MEMBERSHIP_PLANS
                                            : ACCOUNT_PAYMENT_OPTIONS
                                    }
                                    className={`greenish-button text-decoration-none px-3 py-2`}
                                    onClick={
                                        ['account-expired', 'account-expiring'].includes(messageKey)
                                            ? analytics.events.dashboardRenewButton
                                            : analytics.events.dashboardAddCardButton
                                    }
                                >
                                    {['account-expired', 'account-expiring'].includes(messageKey)
                                        ? t('Renew')
                                        : t('Add Card')}
                                </Link>
                            </div>
                        </p>
                    )}
                    {counts[PARCEL_CONSTANTS.READY_PENDING] > 0 ? (
                        isMobile ? (
                            activeTab !== PARCEL_CONSTANTS.READY_PENDING && (
                                <div
                                    className={`row ${isEnglish ? 'text-left' : 'text-right'} bg-white py-3 mb-2 px-2 mx-2`}
                                >
                                    <div className="col-8">
                                        <strong className={`d-block`}>
                                            <span className={`roundcrl orng ${isEnglish ? 'mx-2' : 'mx-2'}`}>!</span>
                                            {t('Pending Payment')}
                                        </strong>
                                        <span
                                            className={`font-12 text-muted pending-parcel-msg-${isEnglish ? 'en' : 'ar'}`}
                                        >
                                            {t('Your Parcel is awaiting payment')}
                                        </span>
                                    </div>
                                    <div
                                        className={`col-4 align-items-center d-flex ${isEnglish ? 'justify-content-end' : 'justify-content-start'}`}
                                    >
                                        <button
                                            className={`font-14 color-inverse-default-button rounded px-3 py-2 ${isEnglish ? 'mx-2' : 'mx-2'}`}
                                            onClick={() => {
                                                addQueryParam('category', PARCEL_CONSTANTS.READY_PENDING)
                                                setActiveTab(PARCEL_CONSTANTS.READY_PENDING)
                                            }}
                                        >
                                            {t('Shipments')}
                                        </button>
                                    </div>
                                </div>
                            )
                        ) : (
                            <p
                                className={`font-1vw px-3 py-3 d-flex justify-content-between w-100 ${isEnglish ? 'text-left' : 'text-right'} text-md-center bg-white rounded`}
                            >
                                <div>
                                    <span className={`roundcrl orng ${isEnglish ? 'mr-2 ml-0' : 'ml-2 mr-0'}`}>!</span>
                                    <strong className={`${isEnglish ? 'mr-2' : 'ml-2'}`}>
                                        {t('Pending Payment')} -
                                    </strong>
                                    {t('You have')}
                                    <span className="font-color-blue mx-1">
                                        {counts[PARCEL_CONSTANTS.READY_PENDING]} {t('parcel')}
                                    </span>
                                    {t('awating payment to proceed further')}
                                </div>
                                <button
                                    className="color-inverse-default-button rounded px-4 py-1"
                                    onClick={() => {
                                        addQueryParam('category', PARCEL_CONSTANTS.READY_PENDING)
                                        setActiveTab(PARCEL_CONSTANTS.READY_PENDING)
                                    }}
                                >
                                    {t('View Pending Payment')}
                                </button>
                            </p>
                        )
                    ) : null}
                </div>
            )}
        </>
    )
}

export default Notifications
