import React from 'react'
import HorizontalCard from './horizontalCard'
// import discountIcon from "./discount-ic.svg";
import insuranceIcon from './insurance.svg'
import buyItForMeIcon from './buyItForMe.svg'
import itemConsIcon from './itemConsolidation.svg'
import packageConsolidationIcon from '../homepage/servicesSection/packageConsolidation.svg'
import usAddressIcon from '../homepage/servicesSection/freeUsAddress.svg'
import repackingIcon from './packageRepacking.svg'
// import returnIcon from "./return services.svg";
import storageIcon from '../homepage/servicesSection/freeStorage.svg'
import supportIcon from '../homepage/servicesSection/247Support.svg'
import weightIcon from './specialRates.svg'
import cameraIcon from './itemsPhoto.svg'
import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import { BUY_IT_FOR_ME, CONSOLIDATION, MEMBERSHIP_PLANS } from '../../constants/navigationConstants'

function Services() {
    const { t, i18n } = useTranslation()

    return (
        <div className="main-section-container grey-background">
            <div className="container-fluid container-width">
                <div className="width-90-sections">
                    <h1 className="sub-page-header">{t('Services you will love')}</h1>
                    <p>
                        {t('Services you will love description 1')} <br />
                        {t('Services you will love description 2')}
                    </p>
                </div>
                <div>
                    <div className="row justify-content-between">
                        <div className="col-12 col-sm-12 col-md-6 flex-grow-1 mb-4">
                            <HorizontalCard
                                imgSrc={usAddressIcon}
                                cardTitle={t('Free address')}
                                cardDescription={t('Free address description')}
                            />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 flex-grow-1 mb-4">
                            <HorizontalCard
                                imgSrc={cameraIcon}
                                cardTitle={t('Items photo')}
                                cardDescription={t('Items photo description')}
                            />
                        </div>
                    </div>

                    <div className="row justify-content-between">
                        <div className="col-12 col-sm-12 col-md-6 flex-grow-1 mb-4">
                            <HorizontalCard
                                imgSrc={insuranceIcon}
                                cardTitle={t('Insurance')}
                                cardDescription={t('Insurance description')}
                            />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 flex-grow-1 mb-4">
                            <HorizontalCard
                                imgSrc={supportIcon}
                                cardTitle={t('247 support')}
                                cardDescription={t('247 support description')}
                            />
                        </div>
                    </div>

                    <div className="row justify-content-between">
                        <div
                            onClick={() => document.getElementById('consolidation-page')?.click()}
                            className="landing-links tr-cursor col-12 col-sm-12 col-md-6 flex-grow-1 mb-4"
                        >
                            <HorizontalCard
                                imgSrc={itemConsIcon}
                                cardTitle={t('Item consolidation')}
                                cardDescription={t('Item consolidation description')}
                            />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 flex-grow-1 mb-4">
                            <HorizontalCard
                                imgSrc={storageIcon}
                                cardTitle={t('Free storage')}
                                cardDescription={t('Free storage description')}
                            />
                        </div>
                    </div>

                    <div className="row justify-content-between">
                        <div className="col-12 col-sm-12 col-md-6 flex-grow-1 mb-4">
                            <HorizontalCard
                                imgSrc={repackingIcon}
                                cardTitle={t('Package repacking')}
                                cardDescription={t('Package repacking description')}
                            />
                        </div>
                        <div
                            onClick={() => document.getElementById('consolidation-page')?.click()}
                            className="landing-links tr-cursor col-12 col-sm-12 col-md-6 flex-grow-1 mb-4"
                        >
                            <HorizontalCard
                                imgSrc={packageConsolidationIcon}
                                cardTitle={t('Package consolidation')}
                                cardDescription={t('Package consolidation description')}
                            />
                        </div>
                    </div>

                    {/* <div className="row justify-content-between">
            <div className="col-12 col-sm-12 col-md-6 flex-grow-1 mb-4">
              <HorizontalCard
                imgSrc={returnIcon}
                cardTitle={t("Return service")}
                cardDescription={t("Return service description")}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-6 flex-grow-1 mb-4">
              <HorizontalCard
                imgSrc={discountIcon}
                cardTitle={t("10 extra discount on all shipping fees")}
                cardDescription={t(
                  "10 extra discount on all shipping fees description"
                )}
              />
            </div>
          </div> */}

                    <div className="row justify-content-between mb-2">
                        <div
                            onClick={() => document.getElementById('buyItForMe-page')?.click()}
                            className="landing-links tr-cursor col-12 col-sm-12 col-md-6 flex-grow-1 mb-4"
                        >
                            <HorizontalCard
                                imgSrc={buyItForMeIcon}
                                cardTitle={t('Buy it for me service')
                                    ?.replaceAll(`${i18n?.language === 'ar' ? '' : '"'}`, '')
                                    ?.replaceAll(`${i18n?.language === 'ar' ? ':' : 'service :'}`, '')}
                                cardDescription={t('buy it for me desc')}
                            />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 flex-grow-1 mb-4">
                            <HorizontalCard
                                imgSrc={weightIcon}
                                cardTitle={t('Special rates on heavy weights')}
                                cardDescription={t('Special rates on heavy weights description')}
                            />
                        </div>
                    </div>
                </div>
                <Link
                    to={MEMBERSHIP_PLANS}
                    onClick={() => {
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth',
                        })
                    }}
                >
                    <button type="button" id="sign-up-now" className="btn btn-primary mb-2">
                        {t('Sign up now')}
                    </button>
                </Link>
            </div>
            <Link
                onClick={() => {
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth',
                    })
                }}
                id="consolidation-page"
                to={CONSOLIDATION}
                className="d-none"
            />
            <Link
                onClick={() => {
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth',
                    })
                }}
                id="buyItForMe-page"
                to={BUY_IT_FOR_ME}
                className="d-none"
            />
        </div>
    )
}

export default Services
