import React from 'react'
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import TableList from './tableList'
import { countryNamesInEnglish, getImageSrc } from '../../utils/commonFunc'
import { useAuth } from '../../contexts/AuthContext'
import { MEMBERSHIP_CONSTANTS } from '../../constants/constants'

export default ({ rates }) => {
    const { t, i18n } = useTranslation()
    const { membershipPlanID } = useAuth()
    const tableColumns = [
        {
            dataField: 'warehouse',
            text: t('Warehouse'),
            formatter: (cell, row) => {
                return (
                    <>
                        <img
                            className="d-inline flags rounded"
                            src={window.location.origin + `/images/flags/1x1/${cell?.country?.toLowerCase()}.svg`}
                        />
                        <span className="mx-2">{cell?.country}</span>
                    </>
                )
            },
        },
        {
            dataField: 'shipping_service',
            text: t('Shipping company'),
            formatter: (cell, row) => {
                return (
                    <>
                        <img
                            className="d-inline shipping-icons"
                            src={getImageSrc(cell?.replaceAll('_', ' ')?.toLowerCase())}
                        />
                        <span className="mx-2 text-capitalize">{cell?.replaceAll('_', ' ')?.toLowerCase()}</span>
                    </>
                )
            },
        },
        {
            dataField: 'countries',
            text: t('Destination'),
            formatter: (cell, row) => {
                return cell && cell?.length > 0
                    ? cell?.map((c, index) => (
                          <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              key={`${c}-tooltip`}
                              overlay={<Tooltip id={`${c}-tooltip`}>{countryNamesInEnglish.of(c || '')}</Tooltip>}
                          >
                              <span className="tr-cursor">
                                  {c} {index === cell?.length - 1 ? '' : ', '}
                              </span>
                          </OverlayTrigger>
                      ))
                    : '-'
            },
        },
        {
            dataField: 'slabs',
            text: t('Weight ranges'),
            formatter: (cell, row) => {
                return cell?.map((slab) => {
                    return (
                        <>
                            <span>
                                {slab?.min_weight}&nbsp;-&nbsp;{slab?.max_weight}&nbsp;{slab?.weight_unit}
                            </span>
                            <br />
                        </>
                    )
                })
            },
        },

        {
            dataField: 'slabs',
            text: t('Discount'),
            classes: (cell, row, rowIndex, colIndex) => {
                return `${rowIndex === rates?.length - 1 ? 'bottom-br' : 'w-100-px'} discount-col text-center`
            },
            formatter: (cell, row) => {
                return cell?.map((slab) => {
                    return (
                        <>
                            <strong>
                                {slab?.discount_value}&nbsp;{slab?.discount_type !== 'flat' ? '%' : `$ ${t('per lb')}`}
                            </strong>
                            <br />
                        </>
                    )
                })
            },
        },
    ]
    return (
        <Row className="myrates-request-container">
            <Col lg={'12'}>
                {rates?.length > 0 ? (
                    <>
                        <p className="w-100 text-start my-3">{t('Shipping Rates')}</p>
                        <TableList
                            data={rates}
                            columns={tableColumns}
                            buttonText={''}
                            exportButton={true}
                            rates={true}
                        />
                    </>
                ) : null}
                {Number(membershipPlanID) > MEMBERSHIP_CONSTANTS.BASIC ? (
                    <p className="w-100 text-center my-4">
                        **&nbsp;
                        <strong className="color-red">
                            {Number(membershipPlanID) === MEMBERSHIP_CONSTANTS.BUSINESS
                                ? t('20% discount')
                                : t('10% discount')}
                        </strong>
                        &nbsp;{t('on all Shipping Fees except')}&nbsp;
                        {t('Shopini Express')}
                    </p>
                ) : null}
            </Col>
        </Row>
    )
}
