import { useMemo } from 'react'
import useVoid from './void/useVoid'

const useCombineClients = (...clientsForCombine) => {
    const baseClient = useVoid()

    const combinedClients = useMemo(
        () => ({
            events: Object.keys(baseClient.events).reduce(
                (eventsFunctionsAcc, eventFunction) => ({
                    ...eventsFunctionsAcc,
                    [eventFunction]: (eventFunctionArgs) => {
                        clientsForCombine.forEach((client) => {
                            client.events?.[eventFunction]?.(eventFunctionArgs)
                        })
                    },
                }),
                {},
            ),
            attributes: Object.keys(baseClient.attributes).reduce(
                (attributesFunctionsAcc, attributesFunction) => ({
                    ...attributesFunctionsAcc,
                    [attributesFunction]: (attributesFunctionArgs) => {
                        clientsForCombine.forEach((client) => {
                            client.attributes?.[attributesFunction]?.(attributesFunctionArgs)
                        })
                    },
                }),
                {},
            ),
        }),
        [clientsForCombine],
    )

    return combinedClients
}

export default useCombineClients
