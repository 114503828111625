const uaeStores = [
    {
        titleEn: 'noon',
        url: 'https://www.noon.com',
        image: require('./logos/ae/logo-ae-noon.svg')?.default,
        categories: [
            "Women's Fashion",
            "Men's Fashion",
            'Kids Fashion',
            'Activewear',
            'Home',
            'Department store',
            'Technology',
            'Toys',
            'Beauty',
            'Accessories',
            'Health and wellness',
        ],
    },
    {
        titleEn: 'Amazon AE',
        url: 'https://amazon.ae',
        image: require('./logos/ae/logo-ae-amazon.svg')?.default,
        categories: [
            "Women's Fashion",
            "Men's Fashion",
            'Kids Fashion',
            'Activewear',
            'Home',
            'Department store',
            'Technology',
            'Toys',
            'Beauty',
            'Accessories',
            'Health and wellness',
        ],
    },
    {
        titleEn: 'Sivvi',
        url: 'https://sivvi.com',
        image: require('./logos/ae/logo-ae-sivvi.svg')?.default,
        categories: ["Women's Fashion", "Men's Fashion", 'Kids Fashion', 'Activewear', 'Beauty', 'Accessories'],
    },
    {
        titleEn: 'The giving movement',
        url: 'https://thegivingmovement.com/',
        image: require('./logos/ae/logo-ae-giving.svg')?.default,
        categories: ["Women's Fashion", "Men's Fashion", 'Kids Fashion', 'Activewear', 'Accessories'],
    },
    {
        titleEn: '6th street ',
        url: 'https://www.6thstreet.com/',
        image: require('./logos/ae/logo-ae-6thstreet.svg')?.default,
        categories: [
            "Women's Fashion",
            "Men's Fashion",
            'Kids Fashion',
            'Activewear',
            'Home',
            'Toys',
            'Beauty',
            'Accessories',
        ],
    },
    {
        titleEn: 'Adidas',
        url: 'https://www.adidas.ae/en',
        image: require('./logos/ae/logo-ae-adidas.svg')?.default,
        categories: ["Women's Fashion", "Men's Fashion", 'Kids Fashion', 'Activewear', 'Accessories'],
    },
    {
        titleEn: 'Namshi ',
        url: 'https://namshi.com',
        image: require('./logos/ae/logo-ae-Namshi.svg')?.default,
        categories: [
            "Women's Fashion",
            "Men's Fashion",
            'Kids Fashion',
            'Activewear',
            'Home',
            'Toys',
            'Beauty',
            'Accessories',
        ],
    },
    {
        titleEn: 'Sharaf DG',
        url: 'https://www.sharafdg.com',
        image: require('./logos/ae/logo-ae-sharafdg.svg')?.default,
        categories: ['Home', 'Technology'],
    },
    {
        titleEn: 'That concept store',
        url: 'https://thatconceptstore.com',
        image: require('./logos/ae/logo-ae-conceptstore.svg')?.default,
        categories: ["Women's Fashion", "Men's Fashion", 'Activewear', 'Home', 'Beauty', 'Accessories'],
    },
    {
        titleEn: 'Jumbo',
        url: 'https://www.jumbo.ae/',
        image: require('./logos/ae/logo-ae-jumbo.svg')?.default,
        categories: ['Home', 'Technology'],
    },
    {
        titleEn: 'Brands for less',
        url: 'https://brandsforless.com',
        image: require('./logos/ae/logo-ae-brands-for-less.svg')?.default,
        categories: [
            "Women's Fashion",
            "Men's Fashion",
            'Kids Fashion',
            'Activewear',
            'Home',
            'Toys',
            'Beauty',
            'Accessories',
        ],
    },
    {
        titleEn: 'CHIC LE FRIQUE',
        url: 'https://chiclefrique.com/',
        image: require('./logos/ae/logo-ae-CHICLEFRIQUE.svg')?.default,
        categories: ["Women's Fashion", 'Activewear'],
    },
    {
        titleEn: 'first cry ',
        url: 'https://firstcry.com',
        image: require('./logos/ae/logo-ae-Firstcry.svg')?.default,
        categories: ["Women's Fashion", 'Kids Fashion', 'Activewear', 'Home', 'Toys', 'Beauty', 'Accessories'],
    },
    {
        titleEn: 'mumzworld',
        url: 'https://mumzworld.com',
        image: require('./logos/ae/logo-ae-mumzworld.svg')?.default,
        categories: ["Women's Fashion", 'Kids Fashion', 'Home', 'Toys', 'Beauty', 'Accessories'],
    },
    {
        titleEn: 'Centrepoint',
        url: 'https://www.centrepointstores.com',
        image: require('./logos/ae/logo-ae-centerpoint.svg')?.default,
        categories: [
            "Women's Fashion",
            "Men's Fashion",
            'Kids Fashion',
            'Activewear',
            'Home',
            'Toys',
            'Beauty',
            'Accessories',
        ],
    },
    {
        titleEn: 'Emax',
        url: 'https://www.emaxme.com/',
        image: require('./logos/ae/logo-ae-emax.svg')?.default,
        categories: ['Home', 'Technology'],
    },
    {
        titleEn: 'Homecentre',
        url: 'https://www.homecentre.com/',
        image: require('./logos/ae/logo-ae-homecenter.svg')?.default,
        categories: ['Home'],
    },
    {
        titleEn: 'Pottery Barn',
        url: 'https://www.potterybarn.com/',
        image: require('./logos/ae/logo-ae-pottery-barn.svg')?.default,
        categories: ['Home'],
    },
    {
        titleEn: 'West Elm',
        url: 'https://www.westelm.com/',
        image: require('./logos/ae/logo-ae-west-elm.svg')?.default,
        categories: ['Home'],
    },
    {
        titleEn: 'Homebox',
        url: 'https://www.homeboxstores.com/',
        image: require('./logos/ae/logo-ae-homebox.svg')?.default,
        categories: ['Home'],
    },
    {
        titleEn: 'Crate and Barrel',
        url: 'https://www.crateandbarrel.me/en-ae',
        image: require('./logos/ae/logo-ae-crate-and-barrel.svg')?.default,
        categories: ['Home'],
    },
    {
        titleEn: 'Marina Home',
        url: 'https://www.marinahomeinteriors.com/',
        image: require('./logos/ae/logo-ae-marina-home.svg')?.default,
        categories: ['Home'],
    },
    {
        titleEn: 'Pottery Barn kids',
        url: 'https://www.potterybarnkids.com',
        image: require('./logos/ae/logo-ae-pottery-barn-kids.svg')?.default,
        categories: ['Kids Fashion', 'Home', 'Toys'],
    },
    {
        titleEn: 'Nike',
        url: 'https://www.nike.com/ae/',
        image: require('./logos/ae/logo-ae-nike.svg')?.default,
        categories: ["Women's Fashion", "Men's Fashion", 'Kids Fashion', 'Activewear', 'Accessories'],
    },
    {
        titleEn: 'Dyson',
        url: 'https://www.dyson.ae/',
        image: require('./logos/ae/logo-ae-dyson.svg')?.default,
        categories: ['Home', 'Technology', 'Beauty'],
    },
    {
        titleEn: 'Junior Couture',
        url: 'https://www.juniorcouture.ae/',
        image: require('./logos/ae/logo-ae-junior-couture.svg')?.default,
        categories: ['Kids Fashion', 'Accessories'],
    },
    {
        titleEn: 'KLA Beauty',
        url: 'https://kla-kbeauty.com',
        image: require('./logos/ae/logo-kla-beauty.svg')?.default,
        categories: ['Beauty'],
    },
]

export default uaeStores
