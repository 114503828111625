import { Alert } from 'react-bootstrap'

import React, { useState } from 'react'
import ReactFlagsSelect from 'react-flags-select'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import { LOGIN_PAGE } from '../../constants/navigationConstants'
import { useAuth } from '../../contexts/AuthContext'
import { postData } from '../../utils/api'
import { capitalizeFirstChar } from '../../utils/string'
import useCountries from '../../utils/useCountries'

const SocialAuthCompleteRegistrationForm = () => {
    const { t, i18n } = useTranslation()
    const classNameForPhoneInput = i18n.language === 'ar' ? 'ltr-direction w-100' : 'w-100'
    const [errorList, setErrorList] = useState([])
    const { countries } = useCountries()
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const { authToken, setCountry, setPhone } = useAuth()
    const [userData, setUserData] = useState({
        country: null,
        code: null,
        phone: null,
    })
    const [loading, setLoading] = useState(false)

    const handlePhoneInput = (phoneValue) => {
        setErrorList([])
        setUserData({ ...userData, phone: phoneValue })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        let requestData = { ...userData }
        const selectedCountry = document.querySelector('#country').innerText || null
        let country
        if (selectedCountry) {
            country = countries?.find((c) => c.name === selectedCountry?.trim()).iso2_code
            requestData = { ...requestData, country: country, code: country }
        }
        const response = await postData('userService', '/update-user', { ...requestData }, authToken, true)
        if (response?.message === 'Errors' && response?.payload?.length > 0) {
            setErrorList(response?.payload)
        } else {
            setErrorList([])
            await setCountry(requestData.country)
            await setPhone(requestData?.phone)
            document.querySelector('#redirect-to-dashboard').click()
        }
        setLoading(false)
    }

    return (
        <div className="grey-background full-height-box">
            <div className={`container white-background login-modal-width  py-4 ${isMobile ? '' : 'w-25'}`}>
                <h1 className="sub-page-section-header text-center mb-4">{t('Contact Details')}</h1>
                <form
                    id="registerForm"
                    className="text-start w-100 pr-0 pl-0"
                    onSubmit={(e) => handleSubmit(e, userData)}
                >
                    {errorList &&
                        errorList.length > 0 &&
                        errorList.map((error) => (
                            <Alert variant={'danger'}>
                                <span className="text-capitalize">
                                    {t(capitalizeFirstChar(error.param))}
                                    {': '}
                                </span>
                                {t(error.msg)}
                                {error.msg === 'Email address alreasy used' && (
                                    <Link to={LOGIN_PAGE} class="alert-link mx-2">
                                        {t('Log-in')}
                                    </Link>
                                )}
                            </Alert>
                        ))}

                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="country" className="required">
                                    {t('Country')}{' '}
                                </label>
                                <ReactFlagsSelect
                                    countries={countries?.map((country) => country.iso2_code)}
                                    className={`${i18n.language === 'ar' && 'rtl-direction'}`}
                                    id="country"
                                    selected={userData.country || 'AE'}
                                    onSelect={(code) => {
                                        setUserData({ ...userData, country: code, code: code })
                                        setCountry(code)
                                    }}
                                    onFocus={(e) => {
                                        if (e.target.autocomplete) {
                                            e.target.autocomplete = 'noautocomplete'
                                        }
                                    }}
                                    showSelectedLabel
                                    placeholder={'Select a country'}
                                    alignOptionsToRight
                                    fullWidth
                                />
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="registerMobileInput" className="required">
                                    {t('Phone')}{' '}
                                </label>
                                <PhoneInput
                                    country={userData.code?.toLowerCase() || 'ae'}
                                    inputProps={{
                                        name: 'phone',
                                        required: true,
                                        autoComplete: 'off',
                                    }}
                                    id="registerMobileInput"
                                    onChange={handlePhoneInput}
                                    inputClass="form-control w-100"
                                    value={userData.phone}
                                    disableDropdown
                                    containerClass={classNameForPhoneInput}
                                />
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary w-100" disabled={loading || errorList?.length > 0}>
                        {loading ? <div class="spinner-border text-light" role="status"></div> : t('Proceed')}
                    </button>
                </form>
            </div>
            {/* <Link id="redirect-to-dashboard" to={DASHBOARD}></Link> */}
        </div>
    )
}

export default SocialAuthCompleteRegistrationForm
