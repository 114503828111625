import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { HOMEPAGE } from '../../constants/navigationConstants'
import successIcon from '../../assets/img/icons/success.gif'
import styles from './styles.module.scss'

const ThankYouForContactingUs = () => {
    const { t, i18n } = useTranslation()
    return (
        <div className={`${styles['container']}`}>
            <div className={`${styles['elements']} bg-white`}>
                <div className={`mb-4 text-center ${styles['image-container']}`}>
                    <img className={`${styles['icon']}`} src={successIcon} alt="" />
                </div>
                <div className={`${styles['thank-you']} mb-4 text-center`}>{t('Thank you for contacting us')}</div>

                <Link id="sign-up-button" to={HOMEPAGE} className={`${styles['go-home']} btn btn-primary d-block`}>
                    {t('Go to Home')}
                </Link>
            </div>
        </div>
    )
}

export default ThankYouForContactingUs
