export const HOMEPAGE = '/home'
export const HOW_IT_WORKS = '/how-it-works'
export const OUR_SERVICES = '/our-services'
export const WHY_CHOOSE_US = '/why-choose-us'
export const CONTACT_US = '/contact-us'
export const PROHIBITED_MATERIALS = '/prohibited-materials'
export const PRIVACY_POLICY = '/privacy-policy'
export const FAQ = '/frequently-asked-questions'
export const FAQ_W_LANG = '/frequently-asked-questions?lang=:lang'
export const TERMS_CONDITIONS = '/terms-and-conditions'
export const MEMBERSHIP_PLANS = '/membership-plans'
export const DASHBOARD = '/dashboard'
export const LOGIN_PAGE = '/login'
export const REGISTRATION_FORM = '/register'
export const REGISTRATION_ADDRESS = '/register-address'
export const VERIFY_EMAIL = '/verify-email'
export const ONE_TIME_VERIFY_EMAIL = '/one-time-verify-email'
export const PAYMENT_PAGE = '/payment-method'
export const ACCOUNT_SETTINGS = '/account-settings'
export const ACCOUNT_ADDRESS_BOOK = '/account-address-book'
export const ACCOUNT_SHIPPER_ADDRESS_BOOK = '/account-shipper-address-book'
export const ACCOUNT_PERSONAL_INFO = '/account-personal-info'
export const ACCOUNT_MEMBERSHIP_PROFILE = '/account-membership-profile'
export const ACCOUNT_PAYMENT_OPTIONS = '/account-payment-options'
export const ACCOUNT_ADD_CARD = '/account-add-card'
export const ACCOUNT_AMAZON_PAY = '/add-amazon-pay'
export const ACCOUNT_PAY_PAL = '/add-paypal'
export const SHIPMENT_OPTIONS = '/parcel-shipment-options'
export const SHIPPING_PAYMENT = '/shipping-payment'
export const PAYMENT_STATUS = '/payment-status'
export const ACCOUNT_PAYMENT = '/account-payment'
export const FORGOT_PASSWORD = '/forgot-password'
export const CONSOLIDATED_PARCEL_PAYMENT = '/consolidation-shipping-payment'
export const AMCHAM_PROMOTION = '/amcham'
export const HALLOWEEN_PROMOTION = '/halloween'
export const COMPLETE_AUTH_REGISTRATION = '/contact-details'
export const PURCHASE_REQUEST = '/purchase-request'
export const WALLET = '/wallet'
export const BUYITFORME_POLICY = '/buy-it-for-me-policy'
export const BUY_IT_FOR_ME = '/buy-it-for-me'
export const CONSOLIDATION = '/consolidation'
export const PAYMENT_RETURN = '/payment-return'
export const SUB_PAYMENT_RETURN = '/sub-payment-return'
export const MOBILE_PAYMENT = '/mobile-payment'
export const MOBILE_ADD_CARD = '/mobile-add-card'
export const ABOUT_US = '/about-us'
export const REFERRAL_PAGE = '/referral'
export const UK_WAREHOUSE = '/uk-warehouse'
export const US_WAREHOUSE = '/us-warehouse'
export const CALCULATOR = '/calculator'
export const THANK_YOU = '/thank-you'
export const INCOMING_ORDERS = '/incoming-orders'
export const DIRECTORY = '/directory'
export const MY_RATES = '/my-rates'
export const TRACK_SHIPMENTS = '/track-shipments'
export const CONTACT = '/ecommerce'
export const THANK_YOU_FOR_CONTACTING_US = '/contact-thank-you'
export const TRUE_WEIGHT = '/trueweight'

export const AUTHED_ROUTES = [
    DASHBOARD,
    VERIFY_EMAIL,
    ACCOUNT_SETTINGS,
    ACCOUNT_ADDRESS_BOOK,
    ACCOUNT_MEMBERSHIP_PROFILE,
    ACCOUNT_PERSONAL_INFO,
    ACCOUNT_PAYMENT_OPTIONS,
    ACCOUNT_ADD_CARD,
    ACCOUNT_AMAZON_PAY,
    ACCOUNT_PAY_PAL,
    SHIPMENT_OPTIONS,
    SHIPPING_PAYMENT,
    PAYMENT_STATUS,
    ACCOUNT_PAYMENT,
    CONSOLIDATED_PARCEL_PAYMENT,
    COMPLETE_AUTH_REGISTRATION,
    PURCHASE_REQUEST,
    WALLET,
    PAYMENT_RETURN,
    INCOMING_ORDERS,
    DIRECTORY,
]

export const SMALL_NAV_ROUTES = [
    MEMBERSHIP_PLANS,
    REGISTRATION_FORM,
    PAYMENT_RETURN,
    PAYMENT_STATUS,
    SUB_PAYMENT_RETURN,
    ACCOUNT_PAYMENT,
    ONE_TIME_VERIFY_EMAIL,
]

export const NO_FOOTER_ROUTES = [US_WAREHOUSE, CALCULATOR]

export const STAND_ALONE_PAGES = [CONTACT]

export const NON_WAREHOUSE_LOCATIONs_ROUTES = [TRUE_WEIGHT]

export const HIDDEN_PAGES = {
    MANIFEST: '/manifest',
}
