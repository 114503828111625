import { MenuItem, TextField, withStyles } from '@material-ui/core'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ShortUniqueId from 'short-unique-id'
import { isUndefined } from 'lodash'
import { styled } from '@material-ui/core'
import { useAuth } from '../../../contexts/AuthContext'
import { getData } from '../../../utils/api'
import Loader from '../../common/loader'

const CustomizedTextField = styled((props) => <TextField {...props} />)(({ theme, ar }) => ({
    '& .MuiFilledInput-input': {
        padding: '14px 12px 0 !important',
    },
    '& .MuiFilledInput-root': {
        border: '1px solid #e2e2e1',
        overflow: 'hidden',
        borderRadius: 4,
        backgroundColor: '#F7F7F8',
        transition: theme.transitions.create(['border-color']),
        '&.Mui-focused': {
            borderColor: '#0000FF',
            backgroundColor: '#FFFFFF',
        },
    },
    label: {
        padding: '0',
    },
    '& .MuiInputLabel-formControl': ar
        ? {
              left: 'auto',
              right: '0',
          }
        : {},
    '& .MuiInputLabel-formControl.MuiInputLabel-animated': {
        color: '#8798A9 !important',
        opacity: '1 !important',
    },
    '& .MuiInputLabel-shrink': ar
        ? {
              transformOrigin: 'top right',
          }
        : {},
    '& .MuiInputLabel-root': ar
        ? {
              transformOrigin: 'top right',
          }
        : {},
    '& .MuiInputLabel-filled.MuiInputLabel-shrink': ar
        ? {
              transform: 'translate(-12px, 10px) scale(0.75)',
          }
        : {},
    '& .MuiInputLabel-filled': {
        ...(ar
            ? {
                  transform: 'translate(-12px, 20px) scale(1)',
              }
            : {}),
    },
}))

export default ({ parcelId, show, handleClose, handleSubmit, setShowResultsModal }) => {
    const { t, i18n } = useTranslation()
    const [parcelItems, setParcelItems] = useState([])
    const uid = new ShortUniqueId({ length: 8 })
    const { authToken } = useAuth()
    const [splitQty, setSplitQty] = useState([])
    const [isDisabled, setIsDisabled] = useState(false)
    const [loading, setLoading] = useState(false)

    const getParcelItems = async () => {
        try {
            setLoading(true)
            let res = await getData('parcelService', `/parcels/items/${parcelId}`, authToken)
            if (res?.status && res?.payload?.parcelItems) {
                let tempParcelItems = res?.payload?.parcelItems?.map((pi) => {
                    return {
                        ...pi,
                        uuid: uid(),
                    }
                })
                setParcelItems([...(tempParcelItems || [])])
            } else {
                setShowResultsModal({
                    show: 1,
                    status: res?.status,
                    message: res?.message,
                })
                setParcelItems([{ awb: null, uuid: null, new_quantity: 0, purchase_tracking_number: '' }])
            }
            setLoading(false)
        } catch (error) {
            setShowResultsModal({
                show: 1,
                status: false,
                message: 'Something went worng',
            })
            setLoading(false)
        }
    }

    useEffect(() => {
        if (parcelId && parcelId !== '') {
            getParcelItems()
        }
    }, [parcelId])

    useEffect(() => {
        const disabled =
            splitQty?.length === 0 ||
            !isUndefined(splitQty?.find((pi) => pi?.quantity == 0)) ||
            !isUndefined(
                splitQty?.find((pi) => !pi?.purchase_tracking_number || pi?.purchase_tracking_number?.length === 0),
            ) ||
            !isUndefined(splitQty?.find((pi) => pi?.new_quantity >= parcelItems?.find((i) => i?.uuid === pi.uuid)?.qty))
        setIsDisabled(disabled)
    }, [JSON.stringify(splitQty)])

    return (
        <Modal
            // show={show && items?.length > 0}
            show={show}
            onHide={handleClose}
            dialogClassName={i18n.language + ''}
            aria-labelledby="contained-modal-title-vcenter"
            id={`${uid()}-itemsModal`}
            size={'lg'}
            centered
        >
            <Modal.Header
                className={`justify-content-between border-bottom-none bg-white ${i18n?.language === 'ar' ? 'close-ar' : ''}`}
                closeButton
            >
                <h3 className={`${i18n?.language === 'ar' ? 'text-right' : 'text-left'} mx-4`}>{t('Split Parcel')}</h3>
            </Modal.Header>
            {loading ? (
                <div className="w-100 m-3">
                    {' '}
                    <Loader />{' '}
                </div>
            ) : (
                <>
                    <Modal.Body className={`py-0 text-center ${parcelItems?.length > 0 ? 'gray-bg' : ''}`}>
                        <p className="mx-4 mt-3 text-start text-grey-light">{t('Enter tracking & quantity')}</p>
                        {parcelItems?.length > 0 ? (
                            <div className={'mx-4'} id={`items-${uid()}`}>
                                <Row className="my-3">
                                    <Col md={12} sm={12} className={`text-start`}>
                                        {parcelItems?.map((i, index) => {
                                            return (
                                                <Row
                                                    className="my-3 mx-1 align-items-start rounded bg-white pt-1 pb-2 my-2"
                                                    key={i?.uuid}
                                                >
                                                    <Col lg={12} sm={12} className="pt-2">
                                                        <CustomizedTextField
                                                            ar={i18n.language === 'ar'}
                                                            label={
                                                                <>
                                                                    {t('Enter Tracking Number')}
                                                                    &nbsp;<span className="color-red">*</span>
                                                                </>
                                                            }
                                                            value={
                                                                splitQty?.find((sp) => sp?.uuid === i?.uuid)
                                                                    ?.purchase_tracking_number
                                                            }
                                                            name="purchase_tracking_number"
                                                            variant={'filled'}
                                                            type="text"
                                                            InputProps={{
                                                                disableUnderline: true,
                                                                maxLength: 75,
                                                            }}
                                                            fullWidth
                                                            id={`${i?.uuid}-purchase_tracking_number`}
                                                            onChange={(e) =>
                                                                setSplitQty([
                                                                    ...(splitQty || [])?.filter(
                                                                        (sq) => sq?.id !== i.id,
                                                                    ),
                                                                    {
                                                                        awb: parcelId,
                                                                        uuid: i?.uuid,
                                                                        new_quantity: (splitQty || [])?.find(
                                                                            (sq) => sq?.uuid === i?.uuid,
                                                                        )?.new_quantity,
                                                                        purchase_tracking_number:
                                                                            e?.target?.value || '',
                                                                    },
                                                                ])
                                                            }
                                                        />
                                                    </Col>
                                                    <Col lg={6} sm={12} className="pt-2">
                                                        <CustomizedTextField
                                                            label={
                                                                <>
                                                                    {t('Quantity')}
                                                                    &nbsp;<span className="color-red">*</span>
                                                                </>
                                                            }
                                                            value={i?.qty}
                                                            name="quantity"
                                                            variant={'filled'}
                                                            disabled
                                                            InputProps={{
                                                                disableUnderline: true,
                                                                inputProps: { min: 0 },
                                                                readOnly: true,
                                                            }}
                                                            type="number"
                                                            id={`${i?.uuid}-qty`}
                                                            fullWidth
                                                        />
                                                    </Col>
                                                    <Col lg={6} sm={12} className="pt-2">
                                                        <CustomizedTextField
                                                            error={
                                                                !isUndefined(
                                                                    splitQty?.find(
                                                                        (pi) =>
                                                                            pi?.new_quantity >=
                                                                            parcelItems?.find(
                                                                                (i) => i?.uuid === pi.uuid,
                                                                            )?.qty,
                                                                    ),
                                                                )
                                                            }
                                                            helperText={
                                                                !isUndefined(
                                                                    splitQty?.find(
                                                                        (pi) =>
                                                                            pi?.new_quantity >=
                                                                            parcelItems?.find(
                                                                                (i) => i?.uuid === pi.uuid,
                                                                            )?.qty,
                                                                    ),
                                                                )
                                                                    ? 'Please enter a valid qty'
                                                                    : ''
                                                            }
                                                            label={
                                                                <>
                                                                    {t('Split Qty')}
                                                                    &nbsp;<span className="color-red">*</span>
                                                                </>
                                                            }
                                                            value={
                                                                splitQty?.find((sp) => sp?.uuid === i?.uuid)
                                                                    ?.new_quantity
                                                            }
                                                            name="new_quantity"
                                                            variant={'filled'}
                                                            type="number"
                                                            InputProps={{
                                                                disableUnderline: true,
                                                                inputProps: { min: 0 },
                                                            }}
                                                            id={`${i?.uuid}-amount`}
                                                            onChange={(e) =>
                                                                setSplitQty([
                                                                    ...(splitQty || [])?.filter(
                                                                        (sq) => sq?.id !== i.id,
                                                                    ),
                                                                    {
                                                                        awb: parcelId,
                                                                        uuid: i?.uuid,
                                                                        new_quantity: parseInt(e?.target?.value),
                                                                        purchase_tracking_number:
                                                                            (splitQty || [])?.find(
                                                                                (sq) => sq?.uuid === i?.uuid,
                                                                            )?.purchase_tracking_number || '',
                                                                    },
                                                                ])
                                                            }
                                                            fullWidth
                                                        />
                                                    </Col>
                                                </Row>
                                            )
                                        })}
                                    </Col>
                                </Row>
                            </div>
                        ) : null}
                    </Modal.Body>
                    {parcelItems?.length > 0 && (
                        <Modal.Footer className="bg-white">
                            <Row>
                                <Col xs={10} className="d-flex">
                                    <a
                                        className="text-center btn d-flex flex-row justify-content-center blue-color light-blue-bg rounded mx-3"
                                        onClick={() => handleClose()}
                                    >
                                        {t('Cancel')}
                                    </a>
                                    <a
                                        className="text-center btn d-flex flex-row justify-content-center text-white rounded btn-bg-color"
                                        style={{
                                            opacity: isDisabled ? 0.4 : 1,
                                            pointerEvents: isDisabled ? 'none' : 'initial',
                                        }}
                                        onClick={() => handleSubmit(splitQty[0])}
                                    >
                                        {t('Confirm')}
                                    </a>
                                </Col>
                            </Row>
                        </Modal.Footer>
                    )}
                </>
            )}
        </Modal>
    )
}
