import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const StyledBox = styled.div`
    padding-top: 71.24%;
    height: 0;
    background-image: ${(props) =>
        props.isAr ? `url(${require('./box-ar.svg').default})` : `url(${require('./box.svg').default})`};
    background-size: 100% 100%;
`

const StyledBoxContainer = styled.div`
    max-width: 306px;
    padding: 0 10px;
    margin: 0 auto;
`

const BoxFigure = () => {
    const { i18n } = useTranslation()

    const isAr = i18n.language === 'ar'

    return (
        <StyledBoxContainer>
            <StyledBox isAr={isAr} />
        </StyledBoxContainer>
    )
}

export default BoxFigure
