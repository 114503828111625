import React, { useEffect, useState } from 'react'
import { Button, Form, OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import ReactPaginate from 'react-paginate'

import { PARCEL_CONSTANTS } from '../../../constants/constants.js'
import { parcelTableDateFormat } from '../../../utils/moment'
import { parcelFormattedDimensions, parcelFormattedWeight } from '../../../utils/parcel'

import ParcelAccordion from './accordion'
import Drawer from './drawer'
import { Link } from 'react-router-dom'
import { useLocalStorage } from '../../../contexts/localStorage.js'
import * as NAVIGATION_CONSTANTS from '../../../constants/navigationConstants'
import { useAuth } from '../../../contexts/AuthContext.js'
import EditIcon from './editIcon.svg'
import { copyTextToClipboard } from '../../../utils/string.js'
import AddIcon from './addIcon.svg'
import ShortUniqueId from 'short-unique-id'
import TextCopyOverlay from '../../common/TextCopyOverlay/index.js'
import useAnalytics from '../../../analytics/useAnalytics.js'

export default (props) => {
    const { t, i18n } = useTranslation()
    const { warehouseId, membershipPlanID, warehousesList, userPin } = useAuth()
    const [parcelData, setParcelData] = useState([...props?.parcelData])
    const [data, setData] = useState([])
    const [pages, setPages] = useState(0)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [selectedParcel, setSelectedParcel] = useState({})
    const [stateCounter, setStateCounter] = useState(0)
    const [selectedParcels, setSelectedParcels] = useState([])
    const [isDisabled, setIsDisabled] = useState([])
    const [copyMsg, setCopyMsg] = useState('Copy')
    const uid = new ShortUniqueId({ length: 8 })
    const analytics = useAnalytics()
    const warehouse = warehousesList.find((warehouse) => warehouse.id === Number(warehouseId))?.country

    const onPageChange = (params) => {
        props?.setPageNumber(params.selected + 1)
        props?.setReload(!props?.reload)
    }

    const checkValidParcel = (parcel) => {
        return !(
            !parcel?.dimension_width ||
            !parcel?.dimension_length ||
            !parcel?.ParcelItems ||
            !parcel?.ParcelItems?.length ||
            !parcel?.supplier ||
            parcel?.supplier === ''
        )
    }

    const toggleParcel = (event, parcel, index) => {
        let checked = event.target.checked
        parcel.isSelected = checked

        let tempData = [...data]
        tempData[index] = parcel
        setData(tempData)
        let selectParcelsTemp = [...selectedParcels]
        if (checked) {
            selectParcelsTemp.push(parcel)
        } else {
            _.remove(selectParcelsTemp, {
                uuid: parcel?.uuid,
            })
        }
        setSelectedParcels(selectParcelsTemp)
        setStateCounter(stateCounter + 1)
    }

    const setSelectedFlagInParcels = (data, checked) => {
        return _.filter(data, (parcel, index) => {
            parcel.isSelected = checked
            return parcel
        })
    }

    const toggleAllParcel = (event) => {
        let checked = event.target.checked
        setData(props?.parcelData)
        let selectedParcelsTemp = setSelectedFlagInParcels(
            data?.filter((p) => p?.can_consolidate),
            checked,
        )
        if (checked) {
            setSelectedParcels(selectedParcelsTemp)
        } else {
            setSelectedParcels([])
        }
        setStateCounter(stateCounter + 1)
    }

    const toggleChevron = (event, parcel, index) => {
        let icon = document.querySelector('#details-chevron-' + props.category + '-' + parcel?.uuid)
        if (!icon) return
        icon.classList.toggle('bx-chevron-up')
        icon.classList.toggle('bx-chevron-down')
    }

    const isParcelEditable = (parcelStatus) => {
        const {
            RECEIVED_PARCEL_STATUS_ID,
            UNPAID_PARCEL_STATUS_ID,
            RECORD_CREATED_PARCEL_STATUS_ID,
            CR_PARCEL_STATUS_ID,
        } = PARCEL_CONSTANTS
        return [
            RECEIVED_PARCEL_STATUS_ID,
            UNPAID_PARCEL_STATUS_ID,
            RECORD_CREATED_PARCEL_STATUS_ID,
            CR_PARCEL_STATUS_ID,
        ]?.includes(parcelStatus)
    }

    const generateStatusBlock = (parcel) => {
        let sortedParcelLogs = []
        switch (props?.category) {
            case PARCEL_CONSTANTS.DELIVERED:
                return (
                    <span>
                        <i className="bx bxs-circle crlgrn8 mr-2" /> {t('Delivered')}
                    </span>
                )
            // case PARCEL_CONSTANTS.EXCEPTION:
            //     return <span>
            //         <i className='bx bxs-circle crlred8 mr-2'/>
            //         {_.last(parcel?.ParcelHasStatuses) && _.last(parcel?.ParcelHasStatuses).ParcelStatus.name ?
            //             _.last(parcel?.ParcelHasStatuses).ParcelStatus.name :
            //             t("Exception")
            //         }
            //     </span>;
            case PARCEL_CONSTANTS.ACTIVE:
            case PARCEL_CONSTANTS.READY_PENDING:
                // sortedParcelLogs = parcel?.ParcelLogs ? parcel?.ParcelLogs.sort((a, b) => b.id - a.id) : [];
                return (
                    <span>
                        <i className={`bx bxs-circle ${parcel?.is_pending_payment ? 'crlorblu8' : 'crlorng8'} mr-2`} />
                        {parcel?.is_pending_payment
                            ? t('Pending Payment')
                            : // parcel?.ParcelLogs && parcel?.ParcelLogs.length ?
                              // 	_.head(sortedParcelLogs).detail !== "" ? _.head(sortedParcelLogs).detail : _.head(sortedParcelLogs).ParcelStatus.name :
                              // 	t("Exception")
                              parcel?.ParcelStatus
                              ? t(parcel.ParcelStatus.replaceAll(':', ''))
                              : t('Exception')}
                    </span>
                )
            case PARCEL_CONSTANTS.ALL:
                // sortedParcelLogs = parcel?.ParcelLogs ? parcel?.ParcelLogs.sort((a, b) => b.id - a.id) : [];
                return (
                    <span className="d-flex align-items-center">
                        <i className="bx bxs-circle crlorng8 mr-2" />
                        <span span className="d-flex flex-column">
                            <span>
                                {/* {parcel?.ParcelLogs && parcel?.ParcelLogs.length ?
								_.head(sortedParcelLogs).detail !== "" ? _.head(sortedParcelLogs).detail : _.head(sortedParcelLogs).ParcelStatus.name : */}
                                {parcel?.ParcelStatus ? t(parcel.ParcelStatus.replaceAll(':', '')) : t('Exception')}
                            </span>
                            <span className={'gray-3' + (i18n.language === 'ar' ? ' rtl' : '')}>
                                {/* {parcelTableDateFormat(parcel?.ParcelLogs?.find(pl => pl?.parcel_status_id === parcel?.parcel_status_id)?.created_at)} */}
                                {parcelTableDateFormat(parcel?.ParcelStatusDate)}
                            </span>
                        </span>
                    </span>
                )
            default:
                // sortedParcelLogs = parcel?.ParcelLogs ? parcel?.ParcelLogs.sort((a, b) => b.id - a.id) : [];
                return (
                    <span>
                        <i className="bx bxs-circle crlorng8 mr-2" />
                        {/* {parcel?.ParcelLogs && parcel?.ParcelLogs.length ?
						_.head(sortedParcelLogs).detail !== "" ? _.head(sortedParcelLogs).detail : _.head(sortedParcelLogs).ParcelStatus.name : */}
                        {parcel?.ParcelStatus ? t(parcel.ParcelStatus.replaceAll(':', '')) : t('Exception')}
                    </span>
                )
        }
    }

    const generateTableColumn = (parcel, col, index) => {
        let isValidParcel = checkValidParcel(parcel)
        let warehouseCountry, dateAndTime, date, time
        switch (col.key) {
            case 'id':
                const parcelAwb =
                    parcel?.new_awb_number && parcel?.new_awb_number !== ''
                        ? parcel?.new_awb_number
                        : parcel?.awb?.length <= 10 || parcel?.parcel_type_id > 1
                          ? parcel?.awb
                          : `${parcel?.awb?.substring(0, 10)}`
                return (
                    <td>
                        {parcel?.uuid}
                        <div className="td-sm-txtclr">
                            {parcel?.is_mps ? (
                                <span className="color-red font-weight-bold">
                                    {t('Multi Boxes')} - {parcel?.SecondaryParcelsCount + 1}
                                </span>
                            ) : (
                                <span
                                    className={`${!parcel?.can_consolidate && parcel?.parcel_type_id === 1 ? 'color-red' : ''} wb-show mb-hide`}
                                >
                                    {parcel?.ChildParcelsCount ? parcel?.ChildParcelsCount + ' parcels' : null}
                                    {parcel?.can_consolidate || parcel?.parcel_type_id > 1 ? (
                                        ![PARCEL_CONSTANTS.DELIVERED, PARCEL_CONSTANTS.ACTIVE].includes(
                                            props?.category,
                                        ) ||
                                        parcel?.parcel_type_id === 1 ||
                                        (parcel?.new_awb_number && parcel?.new_awb_number !== '') ? (
                                            <>
                                                <TextCopyOverlay
                                                    textToBeCopied={
                                                        parcel?.new_awb_number && parcel?.new_awb_number !== ''
                                                            ? parcel?.new_awb_number
                                                            : parcel?.awb
                                                    }
                                                >
                                                    <span className="d-block tr-cursor">
                                                        {!parcel?.awb?.length > 10 && parcel?.parcel_type_id === 1
                                                            ? parcel?.new_awb_number !== parcelAwb
                                                                ? parcelAwb
                                                                : `${parcelAwb} ...`
                                                            : parcelAwb}
                                                        &nbsp;
                                                        {[
                                                            PARCEL_CONSTANTS.DELIVERED,
                                                            PARCEL_CONSTANTS.ACTIVE,
                                                            PARCEL_CONSTANTS.ALL,
                                                        ].includes(props?.category) ? (
                                                            <i
                                                                className="copy-btn fas fa-clone tr-cursor"
                                                                // onClick={() => copyTextToClipboard(parcel?.awb)}
                                                            />
                                                        ) : null}
                                                    </span>
                                                </TextCopyOverlay>
                                            </>
                                        ) : null
                                    ) : (
                                        t('Not eligible for consolidation')
                                    )}
                                </span>
                            )}
                            {parcel?.tracking_number && parcel?.tracking_number !== '' ? (
                                <>
                                    <TextCopyOverlay textToBeCopied={parcel?.tracking_number}>
                                        <span className="d-block tr-cursor">
                                            {`${t('Trk no')} ${parcel?.tracking_number}`}
                                            &nbsp;
                                            <i className="copy-btn fas fa-clone tr-cursor" />
                                        </span>
                                    </TextCopyOverlay>
                                    {/* &nbsp;
								<OverlayTrigger
									placement="bottom"
									delay={{ show: 250, hide: 400 }}
									key={`${uid()}-tooltip`}
									overlay={< Tooltip id={`${uid()}-tooltip`
									} >
										{t(copyMsg)}
									</Tooltip >}
								>
									<i
										className="copy-btn fas fa-clone tr-cursor"
										onClick={() => {
												copyTextToClipboard(parcel?.tracking_number)
												setCopyMsg("Copied")
											}
										}
									/>
								</OverlayTrigger> */}
                                </>
                            ) : null}
                            {/* {(parcel?.Website && parcel?.supplier)
							? <span className="mb-show">
								{parcel?.supplier}
							</span>
							: <span className="mb-show fs-12 gray-3">
								{t("In progress")}
							</span>
						} */}
                        </div>
                    </td>
                )
            case 'supplier':
                return (
                    <td>
                        <span className="mb-hide">
                            {parcel?.supplier ? (
                                parcel?.supplier?.length > 15 ? (
                                    <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        key={`${uid()}-tooltip`}
                                        overlay={<Tooltip id={`${uid()}-tooltip`}>{parcel?.supplier}</Tooltip>}
                                    >
                                        <span className="tr-cursor">{`${parcel?.supplier?.slice(0, 15)} ...`}</span>
                                    </OverlayTrigger>
                                ) : (
                                    parcel?.supplier
                                )
                            ) : (
                                <span className="fs-12 gray-3">{t('In progress')}</span>
                            )}
                            <div className="td-sm-txtclr">
                                {(parcel?.ParcelItems && parcel?.ParcelItems?.length) ||
                                parcel?.ParcelItemsCount ||
                                parcel?.ChildItemsCount
                                    ? (parcel?.ParcelItems?.length ||
                                          parcel?.ParcelItemsCount ||
                                          parcel?.ChildItemsCount) + ` ${t('items')}`
                                    : t('In Progress')}
                            </div>
                        </span>
                    </td>
                )
            case 'updatedAt':
                return (
                    <td>
                        <span className="mb-hide">{parcelTableDateFormat(parcel?.updated_at)}</span>
                    </td>
                )
            case 'deliveredAt':
                return (
                    <td>
                        <span className="mb-hide">
                            {parcelTableDateFormat(parcel?.delivery_date)}
                            {/* || parcel?.ParcelLogs?.find(ps => Number(ps?.parcel_status_id) === PARCEL_CONSTANTS.DELIVERED_PARCEL_STATUS_ID)?.created_at */}
                        </span>
                    </td>
                )
            case 'receivedAt':
                warehouseCountry = parcel?.uuid?.slice(2, 4)
                // const parcelReceivedAt = parcel?.ParcelLogs?.find(ps => Number(ps?.parcel_status_id) === PARCEL_CONSTANTS.RECEIVED_PARCEL_STATUS_ID)?.created_at
                const parcelReceivedAt = parcel?.received_date
                if (parcelReceivedAt) {
                    dateAndTime = parcelTableDateFormat(parcelReceivedAt, true, warehouseCountry)?.split(',')
                    date = dateAndTime[0].trim()
                    time = dateAndTime[1].trim()
                }
                return (
                    <td>
                        <span className="mb-hide">
                            {parcelReceivedAt ? (
                                <>
                                    <span className="d-block">{date}</span>
                                    <small className="text-muted">{time}</small>
                                </>
                            ) : (
                                '-'
                            )}
                        </span>
                    </td>
                )
            case 'dispatchedAt':
                warehouseCountry = warehousesList.find((w) => w.id === parcel?.id)?.country || 'US'
                const parcelDispatchedAt = parcel?.dispatched_date
                //|| parcel?.ParcelLogs?.find(ps => Number(ps?.parcel_status_id) === PARCEL_CONSTANTS.DISPATCHED_PARCEL_STATUS_ID)?.created_at
                if (parcelDispatchedAt) {
                    dateAndTime = parcelTableDateFormat(parcelDispatchedAt, true, warehouseCountry)?.split(',')
                    date = dateAndTime[0].trim()
                    time = dateAndTime[1].trim()
                }
                return (
                    <td>
                        <span className="mb-hide">{date ? <span className="d-block">{date}</span> : '-'}</span>
                    </td>
                )
            case 'dimensions':
                return (
                    <td>
                        <span className="mb-hide">
                            {parcel?.dimension_length ? (
                                parcelFormattedDimensions(parcel, t)
                            ) : (
                                <span className="fs-12 gray-3">{t('In progress')}</span>
                            )}
                        </span>
                    </td>
                )
            case 'weight':
                return (
                    <td>
                        <span className="wb-show mb-hide">
                            {parcel?.total_chargeable_weight || parcel?.weight_value ? (
                                parcelFormattedWeight(
                                    parcel,
                                    ![
                                        PARCEL_CONSTANTS.ACTIVE,
                                        PARCEL_CONSTANTS.READY_PENDING,
                                        PARCEL_CONSTANTS.DELIVERED,
                                    ].includes(props?.category),
                                    t,
                                )
                            ) : (
                                <span className="fs-12 gray-3">{t('In progress')}</span>
                            )}
                        </span>
                        <span
                            className={`${props.category === PARCEL_CONSTANTS.ALL ? 'mb-show' : 'mb-hide'} font12-gry3`}
                        >
                            {(parcel?.ParcelItems && parcel?.ParcelItems?.length) ||
                            parcel?.ParcelItemsCount ||
                            parcel?.ChildItemsCount
                                ? (parcel?.ParcelItems?.length || parcel?.ParcelItemsCount || parcel?.ChildItemsCount) +
                                  ` ${t('items')}`
                                : t('In Progress')}
                        </span>
                    </td>
                )
            case 'status':
                return <td className="mb-hide white-space-nowrap">{generateStatusBlock(parcel)}</td>
            case 'accordion':
                if (
                    props?.category === PARCEL_CONSTANTS.ALL &&
                    (!parcel?.ParcelItems || parcel?.ParcelItems?.length === 0) &&
                    userPin !== 'SW17697'
                ) {
                    return (
                        <td>
                            <a
                                aria-expanded="false"
                                data-toggle="tooltip"
                                className="checkedoff checkedin"
                                data-checkedto="false"
                                data-placement="top"
                                title="We are processing your package"
                            >
                                <a
                                    className={`nowrap-space p-2 rounded light-blue-bg add-edit-items-hover`}
                                    onClick={() => {
                                        props?.setParcel({ ...parcel })
                                    }}
                                >
                                    <img src={AddIcon} />
                                    &nbsp;
                                    {t('Add Items')}
                                </a>
                            </a>
                        </td>
                    )
                } else {
                    return (
                        <td className="btnclr">
                            <a
                                aria-expanded="false"
                                data-checkedto="true"
                                className="checkedin d-flex justify-content-end align-items-center"
                                aria-controls="collapseExample"
                            >
                                {props?.category === PARCEL_CONSTANTS.ALL &&
                                isParcelEditable(parcel?.parcel_status_id) &&
                                userPin !== 'SW17697' ? (
                                    <a
                                        className={`d-flex nowrap-space p-2 rounded light-blue-bg add-edit-items-hover`}
                                        onClick={() => {
                                            props?.setParcel({ ...parcel })
                                        }}
                                    >
                                        <img src={EditIcon} />
                                        &nbsp;
                                        {t('Edit Items')}
                                    </a>
                                ) : null}
                                <i
                                    onClick={(e) => toggleChevron(e, parcel, index)}
                                    className={'bx bx-chevron-down fs-24 mx-1'}
                                    data-toggle="collapse"
                                    href={'#details-' + parcel?.id}
                                    role="button"
                                    id={'details-chevron-' + props.category + '-' + parcel?.uuid}
                                />
                            </a>
                        </td>
                    )
                }
            case 'view':
                return (
                    <>
                        {parcel?.is_pending_payment ? (
                            <td className={`${props.isEnglish ? 'text-right' : 'text-left'}`}>
                                <Link
                                    className={`btn-primary rounded text-decoration-none font-13`}
                                    onClick={() =>
                                        analytics.events.shippingConsolidationPayButton({
                                            parcelId: parcel?.uuid,
                                            warehouse,
                                            consolidationType: `${parcel?.uuid}`.substring(0, 2),
                                        })
                                    }
                                    to={{
                                        pathname: NAVIGATION_CONSTANTS.CONSOLIDATED_PARCEL_PAYMENT,
                                        search: '',
                                        state: {
                                            parcels: [parcel],
                                            paymentType: 'consolidation-after',
                                        },
                                    }}
                                >
                                    <span>{`${t('Pay')}`}</span>
                                </Link>
                            </td>
                        ) : (
                            <td className={`${props?.isEnglish ? 'text-right' : 'text-left'}`}>
                                <a
                                    href="javascript:void(0)"
                                    onClick={() => {
                                        setSelectedParcel(parcel)
                                        setIsDrawerOpen(!isDrawerOpen)
                                    }}
                                >
                                    {t('View')}
                                    {props?.isMobile && (
                                        <i
                                            className={`mt-1 ${props?.isEnglish ? 'fas fa-chevron-right float-right ml-4' : 'float-left fas fa-chevron-left mr-4'}`}
                                        />
                                    )}
                                </a>
                            </td>
                        )}
                        {props?.isMobile && <td></td>}
                    </>
                )
            case 'checkbox':
                return (
                    <td className={'text-center'}>
                        <Form.Check
                            type="checkbox"
                            disabled={
                                isDisabled.includes(parcel?.id) ||
                                (!parcel?.can_consolidate &&
                                    selectedParcels?.filter((parcel) => parcel?.can_consolidate)?.length > 0) ||
                                (parcel?.can_consolidate &&
                                    selectedParcels?.filter((parcel) => !parcel?.can_consolidate)?.length > 0)
                            }
                            onChange={(e) => toggleParcel(e, parcel, index)}
                            checked={parcel?.isSelected || false}
                        />
                    </td>
                )
            case 'amount':
                return (
                    <td>
                        <span className="mb-hide">$ {parcel?.amount}</span>
                    </td>
                )
        }
    }

    useEffect(() => {
        let perPage = props?.category === PARCEL_CONSTANTS.READY_TO_SHIP ? 50 : PARCEL_CONSTANTS.PAGE_SIZE
        setPages(Math.ceil(props?.count / perPage))
    }, [props?.count, warehouseId])

    useEffect(() => {
        setData(props.parcelData || [])
        if (props?.pageNumber > pages) {
            props?.setPageNumber(1)
        }
    }, [props?.pageNumber, warehouseId, JSON.stringify(props?.parcelData), data?.length])

    useEffect(() => {
        if (props?.category === PARCEL_CONSTANTS.READY_TO_SHIP) {
            const parcelsTemp = setSelectedFlagInParcels(
                props?.parcelData?.filter((p) => p?.can_consolidate),
                true,
            )
            setData(parcelsTemp)
            setSelectedParcels(parcelsTemp)
            return
        }
    }, [props?.parcelData?.length, props?.category, warehouseId])

    useEffect(() => {
        props?.setSelectedParcels(selectedParcels)
    }, [selectedParcels.length, warehouseId])

    useEffect(() => {
        if (!props?.loading) {
            setParcelData([...props?.parcelData])
            props?.parcelData?.forEach((p) => {
                let validity = checkValidParcel(p)
                if (!validity) {
                    setIsDisabled([...isDisabled, p?.id])
                }
            })
            // if (props?.category === PARCEL_CONSTANTS.ALL) {
            // 	props?.setCounts({ ...props?.counts, [PARCEL_CONSTANTS.ALL]:
            // 		//props?.allParcelsCount || props?.parcelData?.length
            // 		props?.count
            // 	})
            // }
        }
    }, [warehouseId, JSON.stringify(props?.parcelData)])

    useEffect(() => {
        props?.setCounts({ ...props?.counts, [props?.category]: props?.count })
        props?.setPages({ ...props?.pages, [props?.category]: props?.pageNumber })
    }, [JSON.stringify(parcelData?.length), warehouseId])

    useEffect(() => {
        if (props?.parcel?.id && props?.category === PARCEL_CONSTANTS.ALL) {
            props?.setShowItemsModal(true)
        }
    }, [props?.parcel?.id, props?.category])

    return (
        <>
            <Table responsive className="parcel-table all-parcel">
                <thead className="tbl-hd-bg font-13">
                    <tr>
                        {props?.tableColumns?.map((col, index) => {
                            return (
                                <th key={col}>
                                    {col.key === 'checkbox' ? (
                                        <Form.Check
                                            type="checkbox"
                                            onChange={(e) => toggleAllParcel(e)}
                                            className={'text-center'}
                                            defaultChecked={
                                                data?.filter((p) => p?.can_consolidate)?.length ===
                                                selectedParcels?.length
                                            }
                                            disabled={selectedParcels?.filter((p) => !p?.can_consolidate)?.length > 0}
                                        />
                                    ) : (
                                        <span className={col.key === 'id' || index === 0 ? '' : 'mb-hide'}>
                                            {col.title}
                                        </span>
                                    )}
                                </th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {_.head([props?.category === PARCEL_CONSTANTS.READY_TO_SHIP ? props?.parcelData : data])?.map(
                        (parcel, index) => {
                            return (
                                <>
                                    <tr
                                        className={parcel?.isSelected ? 'selected-row' : ''}
                                        key={`${parcel?.id}-${props?.category}-data`}
                                    >
                                        {props.tableColumns?.map((col, colIndex) => {
                                            return generateTableColumn(parcel, col, index)
                                        })}
                                    </tr>
                                    {props?.category === PARCEL_CONSTANTS.ALL ||
                                    props?.category === PARCEL_CONSTANTS.READY_TO_SHIP ? (
                                        <tr key={`accordion-${parcel?.uuid}`}>
                                            <td
                                                colSpan="7"
                                                className="hiddenRow sptopbrdr0 td-vertical-top parcel-accordian"
                                            >
                                                <ParcelAccordion
                                                    parcel={parcel}
                                                    category={props?.category}
                                                    setParcels={props?.setParcelData}
                                                    isMobile={props?.isMobile}
                                                    setParcel={props?.setParcel}
                                                />
                                            </td>
                                        </tr>
                                    ) : null}
                                </>
                            )
                        },
                    )}
                    {_.find(props?.tableColumns, { key: 'view' }) && isDrawerOpen ? (
                        <Drawer isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} parcel={selectedParcel} />
                    ) : null}
                </tbody>
            </Table>
            {props.parcelData?.length < 10 && props?.count < 10 ? null : ( //|| props.category === PARCEL_CONSTANTS.READY_TO_SHIP)
                <ReactPaginate
                    pageCount={pages}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={1}
                    previousLabel={t('Previous')}
                    nextLabel={t('Next')}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'parcel-pagination pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    activeClassName={'active'}
                    previousClassName={'page-item'}
                    nextClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextLinkClassName={'page-link'}
                    onPageChange={onPageChange}
                    forcePage={props?.pages[props?.category] - 1}
                />
            )}
        </>
    )
}
