import React, { useEffect, useRef, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import _, { isEmpty, isUndefined } from 'lodash'
import { useAuth } from '../../contexts/AuthContext'
import { getData } from '../../utils/api'

import * as NAVIGATION_CONSTANTS from '../../constants/navigationConstants'
import PaymentCardList from './CardList'

import Auth from '../auth'
import PaymentSummary from '../consolidatedParcelPaymentSummary'
import Payment from '../stripe'

import { Loader } from '../common'
import { postData } from '../../utils/api'
import AmazonPay from '../AmazonPay'
import { PROMOS } from '../../constants/constants'
import ResultsModal from '../resultsModal'
import ShippingServicesModal from '../shipmentOptions/shippingServicesModal'
import useUserAddresses from '../../utils/useUserAddresses'
import AddressModal from '../shipmentOptions/address'
import useAnalytics from '../../analytics/useAnalytics'
import { screenEvent } from '../../analytics/constants'

export default (props) => {
    const { t, i18n } = useTranslation()
    const { authToken, userPin, warehousesList, warehouseId } = useAuth()

    const parcels = (props.location && props.location.state && props.location.state.parcels) || []
    const paymentTypeFromProps =
        (props.location && props.location.state && props.location.state.paymentType) || 'consolidation-after'
    const floater = i18n.language === 'ar' ? 'float-left' : 'float-right'
    const { addressList } = useUserAddresses()

    const [isAmazonPay, setIsAmazonPay] = useState(false)
    const [isPaypal, setIsPaypal] = useState(false)
    const [isOthers, setIsOthers] = useState(false)
    const [loading, setLoading] = useState(true)
    const [paymentRequestData, setPaymentRequestData] = useState({})
    const [proceedToNextPage, setProceedToNextPage] = useState(null)
    const [paymentStatus, setPaymentStatus] = useState('in-progress')
    const [paymentDetails, setPaymentDetails] = useState({})
    const [savedCardList, setSavedCardList] = useState([])
    const [creditCard, setCreditCard] = useState({})
    const [selectedShippingRate, setSelectedShippingRate] = useState({})
    const [shippingAddress, setShippingAddress] = useState({})
    const [promoCode, setPromoCode] = useState(null)
    const [userAddressesState, setUserAddressesState] = useState(false)
    const [showByPassPaymentModal, setShowByPassPaymentModal] = useState({
        show: paymentDetails?.creditedUser || 0,
        status: 0,
    })
    const [walletBalance, setWalletBalance] = useState(undefined)
    const warehouse = warehousesList.find((warehouse) => warehouse.id === Number(warehouseId))?.country
    const paymentStartedRef = useRef(false)
    const analytics = useAnalytics()
    const parcelData = paymentDetails.parcel_data?.[0] ?? {}
    const rate =
        (paymentDetails?.rates ?? []).find(
            (rate) =>
                Number(rate.shipping_company_id) === Number(parcelData.shipping_company_id) &&
                rate.service === parcelData.service,
        ) ?? {}
    const analyticsAttributes = {
        parcelId: parcelData.uuid ?? undefined,
        consolidationType: (parcelData.uuid ?? '  ').substr(0, 2),
        country: parcelData.parcel_address?.country ?? '',
        city: parcelData.parcel_address?.city ?? '',
        shippingCompany: parcelData.service ?? '',
        extraServices: (rate.add_on_services ?? []).map((addonService) => addonService.service),
        promoCode: !!rate.promo_code_discount?.is_valid ? rate.promo_code_discount.discount_code : undefined,
        parcelIds: [
            {
                'Parcel ID': parcelData.uuid,
                'Actual Weight': parcelData.actual_weight,
                'Volumetric Weight': parcelData.volumetric_weight,
                'Chargeable Weight': parcelData.chargeable_weight,
            },
            ...(parcelData.secondary_parcels ?? []).map((parcel) => ({
                'Parcel ID': parcel.uuid,
                'Actual Weight': parcel.actual_weight,
                'Volumetric Weight': parcel.volumetric_weight,
                'Chargeable Weight': parcel.chargeable_weight,
            })),
        ],
        discountAmount: !!rate.promo_code_discount?.is_valid ? rate.promo_code_discount.discounted_amount : undefined,
        subtotal: rate.sub_total,
        totalAmount: rate.total,
        tax: 0,
        totalActualWeight: paymentDetails.total_actual_weight,
        totalChargeableWeight: paymentDetails.total_chargeable_weight,
        shippingFees: rate.shipping_charges,
        consolidationFees: rate.consolidation_charges,
        warehouse,
        paymentMethod: paymentRequestData?.amount
            ? creditCard?.card_type === 'PAYPAL'
                ? 'PayPal'
                : creditCard?.card_type === 'AMAZON_PAY'
                  ? 'Amazon'
                  : undefined
            : undefined,
        walletBalance,
        usedWalletAmount: paymentRequestData?.wallet_amount ? Number(paymentRequestData?.wallet_amount) : undefined,
    }

    const getSavedCard = async () => {
        const response = await getData('paymentService', '/cards', authToken)
        if (response.status && response.payload && response.payload.length) {
            setSavedCardList(response.payload)
        }
        setLoading(false)
    }

    const onPaymentCompletion = async (response, byPassPayment = false) => {
        if (response?.status) {
            setPaymentStatus('success')
        } else {
            setPaymentStatus('fail')
        }
        setShowByPassPaymentModal({
            show: response?.status ? 1 : 0,
            status: response?.status,
        })
        if (!byPassPayment) {
            if (creditCard?.id || response?.skip) {
                setProceedToNextPage('success-return')
            } else {
                setProceedToNextPage('amazon-return')
            }
        } else {
            const { paymentMethod, walletBalance, usedWalletAmount, ...eventAttributes } = analyticsAttributes
            analytics.events.shippingConsolidationCreditedCompleted(eventAttributes)
        }
    }

    const handlePayNowEvent = () => {
        const { paymentMethod, walletBalance, usedWalletAmount, ...eventAttributes } = analyticsAttributes
        analytics.events.shippingConsolidationPayNowButton(eventAttributes)
    }

    const handleSubmit = async (event, skip = false, byPassPayment = false) => {
        event.preventDefault()
        let data = byPassPayment
            ? {
                  parcel_ids: _.map(parcels, 'id'),
                  promo_code: paymentRequestData?.promo_code || null,
                  add_on_services: paymentRequestData?.add_on_services || [],
                  user_address_id: paymentRequestData?.user_address?.id,
                  country_id: paymentRequestData?.user_address?.country,
                  shipping_service: paymentRequestData?.shipping_service,
                  shipping_company_id: paymentRequestData?.shipping_company_id,
                  is_consolidation: 1,
                  wallet_amount: paymentRequestData?.wallet_amount,
              }
            : {
                  parcel_uuids: _.map(parcels, 'uuid'),
                  parcel_ids: _.map(parcels, 'id'),
                  total_amount: paymentRequestData?.amount,
                  is_consolidation: 1,
                  name: creditCard.name,
                  promo_code: paymentRequestData?.promo_code || null,
                  card_reference: creditCard?.card_reference,
                  card: {
                      name: creditCard.name,
                      digits: creditCard.number,
                  },
                  user_pin: userPin,
                  wallet_amount: paymentRequestData?.wallet_amount,
                  shipping_service: paymentRequestData?.shipping_service,
                  shipping_company_id: paymentRequestData?.shipping_company_id,
                  user_address_id: paymentRequestData?.user_address?.id,
                  country_id: paymentRequestData?.user_address?.country,
              }

        setLoading(true)
        try {
            let response
            if (byPassPayment) {
                response = await postData('paymentService', '/bypass-payment', data, authToken)
            } else {
                if (paymentRequestData?.amount > 0) {
                    if (creditCard?.card_type === 'PAYPAL') {
                        response = await postData(
                            'paymentService',
                            '/paypal/agreement?payment_type=consolidation&capture=true',
                            data,
                            authToken,
                        )
                    } else {
                        response = await postData(
                            'paymentService',
                            '/amazon/signature?type=consolidation&capture=true',
                            data,
                            authToken,
                        )
                    }
                } else {
                    response = await postData('paymentService', `/pay?type=parcel`, data, authToken)
                    response.skip = true
                }
            }
            handlePayNowEvent()
            onPaymentCompletion(response, byPassPayment)
        } catch (error) {
            console.log('error - ', error)
            handlePayNowEvent()
            onPaymentCompletion(error, byPassPayment)
        }
        setLoading(false)
    }

    const onAddressChange = async (address = {}) => {
        await calculateShippingRate(address)
    }

    const calculateShippingRate = async (address = {}) => {
        try {
            setLoading(true)
            const response = await postData(
                'shippingService',
                '/parcel/shipping/rates/v4',
                {
                    parcel_ids: _.map(parcels, 'id'),
                    is_consolidation: 1,
                    promo_code: promoCode && promoCode !== '' ? promoCode : null,
                    country_code: address?.country ? address.country : null,
                },
                authToken,
            )
            if (response.status) {
                let tempSelectedRate =
                    isEmpty(selectedShippingRate) || !selectedShippingRate
                        ? response.payload?.rates?.find(
                              (sc) =>
                                  sc.shipping_company_id ===
                                      Number(response.payload?.parcel_data[0]?.shipping_company_id) &&
                                  sc.service === response.payload?.parcel_data[0]?.service,
                          )
                        : response.payload?.rates?.find(
                              (sc) =>
                                  sc.shipping_company_id === Number(selectedShippingRate?.shipping_company_id) &&
                                  sc.service === selectedShippingRate?.service,
                          )
                setPaymentDetails(response.payload)
                setUserAddressesState(response.payload?.have_address)
                setShippingAddress(isEmpty(address) ? response.payload?.parcel_data[0]?.parcel_address : address)
                const payments = response?.payload?.parcel_data?.map((parcel) => {
                    return {
                        parcel_uuids: [parcel.uuid],
                        parcel_ids: [parcel.id],
                        amount: tempSelectedRate?.total,
                        promo_code: tempSelectedRate?.promo_code_discount?.is_valid
                            ? tempSelectedRate?.promo_code_discount?.discount_code
                            : null,
                        shipping_service: tempSelectedRate?.service,
                        shipping_company_id: tempSelectedRate?.shipping_company_id,
                        user_address: address,
                    }
                })

                setPaymentRequestData({
                    // payments: [...(payments || [])],
                    // amount: paymentDetails.total_amount
                    ...(payments[0] || {}),
                })
                setSelectedShippingRate(tempSelectedRate)
            }
            setLoading(false)
        } catch (error) {
            console.log('something went wrong')
            setLoading(false)
        }
    }

    useEffect(async () => {
        //call the parcel payment api
        // const response = await postData("shippingService", "/parcel/console-box/rates", {parcel_ids: _.map(parcels, "id")}, authToken)
        await calculateShippingRate({})
    }, [promoCode])

    useEffect(() => {
        if (!isEmpty(selectedShippingRate)) {
            setPaymentRequestData({
                ...paymentRequestData,
                amount: selectedShippingRate?.total,
                promo_code:
                    selectedShippingRate?.applied_discount_type !== 'membership' &&
                    selectedShippingRate?.promo_code_discount?.discount_value > 0
                        ? selectedShippingRate?.promo_code_discount?.discount_code
                        : null,
                shipping_service: selectedShippingRate?.service,
                shipping_company_id: selectedShippingRate?.shipping_company_id,
            })
        }
    }, [JSON.stringify(selectedShippingRate)])

    useEffect(() => {
        if (!isEmpty(paymentDetails) && typeof walletBalance === 'number') {
            paymentStartedRef.current = true
            const { paymentMethod, walletBalance, usedWalletAmount, ...consolidationPaymentStartedAttributes } =
                analyticsAttributes
            analytics.events.shippingConsolidationPaymentStarted(consolidationPaymentStartedAttributes)
        }
    }, [paymentDetails, walletBalance])

    useEffect(async () => {
        const retriveWalletBalance = async () => {
            try {
                const response = await getData(
                    'paymentRequestService',
                    '/v1/PurchaseRequestController/getTransactionsWallet',
                    authToken,
                )
                let tempWalletData = !response.status || response.error ? [] : response.payload.data
                var newBalance = tempWalletData.length > 0 ? tempWalletData[0].available_balance.toFixed(2) : 0
                setWalletBalance(parseFloat(newBalance) ?? 0)
            } catch (e) {}
        }
        await retriveWalletBalance()
    }, [authToken])

    useEffect(() => {
        analytics.events.screenVisited({ screen: screenEvent.CONSOLIDATION_PAYMENT })
    }, [])

    return (
        <>
            {!parcels.length ? <Redirect to={NAVIGATION_CONSTANTS.DASHBOARD} /> : null}
            {proceedToNextPage === 'amazon-return' ? (
                <Redirect
                    to={{
                        pathname: NAVIGATION_CONSTANTS.PAYMENT_RETURN,
                        search: '',
                        state: {
                            ...(props.location && props.location.state && props.location.state
                                ? props.location.state
                                : {}),
                            consolidationPaymentSavedCardEventsAttributes: analyticsAttributes,
                        },
                    }}
                />
            ) : proceedToNextPage === 'success-return' ? (
                <Redirect
                    to={{
                        pathname: NAVIGATION_CONSTANTS.PAYMENT_STATUS + '/' + paymentStatus,
                        search: '',
                        state: {
                            ...(props.location && props.location.state && props.location.state
                                ? props.location.state
                                : {}),
                            consolidationPaymentSavedCardEventsAttributes: analyticsAttributes,
                        },
                    }}
                />
            ) : null}
            {loading || isUndefined(paymentDetails?.parcel_data) || isUndefined(paymentRequestData) ? (
                <Loader />
            ) : (
                <section className="content pb-0 pt-0 shipping-options-container-wrapper">
                    <div className="container-fluid container-width padding-vertical-25 payment-option-save-card-height shipping-options-container">
                        <Row>
                            <Col lg={7} md={12} sm={12}>
                                <div className="widget__inner24 p-4 shipment-ar mb-3">
                                    <Row>
                                        <Col sm={12}>
                                            <span className="font18-gry1">{t('Delivery Address')}</span>
                                            <a
                                                id="change-address"
                                                data-toggle="modal"
                                                className={`${floater} font-weight-500 change-link`}
                                                data-target="#address-modal"
                                            >
                                                {userAddressesState ? t('Change Address') : t('Add Delivery Address')}
                                            </a>
                                        </Col>
                                        <Col md={12} className="pt-2">
                                            <span className="font14-gry1">
                                                {shippingAddress?.id ? (
                                                    <>
                                                        <input
                                                            type="hidden"
                                                            name="shipment_address_id"
                                                            id="shipment_address_id"
                                                            value={shippingAddress?.id}
                                                        />
                                                        <span className="parcel-address">
                                                            {shippingAddress?.address +
                                                                ', ' +
                                                                shippingAddress?.state +
                                                                ', ' +
                                                                (shippingAddress?.postal_code
                                                                    ? shippingAddress?.postal_code + ', '
                                                                    : '') +
                                                                shippingAddress?.country}
                                                        </span>
                                                    </>
                                                ) : null}
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                                <AmazonPay
                                    paymentRequestData={paymentRequestData}
                                    setShipmentLoading={setLoading}
                                    onPaymentCompletion={onPaymentCompletion}
                                    paymentType={'consolidation'}
                                    shipmentLoading={loading}
                                    totalPayment={paymentRequestData?.amount?.toFixed(2) || 0}
                                    parcels={parcels}
                                    setCreditCard={setCreditCard}
                                    creditCard={creditCard}
                                    setIsAmazonPay={setIsAmazonPay}
                                    setIsPaypal={setIsPaypal}
                                    setIsOthers={setIsOthers}
                                    byPassPayment={paymentDetails?.parcel_user?.is_credit_user}
                                    setPaymentRequestData={setPaymentRequestData}
                                    analyticsEventAttributes={analyticsAttributes}
                                />
                            </Col>
                            <Col md={12} sm={12} lg={5} className="mobile-margin-bottom-90 text-start">
                                <PaymentSummary
                                    parcels={paymentDetails.parcel_data}
                                    paymentSummary={paymentDetails}
                                    loading={loading}
                                    submit={
                                        (creditCard?.id ||
                                            paymentDetails?.parcel_user?.is_credit_user ||
                                            (paymentRequestData?.amount === 0 &&
                                                paymentRequestData?.wallet_amount &&
                                                paymentRequestData?.wallet_amount > 0)) &&
                                        handleSubmit
                                    }
                                    paymentRequestData={paymentRequestData}
                                    selectedShippingRate={selectedShippingRate}
                                    setSelectedShippingRate={setSelectedShippingRate}
                                    promoCode={promoCode}
                                    setPromoCode={setPromoCode}
                                />
                            </Col>
                        </Row>
                    </div>
                    <ResultsModal
                        showModal={showByPassPaymentModal}
                        setShowModal={setShowByPassPaymentModal}
                        redirectLink={NAVIGATION_CONSTANTS.DASHBOARD}
                    />
                    <ShippingServicesModal
                        setShippingService={setSelectedShippingRate}
                        rates={paymentDetails?.rates}
                        separatedParcelsRates={[]}
                        shippingService={selectedShippingRate || {}}
                    />
                </section>
            )}
            <AddressModal
                address={shippingAddress}
                userAddressesState={userAddressesState}
                setUserAddressesState={setUserAddressesState}
                onAddressChange={onAddressChange}
            />
        </>
    )
}
