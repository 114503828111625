import AE from '../pages/contactUsPage/AE.svg'
import US from '../pages/contactUsPage/US.svg'
import IQ from '../pages/contactUsPage/IQ.svg'
import TR from '../pages/contactUsPage/TR.svg'
import EG from '../pages/contactUsPage/EG.svg'
import SA from '../pages/contactUsPage/SA.png'

import ios from '../sections/shopiniFooter/ios.svg'
import android from '../sections/shopiniFooter/android.svg'

export const FACEBOOK_SHOPINI_URL = 'https://www.facebook.com/shopiniworld/'
export const TWITTER_SHOPINI_URL = 'https://twitter.com/ShopiniW'
export const INSTAGRAM_SHOPINI_URL = 'https://www.instagram.com/shopiniworld/?hl=en'
export const LINKEDIN_SHOPINI_URL = 'https://www.linkedin.com/company/shopiniworld/about/?viewAsMember=true'

export const whatsappShopiniUrl = (whatsappShopiniNumber) =>
    `https://wa.me/${whatsappShopiniNumber?.replaceAll(/\D/g, '')}`

export const STRIPE_PRIVATE_KEY = process?.env?.REACT_APP_STRIPE_KEY
export const SHOPINIWORLD_LINK = 'www.shopiniworld.com'

export const PROMOTION_PAGES = ['/amcham']

export const PARCEL_CONSTANTS = {
    NOT_RECEIVED_PARCEL_STATUS_ID: 0,
    RECEIVED_PARCEL_STATUS_ID: 1,
    DISPATCHED_PARCEL_STATUS_ID: 14,
    UNPAID_PARCEL_STATUS_ID: 3,
    RECORD_CREATED_PARCEL_STATUS_ID: 2,
    CR_PARCEL_STATUS_ID: 21,
    PAID_PARCEL_STATUS_ID: 4,
    DELIVERED_PARCEL_STATUS_ID: 9,
    SHOTLM_PARCEL_STATUS_ID: 38,

    PAGE_SIZE: 10,

    AMOUNT: '$',
    PERCENT: '%',
    CONSOLIDATION_BOX_SAVING_PERCENTAGE: '60',
    CONSOLIDATION_ITEM_SAVING_PERCENTAGE: '80',

    BOX_CONSOLIDATION: 'BC',
    ITEM_CONSOLIDATION: 'IC',

    CONSOLIDATION_CHARGE: 3,
    ITEM_CONSOLIDATION_DISCOUNT: 15,

    SERVICE_INSURANCE: 'insurance',
    SERVICE_REPACKING: 'repacking',

    ALL: 'all',
    READY_TO_SHIP: 'ready',
    ACTIVE: 'shipment',
    DELIVERED: 'delivered',
    EXCEPTION: 'exception',
    PENDING_PAYMENT: 'pending',
    READY_PENDING: 'pending',
    WAREHOUSE: {
        id: 1,
        lang: 'en',
        title: 'Parcel Hub',
        currency: 'USD',
        code: 'JFK',
        weight_unit: 'LBS',
        dimension_unit: 'Inches',
        country_code: 'US',
        state_code: 'JFK',
        city: 'Wilmington',
        phone: '+1 (844 274-6733)',
        state: 'DE',
        address: '183 Edgemoor Road',
        customs_threshold: '500',
        custom_duty: '5',
        postal_code: '19809',
        shipping_companies: '1',
        status: '1',
    },
}

export const USER_CONSTANTS = {
    BASIC: 'B',
    PREMIUM: 'P',
    BUSINESS: 'W',
}

export const MEMBERSHIP_CONSTANTS = {
    BASIC_NAME: 'Basic',
    PREMIUM_NAME: 'Premium',
    BUSINESS_NAME: 'Business',
    PLAN_DURATION_MONTH: 1,
    PLAN_DURATION_YEAR: 12,
    BASIC: 1,
    PREMIUM: 2,
    BUSINESS: 3,
    1: 'Basic',
    2: 'Premium',
    3: 'Business',
    PREMIUM_COST_MONTH: 8,
    BUSINESS_COST_MONTH: 10,
    PREMIUM_COST_YEAR: 48,
    BUSINESS_COST_YEAR: 120,
}

export const NUM_DAYS_BEFORE_EXPIRY = 30

export const PROMOS = {
    COUPON_TYPE_SUBSCRIPTION: 'subscription',
    COUPON_TYPE_SHIPPING: 'shipping',
    SUBSCRIPTION_TYPE_BOTH: 'both',
    SUBSCRIPTION_TYPE_PREMIUM: 'premium',
    SUBSCRIPTION_TYPE_BUSINESS: 'business',
    DISCOUNT_TYPE_FLAT: 'flat',
    DISCOUNT_TYPE_PERCENT: 'percent',
}

export const TOGGLES = {
    NEW_CONSOLIDATION_PAYMENT: 'PAYMENT_AFTER_CONSOLIDATION',
}

export const MENU_STATES = {
    CLOSE_MENU: 'closeMenu',
    OPEN_MENU: 'openMenu',
}

export const RATE_CALCULATOR_INPUTS = {
    WEIGHT_MIN_VALUE: '0.5',
    DIMENSION_MIN_VALUE: '1',
    DIMENSION_MAX_VALUE_CM: '100',
    DIMENSION_MAX_VALUE_INCH: '40',
}

export const DEFAULT_RATE = 'INTERNATIONAL_PRIORITY'

export const WEIGHT_TO_DIMENSION = {
    KG: 'kg',
    LB: 'lb',
    CM: 'cm',
    INCH: 'in',
}

export const getMaximumWeight = (unit = '') => {
    return unit !== '' ? (unit?.toLowerCase() === WEIGHT_TO_DIMENSION.KG ? 68 : 150) : 0
}

export const officeLocationsFlags = [AE, US, IQ, EG, TR, SA]

export const mobileStores = {
    ios: ios,
    android: android,
}

export const REQUESTS_CONSTANTS = {
    ALL: 'all',
    PENDING: 'Payment Pending',
    COMPLETED: 'Completed',
    PENDING_VAL: '1',
    COMPLETED_VAL: '2',
    EXCEPTION: 'exception',
    ALL_REQUESTS: 'All_Requests',
    SERVICE_FEE: 1,
    PRODUCT_NAME_CHARACTER_LENGTH: 255,
    PRODUCT_URL_CHARACTER_LENGTH: 2048,
    MAXIMUM_QUANTITY_COUNT: 100,
    OTHER_FIELDS_CHARACTER_LENGTH: 35,
    DEDUCT_REMARKS_ID: 5,
}

export const INCOMING_ORDERS_CONSTANTS = {
    INCOMING_ORDERS: 'Incoming Orders',
    PARCELS: 'Parcels',
    INCOMING_ORDERS_VAL: '1',
    PARCELS_VAL: '2',
}

export const VERIFY_REDIRECT_DELAY = 45

export const GLOBAL_CONSTANTS = {
    CURRENCY_VAL: '$',
    CURRENCY: 'USD',
    CHARACTER_LENGTH: 500,
    MINIMUM_COUNT: 1,
    SHIPPING_MINIMUM_COUNT: 0,
    MAXIMUM_PRICE_COUNT: 999999,
}

export const PROMOCODES = {
    ARAMCO: 'ARAMCOP',
}

export const CUSTOMER_CARE_EMAIL = 'customercare@shopiniworld.com'

export const UK_GOV = 'https://www.gov.uk/vat-rates'

export const REFERRAL_PREFIX = 'BC'

export const SERVICES_NO_PROMOCODE = [4, 14]

export const SERVICES_WITH_VAT = [14]

export const SPAIN_CUSTOMERS = ['SW13886', 'SW12571', 'SW11303', 'SW10261']

export const VIRTUAL_WAREHOUSES = [6]

export const ALLOWED_FRANCE_CUSTOMERS = ['SW16139']
