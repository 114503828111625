import React, { useState, useEffect } from 'react'
import { Link, Redirect, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useAuth } from '../../contexts/AuthContext'
import { CONTACT_US, DASHBOARD, HOMEPAGE, LOGIN_PAGE, THANK_YOU } from '../../constants/navigationConstants'

import { postData } from '../../utils/api'
import { useEmailAndPlanChecking } from '../../components/auth'
import { MEMBERSHIP_CONSTANTS, VERIFY_REDIRECT_DELAY } from '../../constants/constants'
import { Alert } from 'react-bootstrap'
import successIcon from './success.svg'
import useAnalytics from '../../analytics/useAnalytics'
import { screenEvent } from '../../analytics/constants'

export default (props) => {
    const { t } = useTranslation()
    const { authToken, userEmail, membershipPlanID } = useAuth()

    const [verificationLoading, setVerificationLoading] = useState(false)
    const [alert, setAlert] = useState({})
    const [seconds, setSeconds] = useState(60)

    const { checkIsEmailVerified } = useEmailAndPlanChecking()
    const paymentStatus = props?.location?.state?.paymentStatus
    const analytics = useAnalytics()

    const sendVerificationEmail = async () => {
        setVerificationLoading(true)
        let response = await postData('userService', '/v2/verify-email', {
            email: userEmail ? userEmail : localStorage.getItem('userEmail'),
        })
        if (response.status) {
            setAlert({ ...alert, message: response.message, severity: 'success' })
            setSeconds(60)
        } else {
            setAlert({ ...alert, message: 'Error Message', severity: 'danger' })
        }
        analytics.events.emailVerificationResendButton()
        setVerificationLoading(false)
    }

    useEffect(() => {
        if (seconds > -1) {
            setTimeout(() => setSeconds(seconds - 1), 1000)
            if (seconds < 35) {
                setAlert({})
            }
        } else {
            setSeconds('Done')
        }
    }, [seconds])

    useEffect(() => {
        analytics.events.screenVisited({ screen: screenEvent.VERIFICATION })
    }, [])

    return (
        <>
            {seconds < VERIFY_REDIRECT_DELAY && !verificationLoading && checkIsEmailVerified.status && (
                <Redirect to={DASHBOARD} />
            )}
            {seconds < VERIFY_REDIRECT_DELAY && !verificationLoading && checkIsEmailVerified.error && (
                <Redirect to={HOMEPAGE} />
            )}

            {seconds < VERIFY_REDIRECT_DELAY && !verificationLoading && checkIsEmailVerified.error && !authToken && (
                <Redirect to={{ pathname: HOMEPAGE, state: { from: 'authedRoute' } }} />
            )}

            <div className="global-section-container full-height-page-small-nav grey-background pt-5 pb-5">
                {paymentStatus &&
                    paymentStatus === 'payment-success' &&
                    Number(membershipPlanID) !== MEMBERSHIP_CONSTANTS.BASIC && (
                        <Alert className="w-50-mobile-100 py-3 mx-auto" variant="success">
                            <img src={successIcon} />
                            &nbsp;&nbsp;
                            {t('Your Payment was successful')}&nbsp;!
                        </Alert>
                    )}
                <div
                    className={
                        'container w-50-mobile-100 white-background login-modal-width ' +
                        (verificationLoading ? 'loading' : null)
                    }
                >
                    {alert?.code && (
                        <Alert variant={alert?.severity}>
                            {t(alert?.message)}&nbsp;
                            {alert.code && alert.code === 201 && (
                                <>
                                    <span>
                                        {seconds - VERIFY_REDIRECT_DELAY}&nbsp;{t('seconds')}
                                    </span>
                                    <Link
                                        to={{ pathname: HOMEPAGE, state: { from: 'authedRoute' } }}
                                        class="alert-link mx-2"
                                    >
                                        {t('Log-in')}
                                    </Link>
                                </>
                            )}
                        </Alert>
                    )}
                    {verificationLoading ? (
                        <div className="spinner-border" role="status" />
                    ) : (
                        <div className="">
                            <h2 className="">{t('Please verify your email')}</h2>

                            <p>{t('You are almost there! we sent an email to')}</p>
                            <p>
                                <strong>{userEmail || localStorage.getItem('userEmail')}</strong>
                            </p>
                            <p>{t('Click on the link to complete your signup')}</p>
                            <p>{t('Still can’t find the email?')}</p>
                            {alert.message !== 'Email verified successfully' &&
                                alert.message !== 'Email already verified' && (
                                    <form onSubmit={(e) => e.preventDefault()}>
                                        <input
                                            type="hidden"
                                            name="_token"
                                            value="AUxtZcsjFvsZHdf8BcZniic19ZKPO1cyJ9jufD4h"
                                        />
                                        <button
                                            className="btn btn-primary rounded"
                                            type="submit"
                                            disabled={seconds !== 'Done'}
                                            onClick={sendVerificationEmail}
                                        >
                                            {t('Resend email')}
                                        </button>
                                        {seconds !== 'Done' && (
                                            <span className="text-muted text-center font-weight-bold d-block my-4">
                                                {t('Click resend after')}
                                                {seconds === 60
                                                    ? '1 : 00'
                                                    : `0 : ${seconds >= 10 ? seconds : '0' + seconds}`}
                                            </span>
                                        )}
                                    </form>
                                )}

                            <p className="mt-3">
                                {t('Need help?')} <Link to={CONTACT_US}>{t('Contact Us')}</Link>
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
