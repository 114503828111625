import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
    ACCOUNT_ADDRESS_BOOK,
    ACCOUNT_PERSONAL_INFO,
    ACCOUNT_MEMBERSHIP_PROFILE,
    ACCOUNT_PAYMENT_OPTIONS,
    DASHBOARD,
    MEMBERSHIP_PLANS,
    MY_RATES,
    ACCOUNT_SHIPPER_ADDRESS_BOOK,
} from '../../constants/navigationConstants'
import { Link } from 'react-router-dom'
import BackButtonSvg from '../../assets/img/icons/backButton.svg'
import { useAuth } from '../../contexts/AuthContext'
import { getUserDetails } from '../../services/userInfoService'
import { MEMBERSHIP_CONSTANTS, PROMOCODES } from '../../constants/constants'
import { checkForLifeTimeSubscribtion } from '../../utils/string'
import * as moment from 'moment'
import useAnalytics from '../../analytics/useAnalytics'
import { screenEvent } from '../../analytics/constants'

const DashboardBackButton = () => {
    const { t, i18n } = useTranslation()
    return (
        <Link to={DASHBOARD} className="row align-items-center mb-2">
            {i18n.language === 'ar' && <img alt="..." src={BackButtonSvg} className="ml-3 mr-3 rotate-180"></img>}
            {i18n.language !== 'ar' && <img alt="..." src={BackButtonSvg} className="ml-3 mr-3"></img>}
            <p className="mb-0 text-start">{t('Dashboard')}</p>
        </Link>
    )
}

const AccountSettingsCard = (props) => {
    const { t, i18n } = useTranslation()
    const { icon, title, description } = props
    const iconClassName = `${icon} font-size-16 margin-top-25-percent`

    return (
        <div className="row w-100 h-100 justify-content-center border grey-background border-radius-10 p-3 flex-nowrap ml-0 mr-0">
            <div className="col-2 mt-1">
                <span className="badge rounded-green" data-inline="false">
                    <i className={iconClassName} />
                </span>
            </div>
            <div className="d-flex flex-column col-10">
                <p className="font-18"> {title} </p>
                <p className="font-18 text-grey"> {description} </p>
            </div>
        </div>
    )
}

export default () => {
    const { t, i18n } = useTranslation()
    const [user, setUser] = useState({
        planType: '',
        dateOfExpiry: '',
    })
    const { authToken, membershipPlanID } = useAuth()
    const styleArabicLeftFloat = i18n.language === 'ar' ? 'rtl' : ''
    const analytics = useAnalytics()

    const handleSetUserAnalyticsAttributes = async (userDetailsRes) => {
        const { expiry_at, is_yearly } = userDetailsRes?.plan_data ?? {}
        const userAttributes = {
            firstName: userDetailsRes.fname ?? '',
            lastName: userDetailsRes.lname ?? '',
            phone: userDetailsRes.phone ?? '',
            email: userDetailsRes.email ?? '',
            country: userDetailsRes.country ?? '',
            membershipType: userDetailsRes.membership_plan_id
                ? MEMBERSHIP_CONSTANTS[`${userDetailsRes.membership_plan_id}`]
                : '',
            planType: !!is_yearly ? 'Yearly' : 'Monthly',
            language: `${i18n.language}`.toUpperCase() ?? '',
            membershipExpiryDate: expiry_at ? moment(expiry_at).toDate() : undefined,
            referralCode: userDetailsRes.referral_code ?? '',
            verificationDate: userDetailsRes.email_verified_at
                ? moment(userDetailsRes.email_verified_at).toDate()
                : undefined,
            signupDate: userDetailsRes.created_at ? moment(userDetailsRes.created_at).toDate() : undefined,
            receiveWhatsappUpdates: !!userDetailsRes.receive_whatsapp,
            receiveEmailUpdates: !!userDetailsRes.receive_emails,
        }
        analytics.attributes.setAttributes(userAttributes)
    }

    async function retrieveUserDetails() {
        try {
            const res = await getUserDetails(authToken)
            setUser({
                planType: res?.membership?.name,
                dateOfExpiry: res?.plan_data?.expiry_at,
                promoCode: res?.plan_data?.promo_code === PROMOCODES?.ARAMCO ? res?.plan_data?.promo_code : null,
                forLifeTime: checkForLifeTimeSubscribtion(res?.plan_data?.expiry_at),
                template: res.template,
            })
            if (res) handleSetUserAnalyticsAttributes(res)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        retrieveUserDetails()
    }, [])

    useEffect(() => {
        analytics.events.screenVisited({ screen: screenEvent.ACCOUNT })
    }, [])

    return (
        <div className="global-section-container flex-col grey-background pt-5 pb-5 align-items-center">
            <div className="container white-background text-start row pt-3 pb-3">
                <div className="col-12 col-sm-12 col-md-8">
                    <DashboardBackButton />
                    <h1 className="sub-page-section-header">{t('My-account-settings')}</h1>
                    <p> {t('My-account-settings-description')}</p>

                    <div className="row mt-5">
                        {/* personal info */}
                        <div className="col-12 col-sm-12 col-md-6 w-100 mb-4 w-100">
                            <Link
                                to={ACCOUNT_PERSONAL_INFO}
                                className="text-decoration-none text-black"
                                onClick={() => {
                                    window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: 'smooth',
                                    })
                                }}
                            >
                                <AccountSettingsCard
                                    icon="bx bx-user"
                                    title={t('Personal-account-holder')}
                                    description={t('Personal-account-holder-description')}
                                />
                            </Link>
                        </div>
                        {/* payment options */}
                        <div className="col-12 col-sm-12 col-md-6 w-100 mb-4 w-100">
                            <Link
                                to={ACCOUNT_PAYMENT_OPTIONS}
                                className="text-decoration-none text-black"
                                onClick={() => {
                                    window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: 'smooth',
                                    })
                                }}
                            >
                                <AccountSettingsCard
                                    icon="bx bx-credit-card"
                                    title={t('Payment-options')}
                                    description={t('Payment-options-description')}
                                />
                            </Link>
                        </div>
                        {/* delivery address book */}
                        <div className="col-12 col-sm-12 col-md-6 w-100 mb-4">
                            <Link
                                to={ACCOUNT_ADDRESS_BOOK}
                                className="text-decoration-none text-black"
                                onClick={() => {
                                    window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: 'smooth',
                                    })
                                }}
                            >
                                <AccountSettingsCard
                                    icon="bx bx-map"
                                    title={t('Delivery-address')}
                                    description={t('Delivery-address-description')}
                                />
                            </Link>
                        </div>
                        {user.template > 0 ? (
                            /* source address book */
                            <div className="col-12 col-sm-12 col-md-6 w-100 mb-4">
                                <Link
                                    to={{ pathname: ACCOUNT_SHIPPER_ADDRESS_BOOK }}
                                    className="text-decoration-none text-black"
                                    onClick={() => {
                                        window.scrollTo({
                                            top: 0,
                                            left: 0,
                                            behavior: 'smooth',
                                        })
                                    }}
                                >
                                    <AccountSettingsCard
                                        icon="bx bx-map"
                                        title={t('Shipper-address')}
                                        description={t('Shipper-address-description')}
                                    />
                                </Link>
                            </div>
                        ) : null}
                        {/* membership profile */}
                        <div className="col-12 col-sm-12 col-md-6 w-100 mb-4">
                            <Link
                                to={ACCOUNT_MEMBERSHIP_PROFILE}
                                className="text-decoration-none text-black"
                                onClick={() => {
                                    window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: 'smooth',
                                    })
                                }}
                            >
                                <AccountSettingsCard
                                    icon="bx bx-id-card"
                                    title={t('My-membership-profile')}
                                    description={t('My-membership-profile-description')}
                                />
                            </Link>
                        </div>
                        {Number(membershipPlanID) > 1 && (
                            <div className="col-12 col-sm-12 col-md-6 w-100 mb-4">
                                <Link
                                    to={MY_RATES}
                                    className="text-decoration-none text-black"
                                    onClick={() => {
                                        window.scrollTo({
                                            top: 0,
                                            left: 0,
                                            behavior: 'smooth',
                                        })
                                    }}
                                >
                                    <AccountSettingsCard
                                        icon="bx bx-star"
                                        title={t('My-Rates')}
                                        description={t('My-Rates-description')}
                                    />
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-4">
                    {user.planType !== 'BASIC' ? (
                        <div className="d-flex flex-column text-center w-100 justify-content-center border grey-background border-radius-10 p-3 flex-nowrap ml-0 mr-0">
                            {i18n.language === 'en' && (
                                <p className="font-18">
                                    {' '}
                                    {t(user.planType)} {t('Plan')}
                                </p>
                            )}
                            {i18n.language === 'ar' && (
                                <p className="font-18">
                                    {' '}
                                    {t('Plan')} {t(user.planType)}{' '}
                                </p>
                            )}
                            <p className="font-18 ">
                                {t('Your current plan is')}
                                {t(user?.planType)},&nbsp;
                                {user?.forLifeTime ? t('Lifetime') : t('It will end on') + ' ' + user?.dateOfExpiry}
                            </p>
                        </div>
                    ) : (
                        <div className="d-flex flex-column">
                            <div className="d-flex w-100 flex-column text-center justify-content-center border grey-background border-radius-10 p-3 mb-3 flex-nowrap ml-0 mr-0">
                                {i18n.language === 'en' && (
                                    <p className="font-18">
                                        {' '}
                                        {t(user.planType)} {t('Plan')}
                                    </p>
                                )}
                                {i18n.language === 'ar' && (
                                    <p className="font-18">
                                        {' '}
                                        {t('Plan')} {t(user.planType)}{' '}
                                    </p>
                                )}
                                <p className="font-18 ">
                                    {t('Your current plan is')}
                                    {t(user.planType)}, {t('Upgrade your plan to enjoy with insurance')}
                                </p>
                            </div>
                            <div className={'mb-hide col-md-12 border border-radius-10 ' + styleArabicLeftFloat}>
                                <h4 className="paddingtop15 text-center">
                                    <i className="bx bxs-diamond font-size-20 green margin-right-15"></i>{' '}
                                    {t('PREMIUM Plan')}
                                </h4>
                                <ul className="planList font-weight-500">
                                    <li>
                                        <i className="fa fa-check-circle green"></i>{' '}
                                        <span className="padding-left-8">{t('Free address')}</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-check-circle green"></i>{' '}
                                        <span className="padding-left-8">{t('Items photo')}</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-check-circle green"></i>{' '}
                                        <span className="padding-left-8">{t('Free storage')}</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-check-circle green"></i>{' '}
                                        <span className="padding-left-8">
                                            {t('10 extra discount on all shipping fees')}
                                        </span>
                                    </li>
                                    <li>
                                        <i className="fa fa-check-circle green"></i>{' '}
                                        <span className="padding-left-8">{t('Package consolidation')}</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-check-circle green"></i>{' '}
                                        <span className="padding-left-8">{t('Item consolidation')}</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-check-circle green"></i>{' '}
                                        <span className="padding-left-8">{t('Package repacking')}</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-check-circle green"></i>{' '}
                                        <span className="padding-left-8">{t('Insurance')}</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-check-circle green"></i>{' '}
                                        <span className="padding-left-8">{t('Return service')}</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-check-circle green"></i>{' '}
                                        <span className="padding-left-8">{t('Regenerate invoice')}</span>
                                    </li>
                                    <li>
                                        <i className="fa fa-check-circle green"></i>{' '}
                                        <span className="padding-left-8">{t('247 support')}</span>
                                    </li>
                                </ul>
                                <div className="upgrade-btn-block">
                                    <Link
                                        className="btn rounded btn-green-smal33 font-size-16"
                                        to={MEMBERSHIP_PLANS}
                                        onClick={() => {
                                            window.scrollTo({
                                                top: 0,
                                                left: 0,
                                                behavior: 'smooth',
                                            })
                                        }}
                                    >
                                        {t('Upgrade Now')}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
