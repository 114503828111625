import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { MEMBERSHIP_PLANS } from '../../constants/navigationConstants'
import './styles.scss'

const Banner = () => {
    const { t, i18n } = useTranslation()
    const alignment = i18n.language === 'en' ? 'ltr' : 'rtl'

    return (
        <div className="w-100 bg-color-light-grey pb-2 pb-sm-0">
            <div className={`header-wrapper ${alignment === 'rtl' ? 'right-to-left' : 'left-to-right'}`}>
                <div
                    className={`${alignment === 'rtl' ? 'rtl' : ''} content px-5 px-sm-0 ${
                        alignment === 'rtl' ? 'text-right' : 'text-left'
                    }`}
                >
                    <div className="row">
                        <div
                            className={`col-12 pb-3 header-title blue-color ${
                                alignment !== 'rtl' ? 'title-mw font-weight-bold' : ''
                            }`}
                        >
                            {t('Still Wondering')}
                            <br />
                            {t('How much does it cost')}
                        </div>
                    </div>
                    <div className="row pb-3">
                        <div className={`col-12 header-subtitle ${alignment === 'rtl' ? 'text-white ar' : ''}`}>
                            {t('Easy peasy with')}
                            {i18n.language === 'en' ? ' our ' : ' مع '}
                            <span className={`rate-calculator ${alignment === 'rtl' ? 'font-weight-normal' : ''}`}>
                                {t('Rate Calculator')}
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Link role={'button'} to={MEMBERSHIP_PLANS} className="d-inline-block">
                                <button className="btn btn-primary font-weight-bold">{t('Sign up - c')}</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner
