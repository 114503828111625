import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import { importFromLocalStorageKey } from './utils/localStorageApi'

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .use(HttpApi)
    .init({
        lng: importFromLocalStorageKey('i18nextLng') || 'en',
        fallbackLng: 'en',
        debug: true,
        detection: {
            order: ['localStorage', 'queryString', 'path', 'cookie'],
            caches: ['cookie'],
        },
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: '/locales/{{lng}}/translation.json',
        },
        react: {
            bindI18n: 'languageChanged',
            bindI18nStore: '',
            transEmptyNodeValue: '',
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
            useSuspense: true,
        },
    })

export default i18n
