import Footer from './Footer'
import Header from './Header'
import HowToCalculate from './HowToCalculate'
import IllustrationFigures from './IllustrationFigures'
import Introduction from './Introduction'
import styles from './styles.module.scss'

const TrueWeight = () => {
    return (
        <div className={styles['font']}>
            <div className="mb-3">
                <Header />
            </div>

            <div className={`mb-3 container ${styles['container-overrides']}`}>
                <Introduction />
            </div>

            <div className={`mb-4 pb-1 container ${styles['container-overrides']}`}>
                <IllustrationFigures />
            </div>

            <div className={`mb-5 container ${styles['container-overrides']}`}>
                <HowToCalculate />
            </div>

            <div className="bg-color-light-grey">
                <Footer />
            </div>
        </div>
    )
}

export default TrueWeight
