import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { postData } from '../../utils/api'
import { useAuth } from '../../contexts/AuthContext'
import * as NAVIGATION_CONSTANTS from '../../constants/navigationConstants'
import _, { isEmpty } from 'lodash'
import { PROMOS } from '../../constants/constants'

const AmazonPayButton = ({
    setLoading,
    creditCardDetails,
    toggleSaveCard,
    setLoaderMessage,
    totalPayment,
    parcels,
    paymentType,
    addOnServices,
    paymentRequestData,
    paymentDetails,
    setIsAmazonPay,
    mobileAuthToken = '',
    onPaymentCompletion = null,
    cardType,
    walletAmount,
    walletBalance,
    analyticsEventAttributes,
}) => {
    const { t, i18n } = useTranslation()
    const { authToken } = useAuth()
    useEffect(() => {
        // if (creditCardDetails?.name && creditCardDetails?.number && creditCardDetails?.name !== "" && creditCardDetails?.number !== "" ) {
        if (setIsAmazonPay) {
            setIsAmazonPay(true)
        }
        // }
    }, [])

    useEffect(
        async () => {
            if (
                totalPayment !== 0 &&
                creditCardDetails?.name &&
                creditCardDetails?.name !== '' &&
                cardType === 'AMAZON'
            ) {
                var data =
                    paymentType === 'shipping'
                        ? {
                              parcel_ids: _.map(parcels, 'id'),
                              parcel_uuids: _.map(parcels, 'uuid'),
                              promo_code: paymentRequestData?.promo_code_discount?.is_valid
                                  ? paymentRequestData?.promo_code_discount?.discount_code
                                  : paymentRequestData?.promo_code || null,
                              country_id: paymentRequestData?.address?.country,
                              add_on_services: addOnServices,
                              user_address_id: paymentRequestData?.address?.id,
                              total_amount: paymentRequestData?.total,
                              shipping_service: paymentRequestData?.service,
                              shipping_company_id: paymentRequestData?.shipping_company_id,
                              card: {
                                  name: creditCardDetails.name,
                                  digits: creditCardDetails.number,
                              },
                              wallet_amount: walletAmount,
                          }
                        : paymentType === 'consolidation'
                          ? {
                                parcel_ids: paymentRequestData?.parcel_ids,
                                parcel_uuids: paymentRequestData?.parcel_uuids,
                                promo_code: paymentRequestData?.promo_code || null,
                                total_amount: paymentRequestData?.amount,
                                is_consolidation: true,
                                shipping_service: paymentRequestData?.shipping_service,
                                shipping_company_id: paymentRequestData?.shipping_company_id,
                                card: {
                                    name: creditCardDetails.name,
                                    digits: creditCardDetails.number,
                                },
                                user_address_id: paymentRequestData?.user_address?.id
                                    ? paymentRequestData?.user_address?.id
                                    : null,
                                country_id: paymentRequestData?.user_address?.country || null,
                                wallet_amount: walletAmount,
                            }
                          : paymentType === 'buy-it'
                            ? {
                                  ...paymentRequestData,
                                  total_amount: paymentRequestData?.card_amount,
                                  card: {
                                      name: creditCardDetails.name,
                                      digits: creditCardDetails.number,
                                  },
                              }
                            : paymentType === 'add-card'
                              ? {
                                    card: {
                                        name: creditCardDetails.name,
                                        digits: creditCardDetails.number,
                                    },
                                }
                              : paymentType === 'membership'
                                ? {
                                      total_amount: paymentDetails?.promo_code
                                          ? paymentDetails?.discountedTotal
                                          : paymentDetails?.grandTotal,
                                      card: {
                                          name: creditCardDetails.name,
                                          digits: creditCardDetails.number,
                                      },
                                      discount: paymentDetails?.promo_code
                                          ? {
                                                original_amount: paymentDetails?.discount?.original_amount || 0,
                                                discounted_amount: paymentDetails?.discount?.discounted_amount || 0,
                                                promo_code_value: paymentDetails?.discount?.promo_code_value || 0,
                                                promo_code_type: paymentDetails?.discount?.promo_code_type || '',
                                                promo_code: paymentDetails?.promo_code || '',
                                            }
                                          : null,
                                      is_yearly: paymentDetails?.is_yearly,
                                  }
                                : {
                                      total_amount: paymentDetails?.promo_code
                                          ? paymentDetails?.discountedTotal
                                          : paymentDetails?.grandTotal,
                                      card: {
                                          name: creditCardDetails.name,
                                          digits: creditCardDetails.number,
                                      },
                                      discount: paymentDetails?.promo_code
                                          ? {
                                                original_amount: paymentDetails?.discount?.original_amount || 0,
                                                discounted_amount: paymentDetails?.discount?.discounted_amount || 0,
                                                promo_code_value: paymentDetails?.discount?.promo_code_value || 0,
                                                promo_code_type: paymentDetails?.discount?.promo_code_type || '',
                                                promo_code: paymentDetails?.promo_code || '',
                                            }
                                          : null,
                                      is_update: true,
                                      membership_plan_id: paymentRequestData?.membership_plan_id,
                                      is_yearly: paymentDetails?.is_yearly,
                                  }
                if (analyticsEventAttributes) data['analytics_event'] = analyticsEventAttributes
                data['payment_method'] = 'Amazon'
                data['wallet_balance'] = walletBalance ?? 0
                if (!isEmpty(paymentRequestData) || paymentType === 'add-card') {
                    let token = authToken && authToken !== '' ? authToken : mobileAuthToken
                    let paymentDataType = paymentType === 'shipping' ? 'parcel' : paymentType
                    const response = await postData(
                        'paymentService',
                        '/amazon/signature?type=' + paymentDataType,
                        data,
                        token,
                    )
                    if (response?.payload && response.status === true) {
                        if (document.getElementById('AmazonPayButton')) {
                            document.getElementById('AmazonPayButton')?.remove()
                        }
                        const amazonPayBtn = document?.createElement('div')
                        amazonPayBtn.id = 'AmazonPayButton'
                        document.getElementById('amazonPayContainer').appendChild(amazonPayBtn)
                        window.amazon.Pay.renderButton('#AmazonPayButton', {
                            // set checkout environment
                            merchantId: process?.env?.REACT_APP_AMAZON_PAY_MERCHANT_ID,
                            publicKeyId: process?.env?.REACT_APP_AMAZON_PAY_PUBLIC_KEY,
                            ledgerCurrency: 'USD',
                            // customize the buyer experience
                            checkoutLanguage: 'en_US',
                            productType: 'PayOnly',
                            placement: 'Checkout',
                            buttonColor: 'Gold',
                            // configure Create Checkout Session request
                            createCheckoutSessionConfig: {
                                payloadJSON: response?.payload?.payload,
                                signature: response?.payload?.signature, // signature generated in step 3
                            },
                        })
                    }
                } else {
                    document.getElementById('AmazonPayButton').remove()
                }
            }
        },
        [
            totalPayment,
            creditCardDetails?.name,
            creditCardDetails?.number,
            paymentDetails,
            walletAmount,
            JSON.stringify(paymentRequestData),
        ],
        walletBalance,
    )

    return (
        <Row>
            <Col lg={8}></Col>
            <Col id="amazonPayContainer" lg={4}></Col>
        </Row>
    )
}

export default AmazonPayButton
