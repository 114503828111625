import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './banner.css'
import CarouselImageSet from '../../../pages/homepage/shopUsBrandsSection/carouselImageSet'
import { useMediaQuery } from 'react-responsive'
import ReactFlagsSelect from 'react-flags-select'
import { useAuth } from '../../../contexts/AuthContext'
import { Link } from 'react-router-dom'
import { DASHBOARD } from '../../../constants/navigationConstants'
import BackButtonSvg from '../../../assets/img/icons/backButton.svg'

const commonContainer = (content, alignment, language, isMobile, t) => (
    <div
        className={`${alignment} position-absolute banner-text-position-${language}${isMobile ? '-mobile container' : ''}`}
    >
        {content}
    </div>
)

function Banner() {
    const { t, i18n } = useTranslation()
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

    const alignment = i18n.language === 'en' ? 'ltr' : 'rtl'
    const { warehousesList, setWarehouseId, warehouseId } = useAuth()
    const [imagesSrc, setImagesSrc] = useState([])
    const createWarehousesLabels = () => {
        const customLabels = {}

        warehousesList?.forEach((w) => {
            return (customLabels[w?.country] = {
                primary: w?.name,
                secondary: w?.active ? null : (
                    <div className="mx-1 coming-soon orange-bg float-end">{t('Coming soon')}</div>
                ),
            })
        })

        return customLabels
    }

    const bannerTexts = {
        ['1']: commonContainer(
            <>
                <small>
                    <Link to={DASHBOARD} className={`row align-items-center mb-2`}>
                        {i18n.language === 'ar' && (
                            <img alt="..." src={BackButtonSvg} className="ml-3 mr-3 rotate-180" />
                        )}
                        {i18n.language !== 'ar' && <img alt="..." src={BackButtonSvg} className="ml-3 mr-3" />}
                        <span className="mb-0 text-start">{t('Dashboard')}</span>
                    </Link>
                </small>
                <div
                    className={`${isMobile ? 'd-block text-start mb-5 mx-2' : 'd-flex justify-content-center align-items-center'}`}
                >
                    <h1 className={`xl-font mb-0`}>{t('Incoming Orders')}</h1>
                    <ReactFlagsSelect
                        countries={warehousesList?.map((warehouse) => warehouse.country)}
                        customLabels={createWarehousesLabels()}
                        className={`ltr-direction h-full mt-2 ${isMobile ? 'mx-auto' : 'mx-3'}`}
                        selected={warehousesList.find((w) => w.id === warehouseId)?.country}
                        onSelect={(code) => {
                            if (warehousesList?.find((w) => w.country === code)?.active) {
                                setWarehouseId(warehousesList?.find((w) => w.country === code)?.id)
                            } else {
                                setWarehouseId(1)
                            }
                        }}
                        onFocus={(e) => {
                            if (e.target.autocomplete) {
                                e.target.autocomplete = 'noautocomplete'
                            }
                        }}
                        showSelectedLabel
                        showSecondarySelectedLabel
                        placeholder={'Select a warehouse'}
                        alignOptionsToRight
                        fullWidth
                    />
                </div>
            </>,
            alignment,
            i18n.language,
            isMobile,
            t,
        ),
    }

    useEffect(() => {
        const svgs = async () => {
            const reqSvgs = await require.context('./', true, /\.svg$/)
            return reqSvgs.keys().map((path, index) => ({
                path,
                file: reqSvgs(path),
                imageClass: `image${index}`,
                bannerText: bannerTexts[path?.replaceAll('./', '').split(isMobile ? '-' : '.')[0]],
            }))
        }

        async function getSvgs() {
            const x = await svgs()
            setImagesSrc(x)
        }
        getSvgs()
    }, [setImagesSrc, t, isMobile, warehouseId])

    return (
        <CarouselImageSet
            isMobile={isMobile}
            imagesSrc={
                isMobile
                    ? imagesSrc.filter(
                          (imageSrc) =>
                              imageSrc?.path?.includes('mobile') &&
                              !imageSrc?.path?.includes(`${i18n.language === 'ar' ? 'en' : 'ar'}`),
                      )
                    : imagesSrc.filter(
                          (imageSrc) =>
                              !imageSrc?.path?.includes('mobile') &&
                              !imageSrc?.path?.includes(`${i18n.language === 'ar' ? 'en' : 'ar'}`),
                      )
            }
            banner={true}
            source="incomingOrders"
        />
    )
}

export default Banner
