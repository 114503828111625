import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

const Steps = () => {
    const { t, i18n } = useTranslation()
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' })
    const reqSec = require.context('./', true, /sec-[0-9](-en|-ar)?.png$/)
    const loadStep = (imageFile) => reqSec(`${imageFile}`).default
    const secDescriptions = {
        'sec-1-strong': 'Find the unique code on your dashboard',
        'sec-1-light': 'once you are logged into your account',
        'sec-2-strong': 'Share that code with your friends and family',
        'sec-2-light': 'so they can use upon registration',
        'sec-3-strong': 'Apply the referral code',
        'sec-4-strong': 'You start winning once they start shipping',
        'sec-4-part-1': 'Once your friend starts shipping a',
        'sec-4-part-2': 'USD 5',
        'sec-4-part-3': 'credit will be added to your',
        'sec-4-part-4': 'Wallet',
        'sec-4-part-5': 'so you can use on',
    }
    return (
        <div className="position-relative text-center mt-3">
            <span className="color-primary">{t('Here is how it works')}</span>
            {reqSec
                .keys()
                ?.filter((img) => img.includes(i18n?.language) || ['./sec-4.png', './sec-2.png']?.includes(img))
                ?.map((sec, index) => {
                    const displayDirection = index % 2 === 0 ? 'end' : 'start'
                    return (
                        <>
                            <div className={`${i18n.language === 'en' ? 'sec-en-' : 'sec-ar-'}${displayDirection}`}>
                                {i18n.language === 'en' ? (
                                    index % 2 === 0 ? (
                                        <>
                                            <span className="sec-desc">
                                                {t(
                                                    `${['./sec-4.png', './sec-2.png']?.includes(sec) ? secDescriptions[sec?.replace('./', '')?.replace('.png', '-strong')] : secDescriptions[sec?.replace('./', '')?.replace('-en.png', '-strong')]}`,
                                                )}
                                            </span>
                                            <br />
                                            {sec !== './sec-3-en.png' && (
                                                <span className="sec-sub-desc">
                                                    {sec === './sec-4.png' ? (
                                                        <>
                                                            <span>{t(`${secDescriptions['sec-4-part-1']}`)}</span>&nbsp;
                                                            <strong>{t(`${secDescriptions['sec-4-part-2']}`)}</strong>
                                                            &nbsp;
                                                            <span>{t(`${secDescriptions['sec-4-part-3']}`)}</span>&nbsp;
                                                            <strong>{t(`${secDescriptions['sec-4-part-4']}`)}</strong>
                                                            &nbsp;
                                                            <span>{t(`${secDescriptions['sec-4-part-5']}`)}</span>&nbsp;
                                                            <strong>
                                                                {t('Buy it for me service')
                                                                    .replace('service :', '')
                                                                    .replace(':', '')}
                                                            </strong>{' '}
                                                            &nbsp; {t('service')}
                                                        </>
                                                    ) : (
                                                        t(
                                                            `${['./sec-4.png', './sec-2.png']?.includes(sec) ? secDescriptions[sec?.replace('./', '')?.replace('.png', '-light')] : secDescriptions[sec?.replace('./', '')?.replace('-en.png', '-light')]}`,
                                                        )
                                                    )}
                                                </span>
                                            )}
                                            <img
                                                className={`${isMobile ? 'w-100' : 'img-fluid'}`}
                                                src={loadStep(sec)}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <img
                                                className={`${isMobile ? (sec === './sec-2.png' ? 'w-50' : 'w-75') : 'img-fluid'} sec-img`}
                                                src={loadStep(sec)}
                                            />
                                            <span className="sec-desc">
                                                {t(
                                                    `${['./sec-4.png', './sec-2.png']?.includes(sec) ? secDescriptions[sec?.replace('./', '')?.replace('.png', '-strong')] : secDescriptions[sec?.replace('./', '')?.replace('-en.png', '-strong')]}`,
                                                )}
                                            </span>
                                            <br />
                                            {sec !== './sec-3-en.png' && (
                                                <span className="sec-sub-desc">
                                                    {sec === './sec-4.png' ? (
                                                        <>
                                                            <span>{t(`${secDescriptions['sec-4-part-1']}`)}</span>&nbsp;
                                                            <strong>{t(`${secDescriptions['sec-4-part-2']}`)}</strong>
                                                            &nbsp;
                                                            <span>{t(`${secDescriptions['sec-4-part-3']}`)}</span>&nbsp;
                                                            <strong>{t(`${secDescriptions['sec-4-part-4']}`)}</strong>
                                                            &nbsp;
                                                            <span>{t(`${secDescriptions['sec-4-part-5']}`)}</span>&nbsp;
                                                            <strong>
                                                                {t('Buy it for me service')
                                                                    .replace('service :', '')
                                                                    .replace(':', '')}
                                                            </strong>{' '}
                                                            &nbsp; {t('service')}
                                                        </>
                                                    ) : (
                                                        t(
                                                            `${['./sec-4.png', './sec-2.png']?.includes(sec) ? secDescriptions[sec?.replace('./', '')?.replace('.png', '-light')] : secDescriptions[sec?.replace('./', '')?.replace('-en.png', '-light')]}`,
                                                        )
                                                    )}
                                                </span>
                                            )}
                                        </>
                                    )
                                ) : index % 2 === 0 ? (
                                    <>
                                        <span
                                            className={`sec-desc ${isMobile && sec === './sec-2.png' ? 'right-32' : ''}`}
                                        >
                                            {t(
                                                `${['./sec-4.png', './sec-2.png']?.includes(sec) ? secDescriptions[sec?.replace('./', '')?.replace('.png', '-strong')] : secDescriptions[sec?.replace('./', '')?.replace('-ar.png', '-strong')]}`,
                                            )}
                                        </span>
                                        <br />
                                        {sec !== './sec-3-ar.png' && (
                                            <span className={`sec-sub-desc ${sec === './sec-4.png' ? 'top-220' : ''}`}>
                                                {sec === './sec-4.png' ? (
                                                    <>
                                                        <span>{t(`${secDescriptions['sec-4-part-1']}`)}</span>&nbsp;
                                                        <strong>{t(`${secDescriptions['sec-4-part-2']}`)}</strong>&nbsp;
                                                        <span>{t(`${secDescriptions['sec-4-part-3']}`)}</span>&nbsp;
                                                        <strong>{t(`${secDescriptions['sec-4-part-4']}`)}</strong>&nbsp;
                                                        <span>{t(`${secDescriptions['sec-4-part-5']}`)}</span>&nbsp;
                                                        <strong>
                                                            {t('Buy it for me service')
                                                                .replace('service :', '')
                                                                .replace(':', '')}
                                                        </strong>{' '}
                                                        &nbsp; {t('service')}
                                                    </>
                                                ) : (
                                                    t(
                                                        `${['./sec-4.png', './sec-2.png']?.includes(sec) ? secDescriptions[sec?.replace('./', '')?.replace('.png', '-light')] : secDescriptions[sec?.replace('./', '')?.replace('-ar.png', '-light')]}`,
                                                    )
                                                )}
                                            </span>
                                        )}
                                        <img className={`${isMobile ? 'w-100' : 'img-fluid'}`} src={loadStep(sec)} />
                                    </>
                                ) : (
                                    <>
                                        <img
                                            className={`${isMobile ? (sec === './sec-2.png' ? 'w-50' : 'w-75') : 'img-fluid'} sec-img`}
                                            src={loadStep(sec)}
                                        />
                                        <span
                                            className={`sec-desc ${isMobile && sec === './sec-2.png' ? 'right-32' : ''}`}
                                        >
                                            {t(
                                                `${['./sec-4.png', './sec-2.png']?.includes(sec) ? secDescriptions[sec?.replace('./', '')?.replace('.png', '-strong')] : secDescriptions[sec?.replace('./', '')?.replace('-ar.png', '-strong')]}`,
                                            )}
                                        </span>
                                        <br />
                                        {sec !== './sec-3-ar.png' && (
                                            <span className={`sec-sub-desc ${sec === './sec-4.png' ? 'top-220' : ''}`}>
                                                {sec === './sec-4.png' ? (
                                                    <>
                                                        <span>{t(`${secDescriptions['sec-4-part-1']}`)}</span>&nbsp;
                                                        <strong>{t(`${secDescriptions['sec-4-part-2']}`)}</strong>&nbsp;
                                                        <span>{t(`${secDescriptions['sec-4-part-3']}`)}</span>&nbsp;
                                                        <strong>{t(`${secDescriptions['sec-4-part-4']}`)}</strong>&nbsp;
                                                        <span>{t(`${secDescriptions['sec-4-part-5']}`)}</span>&nbsp;
                                                        <strong>
                                                            {t('Buy it for me service')
                                                                .replace('service :', '')
                                                                .replace(':', '')}
                                                        </strong>{' '}
                                                        &nbsp; {t('service')}
                                                    </>
                                                ) : (
                                                    t(
                                                        `${['./sec-4.png', './sec-2.png']?.includes(sec) ? secDescriptions[sec?.replace('./', '')?.replace('.png', '-light')] : secDescriptions[sec?.replace('./', '')?.replace('-ar.png', '-light')]}`,
                                                    )
                                                )}
                                            </span>
                                        )}
                                    </>
                                )}
                            </div>
                        </>
                    )
                })}
        </div>
    )
}

export default Steps
