import Contents from './contents'
import SmartTrackingImage from './image'
import styles from './styles.module.scss'

const SmartTracking = () => {
    return (
        <div className={`${styles['schedule-a-call']}`}>
            <div className="d-flex flex-column-reverse flex-lg-row justify-content-between align-items-start flex-wrap">
                <div className={styles['image']}>
                    <SmartTrackingImage />
                </div>
                <div className={styles['contents']}>
                    <Contents />
                </div>
            </div>
        </div>
    )
}

export default SmartTracking
