import { useMemo } from 'react'
import { event, eventAttribute, userAttribute } from '../../constants'
import { getAnalytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics'
import useFirebase from '../../../firebase/useFirebase'
import * as _ from 'lodash'

const useGoogleAnalytics = () => {
    const app = useFirebase()
    const analytics = app ? getAnalytics(app) : undefined

    const events = useMemo(
        () => ({
            signupButton: () => {
                if (analytics) logEvent(analytics, event.SINGUP_BUTTON)
            },

            signinButton: () => {
                if (analytics) logEvent(analytics, event.SIGNIN_BUTTON)
            },

            membershipSelected: ({ membershipType, planType, amount, action }) => {
                const attributes = {
                    [eventAttribute.MEMBERSHIP_TYPE]: membershipType,
                    [eventAttribute.PLAN_TYPE]: planType ?? '',
                    [eventAttribute.AMOUNT]: amount,
                    [eventAttribute.ACTION]: action,
                }
                if (analytics) logEvent(analytics, event.MEMBERSHIP_SELECTED, attributes)
            },

            userLogin: ({ userPin }) => {
                if (analytics) setUserId(analytics, userPin)
            },

            userLoggedIn: ({ userPin, provider }) => {
                if (analytics) setUserId(analytics, userPin)
                const attributes = {
                    [eventAttribute.PROVIDER]: provider,
                }
                if (analytics) logEvent(analytics, event.USER_LOGGED_IN, attributes)
            },

            userLogout: () => {
                if (analytics) {
                    const initialAttributes = {
                        [userAttribute.FIRST_NAME]: null,
                        [userAttribute.LAST_NAME]: null,
                        [userAttribute.PHONE]: null,
                        [userAttribute.EMAIL]: null,
                        [userAttribute.COUNTRY]: null,
                        [userAttribute.MEMBERSHIP_TYPE]: null,
                        [userAttribute.PLAN_TYPE]: null,
                        [userAttribute.MEMBERSHIP_EXPIRY_DATE]: null,
                        [userAttribute.REFERRAL_CODE]: null,
                        [userAttribute.VERIFICATION_DATE]: null,
                        [userAttribute.SIGNUP_DATE]: null,
                    }
                    logEvent(analytics, event.LOGOUT)
                    setUserProperties(analytics, initialAttributes)
                    setUserId(analytics, null)
                }
            },

            registrationCompleted: ({
                firstName,
                lastName,
                phoneNumber,
                countryCode,
                email,
                referralCode,
                membershipType,
                receiveMarketing,
                receiveWhatsapp,
                planType,
            }) => {
                const attributes = {
                    [eventAttribute.FIRST_NAME]: firstName,
                    [eventAttribute.LAST_NAME]: lastName,
                    [eventAttribute.PHONE_NUMBER]: phoneNumber,
                    [eventAttribute.COUNTRY_CODE]: countryCode,
                    [eventAttribute.EMAIL]: email,
                    [eventAttribute.REFERRAL_CODE]: referralCode ?? '',
                    [eventAttribute.MEMBERSHIP_TYPE]: membershipType,
                    [eventAttribute.RECEIVE_MARKETING]: receiveMarketing,
                    [eventAttribute.PLAN_TYPE]: planType ?? '',
                    [eventAttribute.RECEIVE_WHATSAPP]: receiveWhatsapp,
                }
                if (analytics) logEvent(analytics, event.REGISTRATION_COMPLETED, attributes)
            },

            membershipPromoApplyCompleted: ({ promoCode, valid, source, membershipType, planType }) => {
                const attributes = {
                    [eventAttribute.PROMO_CODE]: promoCode,
                    [eventAttribute.VALID]: valid,
                    [eventAttribute.SOURCE]: source,
                    [eventAttribute.MEMBERSHIP_TYPE]: membershipType,
                    [eventAttribute.PLAN_TYPE]: planType,
                }
                if (analytics) logEvent(analytics, event.MEMBERSHIP_PROMO_APPLY_COMPLETED, attributes)
            },

            membershipPromoCancelButton: ({ source, membershipType, planType, promoCode }) => {
                const attributes = {
                    [eventAttribute.SOURCE]: source,
                    [eventAttribute.MEMBERSHIP_TYPE]: membershipType,
                    [eventAttribute.PLAN_TYPE]: planType,
                    [eventAttribute.PROMO_CODE]: promoCode,
                }
                if (analytics) logEvent(analytics, event.MEMBERSHIP_PROMO_CANCEL_BUTTON, attributes)
            },

            membershipPromoSubscribeButton: ({
                membershipType,
                planType,
                subtotal,
                promoCode,
                discountAmount,
                totalAmount,
                source,
            }) => {
                const attributes = {
                    [eventAttribute.MEMBERSHIP_TYPE]: membershipType,
                    [eventAttribute.PLAN_TYPE]: planType ?? '',
                    [eventAttribute.SUBTOTAL]: subtotal,
                    [eventAttribute.PROMO_CODE]: promoCode ?? '',
                    [eventAttribute.DISCOUNT_AMOUNT]: discountAmount ?? 0,
                    [eventAttribute.TOTAL_AMOUNT]: totalAmount,
                    [eventAttribute.SOURCE]: source,
                }
                if (analytics) logEvent(analytics, event.MEMBERSHIP_PROMO_SUBSCRIBE_BUTTON, attributes)
            },

            membershipPaymentStarted: ({
                membershipType,
                planType,
                subtotal,
                promoCode,
                discountAmount,
                totalAmount,
                source,
            }) => {
                const attributes = {
                    [eventAttribute.MEMBERSHIP_TYPE]: membershipType,
                    [eventAttribute.PLAN_TYPE]: planType ?? '',
                    [eventAttribute.SUBTOTAL]: subtotal,
                    [eventAttribute.PROMO_CODE]: promoCode ?? '',
                    [eventAttribute.DISCOUNT_AMOUNT]: discountAmount ?? 0,
                    [eventAttribute.TOTAL_AMOUNT]: totalAmount,
                    [eventAttribute.SOURCE]: source,
                }
                if (analytics) logEvent(analytics, event.MEMBERSHIP_PAYMENT_STARTED, attributes)
            },

            membershipPaymentCompleted: ({
                membershipType,
                planType,
                subtotal,
                promoCode,
                discountAmount,
                totalAmount,
                paymentMethod,
                source,
            }) => {
                const attributes = {
                    [eventAttribute.MEMBERSHIP_TYPE]: membershipType,
                    [eventAttribute.PLAN_TYPE]: planType ?? '',
                    [eventAttribute.SUBTOTAL]: subtotal,
                    [eventAttribute.PROMO_CODE]: promoCode ?? '',
                    [eventAttribute.DISCOUNT_AMOUNT]: discountAmount ?? 0,
                    [eventAttribute.TOTAL_AMOUNT]: totalAmount,
                    [eventAttribute.PAYMENT_METHOD]: paymentMethod ?? '',
                    [eventAttribute.SOURCE]: source,
                }
                if (analytics) logEvent(analytics, event.MEMBERSHIP_PAYMENT_COMPLETED, attributes)
            },

            membershipPaymentFailure: ({ reason, paymentMethod, totalAmount, planType, membershipType, source }) => {
                const attributes = {
                    [eventAttribute.REASON]: reason,
                    [eventAttribute.PAYMENT_METHOD]: paymentMethod ?? '',
                    [eventAttribute.TOTAL_AMOUNT]: totalAmount,
                    [eventAttribute.PLAN_TYPE]: planType ?? '',
                    [eventAttribute.MEMBERSHIP_TYPE]: membershipType,
                    [eventAttribute.SOURCE]: source,
                }
                if (analytics) logEvent(analytics, event.MEMBERSHIP_PAYMENT_FAILURE, attributes)
            },

            verifyEmailCompleted: ({ status }) => {
                const attributes = {
                    [eventAttribute.STATUS]: status,
                }
                if (analytics) logEvent(analytics, event.VERIFY_EMAIL_COMPLETED, attributes)
            },

            emailVerificationResendButton: () => {
                if (analytics) logEvent(analytics, event.EMAIL_VERIFICATION_SEND_BUTTON)
            },

            forgotPasswordButton: () => {
                if (analytics) logEvent(analytics, event.FORGOT_PASSWORD_BUTTON)
            },

            sendResetLinkButton: ({ email }) => {
                const attributes = {
                    [eventAttribute.EMAIL]: email,
                }
                if (analytics) logEvent(analytics, event.SEND_RESET_LINK_BUTTON, attributes)
            },

            shippingShipSelectedParcelsButton: ({ numberOfParcels, warehouse }) => {
                const attributes = {
                    [eventAttribute.NO_OF_PARCELS]: numberOfParcels,
                    [eventAttribute.WAREHOUSE]: warehouse,
                }
                if (analytics) logEvent(analytics, event.SHIPPING_SHIP_SELECTED_PARCELS_BUTTON, attributes)
            },

            shippingApplyPromoCompleted: ({ promoCode, valid }) => {
                const attributes = {
                    [eventAttribute.PROMO_CODE]: promoCode,
                    [eventAttribute.VALID]: valid,
                }
                if (analytics) logEvent(analytics, event.SHIPPING_APPLY_PROMO_COMPLETED, attributes)
            },

            shippingCancelPromoButton: ({ promoCode }) => {
                const attributes = {
                    [eventAttribute.PROMO_CODE]: promoCode,
                }
                if (analytics) logEvent(analytics, event.SHIPPING_CANCEL_PROMO_BUTTON, attributes)
            },

            shippingNoConsolidationCreditedCompleted: ({
                country,
                city,
                shippingCompany,
                extraServices,
                promoCode,
                parcelIds,
                totalChargeableWeight,
                shippingFees,
                discountAmount,
                subtotal,
                totalAmount,
                tax,
                warehouse,
            }) => {
                const attributes = {
                    [eventAttribute.COUNTRY]: country,
                    [eventAttribute.CITY]: city,
                    [eventAttribute.SHIPPING_COMPANY]: shippingCompany,
                    [eventAttribute.EXTRA_SERVICES]: extraServices,
                    [eventAttribute.PROMO_CODE]: promoCode ?? '',
                    [eventAttribute.PARCEL_IDS]: parcelIds,
                    [eventAttribute.TOTAL_CHARGEABLE_WEIGHT]: totalChargeableWeight,
                    [eventAttribute.SHIPPING_FEES]: shippingFees,
                    [eventAttribute.DISCOUNT_AMOUNT]: discountAmount ?? 0,
                    [eventAttribute.SUBTOTAL]: subtotal,
                    [eventAttribute.TOTAL_AMOUNT]: totalAmount,
                    [eventAttribute.TAX]: tax,
                    [eventAttribute.WAREHOUSE]: warehouse,
                }
                if (analytics) logEvent(analytics, event.SHIPPING_NO_CONSOLIDATION_CREDITED_COMPLETED, attributes)
            },

            shippingNoConsolidationPayNowButton: ({
                country,
                city,
                shippingCompany,
                extraServices,
                promoCode,
                parcelIds,
                totalChargeableWeight,
                shippingFees,
                discountAmount,
                subtotal,
                totalAmount,
                tax,
                warehouse,
            }) => {
                const attributes = {
                    [eventAttribute.COUNTRY]: country,
                    [eventAttribute.CITY]: city,
                    [eventAttribute.SHIPPING_COMPANY]: shippingCompany,
                    [eventAttribute.EXTRA_SERVICES]: extraServices,
                    [eventAttribute.PROMO_CODE]: promoCode ?? '',
                    [eventAttribute.PARCEL_IDS]: parcelIds,
                    [eventAttribute.TOTAL_CHARGEABLE_WEIGHT]: totalChargeableWeight,
                    [eventAttribute.SHIPPING_FEES]: shippingFees,
                    [eventAttribute.DISCOUNT_AMOUNT]: discountAmount ?? 0,
                    [eventAttribute.SUBTOTAL]: subtotal,
                    [eventAttribute.TOTAL_AMOUNT]: totalAmount,
                    [eventAttribute.TAX]: tax,
                    [eventAttribute.WAREHOUSE]: warehouse,
                }
                if (analytics) logEvent(analytics, event.SHIPPING_NO_CONSOLIDATION_PAY_NOW_BUTTON, attributes)
            },

            shippingNoConsolidationPaymentStarted: ({
                country,
                city,
                shippingCompany,
                extraServices,
                promoCode,
                parcelIds,
                totalChargeableWeight,
                shippingFees,
                discountAmount,
                subtotal,
                totalAmount,
                tax,
                warehouse,
            }) => {
                const attributes = {
                    [eventAttribute.COUNTRY]: country,
                    [eventAttribute.CITY]: city,
                    [eventAttribute.SHIPPING_COMPANY]: shippingCompany,
                    [eventAttribute.EXTRA_SERVICES]: extraServices,
                    [eventAttribute.PROMO_CODE]: promoCode ?? '',
                    [eventAttribute.PARCEL_IDS]: parcelIds,
                    [eventAttribute.TOTAL_CHARGEABLE_WEIGHT]: totalChargeableWeight,
                    [eventAttribute.SHIPPING_FEES]: shippingFees,
                    [eventAttribute.DISCOUNT_AMOUNT]: discountAmount ?? 0,
                    [eventAttribute.SUBTOTAL]: subtotal,
                    [eventAttribute.TOTAL_AMOUNT]: totalAmount,
                    [eventAttribute.TAX]: tax,
                    [eventAttribute.WAREHOUSE]: warehouse,
                }
                if (analytics) logEvent(analytics, event.SHIPPING_NO_CONSOLIDATION_PAYMENT_STARTED, attributes)
            },

            shippingNoConsolidationPaymentCompleted: ({
                country,
                city,
                shippingCompany,
                extraServices,
                promoCode,
                parcelIds,
                totalChargeableWeight,
                shippingFees,
                discountAmount,
                subtotal,
                totalAmount,
                tax,
                warehouse,
                paymentMethod,
                walletBalance,
                usedWalletAmount,
            }) => {
                const attributes = {
                    [eventAttribute.COUNTRY]: country,
                    [eventAttribute.CITY]: city,
                    [eventAttribute.SHIPPING_COMPANY]: shippingCompany,
                    [eventAttribute.EXTRA_SERVICES]: extraServices,
                    [eventAttribute.PROMO_CODE]: promoCode ?? '',
                    [eventAttribute.PARCEL_IDS]: parcelIds,
                    [eventAttribute.TOTAL_CHARGEABLE_WEIGHT]: totalChargeableWeight,
                    [eventAttribute.SHIPPING_FEES]: shippingFees,
                    [eventAttribute.DISCOUNT_AMOUNT]: discountAmount ?? 0,
                    [eventAttribute.SUBTOTAL]: subtotal,
                    [eventAttribute.TOTAL_AMOUNT]: totalAmount,
                    [eventAttribute.TAX]: tax,
                    [eventAttribute.WAREHOUSE]: warehouse,
                    [eventAttribute.PAYMENT_METHOD]: paymentMethod ?? '',
                    [eventAttribute.WALLET_BALANCE]: walletBalance,
                    [eventAttribute.USED_WALLET_AMOUNT]: usedWalletAmount,
                }
                if (analytics) logEvent(analytics, event.SHIPPING_NO_CONSOLIDATION_PAYMENT_COMPLETED, attributes)
            },

            shippingNoConsolidationPaymentFailure: ({ reason, paymentMethod, totalAmount, warehouse, parcelIds }) => {
                const attributes = {
                    [eventAttribute.REASON]: reason,
                    [eventAttribute.PAYMENT_METHOD]: paymentMethod ?? '',
                    [eventAttribute.TOTAL_AMOUNT]: totalAmount,
                    [eventAttribute.WAREHOUSE]: warehouse,
                    [eventAttribute.PARCEL_IDS]: parcelIds,
                }
                if (analytics) logEvent(analytics, event.SHIPPING_NO_CONSOLIDATION_PAYMENT_FAILURE, attributes)
            },

            shippingConsolidationRequestCompleted: ({
                consolidationType,
                country,
                city,
                shippingCompany,
                extraServices,
                promoCode,
                parcelIds,
                warehouse,
            }) => {
                const attributes = {
                    [eventAttribute.CONSOLIDATION_TYPE]: consolidationType,
                    [eventAttribute.COUNTRY]: country,
                    [eventAttribute.CITY]: city,
                    [eventAttribute.SHIPPING_COMPANY]: shippingCompany,
                    [eventAttribute.EXTRA_SERVICES]: extraServices,
                    [eventAttribute.PROMO_CODE]: promoCode ?? '',
                    [eventAttribute.PARCEL_IDS]: parcelIds,
                    [eventAttribute.WAREHOUSE]: warehouse,
                }
                if (analytics) logEvent(analytics, event.SHIPPING_CONSOLIDATION_REQUEST_COMPLETED, attributes)
            },

            shippingConsolidationCreditedCompleted: ({
                parcelId,
                consolidationType,
                country,
                city,
                shippingCompany,
                extraServices,
                promoCode,
                parcelIds,
                discountAmount,
                subtotal,
                totalAmount,
                tax,
                totalActualWeight,
                totalChargeableWeight,
                shippingFees,
                consolidationFees,
                warehouse,
            }) => {
                const attributes = {
                    [eventAttribute.PARCEL_ID]: parcelId,
                    [eventAttribute.CONSOLIDATION_TYPE]: consolidationType,
                    [eventAttribute.COUNTRY]: country,
                    [eventAttribute.CITY]: city,
                    [eventAttribute.SHIPPING_COMPANY]: shippingCompany,
                    [eventAttribute.EXTRA_SERVICES]: extraServices,
                    [eventAttribute.PROMO_CODE]: promoCode,
                    [eventAttribute.PARCEL_IDS]: parcelIds,
                    [eventAttribute.DISCOUNT_AMOUNT]: discountAmount,
                    [eventAttribute.SUBTOTAL]: subtotal,
                    [eventAttribute.TOTAL_AMOUNT]: totalAmount,
                    [eventAttribute.TAX]: tax,
                    [eventAttribute.TOTAL_ACTUAL_WEIGHT]: totalActualWeight,
                    [eventAttribute.TOTAL_CHARGEABLE_WEIGHT]: totalChargeableWeight,
                    [eventAttribute.SHIPPING_FEES]: shippingFees,
                    [eventAttribute.CONSOLIDATION_FEES]: consolidationFees,
                    [eventAttribute.WAREHOUSE]: warehouse,
                }
                if (analytics) logEvent(analytics, event.SHIPPING_CONSOLIDATION_CREDITED_COMPLETED, attributes)
            },

            shippingConsolidationPayButton: ({ parcelId, consolidationType, warehouse }) => {
                const attributes = {
                    [eventAttribute.PARCEL_ID]: parcelId,
                    [eventAttribute.CONSOLIDATION_TYPE]: consolidationType,
                    [eventAttribute.WAREHOUSE]: warehouse,
                }
                if (analytics) logEvent(analytics, event.SHIPPING_CONSOLIDATION_PAY_BUTTON, attributes)
            },

            shippingConsolidationPayNowButton: ({
                parcelId,
                consolidationType,
                country,
                city,
                shippingCompany,
                extraServices,
                promoCode,
                parcelIds,
                discountAmount,
                subtotal,
                totalAmount,
                tax,
                totalActualWeight,
                totalChargeableWeight,
                shippingFees,
                consolidationFees,
                warehouse,
            }) => {
                const attributes = {
                    [eventAttribute.PARCEL_ID]: parcelId,
                    [eventAttribute.CONSOLIDATION_TYPE]: consolidationType,
                    [eventAttribute.COUNTRY]: country,
                    [eventAttribute.CITY]: city,
                    [eventAttribute.SHIPPING_COMPANY]: shippingCompany,
                    [eventAttribute.EXTRA_SERVICES]: extraServices,
                    [eventAttribute.PROMO_CODE]: promoCode,
                    [eventAttribute.PARCEL_IDS]: parcelIds,
                    [eventAttribute.DISCOUNT_AMOUNT]: discountAmount,
                    [eventAttribute.SUBTOTAL]: subtotal,
                    [eventAttribute.TOTAL_AMOUNT]: totalAmount,
                    [eventAttribute.TAX]: tax,
                    [eventAttribute.TOTAL_ACTUAL_WEIGHT]: totalActualWeight,
                    [eventAttribute.TOTAL_CHARGEABLE_WEIGHT]: totalChargeableWeight,
                    [eventAttribute.SHIPPING_FEES]: shippingFees,
                    [eventAttribute.CONSOLIDATION_FEES]: consolidationFees,
                    [eventAttribute.WAREHOUSE]: warehouse,
                }
                if (analytics) logEvent(analytics, event.SHIPPING_CONSOLIDATION_PAY_NOW_BUTTON, attributes)
            },

            shippingConsolidationPaymentStarted: ({
                parcelId,
                consolidationType,
                country,
                city,
                shippingCompany,
                extraServices,
                promoCode,
                parcelIds,
                discountAmount,
                subtotal,
                totalAmount,
                consolidationFees,
                tax,
                totalChargeableWeight,
                totalActualWeight,
                shippingFees,
                warehouse,
            }) => {
                const attributes = {
                    [eventAttribute.PARCEL_ID]: parcelId,
                    [eventAttribute.CONSOLIDATION_TYPE]: consolidationType,
                    [eventAttribute.COUNTRY]: country,
                    [eventAttribute.CITY]: city,
                    [eventAttribute.SHIPPING_COMPANY]: shippingCompany,
                    [eventAttribute.EXTRA_SERVICES]: extraServices,
                    [eventAttribute.PROMO_CODE]: promoCode ?? '',
                    [eventAttribute.PARCEL_IDS]: parcelIds,
                    [eventAttribute.TOTAL_CHARGEABLE_WEIGHT]: totalChargeableWeight,
                    [eventAttribute.SHIPPING_FEES]: shippingFees,
                    [eventAttribute.TOTAL_ACTUAL_WEIGHT]: totalActualWeight,
                    [eventAttribute.DISCOUNT_AMOUNT]: discountAmount ?? 0,
                    [eventAttribute.SUBTOTAL]: subtotal,
                    [eventAttribute.TOTAL_AMOUNT]: totalAmount,
                    [eventAttribute.CONSOLIDATION_FEES]: consolidationFees,
                    [eventAttribute.TAX]: tax,
                    [eventAttribute.WAREHOUSE]: warehouse,
                }
                if (analytics) logEvent(analytics, event.SHIPPING_CONSOLIDATION_PAYMENT_STARTED, attributes)
            },

            shippingConsolidationPaymentCompleted: ({
                parcelId,
                consolidationType,
                country,
                city,
                shippingCompany,
                extraServices,
                promoCode,
                parcelIds,
                discountAmount,
                subtotal,
                totalAmount,
                tax,
                totalActualWeight,
                totalChargeableWeight,
                shippingFees,
                consolidationFees,
                warehouse,
                paymentMethod,
                walletBalance,
                usedWalletAmount,
            }) => {
                const attributes = {
                    [eventAttribute.PARCEL_ID]: parcelId,
                    [eventAttribute.CONSOLIDATION_TYPE]: consolidationType,
                    [eventAttribute.COUNTRY]: country,
                    [eventAttribute.CITY]: city,
                    [eventAttribute.SHIPPING_COMPANY]: shippingCompany,
                    [eventAttribute.EXTRA_SERVICES]: extraServices,
                    [eventAttribute.PROMO_CODE]: promoCode ?? '',
                    [eventAttribute.PARCEL_IDS]: parcelIds,
                    [eventAttribute.TOTAL_CHARGEABLE_WEIGHT]: totalChargeableWeight,
                    [eventAttribute.TOTAL_ACTUAL_WEIGHT]: totalActualWeight,
                    [eventAttribute.SHIPPING_FEES]: shippingFees,
                    [eventAttribute.DISCOUNT_AMOUNT]: discountAmount,
                    [eventAttribute.SUBTOTAL]: subtotal,
                    [eventAttribute.TOTAL_AMOUNT]: totalAmount,
                    [eventAttribute.CONSOLIDATION_FEES]: consolidationFees,
                    [eventAttribute.TAX]: tax,
                    [eventAttribute.WAREHOUSE]: warehouse,
                    [eventAttribute.PAYMENT_METHOD]: paymentMethod ?? '',
                    [eventAttribute.WALLET_BALANCE]: walletBalance,
                    [eventAttribute.USED_WALLET_AMOUNT]: usedWalletAmount,
                }
                if (analytics) logEvent(analytics, event.SHIPPING_CONSOLIDATION_PAYMENT_COMPLETED, attributes)
            },

            shippingConsolidationPaymentFailure: ({ reason, paymentMethod, totalAmount, warehouse, parcelId }) => {
                const attributes = {
                    [eventAttribute.REASON]: reason,
                    [eventAttribute.PAYMENT_METHOD]: paymentMethod ?? '',
                    [eventAttribute.TOTAL_AMOUNT]: totalAmount,
                    [eventAttribute.WAREHOUSE]: warehouse,
                    [eventAttribute.PARCEL_ID]: parcelId,
                }
                if (analytics) logEvent(analytics, event.SHIPPING_CONSOLIDATION_PAYMENT_FAILURE, attributes)
            },

            dashboardBifmSubmitButton: () => {
                if (analytics) logEvent(analytics, event.DASHBOARD_BIFM_SUBMIT_BUTTON)
            },

            bifmButton: () => {
                if (analytics) logEvent(analytics, event.BIFM_BUTTON)
            },

            bifmNewPurchaseRequestButton: () => {
                if (analytics) logEvent(analytics, event.BIFM_PURCHASE_REQUEST_BUTTON)
            },

            bifmCancelPurchaseRequestButton: ({ requestId }) => {
                const attributes = {
                    [eventAttribute.REQUEST_ID]: requestId,
                }
                if (analytics) logEvent(analytics, event.BIFM_CANCEL_PURCHASE_REQUEST_BUTTON, attributes)
            },

            bifmPurchaseRequestCompleted: ({
                productUrl,
                productName,
                unitPrice,
                quantity,
                shippingFees,
                color,
                size,
                attachPictures,
                comments,
                totalAmount,
            }) => {
                const attributes = {
                    [eventAttribute.PRODUCT_URL]: productUrl,
                    [eventAttribute.PRODUCT_NAME]: productName,
                    [eventAttribute.UNIT_PRICE]: unitPrice,
                    [eventAttribute.QUANTITY]: quantity,
                    [eventAttribute.SHIPPING_FEES]: shippingFees,
                    [eventAttribute.COLOR]: color ?? '',
                    [eventAttribute.SIZE]: size ?? 0,
                    [eventAttribute.ATTACH_PICTURES]: attachPictures,
                    [eventAttribute.COMMENTS]: comments ?? '',
                    [eventAttribute.TOTAL_AMOUNT]: totalAmount,
                }
                if (analytics) logEvent(analytics, event.BIFM_PURCHASE_REQUEST_COMPLETED, attributes)
            },

            bifmPurchaseRequestUpdateCompleted: ({
                productUrl,
                productName,
                unitPrice,
                quantity,
                shippingFees,
                color,
                size,
                attachPictures,
                comments,
                totalAmount,
                requestId,
            }) => {
                const attributes = {
                    [eventAttribute.PRODUCT_URL]: productUrl,
                    [eventAttribute.PRODUCT_NAME]: productName,
                    [eventAttribute.UNIT_PRICE]: unitPrice,
                    [eventAttribute.QUANTITY]: quantity,
                    [eventAttribute.SHIPPING_FEES]: shippingFees,
                    [eventAttribute.COLOR]: color ?? '',
                    [eventAttribute.SIZE]: size ?? 0,
                    [eventAttribute.ATTACH_PICTURES]: attachPictures,
                    [eventAttribute.COMMENTS]: comments ?? '',
                    [eventAttribute.TOTAL_AMOUNT]: totalAmount,
                    [eventAttribute.REQUEST_ID]: requestId,
                }
                if (analytics) logEvent(analytics, event.BIFM_PURCHASE_REQUEST_UPDATE_COMPLETED, attributes)
            },

            bifmPurchaseRequestPayButton: ({
                productUrl,
                productName,
                unitPrice,
                quantity,
                shippingFees,
                color,
                size,
                attachPictures,
                comments,
                totalAmount,
                requestId,
                serviceFees,
            }) => {
                const attributes = {
                    [eventAttribute.PRODUCT_URL]: productUrl,
                    [eventAttribute.PRODUCT_NAME]: productName,
                    [eventAttribute.UNIT_PRICE]: unitPrice,
                    [eventAttribute.QUANTITY]: quantity,
                    [eventAttribute.SHIPPING_FEES]: shippingFees,
                    [eventAttribute.COLOR]: color ?? '',
                    [eventAttribute.SIZE]: size ?? 0,
                    [eventAttribute.ATTACH_PICTURES]: attachPictures,
                    [eventAttribute.COMMENTS]: comments ?? '',
                    [eventAttribute.TOTAL_AMOUNT]: totalAmount,
                    [eventAttribute.REQUEST_ID]: requestId,
                    [eventAttribute.SERVICE_FEES]: serviceFees,
                }
                if (analytics) logEvent(analytics, event.BIFM_PURCHASE_REQUEST_PAY_BUTTON, attributes)
            },

            bifmPurchaseRequestPaymentStarted: ({
                productUrl,
                productName,
                unitPrice,
                quantity,
                shippingFees,
                color,
                size,
                attachPictures,
                comments,
                totalAmount,
                paymentMethod,
                requestId,
                serviceFees,
            }) => {
                const attributes = {
                    [eventAttribute.PRODUCT_URL]: productUrl,
                    [eventAttribute.PRODUCT_NAME]: productName,
                    [eventAttribute.UNIT_PRICE]: unitPrice,
                    [eventAttribute.QUANTITY]: quantity,
                    [eventAttribute.SHIPPING_FEES]: shippingFees,
                    [eventAttribute.COLOR]: color ?? '',
                    [eventAttribute.SIZE]: size ?? 0,
                    [eventAttribute.ATTACH_PICTURES]: attachPictures,
                    [eventAttribute.COMMENTS]: comments ?? '',
                    [eventAttribute.TOTAL_AMOUNT]: totalAmount,
                    [eventAttribute.REQUEST_ID]: requestId,
                    [eventAttribute.SERVICE_FEES]: serviceFees,
                }
                if (analytics) logEvent(analytics, event.BIFM_PURCHASE_REQUEST_CHECKOUT_STARTED, attributes)
            },

            bifmPurchaseRequestPaymentCompleted: ({
                productUrl,
                productName,
                unitPrice,
                quantity,
                shippingFees,
                color,
                size,
                attachPictures,
                comments,
                totalAmount,
                paymentMethod,
                walletBalance,
                usedWalletAmount,
                requestId,
                serviceFees,
            }) => {
                const attributes = {
                    [eventAttribute.PRODUCT_URL]: productUrl,
                    [eventAttribute.PRODUCT_NAME]: productName,
                    [eventAttribute.UNIT_PRICE]: unitPrice,
                    [eventAttribute.QUANTITY]: quantity,
                    [eventAttribute.SHIPPING_FEES]: shippingFees,
                    [eventAttribute.COLOR]: color ?? '',
                    [eventAttribute.SIZE]: size ?? 0,
                    [eventAttribute.ATTACH_PICTURES]: attachPictures,
                    [eventAttribute.COMMENTS]: comments ?? '',
                    [eventAttribute.TOTAL_AMOUNT]: totalAmount,
                    [eventAttribute.PAYMENT_METHOD]: paymentMethod ?? '',
                    [eventAttribute.WALLET_BALANCE]: walletBalance,
                    [eventAttribute.USED_WALLET_AMOUNT]: usedWalletAmount,
                    [eventAttribute.REQUEST_ID]: requestId,
                    [eventAttribute.SERVICE_FEES]: serviceFees,
                }
                if (analytics) logEvent(analytics, event.BIFM_PURCHASE_REQUEST_CHECKOUT_COMPLETED, attributes)
            },

            bifmPurchaseRequestPaymentFailure: ({ reason, paymentMethod, totalAmount, warehouse, requestId }) => {
                const attributes = {
                    [eventAttribute.REASON]: reason,
                    [eventAttribute.PAYMENT_METHOD]: paymentMethod ?? '',
                    [eventAttribute.TOTAL_AMOUNT]: totalAmount,
                    [eventAttribute.WAREHOUSE]: warehouse,
                    [eventAttribute.REQUEST_ID]: requestId,
                }
                if (analytics) logEvent(analytics, event.BIFM_PURCHASE_REQUEST_CHECKOUT_FAILURE, attributes)
            },

            upgradeNowButton: () => {
                if (analytics) logEvent(analytics, event.UPGRADE_NOW_BUTTON)
            },

            languageButton: ({ language }) => {
                const attributes = { [eventAttribute.LANGUAGE]: language }
                if (analytics) logEvent(analytics, event.LANGUAGE_BUTTON, attributes)
            },

            rateCalculatorButton: () => {
                if (analytics) logEvent(analytics, event.RATE_CALCULATOR_BUTTON)
            },

            calculateShippingCostButton: ({ shipFrom, shipTo, parcelWeight, weightUnit }) => {
                const attributes = {
                    [eventAttribute.SHIP_FORM]: shipFrom,
                    [eventAttribute.SHIP_TO]: shipTo,
                    [eventAttribute.PARCEL_WEIGHT]: parcelWeight,
                    [eventAttribute.WEIGHT_UNIT]: weightUnit,
                }
                if (analytics) logEvent(analytics, event.CALCULATE_SHIPPING_COST_BUTTON, attributes)
            },

            trackShipmentButton: () => {
                if (analytics) logEvent(analytics, event.TRACK_SHIPMENT_BUTTON)
            },

            trackShipmentCompleted: ({ trackingNumber, status }) => {
                const attributes = {
                    [eventAttribute.TRACKING_NUMBER]: trackingNumber,
                    [eventAttribute.STATUS]: status,
                }
                if (analytics) logEvent(analytics, event.TRACK_SHIPMENT_COMPLETED, attributes)
            },

            walletButton: () => {
                if (analytics) logEvent(analytics, event.WALLET_BUTTON)
            },

            helpFaqButton: () => {
                if (analytics) logEvent(analytics, event.HELP_FAQ_BUTTON)
            },

            helpProhibitedMaterialsButton: () => {
                if (analytics) logEvent(analytics, event.HelpProhibitedMaterialsButton)
            },

            helpShoppingDirectoryButton: () => {
                if (analytics) logEvent(analytics, event.HELP_SHOPPING_DIRECTORY_BUTTON)
            },

            helpTrueWeightButton: () => {
                if (analytics) logEvent(analytics, event.HELP_TRUE_WEIGHT_BUTTON)
            },

            servicesPackageConsolidationButton: () => {
                if (analytics) logEvent(analytics, event.SERVICE_PACKAGE_CONSOLIDATION_BUTTON)
            },

            servicesItemConsolidationButton: () => {
                if (analytics) logEvent(analytics, event.SERVICE_ITEM_CONSOLIDATION_BUTTON)
            },

            servicesOtherServicesButton: () => {
                if (analytics) logEvent(analytics, event.SERVICES_OTHER_SERVICES_BUTTON)
            },

            servicesBifmButton: () => {
                if (analytics) logEvent(analytics, event.SERVICES_BIFM_BUTTON)
            },

            contactUsSubmitCompleted: ({ name, email, companyName, shipTo, phoneNumber, Subject, message }) => {
                const attributes = {
                    [eventAttribute.NAME]: name,
                    [eventAttribute.EMAIL]: email,
                    [eventAttribute.COMPANY_NAME]: companyName,
                    [eventAttribute.SHIP_TO]: shipTo,
                    [eventAttribute.PHONE_NUMBER]: phoneNumber,
                    [eventAttribute.SUBJECT]: Subject,
                    [eventAttribute.MESSAGE]: message,
                }
                if (analytics) logEvent(analytics, event.CONTACT_US_SUBMIT_COMPLETED, attributes)
            },

            eCommerceButton: () => {
                if (analytics) logEvent(analytics, event.E_COMMERCE_BUTTON)
            },

            contactUs: ({ source }) => {
                const attributes = { [eventAttribute.SOURCE]: source }
                if (analytics) logEvent(analytics, event.CONTACT_US, attributes)
            },

            appleDownloadButton: () => {
                if (analytics) logEvent(analytics, event.APPLE_DOWNLOAD_BUTTON)
            },

            googleDownloadButton: () => {
                if (analytics) logEvent(analytics, event.GOOGLE_DOWNLOAD_BUTTON)
            },

            facebookButton: () => {
                if (analytics) logEvent(analytics, event.FACEBOOK_BUTTON)
            },

            igButton: () => {
                if (analytics) logEvent(analytics, event.IG_BUTTON)
            },

            linkedInButton: () => {
                if (analytics) logEvent(analytics, event.LINKED_ID_BUTTON)
            },

            twitterButton: () => {
                if (analytics) logEvent(analytics, event.TWITTER_BUTTON)
            },

            rateUsButton: () => {
                if (analytics) logEvent(analytics, event.RATE_US_BUTTON)
            },

            helpSupportButton: () => {
                if (analytics) logEvent(analytics, event.HELP_AND_SUPPORT_BUTTON)
            },

            dashboardReferralCopyButton: () => {
                if (analytics) logEvent(analytics, event.DASHBOARD_REFERRAL_COPY_BUTTON)
            },

            dashboardRenewButton: () => {
                if (analytics) logEvent(analytics, event.DASHBOARD_RENEW_BUTTON)
            },

            dashboardAddCardButton: () => {
                if (analytics) logEvent(analytics, event.DASHBOARD_ADD_CARD_BUTTON)
            },

            screenVisited: ({ screen }) => {
                if (screen?.name && analytics)
                    logEvent(analytics, 'screen_view', {
                        firebase_screen: screen.name,
                    })
            },
        }),
        [analytics],
    )

    const attributes = useMemo(
        () => ({
            setAttributes: ({
                firstName,
                lastName,
                phone,
                email,
                country,
                membershipType,
                planType,
                language,
                membershipExpiryDate,
                referralCode,
                verificationDate,
                signupDate,
            }) => {
                const attributes = {
                    [userAttribute.FIRST_NAME]: firstName,
                    [userAttribute.LAST_NAME]: lastName,
                    [userAttribute.PHONE]: phone,
                    [userAttribute.EMAIL]: email,
                    [userAttribute.COUNTRY]: country,
                    [userAttribute.MEMBERSHIP_TYPE]: membershipType,
                    [userAttribute.PLAN_TYPE]: planType,
                    [userAttribute.LANGUAGE]: language,
                    [userAttribute.MEMBERSHIP_EXPIRY_DATE]: membershipExpiryDate,
                    [userAttribute.REFERRAL_CODE]: referralCode,
                    [userAttribute.VERIFICATION_DATE]: verificationDate,
                    [userAttribute.SIGNUP_DATE]: signupDate,
                }
                const filteredAttributes = _(attributes).omitBy(_.isUndefined).value()
                if (analytics) setUserProperties(analytics, filteredAttributes)
            },
        }),
        [analytics],
    )

    return useMemo(
        () => ({
            events,
            attributes,
        }),
        [attributes, events],
    )
}

export default useGoogleAnalytics
