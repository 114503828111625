import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import { MEMBERSHIP_PLANS, DASHBOARD, FORGOT_PASSWORD } from '../../constants/navigationConstants'
import { Link } from 'react-router-dom'
import { getUserLogin } from '../../services/loginRegistrationService'
import { useAuth } from '../../contexts/AuthContext'

import SocialAuth from '../../components/socialAuth'
import { MENU_STATES, SPAIN_CUSTOMERS, VIRTUAL_WAREHOUSES, MEMBERSHIP_CONSTANTS } from '../../constants/constants'
import { Alert } from 'react-bootstrap'
import { ShowPassword } from '../../components/common'
import useAnalytics from '../../analytics/useAnalytics'
import { getUserDetails } from '../../services/userInfoService'
import * as moment from 'moment'

function LoginForm(props) {
    const { t, i18n } = useTranslation()
    const [loginUser, setLoginUser] = useState({
        email: '',
        password: '',
    })
    const [rememberMeBool, setRememberMeBool] = useState(false)
    const [error, setError] = useState(null)

    const analytics = useAnalytics()
    const {
        setAuthToken,
        setUserPin,
        setUsername,
        setUserEmail,
        setMembershipPlanID,
        setCountry,
        setPhone,
        setPostalCode,
        login,
        setIsSubYearly,
        setIsRenewalYearly,
        setWalletBalance,
        setShowIncomingOrders,
        setWarehouseId,
        setUploadFiles,
    } = useAuth()

    let name, value

    const handleInputs = (event) => {
        name = event.target.name
        value = event.target.value

        setLoginUser({ ...loginUser, [name]: value })
    }

    const closeModal = () => {
        document.querySelector('#dismiss-modal').click()
        if (props.toggleMenuState) {
            props.toggleMenuState()
        }
    }

    const handleSetUserAnalyticsAttributes = async (token) => {
        const res = await getUserDetails(token)
        if (!res) return
        const { expiry_at, is_yearly } = res?.plan_data ?? {}
        const userAttributes = {
            firstName: res.fname ?? '',
            lastName: res.lname ?? '',
            phone: res.phone ?? '',
            email: res.email ?? '',
            country: res.country ?? '',
            membershipType: res.membership_plan_id ? MEMBERSHIP_CONSTANTS[`${res.membership_plan_id}`] : '',
            planType: !!is_yearly ? 'Yearly' : 'Monthly',
            language: `${i18n.language}`.toUpperCase() ?? '',
            membershipExpiryDate: expiry_at ? moment(expiry_at).toDate() : undefined,
            referralCode: res.referral_code ?? '',
            verificationDate: res.email_verified_at ? moment(res.email_verified_at).toDate() : undefined,
            signupDate: res.created_at ? moment(res.created_at).toDate() : undefined,
            receiveWhatsappUpdates: !!res.receive_whatsapp,
            receiveEmailUpdates: !!res.receive_emails,
        }
        analytics.attributes.setAttributes(userAttributes)
    }

    const postLogin = async (event, loginUser, provider) => {
        if (event) event.preventDefault()
        try {
            const res = await getUserLogin(loginUser)
            //user pin not used so far
            if (res?.error) {
                throw new Error(res.error)
            }

            if (!res?.status) {
                return setError('Invalid Credentials')
            }

            setError(null)
            const {
                name,
                accessToken,
                user_pin,
                membership_plan_id,
                country,
                phone,
                postalCode,
                wallet_balance,
                planData,
                show_incoming_orders,
                upload_files,
            } = res?.payload

            if (name) {
                await setUsername(name)
            }
            if (user_pin) {
                await setUserPin(user_pin)
                analytics.events.userLoggedIn({ userPin: user_pin, provider })
                localStorage.setItem('analyticsLoggedInBefore', true)
            }
            if (accessToken) {
                await setAuthToken(accessToken)
                await login()
            }
            if (country) {
                await setCountry(country)
            }
            if (phone) {
                await setPhone(phone)
            }
            if (postalCode && postalCode !== '') {
                await setPostalCode(postalCode)
            }

            if (planData && _.isEmpty(planData)) {
                await setIsSubYearly(planData?.is_yearly?.toString())
                await setIsRenewalYearly(0)
            }

            if (show_incoming_orders) {
                await setShowIncomingOrders(show_incoming_orders)
            }

            if (upload_files) {
                await setUploadFiles(upload_files)
            }

            await setWalletBalance(parseFloat(wallet_balance || 0))
            await setUserEmail(loginUser.email)

            setMembershipPlanID(membership_plan_id)

            if (name && accessToken && user_pin) {
                if (SPAIN_CUSTOMERS.includes(user_pin.toUpperCase())) {
                    await setWarehouseId(VIRTUAL_WAREHOUSES[0])
                }
                if (props.modal && props.modal) {
                    closeModal()
                    props.toggleMenuState && props.toggleMenuState(MENU_STATES.CLOSE_MENU)
                }
                document.querySelector('#redirect-to-dashboard').click()
            }
            await handleSetUserAnalyticsAttributes(accessToken)
        } catch (e) {
            setError(e.message)
        }
    }

    const onSocialAuthComplete = (userDetails) => {
        if (_.isEmpty(userDetails)) return
        const capitalizedProviderName = userDetails.loginVia
            .map((char, index) => (index ? char : char.toUpperCase()))
            .join('')
        postLogin(null, userDetails, capitalizedProviderName)
    }

    return (
        <div className="container-fluid pr-0 pl-0 w-100">
            <form
                id="loginForm"
                method="POST"
                className="text-start w-100 pr-0 pl-0"
                onSubmit={(e) => postLogin(e, loginUser, 'Email')}
            >
                <div className="form-group">
                    <label htmlFor="loginEmail">{t('Email address')}</label>
                    <input
                        autocomplete="off"
                        type="email"
                        className="form-control"
                        id="loginEmail"
                        name="email"
                        value={loginUser.email}
                        onChange={handleInputs}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="registerPassword">{t('Password')}</label>
                    <div className="d-block password-field">
                        <ShowPassword password={loginUser?.password} onChange={handleInputs} />
                    </div>
                </div>
                <div
                    className={`form-group d-flex row justify-content-center ml-0 mr-0 align-items-center ${i18n.language === 'en' && 'form-check'}`}
                >
                    <div className="col-5 pl-0 pr-0">
                        <input
                            type="checkbox"
                            className="form-check-input h-auto"
                            id="loginRememberMeCheckbox"
                            value={rememberMeBool}
                            onClick={() => setRememberMeBool(!rememberMeBool)}
                        />
                        <label className="form-check-label" htmlFor="loginRememberMeCheckbox">
                            {t('Remember me')}
                        </label>
                    </div>
                    <div className="col-7 text-end pl-0 pr-0">
                        <Link
                            to={FORGOT_PASSWORD}
                            onClick={() => {
                                closeModal()
                                analytics.events.forgotPasswordButton()
                            }}
                        >
                            {t('Forget password?')}
                        </Link>
                    </div>
                </div>
                {!!error && <Alert variant={'danger'}>{error}</Alert>}
                <button type="submit" className="btn btn-primary w-100">
                    {t('Login')}
                </button>

                <div className="row mt-3">
                    <div className="col-12 col-md-5">
                        <hr />
                    </div>
                    <div className="col-12 col-md-2 text-center"> {t('Or')}</div>
                    <div className="col-12 col-md-5">
                        <hr />
                    </div>
                </div>

                <SocialAuth onSocialAuthComplete={onSocialAuthComplete} />

                <div className="row justify-content-center mt-3">
                    {t("Don't-have-account")}
                    <Link
                        className="full-features-link text-decoration-none"
                        to={MEMBERSHIP_PLANS}
                        onClick={() => {
                            closeModal()
                            analytics.events.signupButton()
                        }}
                    >
                        &nbsp;&nbsp;
                        {t('Sing-up-now')}
                    </Link>
                    <a id="dismiss-modal" data-dismiss="modal"></a>
                    <Link id="redirect-to-dashboard" to={DASHBOARD}></Link>
                </div>
            </form>
        </div>
    )
}

export default LoginForm
