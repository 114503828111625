import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './banner.scss'
import CarouselImageSet from '../../../pages/homepage/shopUsBrandsSection/carouselImageSet'
import { useMediaQuery } from 'react-responsive'
import { useAuth } from '../../../contexts/AuthContext'
import BackButtonSvg from '../../../assets/img/icons/backButton.svg'
import { DASHBOARD } from '../../../constants/navigationConstants'
import { Link } from 'react-router-dom'

const commonContainer = (content, alignment, language, isMobile, t) => (
    <div
        className={`${alignment} position-absolute banner-text-position-${language}${isMobile ? '-mobile container' : ''}`}
    >
        {content}
    </div>
)

function Banner() {
    const { t, i18n } = useTranslation()
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

    const alignment = i18n.language === 'en' ? 'ltr' : 'rtl'
    const { warehouseId } = useAuth()
    const [imagesSrc, setImagesSrc] = useState([])

    const bannerTexts = {
        ['1']: commonContainer(
            <>
                <Link to={DASHBOARD} className="row align-items-center mb-2">
                    {i18n.language === 'ar' && <img alt="..." src={BackButtonSvg} className="ml-3 mr-3 rotate-180" />}
                    {i18n.language !== 'ar' && <img alt="..." src={BackButtonSvg} className="ml-3 mr-3" />}
                    <p className="mb-0 text-start">{t('Dashboard')}</p>
                </Link>
                <div className={`${isMobile ? 'd-block w-100' : 'd-flex justify-content-center align-items-end'}`}>
                    <h1 className={`xl-font mb-0 d-inline`}>{t('My-Rates')}</h1>
                    {/* <span className={`${i18n.language === "ar" ? "mr-3" : "ml-3"} mb-2 font-weight-300`}>{t("Note Rate discounts below are specially for personalised for you")}</span> */}
                </div>
            </>,
            alignment,
            i18n.language,
            isMobile,
            t,
        ),
    }

    useEffect(() => {
        const svgs = async () => {
            const reqSvgs = await require.context('./', true, /\.svg$/)
            return reqSvgs.keys().map((path, index) => ({
                path,
                file: reqSvgs(path),
                imageClass: `image${index}`,
                bannerText: bannerTexts[path?.replaceAll('./', '').split(isMobile ? '-' : '.')[0]],
            }))
        }

        async function getSvgs() {
            const x = await svgs()
            setImagesSrc(x)
        }
        getSvgs()
    }, [setImagesSrc, t, isMobile, warehouseId])

    return (
        <CarouselImageSet
            isMobile={isMobile}
            imagesSrc={
                isMobile
                    ? imagesSrc.filter(
                          (imageSrc) =>
                              imageSrc?.path?.includes('mobile') &&
                              !imageSrc?.path?.includes(`${i18n.language === 'ar' ? 'en' : 'ar'}`),
                      )
                    : imagesSrc.filter(
                          (imageSrc) =>
                              !imageSrc?.path?.includes('mobile') &&
                              !imageSrc?.path?.includes(`${i18n.language === 'ar' ? 'en' : 'ar'}`),
                      )
            }
            banner={true}
            source="myRates"
        />
    )
}

export default Banner
