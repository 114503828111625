import React from 'react'
import oneSvg from './section1.svg'
import { useTranslation } from 'react-i18next'

function One() {
    const { t, i18n } = useTranslation()
    return (
        <div className="main-section-container container mb-4 d-flex row justify-content-between">
            <div className="col-12 col-sm-12 col-md-8 text-start">
                <h2 className="sub-page-section-header">{t('General information')}</h2>
                <p className="text-start">{t('General information description')}</p>
            </div>
            <div className="col-12 col-sm-12 col-md-4">
                <img alt="..." className="w-100 rounded" src={oneSvg}></img>
            </div>
        </div>
    )
}

export default One
