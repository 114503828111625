import { useTranslation, Trans } from 'react-i18next'

const HowToCalculate = () => {
    const [t, i18n] = useTranslation()
    const isAr = i18n.language === 'ar'

    return (
        <>
            <div>
                <p className={`mb-0 fs-22px ${isAr ? 'font-weight-bold' : 'fw-500'}`}>
                    {t('TRUE_WEIGHT_how-to-calculate-title')}
                </p>
                <br />
                <p className="mb-0">{t('TRUE_WEIGHT_how-to-calculate-desc')}</p>
                <br />
                <p className={`mb-0 text-blue-color ${isAr ? 'font-weight-bold' : ''}`}>{`${t('Step')} 1:`}</p>
                <p className="mb-0">
                    <p className="m-0">{t('TRUE_WEIGHT_step-1-content')}</p>
                    <p className="m-0">
                        <span className="font-weight-bold">{`${t('Length')} (L): `}</span>
                        <span>{t('TRUE_WEIGHT_how-to-calculate-length')}</span>
                    </p>
                    <p className="m-0">
                        <span className="font-weight-bold">{`${t('Width')} (W): `}</span>
                        <span>{t('TRUE_WEIGHT_how-to-calculate-width')}</span>
                    </p>
                    <p className="m-0">
                        <span className="font-weight-bold">{`${t('Height')} (H): `}</span>
                        <span>{t('TRUE_WEIGHT_how-to-calculate-height')}</span>
                    </p>
                </p>
                <br />

                <p className={`mb-0 text-blue-color ${isAr ? 'font-weight-bold' : ''}`}>{`${t('Step')} 2:`}</p>
                <p className="mb-0">
                    <span>{t('TRUE_WEIGHT_step-2-content')}</span>
                    <br />
                    <span>
                        {t('TRUE_WEIGHT_volume_of_the_box')} = {t('Length')}
                        &nbsp;(L) &#x2715; {t('Width')}&nbsp;(W) &#x2715; {t('Height')}&nbsp;(H)
                    </span>
                    <br />
                    <span>
                        {t('TRUE_WEIGHT_volume_of_the_box')} = 60 {t('cm')} &#x2715; 30 {t('cm')} &#x2715; 40 {t('cm')}
                    </span>
                    <br />
                    <span>
                        {t('TRUE_WEIGHT_volume_of_the_box')} = 72,000 {t('cubic centimeters')}
                    </span>
                    <br />
                    <span>{t('TRUE_WEIGHT_volumetric_weight_calculator')}</span>
                </p>
                <br />

                <p className={`mb-0 text-blue-color ${isAr ? 'font-weight-bold' : ''}`}>{`${t('Step')} 3:`}</p>
                <p className="mb-0">
                    <span>{t('TRUE_WEIGHT_step-3-content')}</span>
                </p>
                <br />
                <p className="mb-0">{`${t(
                    'TRUE_WEIGHT_volumetric_weight_of_the_box',
                )} = ${t('TRUE_WEIGHT_volume_of_the_box')} (${t(
                    'Length',
                )} (L) x ${t('Width')} (W) x ${t('Height')} (H)) / 5000`}</p>
                <br />

                <p className="mb-0">
                    <span>{t('TRUE_WEIGHT_eg')}</span>
                    <br />
                    <span>{`${t(
                        'TRUE_WEIGHT_volumetric_weight_of_the_box',
                    )} = ${t('TRUE_WEIGHT_volume_of_the_box')} (60 ${t(
                        'cm',
                    )} x 30 ${t('cm')} x 40 ${t('cm')}) / 5000 = 72,000 ${t(
                        'cubic centimeters',
                    )} / 5000 = 14.4 ${t('TRUE_WEIGHT_kgs')}`}</span>
                    <br />
                    <span>{`${t('TRUE_WEIGHT_step-3-lets-assume')}: 10 ${t('TRUE_WEIGHT_kgs')}`}</span>
                </p>
                <br />
                <p className="mb-0">
                    <Trans i18nKey="TRUE_WEIGHT_step-3-with-new-true-weight">
                        <span className="text-blue-color" />
                        <span className="color-green" />
                        {` 10 ${t('TRUE_WEIGHT_kgs')}`}
                    </Trans>
                </p>
                <br />
                <p className="mb-0">
                    <Trans i18nKey="TRUE_WEIGHT_step-3-with-others">
                        <span className="text-danger"></span>
                        {` 14.4 ${t('TRUE_WEIGHT_kgs')}`}
                    </Trans>
                </p>
            </div>
        </>
    )
}

export default HowToCalculate
