import HeaderBar from './header-bar'
import HeaderContent from './header-content'
import styles from './styles.module.scss'

const Header = () => {
    return (
        <div className={`position-lg-relative ${styles['header']} ${styles['header-background']}`}>
            <div className={`${styles['header-bar']}`}>
                <HeaderBar />
            </div>
            <div className={`${styles['header-content']}`}>
                <HeaderContent />
            </div>
            <div className={`d-block d-lg-none position-relative ${styles['mobile-image']}`}>
                <div className={`position-absolute ${styles['mobile-image-tape']}`}></div>
            </div>
            <div className={`d-none d-lg-block position-absolute ${styles['header-background-tape']}`}></div>
        </div>
    )
}

export default Header
