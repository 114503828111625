import { useState } from 'react'
import Nav from 'react-bootstrap/Nav'
import Tab from 'react-bootstrap/Tab'
import CustomTab from './customTab/CustomTab.js'
import { BuyItForMeTabElements, HowItWorksTabElements } from './TabsElements'
import { useTranslation } from 'react-i18next'

import './styles.scss'

const HowItWorks = () => {
    const [tab, setTab] = useState('how-it-works')
    const { t, i18n } = useTranslation()

    return (
        <div className="how-it-works">
            <p className="title">{t('directory_how_it_works')}</p>

            <Tab.Container defaultActiveKey="buy-it-for-me" activeKey={tab} onSelect={(tab) => setTab(tab)}>
                <div className="text-center">
                    <Nav className="tab-bar">
                        <Nav.Link eventKey="how-it-works">{t('directory_how_sw_works')}</Nav.Link>
                        <Nav.Link eventKey="buy-it-for-me">{t('directory_buy_it_for_me')}</Nav.Link>
                    </Nav>
                </div>

                <Tab.Content>
                    <Tab.Pane eventKey="buy-it-for-me">
                        <CustomTab elements={BuyItForMeTabElements} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="how-it-works">
                        <CustomTab elements={HowItWorksTabElements} />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </div>
    )
}

export default HowItWorks
