import React, { useEffect, useState } from 'react'
import { Container, Button, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import './mainSection.css'
import { MEMBERSHIP_PLANS } from '../../../constants/navigationConstants'
import CarouselImageSet from '../shopUsBrandsSection/carouselImageSet'
import { useMediaQuery } from 'react-responsive'
import useAnalytics from '../../../analytics/useAnalytics'

const commonContainer = (content, alignment, language, isMobile, t, analytics) => (
    <div
        className={`${alignment} position-absolute banner-text-position-${language}${isMobile ? '-mobile container' : ''}`}
    >
        {content}
        <Link
            id=""
            to={MEMBERSHIP_PLANS}
            className={`text-center text-white text-uppercase border-radius-5 btn-bg-color text-decoration-none px-4 py-2 ${isMobile ? 'btn-block' : ''}`}
            onClick={analytics.events.signupButton}
        >
            {t('Sign up now')}
        </Link>
    </div>
)

const SinglesDayText = (t, language, isMobile) => (
    <>
        <h1 className="singles-day-head text-white">
            {!isMobile ? (
                <span>{t('Shop the best deals from the US on')} </span>
            ) : (
                <>
                    <span>{t('mobile-singles-day.Shop')}</span>
                    <br />
                    <span>{t('mobile-singles-day.US')}</span>
                    <br />
                    <span>{t('mobile-singles-day.on')}</span>
                </>
            )}
            <strong className="mx-2 "> {t('Single’s Day')}</strong>

            {t('and save big ')}
            {language === 'ar' ? <strong className="mx-2">{t('20% discount')}</strong> : t('on shipping with our')}
            {language === 'ar' ? t('on shipping with our') : <strong className="mx-2">{t('20% discount')}</strong>}
        </h1>
        <p className="singles-day-desc text-white">
            {language === 'ar' ? (
                <>
                    <span>{t('Use')} </span>
                    <span> {t('to avail the offer now')}</span>
                    <strong> {t('SHIP20')} </strong>
                </>
            ) : (
                <>
                    <span>{t('Use')} </span> <strong> {t('SHIP20')} </strong>
                    <span> {t('to avail the offer now')}</span>
                </>
            )}
        </p>
    </>
)

function MainSection() {
    const { t, i18n } = useTranslation()
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

    const alignment = i18n.language === 'en' ? 'ltr' : 'rtl'

    const [imagesSrc, setImagesSrc] = useState([])
    const analytics = useAnalytics()
    const bannerTexts = {
        //[`1${i18n.language}`]: commonContainer(SinglesDayText(t, i18n.language, isMobile), alignment, i18n.language, isMobile, t),
        //<strong className="text-uppercase">{t("Tax free")}</strong>
        ['1']: commonContainer(
            <>
                <h1 className="xl-font">{t('Shop any us brands')}</h1>
                <h1 className="l-font">{t('We ship it')}</h1>
                <p className="main-desc">{t('Main section description')}</p>
            </>,
            alignment,
            i18n.language,
            isMobile,
            t,
            analytics,
        ),
        ['2']: commonContainer(
            <>
                <h1 className="xl-font">{t('Free Sign Up')}</h1>
                <p className="banner-desc">{t('Getting on board easy with simple steps')}</p>
            </>,
            alignment,
            i18n.language,
            isMobile,
            t,
            analytics,
        ),
        ['3']: commonContainer(
            <>
                <h1 className="xl-font">{t('Package Consolidation')}</h1>
                <p className="banner-desc">{t('So when you save, you can spend on something precious')}</p>
            </>,
            alignment,
            i18n.language,
            isMobile,
            t,
            analytics,
        ),
        ['4']: commonContainer(
            <>
                <h1 className="xl-font">{t('Leave the Hassles to us')}</h1>
                <p className="banner-desc">
                    {t('With consolidation and storage options we take worries from your hand')}
                </p>
            </>,
            alignment,
            i18n.language,
            isMobile,
            t,
            analytics,
        ),
        //["5"]: commonContainer(<><h1 className="xl-font">{t("Shop from the UK")}</h1><p className="banner-desc">{t("we’ll deliver it to you")}</p></>, alignment, i18n.language, isMobile, t, analytics),
        ['6']: commonContainer(
            <>
                <h1 className="xl-font">{t('#Choices')}</h1>
                <p className="banner-desc">
                    {t('Get exclusive choices, so you can choose your preferred delivery partner')}
                </p>
            </>,
            alignment,
            i18n.language,
            isMobile,
            t,
            analytics,
        ),
        ['7']: commonContainer(
            <>
                <h1 className="xl-font">{t('Try our Buy it for me service')}</h1>
                <p className="banner-desc">{t('And we will shop on your behalf')}</p>
            </>,
            alignment,
            i18n.language,
            isMobile,
            t,
            analytics,
        ),
    }

    useEffect(() => {
        const svgs = async () => {
            const reqSvgs = await require.context('./', true, /\.png$/)
            return reqSvgs.keys().map((path, index) => ({
                path,
                file: reqSvgs(path),
                imageClass: `image${index}`,
                bannerText: bannerTexts[path?.replaceAll('./', '').split(isMobile ? '-' : '.')[0]],
            }))
        }

        async function getSvgs() {
            const x = await svgs()
            setImagesSrc(x)
        }
        getSvgs()
    }, [setImagesSrc, t, isMobile])

    return (
        <>
            <CarouselImageSet
                isMobile={isMobile}
                imagesSrc={
                    isMobile
                        ? imagesSrc.filter(
                              (imageSrc) =>
                                  imageSrc?.path?.includes('mobile') &&
                                  !imageSrc?.path?.includes(`${i18n.language === 'ar' ? 'en' : 'ar'}`),
                          )
                        : imagesSrc.filter(
                              (imageSrc) =>
                                  !imageSrc?.path?.includes('mobile') &&
                                  !imageSrc?.path?.includes(`${i18n.language === 'ar' ? 'en' : 'ar'}`),
                          )
                }
                banner={true}
            />
        </>
    )
}

export default MainSection
