import React from 'react'
import { useTranslation } from 'react-i18next'
import { SHOPINIWORLD_LINK } from '../../constants/constants'

const AboutUs = () => {
    const { t, i18n } = useTranslation()
    return (
        <div className="global-section-container flex-col">
            <div
                className={`main-section-container container ${i18n.language === 'ar' ? 'rtl-direction' : 'ltr-direction'} text-start`}
            >
                <div id="direction_conditions" className="widget__inner mobile-no-padding">
                    <h1 className="sub-page-header mb-5">{t('About Us')}</h1>
                    <p>
                        <strong>
                            <a className="mx-1" target="_blank" href={`https://${SHOPINIWORLD_LINK}`} rel="noreferrer">
                                {SHOPINIWORLD_LINK}
                            </a>
                            {t(
                                'is a website owned and operated by Shopini Logistics INC in USA and Shopini Logistics LLC in UAE',
                            )}
                        </strong>
                    </p>
                    <p>
                        {t(
                            'Shopiniworld was established to serve international shoppers get what they need and wants from overseas markets like the US, Turkey and Dubai',
                        )}
                    </p>
                    <p>{t('To deliver on our service promises')}</p>
                    <p>{t('We love making it easy for our customers shop US stores')}</p>
                    <p>{t('We have passion for delivering excellence in shopping and shipping')}</p>
                </div>
            </div>
        </div>
    )
}

export default AboutUs
