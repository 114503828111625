import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { postData } from '../../utils/api'
import { useAuth } from '../../contexts/AuthContext'
import _, { isEmpty } from 'lodash'
import { PROMOS } from '../../constants/constants'
import PaypalBtn from './PaypalButton.svg'

const PayPalButton = ({
    setLoading,
    creditCardDetails,
    toggleSaveCard,
    setLoaderMessage,
    totalPayment,
    parcels,
    paymentType,
    addOnServices,
    paymentRequestData,
    paymentDetails,
    setIsPaypal,
    mobileAuthToken = '',
    onPaymentCompletion = null,
    walletAmount,
    walletBalance,
    analyticsEventAttributes,
}) => {
    const { t, i18n } = useTranslation()
    const { authToken } = useAuth()
    const [paypalActive, setPaypalActive] = useState(false)
    const [paypalActiveLoading, setPaypalActiveLoading] = useState(false)
    useEffect(() => {
        setPaypalActive(false)
        if (setIsPaypal) {
            setIsPaypal(true)
        }
    }, [])

    useEffect(async () => {
        if (totalPayment !== 0 && creditCardDetails?.name !== '') {
            setPaypalActive(true)
        } else {
            if (document.getElementById('AmazonPayButton')) {
                document.getElementById('AmazonPayButton')?.remove()
            }
        }
    }, [totalPayment, creditCardDetails?.name, paymentDetails, walletAmount, JSON.stringify(paymentRequestData)])

    const paypalButtonClick = async () => {
        setPaypalActiveLoading(true)
        var data =
            paymentType === 'shipping'
                ? {
                      parcel_ids: _.map(parcels, 'id'),
                      parcel_uuids: _.map(parcels, 'uuid'),
                      promo_code: paymentRequestData?.promo_code_discount?.is_valid
                          ? paymentRequestData?.promo_code_discount?.discount_code
                          : paymentRequestData?.promo_code || null,
                      country_id: paymentRequestData?.address?.country,
                      add_on_services: addOnServices,
                      user_address_id: paymentRequestData?.address?.id,
                      total_amount: paymentRequestData?.total,
                      shipping_service: paymentRequestData?.service,
                      shipping_company_id: paymentRequestData?.shipping_company_id,
                      card: {
                          name: creditCardDetails.name,
                          digits: creditCardDetails.number,
                      },
                      wallet_amount: walletAmount,
                  }
                : paymentType === 'consolidation'
                  ? {
                        parcel_ids: paymentRequestData?.parcel_ids,
                        parcel_uuids: paymentRequestData?.parcel_uuids,
                        promo_code: paymentRequestData?.promo_code || null,
                        total_amount: paymentRequestData?.amount,
                        shipping_service: paymentRequestData?.shipping_service,
                        shipping_company_id: paymentRequestData?.shipping_company_id,
                        user_address_id: paymentRequestData?.user_address?.id
                            ? paymentRequestData?.user_address?.id
                            : null,
                        country_id: paymentRequestData?.user_address?.country || null,
                        is_consolidation: true,
                        card: {
                            name: creditCardDetails.name,
                            digits: creditCardDetails.number,
                        },
                        wallet_amount: walletAmount,
                    }
                  : paymentType === 'buy-it'
                    ? {
                          ...paymentRequestData,
                          total_amount: paymentRequestData?.card_amount,
                          card: {
                              name: creditCardDetails.name,
                              digits: creditCardDetails.number,
                          },
                      }
                    : paymentType === 'add-card'
                      ? {
                            card: {
                                name: creditCardDetails.name,
                                digits: creditCardDetails.number,
                            },
                        }
                      : paymentType === 'membership'
                        ? {
                              total_amount: paymentDetails?.promo_code
                                  ? paymentDetails?.discountedTotal
                                  : paymentDetails?.grandTotal,
                              card: {
                                  name: creditCardDetails.name,
                                  digits: creditCardDetails.number,
                              },
                              discount: paymentDetails?.promo_code
                                  ? {
                                        original_amount: paymentDetails?.discount?.original_amount || 0,
                                        discounted_amount: paymentDetails?.discount?.discounted_amount || 0,
                                        promo_code_value: paymentDetails?.discount?.promo_code_value || 0,
                                        promo_code_type: paymentDetails?.discount?.promo_code_type || '',
                                        promo_code: paymentDetails?.promo_code || '',
                                    }
                                  : null,
                              is_yearly: paymentDetails?.is_yearly,
                          }
                        : {
                              total_amount: paymentDetails?.promo_code
                                  ? paymentDetails?.discountedTotal
                                  : paymentDetails?.grandTotal,
                              card: {
                                  name: creditCardDetails.name,
                                  digits: creditCardDetails.number,
                              },
                              discount: paymentDetails?.promo_code
                                  ? {
                                        original_amount: paymentDetails?.discount?.original_amount || 0,
                                        discounted_amount: paymentDetails?.discount?.discounted_amount || 0,
                                        promo_code_value: paymentDetails?.discount?.promo_code_value || 0,
                                        promo_code_type: paymentDetails?.discount?.promo_code_type || '',
                                        promo_code: paymentDetails?.promo_code || '',
                                    }
                                  : null,
                              membership_plan_id: paymentRequestData?.membership_plan_id,
                              is_yearly: paymentDetails?.is_yearly,
                          }
        if (analyticsEventAttributes) data['analytics_event'] = analyticsEventAttributes
        data['payment_method'] = 'PayPal'
        data['wallet_balance'] = walletBalance ?? 0
        let paymentDataType = paymentType === 'shipping' || paymentType === 'consolidation' ? 'parcel' : paymentType

        let token = authToken && authToken !== '' ? authToken : mobileAuthToken
        const response = await postData(
            'paymentService',
            '/paypal/agreement?payment_type=' + paymentDataType,
            data,
            token,
        )
        if (response?.payload && response.status === true) {
            window.location = response?.payload?.agreement_approval_link
        }
    }

    return (
        <Row>
            <Col lg={6}></Col>
            <Col lg={6} className=" text-end paypal">
                {paypalActive && !paypalActiveLoading && (
                    <img src={PaypalBtn} className="my-3 tr-cursor" onClick={(e) => paypalButtonClick()} />
                )}
                {paypalActive && paypalActiveLoading && (
                    <div className="paypal-button">
                        <span className="spinner"></span>
                        <img src={PaypalBtn} className="my-3 paypal-deactive" />
                    </div>
                )}
            </Col>
        </Row>
    )
}

export default PayPalButton
