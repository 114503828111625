import React from 'react'
import Whatsapp from './whatsApp.svg'
import PhoneIcon from './Phone.svg'
import EmailIcon from './Email.svg'
import { whatsappShopiniUrl } from '../../constants/constants'
import { officeLocationsFlags } from '../../constants/constants'
import { useTranslation } from 'react-i18next'

const WHATSAPP_ONLY_COUNTRIES = ['AE', 'SA']

const AddressCard = ({ location, isMobile }) => {
    const { t } = useTranslation()
    const { country_code, country_name, address, whatsapp, phone, email } = location ? location : {}
    const flag = officeLocationsFlags.find((flag) => flag.includes(country_code))
    return (
        <div className={`bg-white ${isMobile && 'p-3'} ${address && 'mx-3 mb-5'}`}>
            <img className="mb-4 w-15" src={flag} />
            <h5 className=" mb-4">{country_name}</h5>
            <p className={address && ['sa', 'Basra'].includes(address) ? 'd-none' : ''}>{address && address}</p>
            <a
                href={whatsappShopiniUrl(whatsapp)}
                role="button"
                className={`${address ? 'text-dark' : ''} text-decoration-none d-block mb-4`}
            >
                <img alt="..." src={Whatsapp} />
                <p className="ltr-direction d-inline mx-1">
                    {whatsapp}
                    {WHATSAPP_ONLY_COUNTRIES.includes(country_code) ? (
                        <>
                            &nbsp;<span className="fs-10px">{t('WhatsApp only text')}</span>
                        </>
                    ) : null}
                </p>
            </a>
            {!WHATSAPP_ONLY_COUNTRIES.includes(country_code) ? (
                <>
                    <a className={`${address ? 'text-dark' : ''} text-decoration-none d-block mb-4`}>
                        <img alt="..." src={PhoneIcon} />
                        <p className="ltr-direction d-inline mx-1">{phone}</p>
                    </a>
                </>
            ) : null}
            <a href={`mailto:${email}`} className={`${address ? 'text-dark' : ''} text-decoration-none d-block mb-4`}>
                <img alt="..." src={EmailIcon} />
                <p className="ltr-direction d-inline mx-1">{email}</p>
            </a>
        </div>
    )
}

export default AddressCard
