import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { height } from '@mui/system'

const Box1 = styled.div`
    content: ' ';
    height: 0;
    padding-top: 91.38%;
    background-image: url(${require('./box1.svg').default});
    background-size: 100% 100%;
    width: 235px;

    html[dir='rtl'] & {
        background-image: url(${require('./box1-ar.svg').default});
    }

    @media (min-width: 1200px) {
        width: 174px;
    }
`

const Arrow = styled.div`
    content: ' ';
    height: 0;
    padding-top: 56.5%;
    background-image: url(${require('./arrow.gif').default});
    background-size: 100% 100%;
    width: 45px;
    transform: rotate(90deg);

    @media (min-width: 1200px) {
        width: 51px;
        transform: rotate(0);
        html[dir='rtl'] & {
            transform: scaleX(-100%);
        }
    }
`

const Box2 = styled.div`
    content: ' ';
    height: 0;
    padding-top: 133.33%;
    background-image: url(${require('./box2.svg').default});
    background-size: 100% 100%;
    width: 158px;

    html[dir='rtl'] & {
        background-image: url(${require('./box2-ar.svg').default});
    }

    @media (min-width: 1200px) {
        width: 117px;
    }
`

const VerifiedIcon = styled.div`
    content: ' ';
    display: inline-block;
    width: 56px;
    height: 56px;
    background-image: url(${require('./success.gif').default});
    background-size: 100% 100%;
`

const NoHiddenFeesWrapper = styled.div`
    padding: 14px 6px;
    background-color: white;
    border-radius: 6px;
    width: 190px;

    @media (min-width: 1200px) {
        width: 140px;
    }
`

const NoHiddenFees = () => {
    return (
        <NoHiddenFeesWrapper>
            <div className="text-center">
                <VerifiedIcon />
            </div>
            <div className="text-center fs-13px font-weight-normal">
                <Trans i18nKey="TRUE_WEIGHT_you-pay-for">
                    <span className="text-blue-color font-weight-bolder"></span>
                    <br />
                    <span className="color-green"></span>
                </Trans>
            </div>
        </NoHiddenFeesWrapper>
    )
}

const TrueWeightWrapper = styled.div`
    position: relative;
    overflow: visible;
    padding: 12px 0;
    background: linear-gradient(0deg, rgba(34, 209, 128, 0.13), rgba(34, 209, 128, 0.13)), #ffffff;
    border: 2.15385px solid #22d180;
    border-radius: 11px;

    @media (min-width: 1200px) {
        padding-right: 6.11%;
        padding-left: 6.11%;
    }
`

const ShopiniTrueWeight = () => {
    return (
        <div>
            <span className="fw-500">ShopiniWorld</span>{' '}
            <span className="font-weight-bolder text-blue-color">True</span>
            <span className="font-weight-bolder color-green">Weight</span>
        </div>
    )
}

const VIcon = styled.img`
    position: absolute;
    bottom: 1px;
    right: calc(50% + 6px);
    transform: translate(50%, 100%);
`

const TrueWeight = () => {
    return (
        <TrueWeightWrapper className="d-flex justify-content-xl-center align-items-center flex-column flex-xl-row">
            <div className="d-block d-xl-none mb-3">
                <ShopiniTrueWeight />
            </div>
            <div className="mb-3 mb-xl-0">
                <Box1 className="" />
            </div>
            <div className="mb-3 mb-xl-0 mx-xl-4">
                <Arrow />
            </div>
            <div className="d-flex flex-column align-items-center mb-3 mb-xl-0 mx-xl-4">
                <div className="d-none d-xl-block mb-3">
                    <ShopiniTrueWeight />
                </div>
                <NoHiddenFees />
            </div>
            <div className="mb-3 mb-xl-0 mx-xl-4">
                <Arrow />
            </div>
            <div>
                <Box2 className="mx-xl-3 mx-xl-4" />
            </div>
            <VIcon src={require('./v.svg').default} height={25} width={23} alt="" />
        </TrueWeightWrapper>
    )
}

export default TrueWeight
