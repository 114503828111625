import React, { useEffect, useState } from 'react'
import { Container, Button, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import './banner.css'
import { MEMBERSHIP_PLANS } from '../../../constants/navigationConstants'
import CarouselImageSet from '../../../pages/homepage/shopUsBrandsSection/carouselImageSet'
import { useMediaQuery } from 'react-responsive'

const commonContainer = (content, alignment, language, isMobile, t) => (
    <div
        className={`${alignment} position-absolute banner-text-position-${language}${isMobile ? '-mobile container' : ''}`}
    >
        {content}
    </div>
)

function Banner() {
    const { t, i18n } = useTranslation()
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

    const alignment = i18n.language === 'en' ? 'ltr' : 'rtl'

    const [imagesSrc, setImagesSrc] = useState([])
    const bannerTexts = {
        //[`1${i18n.language}`]: commonContainer(SinglesDayText(t, i18n.language, isMobile), alignment, i18n.language, isMobile, t),
        ['1']: commonContainer(
            <>
                <h1 className="xl-font mb-0">{t('Smart Shopping')}</h1>
                <h1 className="xl-font">{t('Made Easy')}</h1>
            </>,
            alignment,
            i18n.language,
            isMobile,
            t,
        ),
    }

    useEffect(() => {
        const svgs = async () => {
            const reqSvgs = await require.context('./', true, /\.png$/)
            return reqSvgs.keys().map((path, index) => ({
                path,
                file: reqSvgs(path),
                imageClass: `image${index}`,
                bannerText: bannerTexts[path?.replaceAll('./', '').split(isMobile ? '-' : '.')[0]],
            }))
        }

        async function getSvgs() {
            const x = await svgs()
            setImagesSrc(x)
        }
        getSvgs()
    }, [setImagesSrc, t, isMobile])

    return (
        <CarouselImageSet
            isMobile={isMobile}
            imagesSrc={
                isMobile
                    ? imagesSrc.filter(
                          (imageSrc) =>
                              imageSrc?.path?.includes('mobile') &&
                              !imageSrc?.path?.includes(`${i18n.language === 'ar' ? 'en' : 'ar'}`),
                      )
                    : imagesSrc.filter(
                          (imageSrc) =>
                              !imageSrc?.path?.includes('mobile') &&
                              !imageSrc?.path?.includes(`${i18n.language === 'ar' ? 'en' : 'ar'}`),
                      )
            }
            banner={true}
        />
    )
}

export default Banner
