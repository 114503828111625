import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Container, Form, Table, useAccordionToggle } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import arrowDown from './arrowDown.svg'
import arrowUp from './arrowUp.svg'
import * as UserInfo from '../../services/userInfoService'
import { humanize } from '../../utils/string'
import Loader from '../../components/common/loader'
import { getMaximumWeight, RATE_CALCULATOR_INPUTS, WEIGHT_TO_DIMENSION } from '../../constants/constants'
import PhoneInput from 'react-phone-input-2'
import ReactFlagsSelect from 'react-flags-select'
import { postData } from '../../utils/api'
import useCountries from '../../utils/useCountries'
import { Alert } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext'
import { useMediaQuery } from 'react-responsive'
//import { Event } from "@material-ui/icons";
import './form.scss'
import { Link } from 'react-router-dom'
import { FAQ, REGISTRATION_ADDRESS, REGISTRATION_FORM } from '../../constants/navigationConstants'

const { KG, LB, CM, INCH } = WEIGHT_TO_DIMENSION

function CalculateShippingFees() {
    const { t, i18n } = useTranslation()
    //original state of expanded in the advanced calculator section
    const [expanded, setExpanded] = useState(true)

    const { countries } = useCountries()
    const { warehousesList, setWarehouseId, warehouseId } = useAuth()
    const alignment = i18n.language === 'en' ? 'ltr' : 'rtl'
    const classNameForPhoneInput = i18n.language === 'ar' ? 'ltr-direction w-100' : 'w-100'

    const [floater, setFloater] = useState('')

    const [rateCalculatorData, setRateCalculatorData] = useState({
        country_id: 'AE',
    })

    const [countryCode, setCountryCode] = useState({
        country_id: ' ',
    })

    const [countryData, setCountryData] = useState({
        country_id: 0,
        weight: '',
        height: '',
        width: '',
        length: '',
        weight_unit: LB,
        dimension_unit: INCH,
    })

    const [contactUserInfo, setContactUserInfo] = useState({
        name: '',
        email: '',
        phone: '',
        country: 'AE',
        code: '',
    })
    const isTablet = useMediaQuery({
        query: '(min-device-width: 768px) and (max-device-width: 1024px) and (orientation:portrait)',
    })
    const [chargesList, setChargesList] = useState([])
    const [chargeableWeight, setChargeableWeight] = useState(0)
    const [chargeableWeightUnit, setChargeableWeightUnit] = useState('lb')
    const [showLoader, setShowLoader] = useState(false)
    const [showResuilts, setShowResuilts] = useState(false)
    const [validationInput, setValidationInput] = useState('')
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const maxWeight = getMaximumWeight(rateCalculatorData.weight_unit)
    const [submitResult, setSubmitResult] = useState({
        message: '',
        severity: '',
    })
    const [formValues, setFormValues] = useState([
        {
            weight: ' ',
            weight_unit: LB,
            height: ' ',
            width: ' ',
            length: ' ',
            dimention_unit: INCH,
            quantity: ' ',
        },
    ])

    useEffect(() => {
        setFloater(i18n.language === 'ar' ? 'float-left' : 'float-right')
    }, [])

    let name, value

    const handleWeightChange = (event) => {
        name = event.target.name
        value = event.target.value
        event.target.value === KG
            ? setRateCalculatorData({
                  ...rateCalculatorData,
                  dimension_unit: CM,
                  [name]: value,
              })
            : setRateCalculatorData({
                  ...rateCalculatorData,
                  dimension_unit: INCH,
                  [name]: value,
              })
    }

    const handleDimensionChange = (event) => {
        name = event.target.name
        value = event.target.value
        event.target.value === CM
            ? setRateCalculatorData({
                  ...rateCalculatorData,
                  weight_unit: KG,
                  [name]: value,
              })
            : setRateCalculatorData({
                  ...rateCalculatorData,
                  weight_unit: LB,
                  [name]: value,
              })
    }

    const openModal = (modalId) => {
        document.querySelector(modalId).click()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (rateCalculatorData.weight !== '' && parseInt(rateCalculatorData.weight) >= maxWeight && maxWeight !== 0) {
            openModal('#contactUsFormModal')
        } else {
            let calculaterData = {
                country_code: contactUserInfo.country,
                boxes: formValues,
            }
            setChargesList([])
            setShowLoader(true)
            setShowResuilts(false)
            setError(null)
            openModal('#chargesModal')
            try {
                const updateBoxes = [...calculaterData.boxes].map((box) => ({
                    ...box,
                    width: typeof box.width === 'number' ? box.width : 1,
                    height: typeof box.height === 'number' ? box.height : 1,
                    length: typeof box.length === 'number' ? box.length : 1,
                }))
                calculaterData.boxes = updateBoxes
                const calculatedList = await UserInfo.postCalculatorRate(calculaterData, warehouseId, true)
                setChargesList(calculatedList.payload)
                setShowLoader(false)
                setShowResuilts(true)
            } catch (error) {
                console.error(error)
                setShowLoader(false)
                setError('Currently no service available, please try later.')
            }
        }
    }

    const validateInputs = () => {
        setValidationInput('rate-calculator-input-validation')
    }
    let addFormFields = () => {
        setFormValues([
            ...formValues,
            {
                dimention_unit: formValues[0].dimention_unit ?? INCH,
                weight_unit: formValues[0].weight_unit ?? LB,
            },
        ])
    }

    const removeFormFields = (i, e) => {
        let newFormValues = [...formValues]
        newFormValues.splice(i, 1)
        setChargesList([])
        setShowResuilts(false)
        setFormValues(newFormValues)
    }

    const [changeDimsValue, setChangeDimsValue] = useState(KG)

    let handleChangeUnits = (i, e) => {
        let newFormValues = [...formValues]
        newFormValues[i][e.target.name] = e.target.value

        e.target.value === INCH ? setChangeDimsValue(LB) : setChangeDimsValue(KG)

        setFormValues(newFormValues)
    }

    let handleChange = (i, e) => {
        let newFormValues = [...formValues]
        newFormValues[i][e.target.name] = e.target.value
        setFormValues(newFormValues)
    }

    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionToggle(eventKey, () => setExpanded(!expanded))

        return (
            <div>
                <Button variant="link" onClick={decoratedOnClick} className="advanced-calculater">
                    {children}
                </Button>
            </div>
        )
    }

    useEffect(() => {
        setRateCalculatorData({
            ...rateCalculatorData,
            length: '1',
            height: '1',
            width: '1',
        })
    }, [expanded])

    const createWarehousesLabels = () => {
        const customLabels = {}
        warehousesList?.forEach(
            (w) =>
                (customLabels[w.country] = {
                    primary: w.name,
                    secondary: w.active ? '' : <div className="mx-1 coming-soon orange-bg">{t('Coming soon')}</div>,
                }),
        )
        return customLabels
    }

    return (
        <>
            <div className="mx-3 mx-sm-0">
                <div className="mx-auto contents-mw">
                    <div className="bg-color-light-grey">
                        <div>
                            <p class="box-tile-main text-center shipping-made-simple">{t('Shipping Made Simple')}</p>
                            <p class="font-size-17 pt-20 box-sub-tile-main text-center where-do-you-want">
                                {t('Where do you want to send a package')}
                            </p>
                            <Form className="max-width calculater-box bg-color-light-grey" onSubmit={handleSubmit}>
                                <Form.Row>
                                    <Row className="col-md-12 m-0 mb-2 calculater waite-bg row-format">
                                        <Col md="6">
                                            <Form.Group as={Col} controlId="formShipParcelFrom" className="pm-zero">
                                                <Form.Label className={`label-pading required ${alignment}`}>
                                                    {t('Ship Parcel from')}
                                                </Form.Label>
                                                <ReactFlagsSelect
                                                    countries={
                                                        warehousesList?.map((warehouse) => warehouse.country) || 'US'
                                                    }
                                                    customLabels={createWarehousesLabels()}
                                                    className={`ltr-direction text-muted`}
                                                    selected={
                                                        warehousesList?.find((w) => w.id === warehouseId)?.country
                                                    }
                                                    onSelect={(code) => {
                                                        if (warehousesList?.find((w) => w.country === code)?.active) {
                                                            setWarehouseId(
                                                                warehousesList?.find((w) => w.country === code)?.id,
                                                            )
                                                        } else {
                                                            setWarehouseId(1)
                                                        }

                                                        const updatedFormValues =
                                                            (code ?? ' ').toLowerCase() === 'us'
                                                                ? formValues.map((element) => ({
                                                                      ...element,
                                                                      weight_unit: LB,
                                                                      dimention_unit: INCH,
                                                                  }))
                                                                : formValues.map((element) => ({
                                                                      ...element,
                                                                      weight_unit: KG,
                                                                      dimention_unit: CM,
                                                                  }))
                                                        setFormValues(updatedFormValues)
                                                    }}
                                                    onFocus={(e) => {
                                                        if (e.target.autocomplete) {
                                                            e.target.autocomplete = 'noautocomplete'
                                                        }
                                                    }}
                                                    showSelectedLabel
                                                    placeholder={t('Select a warehouse')}
                                                    alignOptionsToRight
                                                    fullWidth
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col md="6">
                                            <Form.Group as={Col} controlId="formShipParcelTo" className="w-100 pm-zero">
                                                <Form.Label className={`required ${alignment}`}>
                                                    {t('Ship parcel to')}
                                                </Form.Label>

                                                <ReactFlagsSelect
                                                    countries={countries?.map((country) => country.iso2_code)}
                                                    className={`ltr-direction text-muted`}
                                                    selected={contactUserInfo.country}
                                                    onSelect={(code) => {
                                                        setContactUserInfo({
                                                            ...contactUserInfo,
                                                            country: code,
                                                            code: code,
                                                        })
                                                    }}
                                                    searchable
                                                    showSelectedLabel
                                                    alignOptionsToRight
                                                    fullWidth
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {formValues.map((element, index) => (
                                        <>
                                            <Row className="col-lg-12 col-md-12 m-0 mb-2 form-body row-format waite-bg">
                                                <div
                                                    key={index}
                                                    className="calculater form-row form-row-dispay col-md-12"
                                                >
                                                    <Container>
                                                        <Row className="">
                                                            <Col lg={2} xs={3}>
                                                                <Form.Group controlId="parcelWeight">
                                                                    <Form.Label className={`label-pading required`}>
                                                                        {t('parcel weight')}
                                                                    </Form.Label>
                                                                    <input
                                                                        type="number"
                                                                        step="0.01"
                                                                        className={`form-control ${validationInput}`}
                                                                        name="weight"
                                                                        value={element.weight}
                                                                        onChange={(e) => handleChange(index, e)}
                                                                        min={RATE_CALCULATOR_INPUTS.WEIGHT_MIN_VALUE}
                                                                        required
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={1} xs={3}>
                                                                <Form.Group controlId="wightUnit">
                                                                    <Form.Label className={`label-pading required`}>
                                                                        {t('Unit')}
                                                                    </Form.Label>
                                                                    <select
                                                                        className={`form-control ${validationInput}`}
                                                                        name="weight_unit"
                                                                        onChange={(e) => handleChange(index, e)}
                                                                        value={element.weight_unit}
                                                                        //value={changeDimsValue}
                                                                        //selected={changeDimsValue === element.weight_unit}
                                                                        required
                                                                    >
                                                                        <option value="kg">{t(KG)}</option>
                                                                        <option value="lb">{t(LB)}</option>
                                                                    </select>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={2} xs={3}>
                                                                <Form.Group controlId="no_of_parcels">
                                                                    <Form.Label className={`label-pading required`}>
                                                                        {t('No of Parcels')}
                                                                    </Form.Label>
                                                                    <input
                                                                        type="number"
                                                                        step="1"
                                                                        className={`form-control ${validationInput}`}
                                                                        name="quantity"
                                                                        value={element.quantity || ''}
                                                                        onChange={(e) => handleChange(index, e)}
                                                                        required
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={1} xs={3}>
                                                                <Form.Group controlId="length">
                                                                    <Form.Label className="label-pading">
                                                                        {t('Length')}
                                                                    </Form.Label>
                                                                    <input
                                                                        type="number"
                                                                        step="1"
                                                                        className={`form-control ${validationInput}`}
                                                                        name="length"
                                                                        value={element.length || ''}
                                                                        onChange={(e) => handleChange(index, e)}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={2} xs={4}>
                                                                <Form.Group controlId="width">
                                                                    <Form.Label className="label-pading">
                                                                        {t('Width')}
                                                                    </Form.Label>
                                                                    <input
                                                                        type="number"
                                                                        step="1"
                                                                        className={`form-control ${validationInput}`}
                                                                        name="width"
                                                                        value={element.width || ''}
                                                                        onChange={(e) => handleChange(index, e)}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={2} xs={4}>
                                                                <Form.Group controlId="width">
                                                                    <Form.Label className="label-pading">
                                                                        {t('Height')}
                                                                    </Form.Label>
                                                                    <input
                                                                        type="number"
                                                                        step="1"
                                                                        className={`form-control ${validationInput}`}
                                                                        name="height"
                                                                        value={element.height || ''}
                                                                        onChange={(e) => handleChange(index, e)}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={1} xs={index ? 3 : 4}>
                                                                <Form.Group controlId="dims">
                                                                    <Form.Label className={`label-pading required`}>
                                                                        {t('Dims')}
                                                                    </Form.Label>
                                                                    <select
                                                                        className={`form-control ${validationInput}`}
                                                                        name="dimention_unit"
                                                                        value={element.dimention_unit || ''}
                                                                        onChange={(e) => handleChangeUnits(index, e)}
                                                                        required
                                                                    >
                                                                        <option value={CM}>{t(CM)}</option>
                                                                        <option value={INCH}>{t(INCH)}</option>
                                                                    </select>
                                                                </Form.Group>
                                                            </Col>
                                                            {index ? (
                                                                <Col lg={1} xs={1} className="text-align-right p-0">
                                                                    <div>&nbsp;</div>

                                                                    <div
                                                                        className={`${
                                                                            alignment === 'rtl'
                                                                                ? 'text-left text-lg-center'
                                                                                : 'text-right text-lg-center'
                                                                        }`}
                                                                    >
                                                                        <button
                                                                            type="button"
                                                                            className={`no-opacity close float-none d-inline-block mt-2 ${
                                                                                alignment === 'rtl' ? 'ml-1' : 'mr-1'
                                                                            }`}
                                                                        >
                                                                            <i
                                                                                className="bx bx-x bx-x-custom mt-2"
                                                                                onClick={(e) => {
                                                                                    removeFormFields(index, e)
                                                                                }}
                                                                            ></i>
                                                                        </button>
                                                                    </div>
                                                                </Col>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </Row>
                                                    </Container>
                                                </div>
                                            </Row>
                                        </>
                                    ))}

                                    <Container className="max-width-containers">
                                        <Row>
                                            <div
                                                className="add-button add text-center text-sm-right w-100 py-3 pt-sm-4 pb-sm-3"
                                                style={{ color: '#0000FF' }}
                                                onClick={() => addFormFields()}
                                            >
                                                <div className={`pb-sm-3 text-center cursor-pointer`}>
                                                    {t('Add another package +')}
                                                </div>
                                            </div>
                                        </Row>
                                    </Container>
                                    <div className="w-100 text-center form-padding">
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-blue w-100"
                                            onClick={validateInputs}
                                        >
                                            {t('Calculate shipping cost button')}
                                        </button>
                                    </div>
                                </Form.Row>
                            </Form>
                        </div>
                    </div>
                    <a
                        id="chargesModal"
                        data-toggle="modal"
                        data-target="#charges-table-modal"
                        className="bg-color-light-grey"
                    />
                    <a
                        id="contactUsFormModal"
                        data-toggle="modal"
                        data-target="#contact-us-form"
                        className="bg-color-light-grey"
                    />
                </div>
            </div>
            {showResuilts || showLoader ? (
                <div className="mx-1 px-sm-0">
                    <div className="bg-white results-padding-top">
                        <div className="shipping-costs-results contents-mw mx-auto">
                            <div className="results-container pb-3">
                                <TableDisplay
                                    showResuilts={showResuilts}
                                    showLoader={showLoader}
                                    chargesList={chargesList}
                                    rateCalculatorData={rateCalculatorData}
                                />
                                {!!error && (
                                    <Alert className="m-0" variant={'danger'}>
                                        {error}
                                    </Alert>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
            {showResuilts && chargesList.length ? (
                <div className="calculator-foot-fs3 px-3 px-sm-0">
                    <div style={{ padding: '67px 0', maxWidth: '1200px' }} className="mx-auto">
                        <div className={`${alignment === 'rtl' ? 'text-right' : 'text-left'}`}>
                            <div className="row justify-content-between">
                                <div className="col-12 col-sm-6 px-3 mb-3" style={{ maxWidth: '536px' }}>
                                    <p className="font-weight-bold">{`${t('Overweight Piece')}:`}</p>
                                    <p className="">
                                        {`${t(
                                            'Additional charges will be added if any piece, or pallet, exceeds 70 kg in either actual or volume weight',
                                        )}`}
                                    </p>
                                </div>
                                <div className="col-12 col-sm-6 px-3 mb-3" style={{ maxWidth: '500px' }}>
                                    <p className="font-weight-bold">{`${t('Address Correction')}:`}</p>
                                    <p className="">
                                        {`${t(
                                            'Additional charges will be added for adding an incorrect address at time of shipping or pickup causing delivery failure',
                                        )}`}
                                    </p>
                                </div>
                            </div>
                            <div className="row justify-content-between">
                                <div className="col-12 col-sm-6 px-3 mb-3" style={{ maxWidth: '536px' }}>
                                    <p className="font-weight-bold">{`${t('Oversized Piece')}:`}</p>
                                    <p className="">
                                        {`${t('Additional charges will be added if the dimensions exceed 120 cm')}`}
                                    </p>
                                </div>
                                <div className="col-12 col-sm-6 px-3 mb-3" style={{ maxWidth: '500px' }}>
                                    <p className="font-weight-bold">{`${t('Insurance')}:`}</p>
                                    <p className="">{`${t('1 calculated by declared shipment value')}`}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}

            <div className="calculator-faq-container">
                <div
                    className={`faq-link d-flex flex-column justify-content-center align-items-center`}
                    onClick={() => {
                        document.getElementById('calculator-faq')?.click()
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth',
                        })
                    }}
                >
                    <div className="faq-div"></div>
                    <p className="text-blue text-center font-weight">{t('FAQs')}</p>
                </div>
            </div>
            <Link
                id="calculator-faq"
                to={{
                    pathname: `${FAQ}?lang=${i18n.language}`,
                    state: 'shipping-rates-calculations-tab',
                }}
            ></Link>
        </>
    )
}

export default CalculateShippingFees

const TableDisplay = (props) => {
    const { t, i18n } = useTranslation()
    const alignment = i18n.language === 'en' ? 'ltr' : 'rtl'
    return (
        <div className="table-wrapper-scroll-y overflow-x-hidden w-100 mr-0">
            {props.showLoader ? (
                <div className="pb-5">
                    <Loader />
                </div>
            ) : null}

            {props.showResuilts &&
                (props.chargesList?.length ? (
                    <div>
                        <Row>
                            <Col xs={4} className="mb-3">
                                <div
                                    className={`h-100 d-flex flex-column align-items-center justify-content-start weight-box resuilts-box ${
                                        alignment === 'rtl' ? 'text-right' : 'text-left'
                                    }`}
                                >
                                    <div className="w-100 d-flex flex-column align-items-start justify-content-center px-4">
                                        <div className={`margin-zero mt-3`}>
                                            <span>{t('Actual weight')}</span>
                                        </div>
                                        <div className={`margin-zero mb-3 `}>
                                            <span className="resuilts-box-w font-weight-bold">
                                                {typeof props.chargesList[0].total_weight === 'number' &&
                                                props.chargesList[0].total_chargable_weight_unit
                                                    ? `${props.chargesList[0].total_weight} ${t(
                                                          props.chargesList[0].total_chargable_weight_unit,
                                                      )}`
                                                    : 'N/A'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={4} className="mb-3">
                                <div
                                    className={`h-100 d-flex flex-column align-items-center justify-content-between weight-box resuilts-box ${
                                        alignment === 'rtl' ? 'text-right' : 'text-left'
                                    }`}
                                >
                                    <div className="w-100 d-flex flex-column align-items-start justify-content-center px-4">
                                        <div className={`margin-zero mt-3 `}>
                                            <span>{t('Volumetrice weight')}</span>
                                        </div>

                                        <div className={`margin-zero mb-3 `}>
                                            <span className="resuilts-box-w font-weight-bold">
                                                {typeof props.chargesList[0].total_volume_weight === 'number' &&
                                                props.chargesList[0].total_chargable_weight_unit
                                                    ? `${props.chargesList[0].total_volume_weight} ${t(
                                                          props.chargesList[0].total_chargable_weight_unit,
                                                      )}`
                                                    : 'N/A'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={4} className="mb-3">
                                <div
                                    className={`h-100 d-flex flex-column align-items-center justify-content-between weight-box resuilts-box ${
                                        alignment === 'rtl' ? 'text-right' : 'text-left'
                                    }`}
                                >
                                    <div className="w-100 d-flex flex-column align-items-start justify-content-center px-4">
                                        <div className={`margin-zero mt-3 `}>
                                            <span>{t('Chargable weight')}</span>
                                        </div>
                                        <div className={`margin-zero mb-3 font-weight-bold`}>
                                            <span className="resuilts-box-w">
                                                {typeof props.chargesList[0].total_chargable_weight === 'number' &&
                                                props.chargesList[0].total_chargable_weight_unit
                                                    ? `${props.chargesList[0].total_chargable_weight} ${t(
                                                          props.chargesList[0].total_chargable_weight_unit,
                                                      )}`
                                                    : 'N/A'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <p class="section-title text-center py-3 py-sm-5">{t('Your Estimated Shipping Cost')}</p>
                    </div>
                ) : (
                    <div className="d-flex flex-column align-items-center justify-content-center no-results-container">
                        <div className="d-inline-block no-results-img"></div>
                        <span className="no-results-custom-font-1">{t('No Rates Available')}&nbsp;</span>
                        <span className="no-results-custom-font-2">
                            {t("Rates aren't available for this weight to this destination")}
                            &nbsp;.
                        </span>
                    </div>
                ))}

            <div>
                <Table striped hover className="mb-0">
                    <tbody>
                        {props.chargesList?.map((chargeCompany) => (
                            <tr
                                key={chargeCompany.service}
                                className="row tr-border justify-content-start text-start shipment-company-card mx-0"
                            >
                                <div
                                    className="col-3 col-sm-2 sc-logo"
                                    style={{
                                        backgroundImage: `url(${chargeCompany.company_logo})`,
                                    }}
                                />
                                <div className="col-5 col-sm-7 d-flex flex-column text-start">
                                    <p className="mb-2 font-weight-bold custom-fs-2">
                                        {humanize(chargeCompany?.service)}
                                    </p>
                                    {(
                                        chargeCompany[
                                            `${i18n.language !== 'en' ? `${i18n.language}_` : ''}description`
                                        ] ?? ''
                                    )
                                        .split(', ')
                                        .map((description) => (
                                            <div className="text-muted custom-fs-3 mb-1">{description}</div>
                                        ))}
                                </div>
                                <div className={`col-4 col-sm-3 ${alignment === 'rtl' ? 'text-left' : 'text-right'}`}>
                                    <span className="d-block font-weight-bold custom-fs-1">
                                        {parseFloat(chargeCompany.amount)?.toFixed(2)}{' '}
                                        {chargeCompany.currency ? t(`${chargeCompany.currency}-logo`) : ''}
                                    </span>
                                    <span className="d-block custom-fs-3">
                                        {chargeCompany?.eta_days?.split(' ')[0]}
                                        &nbsp;
                                        {t(chargeCompany?.eta_days?.split(' ')[1])}
                                    </span>
                                </div>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>

            {props.chargesList?.length ? (
                <div className="w-100 text-center my-5">
                    <Link to={REGISTRATION_FORM}>
                        <button type="button" className="btn btn-primary btn-blue d-sm-inline">
                            {t('Claim your address now')}
                        </button>
                    </Link>
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}
