import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    ACCOUNT_PAYMENT,
    DASHBOARD,
    LOGIN_PAGE,
    ONE_TIME_VERIFY_EMAIL,
    REGISTRATION_ADDRESS,
    VERIFY_EMAIL,
} from '../../constants/navigationConstants'
import { Link, Redirect } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
// import {importFromLocalStorageKey} from "../../utils/localStorageApi"

import SocialAuth from '../../components/socialAuth'
import { postData } from '../../utils/api'
import { isEmpty, uniqBy } from 'lodash'
import ReactFlagsSelect from 'react-flags-select'
import useCountries from '../../utils/useCountries'
import { getUserLogin, postRegister } from '../../services/loginRegistrationService'
import { MEMBERSHIP_CONSTANTS } from '../../constants/constants'
import PhoneInput from 'react-phone-input-2'
import { capitalizeFirstChar, checkStrValidity } from '../../utils/string'
import { Alert } from 'react-bootstrap'
import { getKeyByValue } from '../../utils/commonFunc'
import { ShowPassword } from '../../components/common'
import useAnalytics from '../../analytics/useAnalytics'
import { getUserDetails } from '../../services/userInfoService'
import * as moment from 'moment'
import { screenEvent } from '../../analytics/constants'

function RegistrationForm(props) {
    const { t, i18n } = useTranslation()
    //init the registration form
    // let fnameFromSession
    // let lnameFromSession
    // let emailFromSession
    // let passwordFromSession
    // let confirmPasswordFromSession
    const classNameForPhoneInput = i18n.language === 'ar' ? 'ltr-direction w-100' : 'w-100'
    const [errorList, setErrorList] = useState([])

    // try {
    //     fnameFromSession = importFromLocalStorageKey("fname") || ""
    //     lnameFromSession = importFromLocalStorageKey("lname") || ""
    //     emailFromSession = importFromLocalStorageKey("email") || ""
    //     passwordFromSession = importFromLocalStorageKey("password") || ""
    // 	confirmPasswordFromSession = importFromLocalStorageKey("confirmPassword") || ""

    // } catch (e) {
    //     console.log(e.message)
    // }
    const { countries } = useCountries()

    const [signUpUser, setSignUpUser] = useState({
        fname: '',
        lname: '',
        email: '',
        password: '',
        country: null,
        code: null,
        phone: null,
        receive_emails: 1,
        recieve_whatsapp: 1,
    })

    const [agreeBool, setAgreeBool] = useState(true)
    // const [receiveEmails, setReceiveEmails] = useState(true)
    const [isReferralCodeValid, setIsReferralCodeValid] = useState('validation')
    const {
        login,
        setUsername,
        setUserPin,
        setUserEmail,
        setAuthToken,
        membershipPlanID,
        setMembershipPlanID,
        setCountry,
        country,
        setPhone,
        setPostalCode,
        isSubYearly,
        loggedIn,
    } = useAuth()
    const [redirectToPayment, setRedirectToPayment] = useState(false)
    const [redirectToEmailVerification, setRedirectToEmailVerification] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [fieldValidations, setFieldValidations] = useState([])
    let name, value
    const analytics = useAnalytics()
    const handleInputs = async (event) => {
        name = event.target.name
        value = event.target.value
        if (event.target?.value?.length > Number(event.target?.max)) {
            event.preventDefault()
        }
        setErrorList([])
        setSignUpUser({ ...signUpUser, [name]: value })
        checkFieldValidity(value, name)
    }
    const handlePhoneInput = (phoneValue) => {
        setErrorList([])
        setSignUpUser({ ...signUpUser, phone: phoneValue })
    }

    // const redirectToRegisterAddress = () => {
    //     document.querySelector("#redirect-to-register-address").click()
    // }

    const redirectToLogin = () => {
        document.querySelector('#redirect-to-login')?.click()
    }

    const handleSetUserAnalyticsAttributes = async (token) => {
        const res = await getUserDetails(token)
        if (!res) return
        const { expiry_at, is_yearly } = res?.plan_data ?? {}
        const userAttributes = {
            firstName: res.fname ?? '',
            lastName: res.lname ?? '',
            phone: res.phone ?? '',
            email: res.email ?? '',
            country: res.country ?? '',
            membershipType: res.membership_plan_id ? MEMBERSHIP_CONSTANTS[`${res.membership_plan_id}`] : '',
            planType: !!is_yearly ? 'Yearly' : 'Monthly',
            language: `${i18n.language}`.toUpperCase() ?? '',
            membershipExpiryDate: expiry_at ? moment(expiry_at).toDate() : undefined,
            referralCode: res.referral_code ?? '',
            verificationDate: res.email_verified_at ? moment(res.email_verified_at).toDate() : undefined,
            signupDate: res.created_at ? moment(res.created_at).toDate() : undefined,
            receiveWhatsappUpdates: !!res.receive_whatsapp,
            receiveEmailUpdates: !!res.receive_emails,
        }
        analytics.attributes.setAttributes(userAttributes)
    }

    const handleRegister = (event, signUpUser, skipCountryAndPhone = false) => {
        if (event) event.preventDefault()
        // localStorage.setItem("fname", signUpUser.fname)
        // localStorage.setItem("lname", signUpUser.lname)
        // localStorage.setItem("email", signUpUser.email)
        // localStorage.setItem("password", signUpUser.password)
        // localStorage.setItem("loginVia", signUpUser.loginVia || "email")

        setUsername(signUpUser.fname)
        setUserEmail(signUpUser.email)
        // redirectToRegisterAddress(
        let registrationData = {
            ...signUpUser,
            membership_plan_id:
                signUpUser.membership_plan_id || membershipPlanID || props?.location?.state?.membership_plan_id || 1,
            login_via: signUpUser.login_via || 'email',
            is_yearly: isSubYearly,
        }
        if (isReferralCodeValid) {
            registrationData['referral_code'] = registrationData['referralCode']
        }
        delete registrationData['referralCode']
        const selectedCountry = signUpUser?.country
        if (selectedCountry) {
            const country = countries?.find((c) => c.iso2_code === selectedCountry.trim())?.iso2_code
            setCountry(country)
            registrationData = { ...registrationData, country: country, code: country }
        }
        async function register(data) {
            setErrorList([])
            try {
                setLoading(true)
                if (!data.phone && !skipCountryAndPhone) {
                    setErrorList([...errorList, { param: 'Phone', msg: 'Phone is required', value: null }])
                    setLoading(false)
                    throw new Error('Registeration failed')
                } else {
                    const res = await postRegister({ ...data, lang: i18n.language })

                    if (!res?.status) {
                        if (res?.error) {
                            setErrorList([{ param: t(`${res.code}.param`), msg: t(`${res.code}.msg`) }])
                            setLoading(false)
                            throw new Error('Registeration failed')
                        } else {
                            setErrorList(res?.payload)
                            setLoading(false)
                            throw new Error('Registeration failed')
                        }
                    } else {
                        setErrorList([])
                        const analyticsAttributes = {
                            firstName: registrationData.fname,
                            lastName: registrationData.lname,
                            phoneNumber: registrationData.phone,
                            countryCode: registrationData.country,
                            email: registrationData.email,
                            referralCode: registrationData.referralCode,
                            membershipType: MEMBERSHIP_CONSTANTS[registrationData.membership_plan_id],
                            receiveMarketing: !!registrationData.receive_emails,
                            receiveWhatsapp: !!registrationData.receive_whatsapp,
                            planType: !!registrationData.is_yearly ? 'Yearly' : 'Monthly',
                        }
                        analytics.events.registrationCompleted(analyticsAttributes)

                        const { name, email, user_pin } = res?.payload
                        if (user_pin) {
                            setUserPin(user_pin)
                            setUsername(name)
                            setUserEmail(email)
                            const res = await getUserLogin({ email: signUpUser?.email, password: signUpUser?.password })
                            //user pin not used so far
                            if (res?.error) {
                                setErrorList([{ param: res.error }])
                            }
                            setErrorList([])
                            const { accessToken, membership_plan_id, country, phone, postalCode } = res?.payload
                            if (accessToken) {
                                await setAuthToken(accessToken)
                                await login()
                            }
                            await setMembershipPlanID(membership_plan_id)
                            if (country) {
                                await setCountry(country)
                            }
                            if (phone) {
                                await setPhone(phone)
                            }
                            if (postalCode && postalCode !== '') {
                                await setPostalCode(postalCode)
                            }

                            login()
                            sessionStorage.clear()
                            setLoading(false)

                            // Check membership before navigating to payment page
                            if (
                                membership_plan_id &&
                                (parseInt(membership_plan_id) === MEMBERSHIP_CONSTANTS.PREMIUM ||
                                    parseInt(membership_plan_id) === MEMBERSHIP_CONSTANTS.BUSINESS)
                            ) {
                                setRedirectToPayment(true)
                            } else {
                                const sendVerificationEmail = async () => {
                                    setLoading(true)
                                    let response = await postData('userService', '/v2/verify-email', {
                                        email: email ? email : localStorage.getItem('userEmail'),
                                    })
                                    setLoading(false)
                                }
                                try {
                                    await sendVerificationEmail()
                                } catch (error) {
                                    setLoading(false)
                                    console.log(error?.message)
                                }
                                setRedirectToEmailVerification(true)
                            }
                            analytics.events.userLoggedIn({ userPin: user_pin, provider: 'Email' })
                            localStorage.setItem('analyticsLoggedInBefore', true)
                            await handleSetUserAnalyticsAttributes(accessToken)
                        }
                    }
                }
            } catch (e) {
                console.log(e.message)
            }
        }
        register(registrationData)
    }

    const onSocialAuthComplete = (userDetails) => {
        if (isEmpty(userDetails)) return
        handleRegister(null, { ...userDetails, lang: i18n.language }, true)
    }

    const checkEmailValidity = async (e) => {
        const response = await postData('userService', '/validate-email', { email: e.target.value })
        if (response.status) {
            setErrorList([...errorList, { param: 'email', msg: 'Email address alreasy used', value: e.target.value }])
        } else {
            if (response?.message === 'Errors' && response?.payload?.length > 0) {
                setErrorList(response?.payload)
            } else {
                setErrorList([])
            }
        }
    }

    const checkFieldValidity = async (str, field) => {
        if (field === 'referralCode' && str !== '') {
            setLoading(true)
            const response = await postData('userService', '/validate-referral-code', { referral_code: str })
            if (!response?.status) {
                setIsReferralCodeValid(false)
            } else {
                setIsReferralCodeValid(true)
            }
            setLoading(false)
        }
        if (str && str !== '') {
            const validity = checkStrValidity(str, field)
            if (!validity?.status) {
                setFieldValidations([
                    ...fieldValidations?.filter((fv) => fv.field !== field),
                    {
                        status: false,
                        field,
                        msg: field === 'password' ? validity : validity?.msg,
                    },
                ])
            } else {
                setFieldValidations([...fieldValidations?.filter((fv) => fv.field !== field)])
            }
        } else {
            setFieldValidations([...fieldValidations?.filter((fv) => fv.field !== field)])
        }
    }

    useEffect(() => {
        if (redirectToPayment) {
            document.getElementById('redirect-to-payment')?.click()
        } else if (redirectToEmailVerification) {
            document.getElementById('redirect-to-verify-email')?.click()
        }
    }, [redirectToPayment, redirectToEmailVerification])

    useEffect(async () => {
        try {
            const res = await fetch('https://ipapi.co/json/', {
                method: 'GET',
            })
            const customerCountry = await res.json()
            if (customerCountry?.country_code) {
                setCountry(customerCountry?.country_code)
                setSignUpUser({
                    ...signUpUser,
                    country: customerCountry?.country_code,
                    code: customerCountry?.country_code,
                })
            }
        } catch (error) {
            console.error(error?.message)
        }
    }, [])

    const checkFiledValidations = () => {
        if (
            signUpUser?.fname &&
            signUpUser?.fname !== '' &&
            signUpUser?.lname &&
            signUpUser?.lname !== '' &&
            signUpUser?.country &&
            signUpUser?.country !== '' &&
            signUpUser?.email &&
            signUpUser?.email !== '' &&
            signUpUser?.password &&
            signUpUser?.password !== '' &&
            signUpUser?.phone &&
            signUpUser?.phone !== ''
        ) {
            if (
                fieldValidations?.length === 1 &&
                fieldValidations[0]?.field === 'password' &&
                !Object.values(fieldValidations[0]?.msg)
                    ?.map((m) => m?.status)
                    .includes(false)
            ) {
                return true
            }
        } else {
            return false
        }
    }

    useEffect(() => {
        analytics.events.screenVisited({ screen: screenEvent.SIGNUP })
    }, [])

    return loggedIn ? (
        <Redirect to={DASHBOARD} />
    ) : (
        <>
            <Link
                id="redirect-to-payment"
                to={{
                    pathname: ACCOUNT_PAYMENT,
                    search: '',
                    state: {
                        ...props.location.state,
                    },
                }}
            />
            <Link id="redirect-to-verify-email" to={ONE_TIME_VERIFY_EMAIL} />
            <div className="global-section-container flex-col grey-background pt-5 pb-5">
                <div className="container w-50-mobile-100 white-background login-modal-width">
                    <h1 className="sub-page-section-header text-start mb-4">{t('Create-your-account')}</h1>
                    <form
                        id="registerForm"
                        className="text-start w-100 pr-0 pl-0"
                        onSubmit={(e) => handleRegister(e, signUpUser)}
                    >
                        {errorList &&
                            errorList.length > 0 &&
                            uniqBy(errorList, 'msg')?.map((error, index) => (
                                <Alert key={`${index + 1}-${error.param}`} variant={'danger'}>
                                    <span className="text-capitalize">
                                        {t(capitalizeFirstChar(error.param))}
                                        {': '}
                                    </span>
                                    {t(error.msg)}
                                    {error.msg === 'Email address alreasy used' && (
                                        <Link to={LOGIN_PAGE} class="alert-link mx-2">
                                            {t('Log-in')}
                                        </Link>
                                    )}
                                </Alert>
                            ))}
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="registerFname" className="required">
                                        {t('Fname')}
                                    </label>
                                    <input
                                        autocomplete="true"
                                        type="text"
                                        className="form-control"
                                        id="registerFname"
                                        name="fname"
                                        minLength={2}
                                        maxLength={25}
                                        onBlur={(e) => checkFieldValidity(e.target.value, e.target.name)}
                                        value={signUpUser.fname}
                                        onChange={handleInputs}
                                        required
                                    />
                                    {fieldValidations?.find((fv) => fv.field === 'fname') && (
                                        <small className="color-red my-1">
                                            {t(fieldValidations?.find((fv) => fv.field === 'fname')?.msg)}
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="registerLname" className="required">
                                        {t('Lname')}
                                    </label>
                                    <input
                                        autocomplete="true"
                                        type="text"
                                        className="form-control"
                                        id="registerLname"
                                        name="lname"
                                        minLength={2}
                                        maxLength={25}
                                        onBlur={(e) => checkFieldValidity(e.target.value, e.target.name)}
                                        value={signUpUser.lname}
                                        onChange={handleInputs}
                                        required
                                    />
                                    {fieldValidations?.find((fv) => fv.field === 'lname') && (
                                        <small className="color-red my-1">
                                            {t(fieldValidations?.find((fv) => fv.field === 'lname')?.msg)}
                                        </small>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="registerShipto" className="required">
                                        {t('Ship-to')}{' '}
                                    </label>
                                    <ReactFlagsSelect
                                        countries={countries?.map((country) => country.iso2_code)}
                                        className={`${i18n.language === 'ar' && 'rtl-direction'}`}
                                        id="registerShipto"
                                        selected={signUpUser.country}
                                        onSelect={(code) => {
                                            console.log(code, 'huda')
                                            setSignUpUser({ ...signUpUser, country: code, code: code })
                                        }}
                                        onFocus={(e) => {
                                            if (e.target.autocomplete) {
                                                e.target.autocomplete = 'noautocomplete'
                                            }
                                        }}
                                        showSelectedLabel
                                        placeholder={'Select a country'}
                                        alignOptionsToRight
                                        fullWidth
                                        searchable
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="registerMobileInput" className="required">
                                        {t('Phone')}{' '}
                                    </label>
                                    <PhoneInput
                                        country={signUpUser.code?.toLowerCase() || ''}
                                        inputProps={{
                                            name: 'phone',
                                            required: true,
                                            autoComplete: 'off',
                                        }}
                                        id="registerMobileInput"
                                        onChange={handlePhoneInput}
                                        inputClass="form-control w-100"
                                        value={signUpUser.phone}
                                        disableDropdown
                                        placeholder={'Please enter your phone no.'}
                                        containerClass={classNameForPhoneInput}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="registerEmail" className="required">
                                {t('Email address')}
                            </label>
                            <input
                                autocomplete="off"
                                type="email"
                                className="form-control"
                                id="registerEmail"
                                name="email"
                                maxLength={80}
                                value={signUpUser.email}
                                onChange={handleInputs}
                                onBlur={(e) => {
                                    checkFieldValidity(e.target.value, e.target.name)
                                    checkEmailValidity(e)
                                }}
                                required
                            />
                            {fieldValidations?.find((fv) => fv.field === 'email') && (
                                <small className="color-red my-1">
                                    {t(fieldValidations?.find((fv) => fv.field === 'email')?.msg)}
                                </small>
                            )}
                        </div>
                        <div className="form-group">
                            <label className="required" htmlFor="registerPassword">
                                {t('Password')}
                            </label>
                            <div className="d-block password-field">
                                <ShowPassword
                                    password={signUpUser?.password}
                                    onChange={(e) =>
                                        handleInputs(e, fieldValidations?.find((fv) => fv.field === 'password')?.msg)
                                    }
                                />
                                {fieldValidations?.find((fv) => fv.field === 'password') && (
                                    <ul className="two-col-ul">
                                        {Object.values(
                                            fieldValidations?.find((fv) => fv.field === 'password')?.msg,
                                        )?.map((m) => (
                                            <li
                                                className={m?.status ? 'success' : 'error'}
                                                key={getKeyByValue(
                                                    fieldValidations?.find((fv) => fv.field === 'password')?.msg,
                                                    m,
                                                )}
                                                id={getKeyByValue(
                                                    fieldValidations?.find((fv) => fv.field === 'password'),
                                                    m,
                                                )}
                                            >
                                                <small className={`${m?.status ? 'text-success' : 'color-red'} my-1`}>
                                                    {t(m.msg)}
                                                </small>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="referralCode">
                                {t('Refferal Code')}&nbsp;&nbsp;
                                <span className="text-muted">{t('Optional')}</span>&nbsp;&nbsp;
                                {/* <i className="blue-color fas fa-question-circle"/> */}
                            </label>
                            <div className="d-block">
                                <input
                                    autocomplete="off"
                                    type="text"
                                    className="form-control"
                                    id="referralCode"
                                    name="referralCode"
                                    maxLength={10}
                                    value={signUpUser.referralCode}
                                    onChange={handleInputs}
                                    onBlur={(e) => {
                                        checkFieldValidity(e.target.value, e.target.name)
                                    }}
                                />
                                {!loading && isReferralCodeValid !== 'validation' ? (
                                    <>
                                        {!isReferralCodeValid && (
                                            <small className="color-red my-1">{t('Referral Code is invalid')}</small>
                                        )}
                                        {isReferralCodeValid && (
                                            <small className="text-success my-1">{t('Referral Code is valid')}</small>
                                        )}
                                    </>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-group form-check d-flex-column row justify-content-start ml-0 mr-0 flex-nowrap">
                            <div className="col-12 col-sm-12 col-md-12 pl-0 pr-0 my-1">
                                <input
                                    type="checkbox"
                                    className="form-check-input h-auto"
                                    id="whatsappSendingCheckbox"
                                    value={signUpUser.recieve_whatsapp}
                                    defaultChecked={signUpUser.recieve_whatsapp}
                                    onClick={() =>
                                        setSignUpUser({ ...signUpUser, recieve_whatsapp: !signUpUser.recieve_whatsapp })
                                    }
                                />
                                <label className="form-check-label" htmlFor="emailSendingCheckbox">
                                    <span>{t('Id like to receive updates on WhatsApp')}</span>
                                </label>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 pl-0 pr-0">
                                <input
                                    type="checkbox"
                                    className="form-check-input h-auto"
                                    id="emailSendingCheckbox"
                                    value={signUpUser.receive_emails}
                                    defaultChecked={signUpUser.receive_emails}
                                    onClick={() =>
                                        setSignUpUser({ ...signUpUser, receive_emails: !signUpUser.receive_emails })
                                    }
                                />
                                <label className="form-check-label" htmlFor="emailSendingCheckbox">
                                    <span>{t('Id like to receive marketing e-mails and updates')}</span>
                                </label>
                            </div>
                        </div>
                        <div className="form-group form-check d-flex row justify-content-start ml-0 mr-0 flex-nowrap">
                            <div className="col-12 col-sm-12 col-md-12 pl-0 pr-0">
                                {/* <input
								type="checkbox"
								className="form-check-input h-auto"
								id="agreeCheckbox"
								value={agreeBool}
								defaultChecked={agreeBool}
								onClick={() => setAgreeBool(!agreeBool)}
							/> */}
                                <label className="form-check-label" htmlFor="agreeCheckbox">
                                    <span>
                                        {t('Agree-statement')}
                                        <a
                                            className="fnt-500"
                                            href="https://shopiniworld.com/terms-and-conditions"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {' '}
                                            {t('Terms and conditions')}{' '}
                                        </a>
                                        {t('And')}
                                        <a
                                            className="fnt-500"
                                            href="https://shopiniworld.com/privacy-policy"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {' '}
                                            {t('Privacy policy')}
                                        </a>
                                        <sup className="must">*</sup>
                                    </span>
                                </label>
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="btn btn-primary w-100"
                            disabled={!agreeBool || errorList?.length > 0 || !checkFiledValidations() || loading}
                        >
                            {loading ? <div class="spinner-border text-light" role="status"></div> : t('Register now')}
                        </button>
                        {/* <div className="row mt-3">
						<div className="col-12 col-md-5">
							<hr />
						</div>
						<div className="col-12 col-md-2 text-center">{t("Or")}</div>
						<div className="col-12 col-md-5">
							<hr />
						</div>
					</div>

					<SocialAuth onSocialAuthComplete={onSocialAuthComplete} countries={countries} /> */}

                        <div className="row justify-content-center mt-4">
                            {t('Already-a-user')}
                            <span
                                style={{
                                    cursor: 'pointer',
                                    color: 'blue',
                                }}
                                onClick={redirectToLogin}
                                className="full-features-link text-decoration-none ml-2"
                                data-dismiss="modal"
                            >
                                {t('Log-in')}
                            </span>
                            <Link id="redirect-to-login" loading={loading} to={LOGIN_PAGE} />
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default RegistrationForm
