import React, { useEffect, useState } from 'react'
import { Button, Col, Collapse, Form, Row } from 'react-bootstrap'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import PromoDiscountIcon from '../../assets/img/icons/promo-discount.svg'

import Loader from '../common/loader'

import { PROMOS, MEMBERSHIP_CONSTANTS } from '../../constants/constants'
import { useAuth } from '../../contexts/AuthContext'
import { postData } from '../../utils/api'
import './style.scss'
import removePromoIcon from './remove-promocode.svg'
import { copyTextToClipboard } from '../../utils/string'
import copyIcon from './copy.svg'
import greenPromoIcon from './green-promo-icon.svg'
import { useMediaQuery } from 'react-responsive'
import useAnalytics from '../../analytics/useAnalytics'
import { useLocation } from 'react-router-dom'

export default ({ source, paymentSummary, setPaymentSummary, membershipId, defaultPromoCode, setShowSkip }) => {
    const { t, i18n } = useTranslation()
    const floater = i18n.language === 'ar' ? 'float-left' : 'float-right'
    const alignment = i18n.language === 'ar' ? 'mr-auto' : 'ml-auto'
    const { authToken, membershipPlanID } = useAuth()
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

    const [isOpen, setIsOpen] = useState(true)
    const [promoCode, setPromoCode] = useState(null)
    const [promoCodeLock, setPromoCodeLock] = useState(false)
    const [promoName, setPromoName] = useState('')
    const [promoLoading, setPromoLoading] = useState(false)
    const [error, setError] = useState('')
    const [showDefaultPromoCode, setShowDefaultPromoCode] = useState(false)
    const [applyDefaultCode, setApplyDefaultCode] = useState(false)
    const analytics = useAnalytics()
    const location = useLocation()
    /**
     *
     * @param promoDetails
     */
    const onPromoValidation = (promoDetails) => {
        setIsOpen(false)
        setPromoName(promoDetails.name)
        setPromoCodeLock(true)
        let discount = 0,
            discountedTotal = 0,
            originalAmount = 0

        let paymentSummaryTemp = _.assign({}, paymentSummary)
        switch (promoDetails.discount_type) {
            case PROMOS.DISCOUNT_TYPE_FLAT:
                discount = parseFloat(promoDetails.discount)
                break

            case PROMOS.DISCOUNT_TYPE_PERCENT:
                discount =
                    ((source !== 'subscription'
                        ? parseFloat(paymentSummary.grandTotal)
                        : promoDetails.subscription_payable_amount) *
                        parseFloat(promoDetails.discount)) /
                    100
            default:
                break
        }

        if (source !== 'subscription') {
            discountedTotal = parseFloat(paymentSummary.grandTotal) - discount
            originalAmount = paymentSummary.grandTotal
        } else {
            discountedTotal = promoDetails.subscription_payable_discounted_amount || 0
            discountedTotal = discountedTotal < 0 ? 0 : discountedTotal
            if (discountedTotal === 0) {
                setShowSkip(true)
            }
            originalAmount = promoDetails.subscription_payable_amount || 0
        }

        paymentSummaryTemp['discount'] = {
            original_amount: originalAmount,
            discounted_amount: discountedTotal,
            promo_code_value: discount,
            promo_code_type: promoDetails.discount_type,
            promo_code: promoDetails.code,
        }

        paymentSummaryTemp['discountedTotal'] = discountedTotal
        paymentSummaryTemp['promo_code'] = promoDetails.code
        paymentSummaryTemp['grandTotal'] = originalAmount
        setShowDefaultPromoCode(false)
        setPaymentSummary(paymentSummaryTemp)
    }

    /**
     *
     * @returns {Promise<void>}
     */
    const validatePromo = async () => {
        setPromoLoading(true)
        setError('')
        try {
            const request = {
                code: promoCode,
                type: source || 'subscription', // shipping/subscription,
                membership_plan_id: membershipId,
                amount: paymentSummary.grandTotal,
                is_yearly: paymentSummary?.is_yearly,
            }
            const apiToHit =
                Number(membershipId) !== Number(membershipPlanID) ? '/coupon/upgrade-validate' : '/coupon/validate'
            const response = await postData('paymentService', apiToHit, request, authToken)
            const analyticsAttributes = {
                promoCode,
                valid: false,
                source:
                    location.state?.membership_plan_id && location.state?.membership_plan_id != membershipPlanID
                        ? 'Upgrade'
                        : location.state?.renewal?.status
                          ? 'Renew'
                          : 'Registration',
                membershipType: MEMBERSHIP_CONSTANTS[`${location.state?.membership_plan_id ?? membershipPlanID}`],
                planType: !!paymentSummary?.is_yearly ? 'Yearly' : 'Monthly',
            }
            if (!response || !response.status) {
                setError(t('Invalid Promo!'))
                analytics.events.membershipPromoApplyCompleted(analyticsAttributes)
            } else {
                onPromoValidation(response.payload.coupon)
                analyticsAttributes.valid = true
                analytics.events.membershipPromoApplyCompleted(analyticsAttributes)
            }
        } catch (error) {
            setError(t('Invalid Promo!'))
        }
        setPromoCodeLock(false)
        setPromoLoading(false)
    }

    const clearPromoCode = async () => {
        setPromoCode(null)
        setPromoCodeLock(false)
        setPromoName('')
        delete paymentSummary?.discountedTotal
        delete paymentSummary?.discount
        delete paymentSummary?.promo_code
        await setPaymentSummary({
            ...paymentSummary,
            grandTotal: parseFloat(
                paymentSummary?.items?.find((item) => item.title === 'Subscription')?.value?.replace('$', ''),
            ),
        })
        setPromoLoading(false)
        setShowSkip(false)
        setShowDefaultPromoCode(defaultPromoCode && defaultPromoCode !== '')
        setApplyDefaultCode(false)
        setIsOpen(true)
        const analyticsAttributes = {
            source:
                location.state?.membership_plan_id && location.state?.membership_plan_id != membershipPlanID
                    ? 'Upgrade'
                    : location.state?.renewal?.status
                      ? 'Renew'
                      : 'Registration',
            membershipType: MEMBERSHIP_CONSTANTS[`${location.state?.membership_plan_id ?? membershipPlanID}`],
            planType: !!paymentSummary?.is_yearly ? 'Yearly' : 'Monthly',
            promoCode,
        }
        analytics.events.membershipPromoCancelButton(analyticsAttributes)
    }

    useEffect(async () => {
        if (!!promoCode && !promoCodeLock) {
            await validatePromo()
        }
    }, [paymentSummary.grandTotal])

    useEffect(async () => {
        if (source === 'subscription' && !_.isEmpty(defaultPromoCode)) {
            setIsOpen(true)
        }
        setShowDefaultPromoCode(defaultPromoCode && defaultPromoCode !== '')
        setApplyDefaultCode(defaultPromoCode && defaultPromoCode !== '')
        setPromoCode(defaultPromoCode)
    }, [defaultPromoCode])

    useEffect(() => {
        if (applyDefaultCode) {
            document?.getElementById('apply-promo')?.click()
        }
    }, [applyDefaultCode])

    return (
        <>
            <div className="promo-code-container">
                {paymentSummary.discount && !isOpen ? (
                    <div className="promo-banner">
                        <img src={PromoDiscountIcon} alt={''} />
                        {`${promoCode} ${t('Coupon Applied')}`}

                        <img
                            src={removePromoIcon}
                            className={`pointer ${floater} ${alignment} mt-0 mb-0`}
                            onClick={() => clearPromoCode()}
                        />
                    </div>
                ) : (
                    <>
                        <span className="anchor-link" onClick={() => setIsOpen(!isOpen)}>
                            {t('Have a Promo Code?')}
                            <span className={floater}>{isOpen ? <BsChevronUp /> : <BsChevronDown />}</span>
                        </span>
                        <Collapse in={isOpen}>
                            <div>
                                {!isMobile ? (
                                    <Form.Row>
                                        <Col sm={8}>
                                            <Form.Control
                                                placeholder={t('Enter Promo Code')}
                                                className="text-uppercase"
                                                onChange={(e) => setPromoCode(e.target.value?.toUpperCase())}
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <Button id="apply-promo" onClick={validatePromo} className="px-1">
                                                {t('Apply')}
                                            </Button>
                                        </Col>
                                    </Form.Row>
                                ) : (
                                    <Row className="px-3 mt-2">
                                        <Col xs={7} className="px-0">
                                            <Form.Control
                                                placeholder={t('Enter Promo Code')}
                                                className="text-uppercase"
                                                onChange={(e) => setPromoCode(e.target.value?.toUpperCase())}
                                            />
                                        </Col>
                                        <Col xs={3} className="px-2">
                                            <Button id="apply-promo" onClick={validatePromo}>
                                                {t('Apply')}
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                                {promoLoading ? (
                                    <div>
                                        <Loader />
                                    </div>
                                ) : null}
                                {error && error !== '' ? (
                                    <span id="promo-error" className="has-error">
                                        {error}
                                    </span>
                                ) : null}
                                {showDefaultPromoCode && (
                                    <div className="default-promocode mt-2 row py-2 px-2 w-100 mx-0">
                                        <div className="col-1 px-1">
                                            <img src={greenPromoIcon} alt={''} />
                                        </div>
                                        <div className="col-8 font16 px-2">
                                            {t('Get 1 year free by using')} <strong>{defaultPromoCode}</strong>
                                        </div>
                                        <div className="col-3 px-0 d-flex justify-content-end">
                                            <button
                                                className="copy-btn"
                                                onClick={() => copyTextToClipboard(defaultPromoCode)}
                                            >
                                                <img src={copyIcon} />
                                                &nbsp;
                                                {t('Copy')}
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Collapse>
                    </>
                )}
            </div>
        </>
    )
}
