import React from 'react'
import { Modal } from 'react-bootstrap'

import { useTranslation } from 'react-i18next'
import success from '../../../../assets/img/icons/success.gif'
import failed from '../../../../assets/img/icons/fail.gif'

export default ({ show, viewModalClose, doneSteps }) => {
    const { t, i18n } = useTranslation()
    return (
        <Modal
            show={show}
            onHide={() => viewModalClose()}
            size="lg"
            dialogClassName={i18n.language + ' purchase-request-modal wallet-modal'}
            aria-labelledby="contained-modal-title-vcenter"
            id="purchase-modal"
            contentClassName={'gray-bg'}
            centered
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="py-0 mb40">
                <div className="card mx-4">
                    <div className="row w-100 mb-3 text-center">
                        <>
                            {doneSteps.includes('failedPurchase') && (
                                <div className="container text-center">
                                    <img src={failed} className="w-50" />
                                    <h4>{t('Payment Failed')}</h4>
                                    <p>{t('Unfortunately , we couldn’t collect payment on your shipment.')}</p>
                                </div>
                            )}

                            {doneSteps.includes('successPurchase') && (
                                <div className="container text-center">
                                    <img src={success} className="w-50" />
                                    <h4>{t('Your Payment was successful')}</h4>
                                    <p>
                                        {t(
                                            'Thank you for your confirmation, you can track your buying request on Request tab in the dashboard',
                                        )}
                                    </p>
                                </div>
                            )}
                        </>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
        </Modal>
    )
}
