import React from 'react'
import StepOne from './stepOne'
import StepTwo from './stepTwo'
import StepThree from './stepThree'
import StepFour from './stepFour'
import StepFive from './stepFive'

function HowItWorks() {
    return (
        <div className="global-section-container flex-col">
            <StepOne />
            <StepTwo />
            <StepThree />
            <StepFour />
            <StepFive />
        </div>
    )
}

export default HowItWorks
