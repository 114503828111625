import moment from 'moment'

const changeTimeZone = (date, timeZone) => {
    if (typeof date === 'string') {
        return new Date(
            new Date(date).toLocaleString('en-US', {
                timeZone,
            }),
        )
    }

    return new Date(
        date.toLocaleString('en-US', {
            timeZone,
        }),
    )
}

export function parcelTableDateFormat(date, showTime = false, country = null) {
    let convertedDate = date
    if (showTime && country) {
        let timeZone =
            country?.toLocaleUpperCase() === 'CN'
                ? 'Asia/Shanghai'
                : country?.toLocaleUpperCase() === 'US'
                  ? 'America/New_York'
                  : country?.toLocaleUpperCase() === 'GB'
                    ? 'Europe/London'
                    : country?.toLocaleUpperCase() === 'TR'
                      ? 'Asia/Istanbul'
                      : 'Asia/Dubai'
        convertedDate = changeTimeZone(date, timeZone)
    }
    return moment(convertedDate).format(showTime ? 'DD MMM YYYY, h:mm a' : 'DD MMM YYYY')
}

export function trackingListDateFormat(date) {
    return moment(date).format('DD MMM YY, h:mm a')
}

export function requestTableDateFormat(date, language) {
    return moment(date).format('YYYY-MM-DD, h:mm a')
}
