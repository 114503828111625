import { Link } from 'react-router-dom'
import useAnalytics from '../../../../analytics/useAnalytics'
import { CONTACT_US } from '../../../../constants/navigationConstants'
import styles from './styles.module.scss'

const Contents = () => {
    const analytics = useAnalytics()

    return (
        <>
            <div className={`${styles['shopini-logistics']}`}>E-COMMERCE SOLUTIONS</div>
            <div className={`${styles['title']}`}>
                We Cross-border parcels
                <br />
                From your website to their doorstep
            </div>
            <div className={`${styles['sub-title']}`}>Scale with us Now!</div>
            <div>
                <Link
                    className={`btn btn-primary signup-btn ${styles['btn-overrides']}`}
                    to={CONTACT_US}
                    onClick={() => analytics.events.contactUs({ source: 'Ecommerce' })}
                >
                    SCHEDULE CALL
                </Link>
            </div>
        </>
    )
}

export default Contents
