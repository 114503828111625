import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, Button } from 'react-bootstrap'

import { useAuth } from '../../contexts/AuthContext'
import PackageWidget from './packageWidget'
import Account from '../account'
import AccountDrawer from './accountDrawer'
import './style.scss'
import HowToUseModal from './parcel/modal'
import PurchaseModal from '../purchaseRequest/requests/modal/modal'
import ReactFlagsSelect from 'react-flags-select'
import { MEMBERSHIP_CONSTANTS } from '../../constants/constants'
import styles from './styles.module.scss'
import useAnalytics from '../../analytics/useAnalytics'
import { screenEvent } from '../../analytics/constants'

const getWarehouseData = (keyName, warehouseId, warehousesList) => {
    const selectedWarehouse = warehousesList?.find((w) => w?.id === warehouseId)
    return selectedWarehouse ? selectedWarehouse[keyName] : null
}

const createWarehousesLabels = (t, warehousesList) => {
    const customLabels = {}
    warehousesList?.forEach((w) => {
        return (customLabels[w?.country] = {
            primary: w?.name,
            secondary: w?.active ? null : (
                <div className="mx-1 coming-soon orange-bg float-end">{t('Coming soon')}</div>
            ),
        })
    })
    return customLabels
}

const Dashboard = (props) => {
    const { t, i18n } = useTranslation()
    const { username, userPin, authToken, membershipPlanID, warehousesList, setWarehouseId, warehouseId, address } =
        useAuth()
    const [openAccountDrawer, setOpenAccountDrawer] = useState(false)
    const [message, setMessage] = useState('Welcome Back')
    const [requestData, setRequestData] = useState({})
    const [show, setShow] = useState(false)
    const analytics = useAnalytics()

    const viewModalClose = () => {
        setShow(false)
        setRequestData({})
    }

    const isBusinessPlan = Number(membershipPlanID) === 4

    const howToUseModalData = {
        fullName: username ?? '',
        address: getWarehouseData('address', warehouseId, warehousesList) ?? '',
        address2: getWarehouseData('address2', warehouseId, warehousesList) ?? '',
        city: getWarehouseData('city', warehouseId, warehousesList) ?? '',
        zip: getWarehouseData('zip_code', warehouseId, warehousesList) ?? '',
        phoneNumber: getWarehouseData('phone', warehouseId, warehousesList) ?? '',
        country: getWarehouseData('country', warehouseId, warehousesList) ?? '',
        state: getWarehouseData('state', warehouseId, warehousesList) ?? '',
        userPin: userPin ?? '',
    }

    useEffect(() => {
        analytics.events.screenVisited({ screen: screenEvent.DASHBOARD })
    }, [])

    return (
        <section className="dashboard-container-wrapper">
            {!isBusinessPlan ? (
                <Row className="mb-show">
                    <Col xs={12}>
                        <Row>
                            <Col xs={5} className={`mt-2 ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}>
                                {t(message)}
                            </Col>
                            <Col xs={12} className="d-flex justify-content-end">
                                <Button
                                    onClick={() => {
                                        setOpenAccountDrawer(true)
                                    }}
                                >
                                    {t('My Address')}
                                </Button>
                            </Col>
                            {openAccountDrawer ? (
                                <AccountDrawer
                                    isDrawerOpen={openAccountDrawer}
                                    setIsDrawerOpen={setOpenAccountDrawer}
                                />
                            ) : null}
                        </Row>
                    </Col>
                </Row>
            ) : null}

            <div className="dashboard-container">
                {isBusinessPlan ? (
                    <Row>
                        <Col xs={12}>
                            <div className="d-flex align-items-center justify-content-between">
                                <div>
                                    <p className={`mb-1 ${styles['hello']}`}>
                                        {t('Hello')} {`${username}!  👋`}
                                    </p>
                                    <p className={`mb-2 ${styles['account-id']}`}>
                                        {t('Account ID')}: <span className={`${styles['pin']}`}>{userPin}</span>
                                    </p>
                                </div>
                                <div className={`${styles['warehouse-select']}`}>
                                    <ReactFlagsSelect
                                        countries={warehousesList?.map((warehouse) => warehouse.country)}
                                        customLabels={createWarehousesLabels(t, warehousesList)}
                                        selected={warehousesList.find((w) => w.id === warehouseId)?.country}
                                        onSelect={(code) => {
                                            if (warehousesList?.find((w) => w.country === code)?.active) {
                                                setWarehouseId(warehousesList?.find((w) => w.country === code)?.id)
                                            } else {
                                                setWarehouseId(1)
                                            }
                                        }}
                                        onFocus={(e) => {
                                            if (e.target.autocomplete) {
                                                e.target.autocomplete = 'noautocomplete'
                                            }
                                        }}
                                        showSelectedLabel
                                        showSecondarySelectedLabel
                                        placeholder={'Select a warehouse'}
                                        alignOptionsToRight
                                        fullWidth
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                ) : null}

                <Row className="row dashboard-row">
                    <Col xxl={isBusinessPlan ? 12 : 8} xl={isBusinessPlan ? 12 : 8} md={12} sm={12}>
                        <PackageWidget setShow={setShow} setMessage={setMessage} />
                    </Col>

                    {!isBusinessPlan ? (
                        <Col xxl={4} xl={4} className="mb-hide ipad-hide">
                            <Account
                                user={{
                                    name: username,
                                    userPin,
                                }}
                            />
                        </Col>
                    ) : null}
                </Row>
            </div>

            <HowToUseModal modalData={howToUseModalData} />
            <PurchaseModal show={show} setShow={setShow} viewModalClose={viewModalClose} modalRow={requestData} />
        </section>
    )
}

export default Dashboard
