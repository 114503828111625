import Others from './Others'
import TrueWeight from './TrueWeight'

const IllustrationFigures = () => {
    return (
        <div>
            <TrueWeight />
            <Others />
        </div>
    )
}

export default IllustrationFigures
