import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ACCOUNT_SETTINGS } from '../../constants/navigationConstants'
import { Link } from 'react-router-dom'
import BackButtonSvg from '../../assets/img/icons/backButton.svg'
import { useAuth } from '../../contexts/AuthContext'
import GroupSvg from '../../sections/navigationBar/group.svg'
import { getUserDetails } from '../../services/userInfoService'
import { PROMOCODES } from '../../constants/constants'
import { checkForLifeTimeSubscribtion } from '../../utils/string'
import useAnalytics from '../../analytics/useAnalytics'
import { screenEvent } from '../../analytics/constants'

const AccountBackButton = () => {
    const { t, i18n } = useTranslation()
    return (
        <Link to={ACCOUNT_SETTINGS} className="row align-items-center mb-2">
            {i18n.language === 'ar' && <img alt="..." src={BackButtonSvg} className="ml-3 mr-3 rotate-180" />}
            {i18n.language !== 'ar' && <img alt="..." src={BackButtonSvg} className="ml-3 mr-3" />}
            <p className="mb-0 text-start">{t('Account')}</p>
        </Link>
    )
}

export default () => {
    const { t } = useTranslation()
    const { authToken } = useAuth()
    const analytics = useAnalytics()

    const [membershipDetails, setMembershipDetails] = useState({
        type: ' ',
        cycle: ' ',
        date: ' ',
        fee: ' ',
    })

    useEffect(() => {
        async function retrieveUserDetails() {
            try {
                const res = await getUserDetails(authToken)
                setMembershipDetails({
                    ...membershipDetails,
                    type: res?.membership.name,
                    cycle: '',
                    date: res?.plan_data.expiry_at,
                    fee: res?.membership.price,
                    promo_code: res.plan_data.promo_code === PROMOCODES.ARAMCO ? res.plan_data.promo_code : null,
                    for_life_time: checkForLifeTimeSubscribtion(res.plan_data.expiry_at),
                })
            } catch (e) {
                console.log(e)
            }
        }

        retrieveUserDetails()
    }, [])

    useEffect(() => {
        analytics.events.screenVisited({ screen: screenEvent.MEMBERSHIP_PROFILE })
    }, [])

    return (
        <div className="global-section-container flex-col grey-background pt-5 pb-5 align-items-center">
            <div className="container white-background text-start row pt-3 pb-3">
                <div className="col-12 col-sm-12 col-md-8">
                    <AccountBackButton />
                    <h1 className="sub-page-section-header">{t('My-membership')}</h1>

                    <div className="row">
                        {/* membership type */}
                        <div className="col-12">
                            <label htmlFor="membershipType" className="description">
                                {t('Current-membership-type')}
                            </label>
                            <div id="membershipType" className="">
                                {membershipDetails.type}
                            </div>
                            <hr />
                        </div>
                        {/* cycle */}
                        <div className="col-12">
                            <label htmlFor="membershipCycle" className="description">
                                {t('Current-billing-cycle')}
                            </label>
                            <div id="membershipCycle" className="">
                                {membershipDetails.cycle}
                                {t('Monthly')}
                            </div>
                            <hr />
                        </div>
                        {/* expirey date*/}
                        <div className="col-12">
                            <label htmlFor="membershipDate" className="description">
                                {t('Next-billing-date')}
                            </label>
                            <div id="membershipDate" className="">
                                {membershipDetails?.for_life_time ? t('Lifetime') : membershipDetails.date}
                            </div>
                            <hr />
                        </div>
                        {/* fee */}
                        <div className="col-12">
                            <label htmlFor="membershipFee" className="description">
                                {t('Membership-fee')}
                            </label>
                            <div id="membershipFee">
                                {membershipDetails?.for_life_time ? (
                                    <s>{membershipDetails.fee} USD</s>
                                ) : (
                                    `${membershipDetails.fee} USD`
                                )}
                            </div>
                            <hr />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-4">
                    <div className="card grey-background border border-radius-10 ">
                        <div className="card-body">
                            <h5 className="card-title">
                                <img alt="..." src={GroupSvg} />
                            </h5>
                            <h5 className="card-title">
                                <span className="pdlft5">{t('Tips')}</span>
                            </h5>
                            <div className="membership-info">
                                <ul>
                                    <li>{t('Pay monthly and save lots of money')}</li>
                                    <li>
                                        {t(
                                            'You can always upgrade your subscription to avail more discounts & more benefit',
                                        )}
                                    </li>
                                    <li>{t('For more help on any of our services, please get in touch now')}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
