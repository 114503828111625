import styled from 'styled-components'

export const MaskedLoader = styled.div`
    background-color: rgba(251, 251, 251, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    padding: 30px;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`
