import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ACCOUNT_PAYMENT_OPTIONS } from '../../constants/navigationConstants'
import { Link } from 'react-router-dom'
import BackButtonSvg from '../../assets/img/icons/backButton.svg'
import { MaskedLoader } from '../common'
import AmazonPayButton from '../AmazonPay/AmazonPayButton'
import CreditCard from '../AmazonPay/CreditCard'
import { useAuth } from '../../contexts/AuthContext'
import { screenEvent } from '../../analytics/constants'
import useAnalytics from '../../analytics/useAnalytics'

const AccountPaymentBackButton = () => {
    const { t, i18n } = useTranslation()

    return (
        <Link to={ACCOUNT_PAYMENT_OPTIONS} className="row align-items-center mb-2">
            {i18n.language === 'ar' && <img alt="..." src={BackButtonSvg} className="ml-3 mr-3 rotate-180" />}
            {i18n.language !== 'ar' && <img alt="..." src={BackButtonSvg} className="ml-3 mr-3" />}
            <p className="mb-0 text-start">{t('Payment')}</p>
        </Link>
    )
}

export default () => {
    const { t } = useTranslation()
    const analytics = useAnalytics()
    const [loading, setLoading] = useState(true)
    const [loaderMessage, setLoaderMessage] = useState('')
    const [isAmazonPay, setIsAmazonPay] = useState(true)
    const { username } = useAuth()
    const [creditCardDetails, setCreditCardDetails] = useState({
        name: '',
        number: '',
    })
    useEffect(() => {
        setCreditCardDetails({ name: username + ' Amazon Pay' })
        setLoaderMessage(t('Please wait while we load your payment details'))
        setLoading(false)
    }, [])

    useEffect(() => {
        analytics.events.screenVisited({ screen: screenEvent.ADD_PAYMENT })
    }, [])

    return (
        <div className="global-section-container flex-col grey-background pt-5 pb-5 align-items-center">
            <div className="container white-background text-start row pt-3 pb-3">
                {loading ? <MaskedLoader message={loaderMessage} /> : null}

                <div className={`col-12 col-sm-12 col-md-12 ${loading ? 'lower-z-2' : 'd-block'}`}>
                    <AccountPaymentBackButton />
                    {/* <AccountPaymentBackButton />
					<h1 className="sub-page-section-header">
						{t("Add-credit-debit-card")}
					</h1>
					<Elements stripe={stripeTestPromise}>
						<NewCard
							setLoading={setLoading}
							setLoaderMessage={setLoaderMessage}
						/>
					</Elements> */}
                    <CreditCard
                        setLoading={setLoading}
                        creditCardDetails={creditCardDetails}
                        setCreditCardDetails={setCreditCardDetails}
                        setLoaderMessage={setLoaderMessage}
                    />
                    <AmazonPayButton
                        setLoading={setLoading}
                        creditCardDetails={creditCardDetails}
                        setLoaderMessage={setLoaderMessage}
                        paymentRequestData={{}}
                        totalPayment={0.01}
                        parcels={[]}
                        addOnServices={[]}
                        paymentType={'add-card'}
                        paymentDetails={{}}
                        cardType={'AMAZON'}
                        setIsAmazonPay={setIsAmazonPay}
                    />
                </div>
            </div>
        </div>
    )
}
