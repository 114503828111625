import analyticsContext from './analyticsContext'
import useCombineClients from './clients/combineClients'
import useDebugger from './clients/debugger/useDebugger'
import useGoogleAnalytics from './clients/googleAnalytics/useGoogleAnalytics'
import useVoid from './clients/void/useVoid'

const AnalyticsProvider = function (props) {
    const google = useGoogleAnalytics()
    const combinedClients = useCombineClients(google)
    const voidClient = useVoid()
    const debuggerClient = useDebugger()
    const analyticsAllowed = true
    const analyticsDebugger = false
    const client = analyticsAllowed ? (analyticsDebugger ? debuggerClient : combinedClients) : voidClient

    return <analyticsContext.Provider value={client}>{props.children ?? null}</analyticsContext.Provider>
}

export default AnalyticsProvider
