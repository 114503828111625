import React, { useState, useEffect } from 'react'

import { Row, Col } from 'react-bootstrap'

import { useTranslation } from 'react-i18next'
import { GLOBAL_CONSTANTS, REQUESTS_CONSTANTS } from '../../../../../constants/constants'
import { validateUrl, checkCharacterCount, checkInvalidNumber, imposeMinMax } from '../../../../../utils/validation'

export default ({
    setProductDetails,
    productDetails,
    loading,
    handleCreateOrUpdateRequest,
    handleFile,
    attachedPictureName,
    apiError,
    setApiError,
}) => {
    const { t, i18n } = useTranslation()
    const [errors, setErrors] = useState({})
    const hiddenFileInput = React.useRef(null)
    const HandleBrowseClick = (event) => {
        if (event) hiddenFileInput.current.click()
    }

    const [disablePlaceOrder, setDisablePlaceOrder] = useState(!productDetails?.id)

    const handleUrlChange = (e) => {
        setApiError('')
        if (!e.target.value) {
            delete errors[e.target.name + '_not_valid']
            setErrors({ ...errors, [e.target.name + '_required']: 'errors.' + e.target.name + '.required' })
        } else if (!validateUrl(e.target.value)) {
            delete errors[e.target.name + '_required']
            setErrors({ ...errors, [e.target.name + '_not_valid']: 'errors.' + e.target.name + '.not_valid' })
        } else if (
            validateUrl(e.target.value) &&
            checkCharacterCount(e.target.value, REQUESTS_CONSTANTS.PRODUCT_URL_CHARACTER_LENGTH)
        ) {
            delete errors[e.target.name + '_required']
            delete errors[e.target.name + '_not_valid']
            setErrors({ ...errors, [e.target.name + '_length']: 'errors.' + e.target.name + '.length' })
        } else {
            delete errors[e.target.name + '_required']
            delete errors[e.target.name + '_not_valid']
            delete errors[e.target.name + '_length']
        }
        setProductDetails({ ...productDetails, [e.target.name]: e.target.value })
    }

    const changeProductName = (e) => {
        setApiError('')
        if (!e.target.value) {
            delete errors[e.target.name + '_length']
            setErrors({ ...errors, [e.target.name + '_required']: 'errors.' + e.target.name + '.required' })
        } else if (checkCharacterCount(e.target.value, REQUESTS_CONSTANTS.PRODUCT_NAME_CHARACTER_LENGTH)) {
            delete errors[e.target.name + '_required']
            setErrors({ ...errors, [e.target.name + '_length']: 'errors.' + e.target.name + '.length' })
        } else {
            delete errors[e.target.name + '_required']
            delete errors[e.target.name + '_length']
        }
        setProductDetails({ ...productDetails, [e.target.name]: e.target.value })
    }

    const checkInvalidNumberValue = (e, type) => {
        setApiError('')
        if (!e.target.value && type !== 'shipping') {
            delete errors[e.target.name + '_length']
            setErrors({ ...errors, [e.target.name + '_required']: 'errors.' + e.target.name + '.required' })
        } else if (!imposeMinMax(e)) {
            e.preventDefault()
            delete errors[e.target.name + '_required']
            setErrors({ ...errors, [e.target.name + '_length']: 'errors.' + e.target.name + '.length' })
        } else {
            delete errors[e.target.name + '_required']
            delete errors[e.target.name + '_length']
        }
        var valueData = e.target.value
        if (e.target.name === 'quantity') valueData = e.target.value.replace(/[^0-9]+/g, '')
        setProductDetails({ ...productDetails, [e.target.name]: valueData })
    }

    const checkAllRequiredFields = () => {
        if (
            productDetails.product_url &&
            productDetails.product_url !== '' &&
            productDetails.product_name &&
            productDetails.product_name !== '' &&
            productDetails.unit_price &&
            productDetails.unit_price !== '' &&
            productDetails.quantity &&
            productDetails.quantity !== '' &&
            productDetails.quantity !== '0' &&
            Object.keys(errors).length === 0
        ) {
            setDisablePlaceOrder(false)
        } else {
            setDisablePlaceOrder(true)
        }
    }

    useEffect(() => {
        checkAllRequiredFields()
    }, [
        productDetails.id,
        productDetails.product_name,
        productDetails.product_url,
        productDetails.unit_price,
        productDetails.quantity,
    ])

    return (
        <div className="product-detail w-100">
            <form className="white-background w-90 mx-4">
                <Row className="my-2">
                    <Col className="form-label required">{t('Product_URL')}</Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <input
                            autoComplete="off"
                            type="text"
                            className="form-control"
                            id="product_url"
                            placeholder={t('Product_URL')}
                            name="product_url"
                            required
                            value={productDetails?.product_url}
                            onChange={(e) => handleUrlChange(e)}
                            maxlength={REQUESTS_CONSTANTS.PRODUCT_URL_CHARACTER_LENGTH}
                        />
                        <div
                            className={
                                errors.product_url_not_valid || errors.product_url_required || errors.product_url_length
                                    ? 'show error'
                                    : 'hide error'
                            }
                        >
                            {errors?.product_url_not_valid && t('add_valid_url')}
                            {errors?.product_url_required && t('url_required')}
                            {errors?.product_url_length && t('url_length')}
                        </div>
                    </Col>
                </Row>
                <Row className="my-2">
                    <Col className="form-label required">{t('Product_Name')}</Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <input
                            autoComplete="off"
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder={t('Product_Name')}
                            name="product_name"
                            value={productDetails?.product_name}
                            onChange={(e) => changeProductName(e)}
                            required
                            maxlength={REQUESTS_CONSTANTS.PRODUCT_NAME_CHARACTER_LENGTH}
                        />
                        <div
                            className={
                                errors.product_name_length || errors.product_name_required ? 'show error' : 'hide error'
                            }
                        >
                            {errors?.product_name_required && t('product_name_required')}
                            {errors?.product_name_length && t('product_name_length')}
                        </div>
                    </Col>
                </Row>
                <Row className="my-2 mx-0">
                    <Col className="form-label label-width" xxl={2} xl={2} md={6} sm={6}>
                        <div className="required mb-2">{t('Unit_Price')}</div>
                        <div className="currency-prefix"> {GLOBAL_CONSTANTS.CURRENCY_VAL}</div>
                        <input
                            type="number"
                            step={'0.1'}
                            className="form-control add-space mb-2"
                            id="unit_price"
                            placeholder={t('Unit_Price')}
                            name="unit_price"
                            value={productDetails?.unit_price}
                            onChange={(e) => checkInvalidNumberValue(e)}
                            onKeyDown={(e) => checkInvalidNumber(e)}
                            required
                            min={GLOBAL_CONSTANTS.MINIMUM_COUNT}
                            max={GLOBAL_CONSTANTS.MAXIMUM_PRICE_COUNT}
                        />
                        <div
                            className={
                                errors.unit_price_length || errors.unit_price_required ? 'show error' : 'hide error'
                            }
                        >
                            {errors?.unit_price_required && t('unit_price_required')}
                            {errors?.unit_price_length && t('unit_price_length')}
                        </div>
                    </Col>
                    <Col className="form-label label-width remove-space-mobile" xxl={2} xl={2} md={6} sm={6}>
                        <div className="required mb-2">{t('Quantity')}</div>
                        <input
                            type="number"
                            className="form-control mb-2"
                            id="quantity"
                            placeholder={t('Quantity')}
                            name="quantity"
                            step={1}
                            min={GLOBAL_CONSTANTS.MINIMUM_COUNT}
                            max={REQUESTS_CONSTANTS.MAXIMUM_QUANTITY_COUNT}
                            onChange={(e) => checkInvalidNumberValue(e)}
                            onKeyDown={(e) => checkInvalidNumber(e)}
                            value={productDetails?.quantity}
                            required
                            // value={requestDetail?.quantity}
                        />
                        <div
                            className={errors.quantity_length || errors.quantity_required ? 'show error' : 'hide error'}
                        >
                            {errors?.quantity_required && t('quantity_required')}
                            {errors?.quantity_length && t('quantity_length')}
                        </div>
                    </Col>
                    <Col className="form-label label-width remove-space" xxl={2} xl={2} md={6} sm={6}>
                        <div className="mb-2">{t('Shipping_Fees')}</div>
                        <div className="currency-prefix currency-prefix-space">{GLOBAL_CONSTANTS.CURRENCY_VAL}</div>
                        <input
                            type="number"
                            step={'0.1'}
                            className="form-control add-space  mb-2"
                            id="fees"
                            placeholder={t('Shipping_Fees')}
                            name="shipping_fee"
                            value={productDetails?.shipping_fee}
                            onChange={(e) => checkInvalidNumberValue(e, 'shipping')}
                            onKeyDown={(e) => checkInvalidNumber(e)}
                            required
                            min={GLOBAL_CONSTANTS.SHIPPING_MINIMUM_COUNT}
                            max={GLOBAL_CONSTANTS.MAXIMUM_PRICE_COUNT}
                        />
                        <div
                            className={
                                errors.shipping_fee_length || errors.shipping_fee_required ? 'show error' : 'hide error'
                            }
                        >
                            {errors?.shipping_fee_required && t('shipping_fee_required')}
                            {errors?.shipping_fee_length && t('shipping_fee_length')}
                        </div>
                    </Col>
                    <Col className="form-label label-width remove-space-mobile" xxl={2} xl={2} md={6} sm={6}>
                        <div className="mb-2">{t('Color')}</div>
                        <input
                            type="text"
                            className="form-control  mb-2"
                            id="color"
                            placeholder={t('Color')}
                            name="color"
                            value={productDetails?.color}
                            onChange={(e) => setProductDetails({ ...productDetails, [e.target.name]: e.target.value })}
                            maxlength={REQUESTS_CONSTANTS.OTHER_FIELDS_CHARACTER_LENGTH}
                        />
                    </Col>
                    <Col className="form-label label-width remove-space" xxl={2} xl={2} md={6} sm={6}>
                        <div className="mb-2">{t('Size')}</div>
                        <input
                            type="text"
                            className="form-control mb-2"
                            id="size"
                            placeholder={t('Size')}
                            name="size"
                            value={productDetails?.size}
                            onChange={(e) => setProductDetails({ ...productDetails, [e.target.name]: e.target.value })}
                            maxlength={REQUESTS_CONSTANTS.OTHER_FIELDS_CHARACTER_LENGTH}
                        />
                    </Col>
                </Row>
                <Row className="mb-2 mt-2">
                    <Col className="form-label">{t('Attach_Picture')}</Col>
                </Row>
                <Row>
                    <Col>
                        <div className="d-flex mb-2">
                            <div className="browse-field white-space-nowrap">{attachedPictureName}</div>
                            <input
                                type="button"
                                value={t('Browse')}
                                id="fakeBrowse"
                                className="browse-button"
                                onClick={HandleBrowseClick}
                            />
                        </div>
                        <input
                            id="fileCustomBrowse"
                            ref={hiddenFileInput}
                            name="file"
                            type="file"
                            onChange={handleFile}
                            className="form-control-file upload-button d-none"
                        />
                    </Col>
                </Row>
                <Row className="my-2">
                    <Col className="form-label">{t('Comments')}</Col>
                </Row>
                <Row className="my-2">
                    <Col className="form-label">
                        <textarea
                            id="comments"
                            name="comments"
                            rows="4"
                            className="w-100 comments-textarea"
                            maxlength={GLOBAL_CONSTANTS.CHARACTER_LENGTH}
                            value={productDetails?.comments}
                            onChange={(e) => setProductDetails({ ...productDetails, [e.target.name]: e.target.value })}
                        ></textarea>
                    </Col>
                </Row>

                <div className="d-flex justify-content-end mb-2">
                    <button
                        disabled={loading || disablePlaceOrder}
                        id="btn-primary"
                        className="btn btn-primary"
                        type="submit"
                        onClick={handleCreateOrUpdateRequest}
                    >
                        {loading ? <div className="spinner-border text-light" role="status"></div> : t('Place Order')}
                    </button>
                </div>
                <div className="d-flex justify-content-end mb-4 error">{apiError}</div>
            </form>
        </div>
    )
}
