import New from './new.svg'
import NewAr from './new-ar.svg'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { style } from '@mui/system'

const StyledNew = styled.img`
    position: absolute;
    top: 0;
    left: ${(props) => (props.isAr ? 0 : 'unset')};
    right: ${(props) => (props.isAr ? 'unset' : 0)};
    // transform: translateX(${(props) => (props.isAr ? '-50%' : '50%')});
`

const TrueWeight = () => {
    const { t, i18n } = useTranslation()

    return (
        <div className="fs-12px position-relative bg-grey-6 rounded py-1" style={{ lineHeight: 'normal' }}>
            <StyledNew isAr={i18n.language == 'ar'} alt="" src={i18n.language == 'ar' ? NewAr : New} />
            <div className="d-flex flex-row flex-nowrap align-items-stretch fs-10">
                <div className="d-flex justify-content-center h-100 mx-1 mt-1" style={{ paddingTop: '2px' }}>
                    <img src={require('./info-icon.svg').default} alt="" width={13} height={13} />
                </div>
                <div className={`${i18n.language === 'ar' ? 'text-right' : 'text-left'} flex-grow-1`}>
                    <p className="m-0 mb-2 pb-2 border-bottom fs-12px">
                        <Trans i18nKey="Now, Ship with our TrueWeight">
                            <span className="text-blue-color" />
                            <span className="color-green" />
                        </Trans>
                    </p>
                    <p className="m-0 mb-2 font-weight-light fs-10px">{t('Now pay as per Actual Weight')}</p>
                    <p
                        className={`m-0 px-2 rounded bg-grey-1-alpha font-weight-light fs-10px ${
                            i18n.language === 'ar' ? 'ml-2' : 'mr-2'
                        }`}
                    >
                        {t(
                            'This would exclude packages with linear dimensions (Length+Width+Depth) exceeding 30 inches or 60 cm',
                        )}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default TrueWeight
