import React from 'react'
import twoSvg from './two.svg'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import { MEMBERSHIP_PLANS } from '../../constants/navigationConstants'

function StepTwo() {
    const { t, i18n } = useTranslation()
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

    return (
        <div className="main-section-container">
            <div className="container-fluid container-width">
                <div className="row justify-content-between justify-content-around-under-768">
                    {isMobile && (
                        <div className="col-12 col-sm-9 col-md-5 flex-grow-1 mb-4 align-self-center text-center">
                            <img className="width-100 big-section-img" src={twoSvg} alt={''} />
                        </div>
                    )}

                    <div className="col-12 col-sm-9 col-md-5 flex-grow-1 mb-4 post-450-start-text-align align-self-center">
                        <h2 className="sub-page-section-header mb-3">{t('Multi shipping addresses')}</h2>
                        <p className="mb-3 text-grey">{t('Multi shipping addresses description')}</p>
                        <Link to={MEMBERSHIP_PLANS}>
                            <button type="button" id="get-us-address" className="btn btn-primary w-100">
                                {t('Get your free tax address button')}
                            </button>
                        </Link>
                    </div>
                    {!isMobile && (
                        <div className="col-12 col-sm-9 col-md-5 flex-grow-1 mb-4 align-self-center text-center">
                            <img className="width-100 big-section-img" src={twoSvg} alt={''} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default StepTwo
